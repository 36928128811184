import React, { useEffect, useState } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import * as routerActions from 'react-router-redux'
import { first, orderBy, sumBy } from 'lodash'
import { Spinner, Input } from '@launchpadlab/lp-components'
import { modifyProps } from '@launchpadlab/lp-hoc'
import { lpForm } from '@launchpadlab/lp-form'
import ReactGA from 'react-ga4'
import { scroller } from 'react-scroll'
import {
  TicketPrice,
  TicketPriceMassInput,
  BookingDetails,
  OrderReceipt,
  TicketTimeRadioInput,
} from '../components'
import {
  calculateFreeChaperoneAmount,
  extractSoldOutDates,
  FREE_CHAPERONE_FLAG,
  flashErrorMessage,
  keys,
} from 'utils'
import * as actions from '../actions'
import * as effects from 'main/effects'
import { selectors } from '../reducer'
import { selectors as userSelectors } from '../../user/reducer'
import SocietyCheckoutLayout from '../../../layout/SocietyCheckoutLayout'
import { TICKETING_CHECKOUT_STEPS } from '../../types'
import SocietyHeading from '../../../layout/SocietyHeading'
import getTicketingRedirectUrl from '../../../utils/getTicketingRedirectUrl'
import TicketAttendees from '../components/TicketAttendees'
import useModal from '../../../hooks/useModal'
import { DateInput } from '../../../components/Form'
import TicketActivityTimesListener from '../components/TicketActivityTimesListener'
import TicketActivitiesListener from '../components/TicketActivitiesListener'
import TicketCartListener from '../components/TicketCartListener'
import TicketTimeListener from '../components/TicketTimeListener'
import TicketsRemaining from '../components/TicketsRemaining'
import RenderedHtml from '../../../components/RenderedHtml'

import { Field, formValueSelector, isInvalid } from 'redux-form'

const ticketActivityForm = 'ticket-activity'

function TicketActivityDetails({
  addTicketLineItem,
  removeTicketLineItem,
  flashErrorMessage,
  massUpdateTicketLineItemPrices,
  disableTicketPriceActions,
  setDisableTicketPriceActions,
  goToAddOns,
  goToCartPreview,
  ticketActivity,
  ticketTimes,
  ticketDates,
  ticketCart,
  setTicketCart,
  location: {
    query: {
      cartToken,
      backPath,
      activityId,
      backUrl: backUrlOverride,
      parentActivityId,
    },
  },
  replace,
  isAuthenticated,
  email,
  emailIsInvalid,
  router,
}) {
  const [ticketTimeId, setTicketTimeId] = useState()
  const [filterTicketDates, setfilterTicketDates] = useState([])
  const [ticketDate = first(filterTicketDates), setTicketDate] = useState()

  useEffect(() => {
    const filterDates = parentActivityId
      ? ticketDates?.filter((date) => {
          return ticketCart.lineItems.some(
            (item) =>
              String(item.productId) === String(parentActivityId) &&
              date === item.startDate
          )
        })
      : ticketDates
    setfilterTicketDates(filterDates)
  }, [ticketDates, ticketCart])

  const soldOutDates = extractSoldOutDates(ticketTimes)

  const displayedTicketTimes = ticketTimes
    ? ticketTimes.filter((t) => t.startDate === ticketDate && !t.soldOut)
    : undefined

  const ticketTime = ticketTimeId
    ? displayedTicketTimes.find((t) => t.id === ticketTimeId)
    : first(displayedTicketTimes)

  const isFieldTrip = ticketActivity && ticketActivity.fieldTrip

  useEffect(() => {
    ReactGA.event('view_item', {
      currency: 'USD',
      value: ticketCart?.total,
      items: ticketCart?.lineItems?.map((cartItem) => ({
        item_id: cartItem.id,
        item_name: cartItem.displayName,
        price: Number(
          cartItem.lineItemPrices
            ?.reduce((total, item) => total + item.price, 0)
            .toFixed(2)
        ),
        item_category: cartItem.productType,
        quantity: cartItem.lineItemPrices.length,
      })),
    })
  }, [ticketCart])

  useEffect(() => {
    if (ticketActivity?.categoryIsCampOrClass) {
      router.push({
        pathname: '/ticketing/camps',
        query: {
          cartToken: cartToken || ticketCart.token,
          categoryId: ticketActivity.categoryId,
        },
      })
    }
  }, [ticketActivity])

  useEffect(() => {
    if (!ticketActivity || !ticketActivity.requireLogin || isAuthenticated)
      return

    const encodedRedirectUrl = encodeURIComponent(
      getTicketingRedirectUrl({
        activityId: ticketActivity.id,
        cartToken: ticketCart.token,
      })
    )

    replace(`/sign-in?redirectUrl=${encodedRedirectUrl}`)
  }, [ticketActivity])

  useEffect(() => {
    if (!ticketCart || !ticketActivity) return
    if (!isFieldTrip) return
    if (ticketCart.schoolId) return

    replace(
      `/ticketing/schools?activityId=${ticketActivity.id}&cartToken=${ticketCart.token}`
    )
  }, [ticketCart, ticketActivity])

  const [interactingWithMassInput, setInteractingWithMassInput] =
    useState(false)

  // Free Chaperone logic:
  // Anytime there's any ticket purchase (therefore the ticketCart changes),
  // we compute the free chaperone quantity for that particulat activity time
  // and then we add these tickets to the cart using handleMassUpdateTicketLineItemPrice
  // We only purchase free chaperone tickets when the quantity for free chaperones changes,
  // otherwise we would run into an infinite loop
  // Free chaperone count resets when switching to another time/date.
  const [freeChaperoneQuantity, setFreeChaperoneQuantity] = useState(undefined)
  useEffect(() => {
    setFreeChaperoneQuantity(undefined)
  }, [ticketTime])

  useEffect(() => {
    if (!ticketActivity || !ticketActivity.largeGroup) return

    if (ticketTimes.length && ticketTime && ticketCart) {
      const newFreeChaperoneQuantity = calculateFreeChaperoneAmount(
        ticketCart,
        ticketTimes,
        ticketTime
      )
      setFreeChaperoneQuantity(newFreeChaperoneQuantity)
    }
  }, [ticketCart, ticketTimes, ticketTime, ticketActivity])

  useEffect(() => {
    const updateFreeChaperones = (quantity) => {
      const freeChaperoneTicketPrice = ticketTime.prices.find(
        (tp) => tp.flag === FREE_CHAPERONE_FLAG
      )
      // call a ticket mass update for free chaperones if there's a free chaperone price
      if (freeChaperoneTicketPrice) {
        handleMassUpdateTicketLineItemPrice(freeChaperoneTicketPrice, quantity)
      }
    }

    if (isNaN(freeChaperoneQuantity)) return
    updateFreeChaperones(freeChaperoneQuantity)
  }, [freeChaperoneQuantity])

  const currentCartLineItem =
    ticketCart &&
    ticketTime &&
    ticketCart.lineItems &&
    ticketCart.lineItems.find(
      (lin) =>
        lin.productId === ticketActivity.id && lin.eventId === ticketTime.id
    )

  const currentBookingUdfs =
    (currentCartLineItem && currentCartLineItem.bookingUdfs) || []

  const canSelectTickets =
    displayedTicketTimes && displayedTicketTimes.some((tt) => !tt.soldOut)

  const currentCartLineItemTicketQuantity = currentCartLineItem
    ? currentCartLineItem.lineItemPrices.filter((lip) => lip.affectsCapacity)
        .length
    : 0

  const contentInTicketCart =
    ticketCart && currentCartLineItemTicketQuantity > 0
  const currentTicketActivityTimeVacancy = ticketTime && ticketTime.vacancy

  const numTicketsLeftToSelect =
    currentTicketActivityTimeVacancy &&
    currentTicketActivityTimeVacancy - currentCartLineItemTicketQuantity

  const canSelectMoreTickets = numTicketsLeftToSelect > 0

  const currentBookingUdfsPresent = currentBookingUdfs.some(
    (udf) => udf.value.length
  )

  const backUrl = backUrlOverride
    ? backUrlOverride
    : isFieldTrip
      ? `/ticketing/schools?activityId=${ticketActivity.id}&cartToken=${ticketCart.token}`
      : backPath && ticketActivity
        ? `/ticketing/${backPath}?categoryId=${ticketActivity.categoryId}`
        : '/ticketing'

  const defaultAddTicketLineItem = (props = {}) => {
    const lineItemParams = {
      ticketCartToken: ticketCart.token,
      ticketProductId: ticketActivity.id,
      ticketProductType: ticketActivity.productType,
      eventId: ticketTime.id,
      eventName: ticketTime.displayName,
      startDatetime: ticketTime.startDatetime,
      endDatetime: ticketTime.endDatetime,
      baseFeeId: ticketTime.baseFeeId,
      baseFeePrice: ticketTime.baseFeePrice,
      baseFeeCostRateId: ticketTime.baseFeeCostRateId,
      ...props,
    }
    return addTicketLineItem(lineItemParams)
  }
  const handleMassUpdateTicketLineItemPrice = (ticketPrice, quantity) => {
    // if the user has reduced the ticketPrice quantity to 0
    // return if there is no cartLineItem
    if (quantity === 0 && !currentCartLineItem) {
      setInteractingWithMassInput(false)
      return
    }
    // see if the current ticketPrice being removed and is the only one left in the current lineItem
    // if so, destroy the lineItem entirely and return
    // if not, continue on with line item price update
    const finalLineItemPriceIsBlank =
      quantity === 0 &&
      !currentCartLineItem.lineItemPrices.find(
        (lip) => lip.priceId !== ticketPrice.id
      )

    if (finalLineItemPriceIsBlank) {
      setDisableTicketPriceActions(true)
      setInteractingWithMassInput(false)
      removeTicketLineItem({
        ticketCartToken: ticketCart.token,
        ticketLineItemId: currentCartLineItem.id,
      })
        .then((resp) => {
          setTicketCart({ success: resp })
        })
        .catch(() => {
          flashErrorMessage('Something went wrong. Please try again later.')
        })
        .finally(() => setDisableTicketPriceActions(false))
    } else {
      const massUpdateTicketLineItemFn = (ticketLineItemId) => {
        setDisableTicketPriceActions(true)
        setInteractingWithMassInput(false)
        return massUpdateTicketLineItemPrices({
          ticketCartToken: ticketCart.token,
          ticketLineItemId,
          ticketPriceId: ticketPrice.id,
          quantity,
          costRateId: ticketPrice.costRateId,
          price: ticketPrice.price,
        })
          .then((resp) => {
            setTicketCart({ success: resp })
          })
          .catch(() => {
            flashErrorMessage('Something went wrong. Please try again later.')
          })
          .finally(() => setDisableTicketPriceActions(false))
      }
      if (currentCartLineItem) {
        massUpdateTicketLineItemFn(currentCartLineItem.id)
      } else {
        defaultAddTicketLineItem({
          parentLineItemId: null,
        }).then((resp) => {
          massUpdateTicketLineItemFn(resp.data.attributes.id)
        })
      }
    }
  }

  const bookingDetailsModal = useModal()

  const continueToAddOns = async () => {
    await effects.updateTicketCart(ticketCart.token, { email })

    if (!ticketActivity.mainPath && parentActivityId) {
      router.push({
        pathname: '/ticketing/add-ons',
        query: {
          activityId: parentActivityId,
          cartToken: ticketCart.token,
          lineItemId: ticketCart.lineItems[0].id,
        },
      })
      return
    }

    if ((ticketActivity.bookingUdfs.length > 0) & !currentBookingUdfsPresent) {
      bookingDetailsModal.open()
      return
    }

    if (disableTicketPriceActions || interactingWithMassInput) {
      return
    }

    const freeChaperoneTicketPrice = ticketTime.prices.find(
      (tp) => tp.flag === FREE_CHAPERONE_FLAG
    )
    // This code protects against the edge case for Field Trips where the first
    // API call to save student tickets works, but the second API call
    // to save chaperones fails:
    if (freeChaperoneTicketPrice && currentCartLineItem) {
      const currentFreeChaperoneTicketQuantity =
        currentCartLineItem.lineItemPrices.filter(
          (lip) => lip.priceId == freeChaperoneTicketPrice.id
        ).length
      // freeChaperoneQuantity is the amount of freeChaperone tickets we should have, based on paid tickets for
      // Field Trips, while currentFreeChaperoneTicketQuantity
      //  is the amount that we do have right now
      if (currentFreeChaperoneTicketQuantity != freeChaperoneQuantity) {
        return flashErrorMessage(
          'The number of chaperones is not adequate for the number of students selected. Please re-enter the number of students for the field trip and we will re-calculate the chaperones.'
        )
      }
    }
    if (!contentInTicketCart)
      return flashErrorMessage('Please select at least one ticket')
    if (
      (ticketActivity.maxTickets || ticketActivity.maxTickets === 0) &&
      currentCartLineItemTicketQuantity > ticketActivity.maxTickets
    )
      return flashErrorMessage(
        `Max ticket quantity (${ticketActivity.maxTickets}) exceeded. Please edit selections to continue.`
      )
    if (currentCartLineItem) {
      goToAddOns(currentCartLineItem.id, ticketDate)
    } else {
      ticketCart.lineItems.length === 1
        ? goToAddOns(ticketCart.lineItems[0].id, ticketDate)
        : goToCartPreview()
    }
  }

  const schoolCopy =
    isFieldTrip &&
    ticketCart &&
    ticketCart.schoolName &&
    ` The school you are selecting tickets for is ${ticketCart.schoolName}.`

  const isContinueDisabled =
    emailIsInvalid ||
    !contentInTicketCart ||
    ((!canSelectTickets || !canSelectMoreTickets) && !contentInTicketCart) ||
    disableTicketPriceActions ||
    interactingWithMassInput

  return (
    <SocietyCheckoutLayout
      progress={{ steps: TICKETING_CHECKOUT_STEPS, currentStep: 1 }}
      title="Ticket Details"
      backUrl={backUrl}
      summary={<OrderReceipt />}
      onContinue={continueToAddOns}
      continueDisabled={isContinueDisabled}
    >
      <TicketActivityTimesListener id={activityId} />

      <TicketTimeListener
        activityId={activityId}
        ticketTime={ticketTime}
        ticketTimes={ticketTimes}
      />
      <TicketActivitiesListener />
      <TicketCartListener />

      {!ticketActivity ? (
        <Spinner />
      ) : filterTicketDates?.length === 0 ? (
        <p>Sorry! There are no tickets available for the chosen activity</p>
      ) : (
        <>
          <p>
            You have chosen <strong>{ticketActivity.displayName}</strong>.
            {schoolCopy} Please complete your ticket details below.
          </p>

          {!isAuthenticated && (
            <form>
              <p>
                A valid email is required to continue and to access your tickets
                and order summary.
              </p>
              <Field name="email" component={Input} label="Email" />
            </form>
          )}

          <SocietyHeading>Select Date</SocietyHeading>
          {ticketActivity.eventInformation && (
            <p>
              <RenderedHtml>{ticketActivity.eventInformation}</RenderedHtml>
            </p>
          )}

          <DateInput
            value={ticketDate}
            dateFormat="EEEE, MMMM dd, yyyy"
            includeDates={filterTicketDates}
            onChange={(date) => {
              setTicketDate(date)
              setTicketTimeId(undefined)
            }}
            highlightDates={[{ 'line-through': soldOutDates }]}
          />

          {/* Allow time selection if there are multiple ticket times OR if the event is not an all day event */}
          {!displayedTicketTimes ? (
            <Spinner />
          ) : displayedTicketTimes.length === 0 ? (
            <p>
              Sorry! There are no tickets available for the chosen date and/or
              time.
            </p>
          ) : (
            displayedTicketTimes.length >= 1 &&
            !ticketActivity.allDay && (
              <div>
                <SocietyHeading>Select Time</SocietyHeading>
                <p>
                  Times that are crossed out are no longer available for
                  purchase online.
                </p>

                <TicketTimeRadioInput
                  times={orderBy(displayedTicketTimes, 'startTime')}
                  value={ticketTime}
                  onChange={({ id, startDate }) => {
                    setTicketTimeId(id)
                    setTicketDate(startDate)
                  }}
                />
              </div>
            )
          )}

          <BookingDetails
            ticketCart={ticketCart}
            ticketActivity={ticketActivity}
            ticketActivityTime={ticketTime}
            lineItem={currentCartLineItem}
            modal={bookingDetailsModal}
          />

          {canSelectTickets && ticketTime && (
            <section>
              <SocietyHeading>
                Select Ticket Amount
                <TicketsRemaining
                  activityId={ticketActivity.id}
                  numTicketsLeftToSelect={numTicketsLeftToSelect}
                />
              </SocietyHeading>

              {ticketTime.prices.length > 0 ? (
                <ol className="space-y-5">
                  {ticketTime.prices.map((tp) => {
                    const existingTicketLineItemPrices =
                      currentCartLineItem &&
                      currentCartLineItem.lineItemPrices.filter(
                        (lip) =>
                          lip.priceId === tp.id ||
                          lip.priceId == tp.alternatePriceId
                      )

                    // if ticket activity is a large group
                    // return the mass ticket price editor rather than standard plus / minus UX
                    if (ticketActivity.largeGroup) {
                      return (
                        <TicketPriceMassInput
                          key={tp.id}
                          ticketPrice={tp}
                          updateTicketLineItemPrice={
                            handleMassUpdateTicketLineItemPrice
                          }
                          disableEdit={disableTicketPriceActions}
                          quantity={
                            existingTicketLineItemPrices
                              ? existingTicketLineItemPrices.length
                              : 0
                          }
                          setInteractingWithMassInput={
                            setInteractingWithMassInput
                          }
                        />
                      )
                    }

                    const lineItem = ticketCart.lineItems
                      ? ticketCart.lineItems.find(
                          ({ productId, eventId }) =>
                            productId === ticketTime.activityId &&
                            eventId === ticketTime.id
                        )
                      : undefined

                    const lineItemPrices =
                      lineItem && lineItem.lineItemPrices
                        ? lineItem.lineItemPrices.filter(
                            ({ priceId }) => priceId === tp.id
                          )
                        : undefined

                    const quantity = sumBy(lineItemPrices, 'quantity')

                    return (
                      <li key={tp.id} className="mb-0 space-y-5">
                        <TicketPrice
                          ticketPrice={tp}
                          ticketActivity={ticketActivity}
                          ticketActivityTime={ticketTime}
                          lineItem={lineItem}
                          quantity={quantity}
                          disableAdd={
                            disableTicketPriceActions ||
                            (tp.affectsCapacity && !canSelectMoreTickets)
                          }
                          disableRemove={disableTicketPriceActions}
                        />
                        {tp.collectAttendeeDetails && (
                          <TicketAttendees
                            ticketActivity={ticketActivity}
                            lineItemPrices={lineItemPrices}
                            lineItem={lineItem}
                          />
                        )}
                      </li>
                    )
                  })}
                </ol>
              ) : (
                <Spinner className="margin-bottom" />
              )}

              <p className="my-4 italic">
                <RenderedHtml>{ticketActivity.ticketRestrictions}</RenderedHtml>
              </p>
            </section>
          )}
        </>
      )}
    </SocietyCheckoutLayout>
  )
}

function mapStateToProps(state, { location }) {
  const { activityId } = location.query
  const activities = selectors.ticketActivities(state)
  const times = selectors.ticketTimes(state)

  if (!activities || !times) {
    return {
      ticketActivity: null,
      ticketTimes: null,
    }
  }

  const dates = times.map((t) => t.startDate)

  const activity = activities.find((ta) => ta.id === parseInt(activityId))

  return {
    ticketActivity: activity,
    ticketTimes: times,
    ticketDates: dates,
    ticketCart: selectors.ticketCart(state),
    disableTicketPriceActions: selectors.disableTicketPriceActions(state),
    isAuthenticated: userSelectors.isAuthenticated(state),
    isVerifiedMilitary: userSelectors.isVerifiedMilitary(state),
    email: formValueSelector(ticketActivityForm)(state, 'email'),
    emailIsInvalid: isInvalid(ticketActivityForm)(state),
  }
}

const mapDispatchToProps = {
  clearTicketTimes: actions.clearTicketTimes,
  setDisableTicketPriceActions: actions.setDisableTicketPriceActions,
  push: routerActions.push,
  replace: routerActions.replace,
  setTicketCart: actions.setTicketCart,
  flashErrorMessage,
}

function modify({ location: { query }, push, ticketCart, ticketActivity }) {
  return {
    constraints: {
      email: {
        presence: true,
        email: true,
        length: { maximum: 320 },
      },
    },
    initialValues: {
      email: ticketCart?.email,
    },
    addTicketLineItem: ({
      ticketCartToken,
      ticketProductType,
      ticketProductId,
      eventId,
      eventName,
      startDatetime,
      endDatetime,
      bookingUdfs,
      parentLineItemId,
      baseFeeId,
      baseFeePrice,
      baseFeeCostRateId,
    }) => {
      return effects.createOrUpdateTicketLineItem({
        ticketCartToken,
        productId: ticketProductId,
        productType: ticketProductType,
        eventId,
        eventName,
        startDatetime,
        endDatetime,
        bookingUdfs,
        parentLineItemId,
        baseFeeId,
        baseFeePrice,
        baseFeeCostRateId,
      })
    },
    removeTicketLineItem: ({ ticketCartToken, ticketLineItemId }) =>
      effects.destroyTicketLineItem(ticketCartToken, ticketLineItemId),
    massUpdateTicketLineItemPrices: ({
      ticketCartToken,
      ticketLineItemId,
      ticketPriceId,
      quantity,
      costRateId,
      price,
    }) => {
      return effects.massUpdateTicketLineItemPrices({
        ticketCartToken,
        ticketLineItemId,
        ticketPriceId,
        quantity,
        costRateId,
        price,
      })
    },
    goToAddOns: (lineItemId) => {
      return push(
        `/ticketing/add-ons?activityId=${ticketActivity.id}&cartToken=${ticketCart.token}&lineItemId=${lineItemId}`
      )
    },
    goToCartPreview: () => {
      return push(
        `/ticketing/cart?cartToken=${ticketCart.token}&activityId=${query.activityId}`
      )
    },
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  modifyProps(modify),
  lpForm({
    name: ticketActivityForm,
    onSubmitFail: (params, dispatch, _, { syncErrors }) => {
      const firstErrorField = first(keys(syncErrors))
      return scroller.scrollTo(firstErrorField, {
        smooth: true,
      })
    },
    enableReinitialize: true,
  })
)(TicketActivityDetails)
