import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'main/types'
import { compose, withProps, withState } from 'recompose'
import { Input, Select } from '@launchpadlab/lp-components'
import { removeNonAsciiChars } from 'utils'
import { isNumber, noop } from 'lodash'
import { get } from 'lodash/fp'
import { NEW_MEMBER_ID } from 'config'
import { onUpdate } from '@launchpadlab/lp-hoc'
import LinkButton from '../../../components/ui/LinkButton'

const propTypes = {
  allowRemove: PropTypes.bool,
  editing: PropTypes.bool.isRequired,
  legend: PropTypes.string.isRequired,
  members: PropTypes.arrayOf(Types.member).isRequired,
  names: PropTypes.arrayOf(PropTypes.string).isRequired,
  options: PropTypes.arrayOf(PropTypes.object),
  isRequired: PropTypes.bool,
  removeMember: PropTypes.func,
  replaceMember: PropTypes.func,
  showDropdown: PropTypes.bool.isRequired,
  saveMember: PropTypes.func,
  selectedMember: PropTypes.object.isRequired,
  setEditing: PropTypes.func.isRequired,
  setSelectedMember: PropTypes.func.isRequired,
  setShowDropdown: PropTypes.func.isRequired,
}

const defaultProps = {
  allowRemove: false,
  options: [],
  isRequired: false,
  removeMember: noop,
  replaceMember: noop,
  saveMember: noop,
}

// Helper component to avoid submitting
const Button = ({ children, ...rest }) => (
  <button type="button" {...rest}>
    {children}
  </button>
)

// Appends optional to a label if the member is not required
const createLabel = (text, isRequired) => {
  if (isRequired) return text
  return text + ' (Optional)'
}

const normalizeName = (e) => {
  return removeNonAsciiChars(e.target.value || '')
}

// Returns a member
function EditableMemberFieldsInput({
  allowRemove,
  editing,
  legend,
  members,
  options,
  isRequired,
  removeMember,
  replaceMember,
  showDropdown,
  setShowDropdown,
  setEditing,
  setSelectedMember,
  saveMember,
  selectedMember,
  names, // eslint-disable-line
  idField,
  firstNameField,
  lastNameField,
  emailField,
}) {
  const areNamesValid = firstNameField.meta.valid && lastNameField.meta.valid

  // Choose an existing member from a dropdown menu
  if (showDropdown)
    return (
      <React.Fragment>
        <Select
          {...idField}
          label={legend}
          placeholder={createLabel('Select Member', isRequired)}
          options={options}
          onChange={(e) => {
            const id = Number(e.target.value)
            const existingMember = members.find((member) => member.id === id)

            setSelectedMember(existingMember)
            firstNameField.input.onChange(existingMember.firstName)
            lastNameField.input.onChange(existingMember.lastName)
            emailField.input.onChange(existingMember.address.email)

            saveMember(existingMember)

            return idField.input.onChange(id)
          }}
        />
        <div className="member-options-container">
          <Button
            className="add-new"
            onClick={() => {
              const currentId = idField.input.value
              const newMember = {
                id: NEW_MEMBER_ID,
                firstName: '',
                lastName: '',
                address: {email: ''},
              }

              // Adds a dummy id value so that id validation will pass for members that are required
              idField.input.onChange(newMember.id)
              firstNameField.input.onChange(newMember.firstName)
              lastNameField.input.onChange(newMember.lastName)
              emailField.input.onChange(newMember.address.email)

              replaceMember({
                id: currentId,
                member: newMember,
              })
              setShowDropdown(false)
            }}
          >
            Add New Member
          </Button>
          {/* Show edit name button if an existing member is selected */}
          {!!(idField && idField.input.value) && (
            <Button
              className="edit-existing"
              onClick={() => {
                setEditing(true)
                setShowDropdown(false)
              }}
            >
              Edit Name
            </Button>
          )}
          {allowRemove && (
            <LinkButton
              type="button"
              label="Remove"
              onClick={removeMember}
              danger
              className="absolute right-0 -top-1"
            />
          )}
        </div>
      </React.Fragment>
    )

  // Add a new member _or_ edit an existing member's first and last name
  return (
    <React.Fragment>
      <fieldset>
        <legend>{legend}</legend>
        <div className="input-container">
          <Input
            {...firstNameField}
            label={createLabel('First Name', isRequired)}
            onChange={(e) => {
              return firstNameField.input.onChange(normalizeName(e))
            }}
          />
          <Input
            {...lastNameField}
            label={createLabel('Last Name', isRequired)}
            onChange={(e) => {
              return lastNameField.input.onChange(normalizeName(e))
            }}
          />
          <Input
            {...emailField}
            label={createLabel('Email', isRequired)}
            onChange={(e) => {
              return emailField.input.onChange(normalizeName(e))
            }}
          />
        </div>
      </fieldset>
      {options.length > 0 && !editing && (
        <div className="member-options-container">
          <Button
            className="select-existing"
            onClick={() => {
              // Set id field to an empty string so that the placeholder will be selected
              idField.input.onChange('')
              setShowDropdown(true)
            }}
          >
            Select Existing Member
          </Button>
        </div>
      )}
      {editing && (
        <div className="member-options-container">
          <Button
            className="cancel-edit"
            onClick={() => {
              // Discard edits by reverting to member details saved locally
              firstNameField.input.onChange(selectedMember.firstName)
              lastNameField.input.onChange(selectedMember.lastName)
              emailField.input.onChange(selectedMember.address.email)

              setEditing(false)
              return setShowDropdown(true)
            }}
          >
            Cancel
          </Button>
          <Button
            className="edit-existing"
            onClick={() => {
              // Force a noop if the names are not valid
              if (!areNamesValid) return

              // Save member edits to cart
              const newSelectedMember = {
                ...selectedMember,
                firstName: firstNameField.input.value,
                lastName: lastNameField.input.value,
                address: {
                  ...selectedMember.address,
                  email: emailField.input.value
                }
              }

              saveMember(newSelectedMember)
              setSelectedMember(newSelectedMember)

              setEditing(false)
              return setShowDropdown(true)
            }}
            aria-disabled={!areNamesValid}
          >
            Save
          </Button>
        </div>
      )}
      {allowRemove && !editing && (
        <LinkButton
          type="button"
          label="Remove"
          onClick={removeMember}
          danger
          className="absolute right-0 -top-1"
        />
      )}
    </React.Fragment>
  )
}

EditableMemberFieldsInput.propTypes = propTypes
EditableMemberFieldsInput.defaultProps = defaultProps

// Map redux-form field specific props based on the prefix of the member form values (e.g., 'nanny' or 'secondaryMember')
const mapFieldInputs = ({ prefix, ...rest }) => {
  const {
    id: idField,
    firstName: firstNameField,
    lastName: lastNameField,
    address: {email: emailField}
  } = get(prefix, rest)

  return {
    idField,
    firstNameField,
    lastNameField,
    emailField,
  }
}

const setShowDropdownInitialValue = ({
  options,
  idField,
  firstNameField,
  lastNameField,
  emailField
}) => {
  const id = idField.input.value
  const firstName = firstNameField.input.value
  const lastName = lastNameField.input.value
  const email = emailField.input.value

  // Show if a member has already been selected; otherwise show new member
  if (isNumber(id)) return id !== NEW_MEMBER_ID

  if (firstName || lastName || email) return false

  // Default to showing _if_ there are members to choose from
  if (options && options.length) return true

  return false
}

const setSelectedMemberInitialValue = ({
  idField,
  firstNameField,
  lastNameField,
  emailField,
  members,
}) => {
  const id = idField.input.value

  if (!id || id === NEW_MEMBER_ID) return {}

  const existingMember = members.find((member) => member.id === id)
  return {
    ...existingMember,
    firstName: firstNameField.input.value,
    lastName: lastNameField.input.value,
    address: {
      ...existingMember.address,
      email: emailField.input.value,
    }
  }
}

// Set the default id value to 0 to pass required constraint validations for a new member
// All fields get registered in a <Fields /> component and thus are included in validation, even if they aren't rendered
const maybeResetMemberId = ({ showDropdown, editing, idField }) => {
  // Do not reset if a member exists, is being edited, or is already reset
  if (showDropdown || editing || idField.input.value === NEW_MEMBER_ID) return

  return idField.input.onChange(NEW_MEMBER_ID)
}

export default compose(
  withProps(mapFieldInputs),
  withState(
    'selectedMember',
    'setSelectedMember',
    setSelectedMemberInitialValue
  ), // used for discarding edits
  withState('showDropdown', 'setShowDropdown', setShowDropdownInitialValue),
  withState('editing', 'setEditing', false),
  onUpdate(maybeResetMemberId)
)(EditableMemberFieldsInput)
