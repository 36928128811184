import React from 'react'
import { Field, propTypes as formPropTypes } from 'redux-form'
import { compose } from 'redux'
import { lpForm } from '@launchpadlab/lp-form'

const propTypes = {
  ...formPropTypes,
}

const defaultProps = { mailingFee: null }

function DeliveryOptionsForm({ handleSubmit, form }) {
  return (
    // TODO: move this inline styling to whatever it might better belong... if that's anywhere
    <div className="" style={{ display: 'none' }}>
      <h2>Select Ticket Delivery</h2>
      <form className="delivery-options-form" onSubmit={handleSubmit}>
        <Field
          name="deliveryOption"
          options={['Digital']}
          aria-label="Select delivery option"
          formName={form}
          component={() => (
            <fieldset className="">
              <input
                id={`${form}.${'deliveryOption'}.${'Digital'}`}
                name={`${form}.${'deliveryOption'}`}
                type="radio"
                value={'Digital'}
                onChange={() => {}}
                checked={true}
              />
            </fieldset>
          )}
        />
      </form>
    </div>
  )
}

DeliveryOptionsForm.propTypes = propTypes
DeliveryOptionsForm.defaultProps = defaultProps

export default compose(
  lpForm({
    name: 'delivery-option',
  })
)(DeliveryOptionsForm)
