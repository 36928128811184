import React from 'react'
import { FormSection } from 'redux-form'
import { Input } from '@launchpadlab/lp-components'
import { NormalizedField as Field } from 'components'

const propTypes = {}

const defaultProps = {}

function PrimaryMemberFormSection({ primaryEmailEditable }) {
  return (
    <FormSection name="primaryMember">
      <fieldset>
        <legend>Primary Member</legend>
        <div className="flex gap-5">
          <Field name="firstName" label="First Name" component={Input} />
          <Field name="lastName" label="Last Name" component={Input} />
          <Field
            name="email"
            label="Email"
            component={Input}
            disabled={!primaryEmailEditable}
          />
        </div>
      </fieldset>
    </FormSection>
  )
}

PrimaryMemberFormSection.propTypes = propTypes
PrimaryMemberFormSection.defaultProps = defaultProps

export default PrimaryMemberFormSection
