import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'main/types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { selectors } from '../../reducer'
import * as apiActions from 'main/apiActions'
import * as effects from 'main/effects'
import * as actions from '../../actions'
import { Button, Spinner } from '@launchpadlab/lp-components'
import { FoundationProduct } from '../components'
import { orderBy, last, get } from 'lodash'
import { routerActions } from 'react-router-redux'
import classnames from 'classnames'
import ReactGA from 'react-ga4'
import { first, flashErrorMessage } from 'utils'
const propTypes = {
  fetchOrCreateFoundationCart: PropTypes.func.isRequired,
  foundationCart: Types.foundationCart,
  setFoundationCart: PropTypes.func.isRequired,
  fetchFoundationProductCategories: PropTypes.func.isRequired,
  foundationProductCategories: PropTypes.arrayOf(
    Types.foundationProductCategory
  ),
  location: PropTypes.object.isRequired,
  push: PropTypes.func.isRequired,
  flashErrorMessage: PropTypes.func.isRequired,
}

const defaultProps = {
  foundationProductCategories: [],
  foundationCart: null,
}

function SelectProduct({
  fetchOrCreateFoundationCart,
  fetchFoundationProductCategories,
  foundationCart,
  setFoundationCart,
  foundationProductCategories,
  flashErrorMessage,
  location: {
    query: { categoryId, cartToken },
  },
  push,
}) {
  const foundationProductCategory = foundationProductCategories.find(
    (category) => category.id == categoryId
  )
  const foundationProducts = orderBy(
    get(foundationProductCategory, 'foundationProducts'),
    'position'
  ).filter((item) => item.active)

  const enableContinueButton =
    foundationProductCategory &&
    foundationCart &&
    foundationCart.foundationLineItems.filter(
      (li) => li.foundationProductCategoryId == foundationProductCategory.id
    ).length > 0
  useEffect(() => {
    fetchOrCreateFoundationCart(cartToken)
  }, [cartToken])

  useEffect(() => {
    ReactGA.event('view_item', {
      currency: 'USD',
      value: foundationCart?.total,
      item_name: foundationProductCategory?.name,
      items: foundationCart?.foundationLineItems?.map((cartItem) => ({
        item_id: cartItem.id,
        price: cartItem.amount,
        quantity: 1,
        item_category: 'foundation_donation',
        item_category2: cartItem.donationType,
      })),
    })
  }, [foundationProductCategory])

  useEffect(() => {
    if (foundationProductCategory) return
    fetchFoundationProductCategories()
  }, [foundationProductCategory])
  if (!foundationCart || !foundationProducts || foundationProducts.length == 0)
    return <Spinner />

  const isAdoptAnAnimal =
    foundationProductCategory.donationType == 'adopt_an_animal'
  const handleAddFoundationProduct = ({
    foundationProduct,
    foundationCart,
  }) => {
    const foundationCartToken = foundationCart && foundationCart.token
    return effects
      .createFoundationLineItem({
        foundationProductId: foundationProduct.sfid,
        amount: foundationProduct.amount,
        cartToken: foundationCartToken,
      })
      .then((resp) => {
        ReactGA.event('add_to_cart', {
          currency: 'USD',
          value: foundationProduct.amount,
          items: [
            {
              item_id: foundationProduct.id,
              item_name: foundationProduct.name,
              item_category: 'foundation_donation',
              price: foundationProduct.amount,
              quantity: 1,
            },
          ],
        })

        return setFoundationCart({ success: resp })
      })
      .catch(() => {
        flashErrorMessage('Something went wrong. Please try again later.', {
          style: 'foundation',
        })
      })
  }

  const handleRemoveFoundationProduct = ({
    foundationProduct,
    foundationCart,
  }) => {
    const foundationCartToken = get(foundationCart, 'token')
    const foundationLineItem = last(
      orderBy(
        foundationCart.foundationLineItems.filter(
          (li) => li.foundationProductId == foundationProduct.sfid,
          'id'
        )
      )
    )
    const foundationLineItemId = get(foundationLineItem, 'id')
    if (!foundationLineItemId) {
      return null
    }

    return effects
      .destroyFoundationLineItem(foundationLineItemId, foundationCartToken)
      .then((resp) => {
        ReactGA.event('remove_from_cart', {
          currency: 'USD',
          value: foundationProduct.amount,
          items: [
            {
              item_id: foundationProduct.id,
              item_name: foundationProduct.name,
              item_category: 'foundation_donation',
              price: foundationProduct.amount,
              quantity: 1,
            },
          ],
        })
        return setFoundationCart({ success: resp })
      })
      .catch(() => {
        flashErrorMessage('Something went wrong. Please try again later.', {
          style: 'foundation',
        })
      })
  }

  const continueToRecipientInfo = (
    foundationProductCategory,
    foundationCart,
    push
  ) => {
    const firstLineItem = first(
      orderBy(
        foundationCart.foundationLineItems.filter(
          (li) => li.foundationProductCategoryId == foundationProductCategory.id
        ),
        ['position', 'id']
      )
    )
    return push(
      `/give/recipient-information?categoryId=${foundationProductCategory.id}&lineItemId=${firstLineItem.id}&cartToken=${foundationCart.token}`
    )
  }
  return (
    <div className="background">
      <div className="selection-group grid gap-10 auto-cols-fr auto-rows-fr grid-cols-1 md:grid-cols-2 ">
        <div className="max-w-lg">
          <img alt="" src={foundationProductCategory.largeImage.url} />
        </div>
        <div className="select-package max-w-lg">
          <h1 className="select-package__name">
            {foundationProductCategory.name}
          </h1>

          {isAdoptAnAnimal && (
            <div className="select-package__product-sub-description">
              <p className="select-package__product-sub-description--status">
                <span>Status:</span> {foundationProductCategory.animalStatus}
              </p>
              <p className="select-package__product-sub-description--location">
                <span>Zoo Location:</span>{' '}
                {foundationProductCategory.animalZooLocation}
              </p>
            </div>
          )}

          <div className="select-package__description">
            <p>{foundationProductCategory.showpageDescription}</p>
          </div>

          <div>
            {foundationProducts.map((foundationProduct) => {
              const packageQuantity = foundationCart.foundationLineItems.filter(
                (li) => li.foundationProductId == foundationProduct.sfid
              ).length
              return (
                <FoundationProduct
                  key={foundationProduct.sfid}
                  foundationProduct={foundationProduct}
                  foundationCart={foundationCart}
                  addFoundationProduct={handleAddFoundationProduct}
                  removeFoundationProduct={handleRemoveFoundationProduct}
                  quantity={packageQuantity}
                />
              )
            })}
          </div>
          <div className="select-package__button-container">
            <Button
              className={classnames(
                'foundation-button-primary',
                'button-next-step',
                {
                  'is-disabled': !enableContinueButton,
                }
              )}
              disabled={!enableContinueButton}
              onClick={() =>
                continueToRecipientInfo(
                  foundationProductCategory,
                  foundationCart,
                  push
                )
              }
            >
              Continue
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

SelectProduct.propTypes = propTypes
SelectProduct.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    foundationCart: selectors.foundationCart(state),
    foundationProductCategories: selectors.foundationProductCategories(state),
  }
}

const mapDispatchToProps = {
  fetchOrCreateFoundationCart: apiActions.fetchOrCreateFoundationCart,
  fetchFoundationProductCategories: apiActions.fetchFoundationProductCategories,
  setFoundationCart: actions.setFoundationCart,
  push: routerActions.push,
  flashErrorMessage,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  SelectProduct
)
