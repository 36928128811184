import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { Link } from 'react-router'

const propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  whiteBg: PropTypes.bool.isRequired,
}

const defaultProps = {}

function LandingOption({ title, description, buttonText, link, whiteBg }) {
  return (
    <div
      className={classnames('title__description-container', {
        'title__description-container--light': !whiteBg,
        'title__description-container--white': whiteBg,
      })}
    >
      <div className="title__description-container__description">
        <div className="title__description-container__description__section title__description-container__description__section--title">
          {title}
        </div>
        <div className="title__description-container__description__section">
          <p>{description}</p>
          <Link to={link} className="foundation-button-primary">
            {buttonText}
          </Link>
        </div>
      </div>
    </div>
  )
}

LandingOption.propTypes = propTypes
LandingOption.defaultProps = defaultProps

export default LandingOption
