import React, { useState } from 'react'
import Form, { FormConstraints, useForm } from '../../../../components/Form'
import Button from '../../../../components/ui/Button'
import SocietyHeading from '../../../../layout/SocietyHeading'
import { connect } from 'react-redux'
import SocietyPageLayout from '../../../../layout/SocietyPageLayout'
import { flashErrorMessage } from 'utils'
import { captureException } from '@sentry/react'
import Users from '../../../ticketing/utils/users'
import { compose } from 'redux'
import { withRouter } from 'react-router'

const MEMBER_FORM_FIELDS = {
  memberNumber: {
    label: 'Card Number',
    placeholder: 'Membership Card Number or Member ID',
    constraints: [FormConstraints.required],
  },

  lastName: {
    label: 'Last Name',
    placeholder: 'The last name of the primary member',
    constraints: [FormConstraints.required],
  },
}

const VerifyMembership = compose(
  connect(),
  withRouter
)(({
  dispatch,
  router,
  email,
  onMembershipVerified,
  onMemberUserExists,
  onCustomerNotMember,
  redirectUrl,
  centamanCustomerId,
}) => {
  const form = useForm(MEMBER_FORM_FIELDS, {})
  const [errorMessage, setErrorerrorMessage] = useState(null)

  async function handleSubmit() {
    const { memberNumber, lastName } = form.values

    if (form.validate()) {
      try {
        await Users.verifyCentamanMemberRecord({
          memberNumber,
          lastName,
        })

        onMembershipVerified({
          memberNumber,
          membershipLastName: lastName,
        })
      } catch (err) {
        if (err.status === 422) {
          onMemberUserExists(err.response.errors.email)
        } else if (err.status < 500) {
          setErrorerrorMessage(
            'Could not find a membership associated with that number and last name. Please review and try again.'
          )
        } else {
          captureException(err)
          dispatch(
            flashErrorMessage(
              'Something went wrong. Please review and try again'
            )
          )
        }
      }
    }
  }

  function handleSkip() {
    if (centamanCustomerId) {
      onCustomerNotMember()
    } else {
      router.push({
        pathname: '/sign-up',
        query: {
          email: email,
          redirectUrl: redirectUrl,
        },
      })
    }
  }

  return (
    <SocietyPageLayout title="Are you a Member?">
      <section className="p-5 mx-auto space-y-10 bg-white shadow pb-7 sm:w-2/3">
        <SocietyHeading>Connect Membership</SocietyHeading>
        <Form {...form} onSubmit={handleSubmit} />
        <footer className="relative pb-3">
          <div className="flex gap-5">
            <Button
              type="primary"
              label="Verify Membership"
              size="xl"
              width="full"
              casing="uppercase"
              onClick={handleSubmit}
            />
            <Button
              type="primary-outline"
              label="I'm not a member"
              size="xl"
              width="full"
              casing="uppercase"
              onClick={handleSkip}
            />
          </div>
          {errorMessage && (
            <div className="absolute inset-x-0 text-center top-full text-danger">
              {errorMessage}
            </div>
          )}
        </footer>
      </section>
    </SocietyPageLayout>
  )
})

export default VerifyMembership
