import React from 'react'
import { connect } from 'react-redux'
import { selectors } from '../reducer'
import * as effects from 'main/effects'
import * as actions from '../actions'
import SocietyOrderSummary from '../../../layout/SocietyOrderSummary'
import { compact, groupBy, map, sumBy } from 'lodash'
import { formatDate, flashErrorMessage } from 'utils'
function OrderReceipt({
  ticketCart,
  setTicketCart,
  flashErrorMessage,
  order,
  readOnly,
  className,
}) {
  const handleRemoveLineItem = (lineItemId) => {
    effects
      .destroyTicketLineItem(ticketCart.token, lineItemId)
      .then((resp) => setTicketCart({ success: resp }))
      .catch(() =>
        flashErrorMessage('Something went wrong. Please try again later.')
      )
  }

  const {
    partialPayment,
    depositTotal,
    total,
    donationAmount,
    mailingFee,
    subtotal,
    taxes,
    coupon,
    totalGiftCard,
    discounts,
  } = ticketCart || {}

  const items =
    ticketCart && ticketCart.lineItems
      ? ticketCart.lineItems.map(
          ({
            eventName,
            startDate,
            displayStartTime,
            lineItemPrices,
            id,
            productId,
            addOnLineItems,
          }) => {
            const prices = map(
              groupBy(lineItemPrices, 'priceId'),
              (priceGroup) => {
                const price = priceGroup[0]

                const isDueAtCheckIn =
                  partialPayment && price.total !== price.depositTotal

                return {
                  id: price.id,
                  label: price.displayName,
                  value: price.price,
                  subtotal: sumBy(priceGroup, 'subtotal'),
                  quantity: sumBy(priceGroup, 'quantity'),
                  note: isDueAtCheckIn && 'Due at time of check-in at the Zoo',
                }
              }
            )

            const addOns = addOnLineItems.map(
              ({ id, lineItemPrices, productCategoryId, productId }) => {
                const prices = map(
                  groupBy(lineItemPrices, 'priceId'),
                  (priceGroup) => {
                    const price = priceGroup[0]
                    return {
                      id: price.id,
                      label: price.displayName,
                      value: price.price,
                      subtotal: price.subtotal,
                      quantity: priceGroup.length,
                      date: displayStartTime,
                    }
                  }
                )

                return {
                  id,
                  prices,
                  editUrl: `/ticketing/add-ons-activity?activityId=${productId}&lineItemId=${id}&editLineItemId=${id}&cartToken=${ticketCart.token}&addOnCategoryId=${productCategoryId}`,
                }
              }
            )

            return {
              id,
              label: eventName,
              date: `${formatDate(startDate)} - ${displayStartTime}`,
              prices,
              addOns,
              editUrl: `/ticketing/details?activityId=${productId}&cartToken=${ticketCart.token}&editLineItemId=${id}`,
              subtotal:
                sumBy(prices, 'subtotal') +
                sumBy(addOns, ({ prices }) => sumBy(prices, 'subtotal')),
            }
          }
        )
      : []

  const summary = compact([
    donationAmount && {
      label: 'Donation',
      amount: donationAmount,
    },
    mailingFee && {
      label: 'Mailing Fee',
      amount: mailingFee,
    },
    {
      label: 'Subtotal',
      amount: subtotal + discounts,
    },
    taxes !== undefined && {
      label: 'Taxes',
      amount: taxes,
    },
    coupon && {
      label: 'Coupon Applied:',
      amount: -discounts,
    },
    totalGiftCard && {
      label: 'Gift Card:',
      amount: -totalGiftCard,
    },
    partialPayment && {
      label: 'Total due at check-in:',
      amount: total - depositTotal,
      highlight: true,
    },
    {
      label: partialPayment ? 'Total due now:' : 'Total:',
      amount: depositTotal,
      highlight: true,
    },
    order && {
      label: 'Total Paid:',
      amount: order.totalPaid,
      highlight: true,
    },
    order && {
      label: 'Remaining Balance:',
      amount: total - (partialPayment ? depositTotal : 0) - order.totalPaid,
      highlight: true,
    },
  ])

  return (
    <SocietyOrderSummary
      onRemove={handleRemoveLineItem}
      readOnly={readOnly}
      items={items}
      summary={summary}
      className={className}
    />
  )
}

function mapStateToProps(state, { ticketCart }) {
  return { ticketCart: ticketCart || selectors.ticketCart(state) }
}

const mapDispatchToProps = {
  setTicketCart: actions.setTicketCart,
  flashErrorMessage,
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderReceipt)
