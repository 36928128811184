import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import * as apiActions from 'main/apiActions'
import { selectors as membershipSelectors } from 'main/membership/reducer'
import { selectors as userSelectors } from 'main/user/reducer'

import MobileWalletButtons from 'components/MobileWalletButtons'

const MembershipWalletButtons = ({
  mobileWallet,
  currentMembership,
  fetchMobileWallet,
}) => {
  useEffect(() => {
    if (currentMembership && !mobileWallet) {
      fetchMobileWallet()
    }
  }, [currentMembership, mobileWallet, fetchMobileWallet])

  return (
    <MobileWalletButtons iosLink={mobileWallet?.iosLink} androidLink={mobileWallet?.androidLink}/>
  )
}

function mapStateToProps(state) {
  const primaryMember =
    userSelectors.primaryMember(state) ||
    membershipSelectors.primaryMember(state)

  return {
    currentMembership: primaryMember.currentMembership || {},
    mobileWallet: membershipSelectors.mobileWallet(state),
  }
}

const mapDispatchToProps = {
  fetchMobileWallet: apiActions.fetchMobileWallet,
}

export default connect(mapStateToProps, mapDispatchToProps)(MembershipWalletButtons)
