import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { logException } from 'services/sentry'
import { onError } from '@launchpadlab/lp-hoc'
import { FlashMessageContainer } from '@launchpadlab/lp-components'
import * as flashActions from 'redux-flash'
import { selectors as userSelectors } from './user/reducer'
import * as apiActions from 'main/apiActions'
import { isEmpty } from 'utils'
import { selectors as membershipSelectors } from './membership/reducer'
import * as Types from 'main/types'

const propTypes = {
  children: PropTypes.node.isRequired,
  flashMessages: PropTypes.arrayOf(flashActions.flashMessageType),
  memberId: PropTypes.number,
  fetchMemberDetails: PropTypes.func.isRequired,
  memberDetails: PropTypes.arrayOf(Types.member),
  hasOAuthSession: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  validateIdMeSession: PropTypes.func.isRequired,
}

const defaultProps = {
  memberId: null,
  memberDetails: [],
}

function Layout({
  children,
  flashMessages,
  memberId,
  fetchMemberDetails,
  memberDetails,
  hasOAuthSession,
  isAuthenticated,
  validateIdMeSession,
}) {
  useEffect(() => {
    if (memberId && isEmpty(memberDetails)) {
      fetchMemberDetails(memberId)
    }
  }, [memberDetails])

  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    if (isAuthenticated || hasOAuthSession) {
      validateIdMeSession()
        .catch(() => {}) // do nothing if user is not validated as a veteran
        .finally(() => setLoaded(true))
    } else {
      setLoaded(true)
    }
  }, [isAuthenticated]) // ignore changes to hasOAuthSession

  return (
    <>
      <FlashMessageContainer messages={flashMessages} />
      {loaded && children}
    </>
  )
}

Layout.propTypes = propTypes
Layout.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    flashMessages: flashActions.getFlashMessages(state),
    memberId: userSelectors.memberId(state),
    memberDetails: membershipSelectors.membershipDetails(state),
    hasOAuthSession: userSelectors.hasOAuthSession(state),
    isAuthenticated: userSelectors.isAuthenticated(state),
  }
}

const mapDispatchToProps = {
  fetchMemberDetails: apiActions.fetchMemberDetails,
  validateIdMeSession: apiActions.validateIdMeSession,
}

function onComponentDidCatch(props, error, errorInfo) {
  logException(error, errorInfo)
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  onError(onComponentDidCatch)
)(Layout)
