import React from 'react'
import { Route, IndexRedirect } from 'react-router'
import * as Views from './views'
import Layout from './Layout'

const questionnaireRoutePaths = ['educational-facility', 'rental', 'group-size']
const questionnaireResultPaths = ['qualified', 'not-qualified']

const Routes = (
  <Route path="questionnaire" component={Layout}>
    <IndexRedirect to="educational-facility" />
    {questionnaireRoutePaths.map((path) => (
      <Route key={path} path={path} component={Views.Question} />
    ))}
    {questionnaireResultPaths.map((path) => (
      <Route key={path} path={path} component={Views.QuestionnaireResults} />
    ))}
  </Route>
)

export default Routes
