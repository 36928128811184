import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { debounce } from 'lodash'

const propTypes = {
  mobileWidthPx: PropTypes.number,
  children: PropTypes.func.isRequired,
}

// set as the deafult to align with mobile breakpoints in our CSS variables ($tablet-smaller-size)
// the mobileWidthPx can be customized wherever the MobileListener is integrated in order to alter
// when the `isMobile` output variable is triggered
const defaultProps = { mobileWidthPx: 720 }

function MobileListener({ mobileWidthPx, children }) {
  const [isMobile, setIsMobile] = useState(
    mobileWidthPx >= window.innerWidth || mobileWidthPx >= window.screen.width
  )

  const handleWindowChange = debounce(() => {
    setIsMobile(
      mobileWidthPx >= window.innerWidth || mobileWidthPx >= window.screen.width
    )
  }, 200)

  const windowListener = () => {
    window.addEventListener('resize', handleWindowChange)
  }

  const windowUnListener = () => {
    window.removeEventListener('resize', handleWindowChange, true)
  }

  useEffect(() => {
    windowListener()
    return windowUnListener
  }, [])

  return children({ isMobile })
}

MobileListener.propTypes = propTypes
MobileListener.defaultProps = defaultProps

export default MobileListener
