import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import * as Types from 'main/types'
import { selectors } from '../reducer'
import { displayAddOnNames, routerSelectors } from 'utils'
import * as actions from '../actions'
import * as effects from 'main/effects'
import { compact } from 'lodash'
import SocietyOrderSummary from '../../../layout/SocietyOrderSummary'

const propTypes = {
  addOns: PropTypes.arrayOf(Types.addOn).isRequired,
  donationAmount: PropTypes.number,
  membershipType: Types.membershipType.isRequired,
  orderTotals: PropTypes.object.isRequired,
  appliedGiftCardAmount: PropTypes.number,
  membershipForm: PropTypes.object.isRequired,
  setGiftCard: PropTypes.func.isRequired,
  isAddOnPage: PropTypes.bool.isRequired,
  coupon: PropTypes.object,
}

const defaultProps = {
  donationAmount: 0,
  appliedGiftCardAmount: 0,
  coupon: null,
}

function OrderReceipt({
  addOns,
  donationAmount,
  coupon,
  membershipType: { displayName, fullPrice },
  orderTotals: { total, discount, fullPriceTotal },
  appliedGiftCardAmount,
  membershipForm,
  setGiftCard,
  isAddOnPage,
  readOnly,
}) {
  const {
    primaryMember,
    secondaryMember,
    children = [],
  } = membershipForm.membershipDetails

  useEffect(() => {
    if (isAddOnPage && primaryMember) {
      effects
        .updateCheckout(membershipForm)
        .then((response) => setGiftCard(response))
    }
  }, [membershipForm.addOns])

  const notes = compact([
    primaryMember && [
      'Primary Member',
      <>
        <div>{primaryMember.fullName}</div>
        <div>{primaryMember.email}</div>
      </>,
    ],
    secondaryMember?.fullName && [
      'Secondary Member',
      `${secondaryMember.fullName}`,
    ],
    ...children.map((child, index) => [`Child ${index + 1}`, child.fullName]),
  ])

  const items = [
    {
      label: `${displayName} Membership`,
      subtotal: fullPrice,
      notes,
      editUrl: '/membership/details',
    },
    ...addOns.map(({ displayName, fullPrice, names }) => ({
      label: displayName,
      prices: names &&
        names.length > 0 && [
          { label: displayAddOnNames(names), value: fullPrice },
        ],
      subtotal: fullPrice,
    })),
  ]

  const summary = compact([
    donationAmount && {
      label: 'Donation',
      amount: donationAmount,
    },
    coupon?.couponCode && {
      label: coupon.sellerCode ? 'Discount Applied' : 'Coupon Applied',
      strVal: coupon.couponCode,
    },
    { label: 'Subtotal', amount: fullPriceTotal },
    discount && { label: 'Discount', amount: -discount },

    appliedGiftCardAmount && {
      label: 'Gift Card:',
      amount: -appliedGiftCardAmount,
    },
    { label: 'Total', amount: total, highlight: true },
  ])

  return (
    <SocietyOrderSummary items={items} summary={summary} readOnly={readOnly} />
  )
}

OrderReceipt.propTypes = propTypes
OrderReceipt.defaultProps = defaultProps

function mapStateToProps(state) {
  const pathname = routerSelectors.pathname(state)
  return {
    addOns: selectors.selectedAddOns(state),
    donationAmount: selectors.donationAmount(state),
    coupon: selectors.coupon(state),
    membershipType: selectors.selectedMembershipType(state),
    orderTotals: selectors.orderTotals(state),
    appliedGiftCardAmount: selectors.appliedGiftCardAmount(state),
    membershipForm: selectors.membershipForm(state),
    isAddOnPage: pathname === '/membership/add-ons',
  }
}

const mapDispatchToProps = {
  setGiftCard: actions.setGiftCard,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  OrderReceipt
)
