import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'

const TOptionPrototype = {
  label: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
}

const TOption = PropTypes.shape(TOptionPrototype)

const TRadioProps = {
  ...TOptionPrototype,
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
}

const Radio = ({ name, value, label, onChange, checked, inline }) => {
  const radioId = `radio-${name}`
  return (
    <label
      key={radioId}
      htmlFor={radioId}
      className={c(
        'cursor-pointer transition-colors flex items-center whitespace-nowrap gap-3',
        inline && {
          'border border-solid rounded flex-1 justify-center p-3': true,
          'bg-secondary-500 text-white border-secondary-500': checked,
          'border-gray-200': !checked,
        }
      )}
    >
      <input
        className={c('cursor-pointer w-6 aspect-square m-0', {
          'sr-only': inline,
        })}
        id={radioId}
        type="radio"
        value={value}
        name={name}
        checked={checked}
        onChange={onChange}
      />
      <span>{label}</span>
    </label>
  )
}

Radio.propTypes = TRadioProps

const TRadioOtherProps = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  decorator: PropTypes.string.isRequired,
  error: PropTypes.string,
  inline: PropTypes.bool,
}

const RadioOther = ({ decorator, value, error, inline, ...props }) => {
  const { checked, onChange } = props

  const [inputValue, setInputValue] = useState(value || '')

  useEffect(() => setInputValue(value), [value])

  function handleChange(event) {
    setInputValue(event.target.value)
  }

  function handleBlur() {
    onChange({ target: { value: inputValue } })
  }

  return (
    <div className="flex flex-col gap-2 md:flex-row">
      <Radio {...props} label="Other" value="" inline={inline} />

      <label
        className={c(
          'space-x-1.5 whitespace-nowrap flex items-center md:block',
          {
            invisible: !checked,
          }
        )}
      >
        <span className="text-lg">{decorator}</span>
        <span className="relative flex-1 inline-block">
          <input
            type="text"
            value={inputValue}
            onChange={handleChange}
            onBlur={handleBlur}
            className="inline"
            placeholder="Donation Amount"
            id="donation-amount"
          />
          <span className="absolute left-0 text-sm top-full text-danger">
            {error}
          </span>
        </span>
      </label>
    </div>
  )
}

RadioOther.propTypes = TRadioOtherProps

const TProps = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(TOption),
  inline: PropTypes.bool,
  labelSrOnly: PropTypes.bool,
  shrink: PropTypes.bool,

  other: PropTypes.shape({
    decorator: PropTypes.string.isRequired,
    error: PropTypes.string,
  }),
}

const RadioSelect = ({
  name,
  value,
  label,
  options,
  onChange,
  other,
  inline,
  labelSrOnly,
  shrink,
}) => {
  const isOtherChecked = !options.some((option) => option.value === value)

  return (
    <fieldset className="space-y-4">
      <legend className={c({ 'sr-only': labelSrOnly })}>{label}</legend>
      <ol
        className={c({
          'flex flex-col md:flex-row md:items-center gap-4': inline,
          'md:max-w-min': shrink,
          'md:flex-wrap': inline && !shrink,
        })}
      >
        {options.map((option) => (
          <li key={`radio-${name}-${option.label}`} className="flex-1 mb-0">
            <Radio
              name={`${name}-${option.label}`}
              checked={value === option.value}
              onChange={onChange}
              inline={inline}
              {...option}
            />
          </li>
        ))}
        {other && (
          <li className="mb-0 min-w-16">
            <RadioOther
              {...other}
              name={`${name}-other`}
              checked={isOtherChecked}
              onChange={onChange}
              value={value}
              inline={inline}
            />
          </li>
        )}
      </ol>
    </fieldset>
  )
}

RadioSelect.propTypes = TProps

export { TOption as TRadioSelectOption }
export default RadioSelect
