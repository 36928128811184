import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { propTypes as formPropTypes } from 'redux-form'
import { lpForm } from '@launchpadlab/lp-form'
import { ButtonArea, Input, SubmitButton } from '@launchpadlab/lp-components'
import { NormalizedField as Field } from 'components'

const propTypes = {
  ...formPropTypes,
  showButton: PropTypes.bool.isRequired,
}

const defaultProps = {}

function SellerCodeForm({ handleSubmit, error, submitting, showButton }) {
  return (
    <form onSubmit={handleSubmit}>
      {error && <div className="error-message">{error}</div>}

      <Field name="sellerCode.sellerCode" component={Input} />
      {showButton && (
        <ButtonArea>
          <SubmitButton {...{ submitting }}>Enter Code</SubmitButton>
        </ButtonArea>
      )}
    </form>
  )
}

SellerCodeForm.propTypes = propTypes

SellerCodeForm.defaultProps = defaultProps

export default compose(
  lpForm({
    name: 'membership-seller-code',
    constraints: {
      'sellerCode.sellerCode': { presence: true, numericality: true },
    },
    enableReinitialize: true,
  })
)(SellerCodeForm)
