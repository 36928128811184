import { createAction } from 'redux-actions'

export const setCurrentFormStep = createAction('SET_CURRENT_FORM_STEP')
export const setTicketCart = createAction('SET_TICKET_CART')
export const clearTicketingForm = createAction('CLEAR_TICKETING_FORM')
export const clearTicketTimes = createAction('CLEAR_TICKET_TIMES')
export const setDisableTicketPriceActions = createAction(
  'SET_DISABLE_TICKET_PRICE_ACTIONS'
)
export const setUserUdfValues = createAction('SET_USER_UDF_VALUES')
