import React from 'react'
import TermsCheckbox from './TermsCheckbox'

function MembershipTermsCheckbox({ ...props }) {
  return (
    <TermsCheckbox
      label="Membership Terms and Conditions"
      id="membershipTerms"
      {...props}
    >
      <h3>Website Terms &amp; Conditions</h3>
      <p>
        Your user name and password are for your personal use only. If you use
        the Site, you are responsible for maintaining the confidentiality of
        your account and password and for restricting access to your computer,
        and you agree to accept responsibility for all activities that occur
        under your account or password.
      </p>
      <p>
        We may, at any time: modify or discontinue all or any part of the Site;
        charge, modify or waive fees required to use the Site; or offer
        opportunities to some or all Site users.
      </p>

      <h3>Membership Terms &amp; Conditions</h3>

      <p>
        All Omaha's Henry Doorly Zoo and Aquarium memberships are
        non-transferable and non-refundable. The information provided in your
        order will be recorded in your permanent member record. Membership
        record changes must be submitted to join@omahazoo.com at least one week
        prior to your next visit.
      </p>
      <p>
        If you are renewing your membership, you may continue to use your
        current Zoo membership card.
      </p>
      <p>
        For new members, your card will be mailed within 2 weeks of the order
        date. Your online purchase includes a temporary pass that can be used
        for admission to the Zoo until your permanent card is received.
      </p>
      <p>
        Members are required to present their membership card and photo I.D.
        matching their member record on each visit to the Zoo.
      </p>

      <h3>Primary Memberships</h3>

      <p>
        <strong>Patron</strong> - Free admission to the Zoo, Wildlife Safari
        Park, Lozier Giant Screen Theater, and Stingray Beach for both listed
        members at one address (or one listed member and one guest), two
        additional guests, and up to six children (ages 0-20) at the member's
        address.
      </p>
      <p>
        <strong>Household</strong> - Free Zoo and Lozier Theater admission for
        both listed members at one address or one listed member and one guest
        and up to six children (ages 0-20) at the member's address.
      </p>
      <p>
        <strong>Dual</strong> - Free Zoo and Lozier Theater admission for both
        listed members at one address or one listed member and one guest.
      </p>
      <p>
        <strong>Grandparent</strong> - Free Zoo and Lozier Theater admission for
        both listed members at one address or one listed member and one guest
        and up to six grandchildren of the member's (ages 0-20).
      </p>
      <p>
        <strong>Individual</strong> - Free Zoo and Lozier Theater admission for
        the listed member only.
      </p>

      <h3>Add-On Memberships</h3>

      <p>
        <strong>Wildlife Safari Park</strong> - Free Wildlife Safari Park
        admission.
      </p>
      <p>
        <strong>Nanny</strong> - Free Zoo and Lozier Theater admission for the
        named nanny when accompanying up to six children (ages 0-20) at the
        primary member's address. Not valid for second adult and not valid on
        the same day the membership is used.
      </p>
      <p>
        <strong>Additional Guest(s)</strong> - Free Zoo and Lozier Theater
        admission for specified number of guests per day. Must accompany valid
        listed member.
      </p>
    </TermsCheckbox>
  )
}

export default MembershipTermsCheckbox
