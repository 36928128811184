import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import {
  formValueSelector,
  propTypes as formPropTypes,
  SubmissionError,
  Field,
  change,
} from 'redux-form'
import { scroller } from 'react-scroll'
import { lpForm } from '@launchpadlab/lp-form'
import { Checkbox, Input, Select } from '@launchpadlab/lp-components'
import { modifyProps } from '@launchpadlab/lp-hoc'
import { displayCurrency, getCountryOptions, first, keys } from 'utils'
import * as options from 'main/options'

import { GiftCardSection } from 'components'
import * as Sentry from '@sentry/react'
import { GpiForm } from 'components/Gpi'
import RadioSelect from '../../components/ui/RadioSelect'
import * as actions from '../ticketing/actions'
import { selectors } from '../ticketing/reducer'
import SocietyHeading from '../../layout/SocietyHeading'
import CovidTermsCheckbox from '../../components/CovidTermsCheckbox'
import TicketingTermsCheckbox from '../../components/TicketingTermsCheckbox'

const cartPaymentForm = 'cart-payment'

const propTypes = {
  ...formPropTypes,
  appliedGiftCardAmount: PropTypes.number,
  remainingGiftCardBalance: PropTypes.number,
  onGiftCardApply: PropTypes.func.isRequired,
  onGiftCardApplySuccess: PropTypes.func.isRequired,
  onGiftCardApplyFail: PropTypes.func.isRequired,
  onGiftCardRemove: PropTypes.func.isRequired,
  onGiftCardRemoveSuccess: PropTypes.func.isRequired,
  onGiftCardRemoveFail: PropTypes.func.isRequired,
  skipBillingInfo: PropTypes.bool,
  enableMobileOptIn: PropTypes.bool,
  extendedCustomerInfo: PropTypes.bool,
  isUsCustomer: PropTypes.bool,
  allowChoosingPaymentMode: PropTypes.bool,
  depositTotal: PropTypes.number,
  orderTotal: PropTypes.number,
  // onValidateOrder must be a Promise and can't be default prop because used in modify method
  onValidateOrder: PropTypes.func.isRequired,
  zeroDeposit: PropTypes.bool.isRequired,
  partialPayment: PropTypes.bool.isRequired,
  changeForm: PropTypes.func,
}

const defaultProps = {
  appliedGiftCardAmount: 0,
  remainingGiftCardBalance: null,
  enableMobileOptIn: false,
  extendedCustomerInfo: false,
  isUsCustomer: true,
  allowChoosingPaymentMode: false,
}

const FormSection = ({ title, children }) => (
  <section className="p-5 space-y-5 bg-white shadow">
    <SocietyHeading>{title}</SocietyHeading>
    <div>{children}</div>
  </section>
)

function PaymentForm({
  handleSubmit,
  appliedGiftCardAmount,
  remainingGiftCardBalance,
  onGiftCardApply,
  onGiftCardApplySuccess,
  onGiftCardApplyFail,
  onGiftCardRemove,
  onGiftCardRemoveSuccess,
  onGiftCardRemoveFail,
  skipBillingInfo,
  enableMobileOptIn,
  extendedCustomerInfo,
  isUsCustomer,
  allowChoosingPaymentMode,
  depositTotal,
  orderTotal,
  zeroDeposit,
  setTicketCart,
  ticketCart,
  partialPayment,
  changeForm,
  customerInfo,
}) {
  // RadioGroup accepts only integers and strings as values, not booleans,
  // we are converting value to boolean on submit
  const payDepositOptions = [
    { label: `Deposit (${displayCurrency(depositTotal)})`, value: 'true' },
    { label: `Pay in full (${displayCurrency(orderTotal)})`, value: 'false' },
  ]

  const [useSameAddressCheckbox, setUseSameAddressCheckbox] = useState(true)

  function setTicketCartPartialPayment(value) {
    setTicketCart({
      success: {
        data: {
          attributes: {
            ...ticketCart,
            partialPayment: value === 'true',
          },
        },
      },
    })
  }

  useEffect(() => {
    if (useSameAddressCheckbox) {
      changeForm(
        cartPaymentForm,
        'billingInfo.firstName',
        customerInfo?.firstName
      )
      changeForm(
        cartPaymentForm,
        'billingInfo.lastName',
        customerInfo?.lastName
      )
      changeForm(
        cartPaymentForm,
        'billingInfo.streetAddress1',
        customerInfo?.streetAddress1
      )
      changeForm(cartPaymentForm, 'billingInfo.zip', customerInfo?.zip)
    }
  }, [
    changeForm,
    useSameAddressCheckbox,
    customerInfo?.firstName,
    customerInfo?.lastName,
    customerInfo?.streetAddress1,
    customerInfo?.zip,
  ])

  function handleUseSameAddress() {
    setUseSameAddressCheckbox(!useSameAddressCheckbox)
  }

  //FIXME: This should propagate to global state without the need of a side-effect
  useEffect(() => {
    if (ticketCart && ticketCart.partialPayment.toString() !== partialPayment) {
      setTicketCartPartialPayment(partialPayment)
    }
  }, [partialPayment, ticketCart && ticketCart.partialPayment])

  return (
    <form onSubmit={handleSubmit} className="space-y-5">
      {!!allowChoosingPaymentMode && (
        <FormSection title="Payment Mode">
          <p>
            School group payment can be made online or in-person. In-person
            payment is due at time of check-in at the Zoo. Cash, check, and all
            major credit cards are accepted. Invoicing is available.
          </p>
          <Field
            name="partialPayment"
            component={({ input: { value, onChange } }) => (
              <RadioSelect
                label="Please select one of the options"
                name="payment-mode"
                value={value}
                onChange={(event) => onChange(event.target.value)}
                options={payDepositOptions}
                labelSrOnly
              />
            )}
          />
        </FormSection>
      )}

      <FormSection title="Contact Details">
        <div className="flex gap-5">
          <Field
            name="customerInfo.firstName"
            component={Input}
            label="First Name"
          />
          <Field
            name="customerInfo.lastName"
            component={Input}
            label="Last Name"
          />
        </div>
        <Field name="customerInfo.email" component={Input} label="Email" />
        <Field name="customerInfo.phone" component={Input} label="Phone" />
        {extendedCustomerInfo && (
          <Fragment>
            <Field
              name="customerInfo.country"
              component={Select}
              options={getCountryOptions()}
              label="Country"
              placeholder="Select"
            />
            <Field
              name="customerInfo.streetAddress1"
              component={Input}
              label="Street Address"
            />
            <Field name="customerInfo.city" component={Input} label="City" />
            {isUsCustomer && (
              <div className="flex gap-5">
                <Field
                  name="customerInfo.state"
                  component={Select}
                  options={options.states}
                  label="State"
                  placeholder="Select"
                />
                <Field
                  name="customerInfo.zip"
                  component={Input}
                  label="Zip / Post Code"
                />
              </div>
            )}
          </Fragment>
        )}
        <section className="space-y-4">
          {enableMobileOptIn && (
            <div className="flex items-center gap-5">
              <Field
                className="m-0"
                aria-label="terms and conditions"
                name="mobileOptIn"
                component={Checkbox}
                label={false}
                required
              />
              <label htmlFor="mobileOptIn" className="h-auto m-0">
                Yes, send me texts about my order status!
              </label>
            </div>
          )}
          <Field
            className="m-0"
            aria-label="terms and conditions"
            name="termsAndConditions"
            component={({ input: { value, onChange }, meta }) => (
              <TicketingTermsCheckbox
                checked={value}
                onChange={onChange}
                error={
                  (!meta.pristine || meta.submitFailed) &&
                  meta.error &&
                  meta.error[0]
                }
              />
            )}
            label={false}
            required
          />

          <Field
            className="m-0"
            aria-label="covid-19 terms and conditions"
            name="covidTermsAndConditions"
            component={({ input: { value, onChange }, meta }) => (
              <CovidTermsCheckbox
                checked={value}
                onChange={onChange}
                productType="ticket"
                error={
                  (!meta.pristine || meta.submitFailed) &&
                  meta.error &&
                  meta.error[0]
                }
              />
            )}
            label={false}
            required
          />
        </section>
      </FormSection>
      {!zeroDeposit && (
        <FormSection title="Billing Details">
          <span className="checkbox">
            <input
              id="copy-to-billing-checkbox"
              type="checkbox"
              onChange={handleUseSameAddress}
              checked={useSameAddressCheckbox}
            />
            <label htmlFor="copy-to-billing-checkbox">
              Use the same address as member details
            </label>
          </span>
          <GiftCardSection
            acceptGiftCard={true}
            appliedAmount={appliedGiftCardAmount}
            remainingBalance={remainingGiftCardBalance}
            onApply={onGiftCardApply}
            onApplySuccess={onGiftCardApplySuccess}
            onApplyFail={onGiftCardApplyFail}
            onRemove={onGiftCardRemove}
            onRemoveSuccess={onGiftCardRemoveSuccess}
            onRemoveFail={onGiftCardRemoveFail}
          />
          {!skipBillingInfo && (
            <Fragment>
              <div className="flex gap-5">
                <Field
                  name="billingInfo.firstName"
                  component={Input}
                  label="First Name"
                />
                <Field
                  name="billingInfo.lastName"
                  component={Input}
                  label="Last Name"
                />
              </div>
              <div>
                <GpiForm />
                <Field
                  name="billingInfo.streetAddress1"
                  component={Input}
                  label="Street Address"
                />
                <Field
                  name="billingInfo.streetAddress2"
                  component={Input}
                  label="Street Address 2"
                />
                <Field
                  name="billingInfo.zip"
                  component={Input}
                  label="Zip / Post Code"
                />
              </div>
            </Fragment>
          )}
        </FormSection>
      )}
    </form>
  )
}

PaymentForm.propTypes = propTypes

PaymentForm.defaultProps = defaultProps

const modify = ({
  onSubmit,
  skipBillingInfo,
  extendedCustomerInfo,
  onValidateOrder,
}) => {
  return {
    constraints: {
      'customerInfo.firstName': { presence: true },
      'customerInfo.lastName': { presence: true },
      'customerInfo.email': {
        presence: true,
        email: true,
        length: { maximum: 60 },
      },
      'customerInfo.phone': { presence: true, length: { maximum: 20 } },
      'customerInfo.streetAddress1': () => {
        if (!extendedCustomerInfo) return null
        return {
          presence: {
            message: `^Street address can't be blank`,
          },
          length: { maximum: 50 },
        }
      },
      'customerInfo.city': () => {
        if (!extendedCustomerInfo) return null
        return {
          presence: true,
        }
      },
      'customerInfo.state': () => {
        if (!extendedCustomerInfo) return null
        return {
          presence: true,
        }
      },
      'customerInfo.country': () => {
        if (!extendedCustomerInfo) return null
        return {
          presence: true,
        }
      },
      'customerInfo.zip': () => {
        if (!extendedCustomerInfo) return null
        return {
          presence: true,
        }
      },
      'billingInfo.firstName': () => {
        if (skipBillingInfo) return null
        return { presence: true }
      },
      'billingInfo.lastName': () => {
        if (skipBillingInfo) return null
        return { presence: true }
      },
      'billingInfo.streetAddress1': () => {
        if (skipBillingInfo) return null
        return {
          presence: {
            message: `^Street address can't be blank`,
          },
          length: { maximum: 50 },
        }
      },
      'billingInfo.zip': () => {
        if (skipBillingInfo) return null
        return {
          presence: true,
        }
      },
      termsAndConditions: () => {
        return {
          presence: {
            message: '^You must accept the terms and conditions',
          },
          inclusion: {
            within: [true],
            message: '^You must accept the terms and conditions',
          },
        }
      },
      covidTermsAndConditions: () => {
        return {
          presence: {
            message:
              '^You must read and accept the COVID-19 terms and conditions',
          },
          inclusion: {
            within: [true],
            message:
              '^You must read and accept the COVID-19 terms and conditions',
          },
        }
      },
    },
    onSubmit: (values, dispatch, props) => {
      return onValidateOrder()
        .then(() => onSubmit(values, dispatch, props))
        .catch((err) => {
          Sentry.captureException(err)
          const errMsg =
            (err &&
              err.response &&
              err.response.errors &&
              err.response.errors.message) ||
            'Something went wrong. Please try again later.'
          throw new SubmissionError({
            _error: errMsg,
          })
        })
    },
  }
}

const formSelector = formValueSelector(cartPaymentForm)

function mapStateToProps(state) {
  return {
    ticketCart: selectors.ticketCart(state),
    partialPayment: formSelector(state, 'partialPayment'),
    customerInfo: formSelector(state, 'customerInfo'),
  }
}

const mapDispatchToProps = {
  setTicketCart: actions.setTicketCart,
  changeForm: change,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  modifyProps(modify),
  lpForm({
    name: cartPaymentForm,
    onSubmitFail: (params, dispatch, _, { syncErrors }) => {
      const firstErrorField = first(keys(syncErrors))
      return scroller.scrollTo(firstErrorField, {
        smooth: true,
      })
    },
    enableReinitialize: true,
  })
)(PaymentForm)
