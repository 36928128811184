import React from 'react'
import c from 'classnames'

const Anchor = ({
  as: Component = 'a',
  label,
  primary,
  sm,
  md,
  lg,
  xl,
  className,
  ...props
}) => {
  return (
    <Component
      className={c(className, 'block', {
        'text-primary hover:text-primary-400': primary,
        'text-sm': sm,
        'text-lg': lg,
        'text-base': !sm && !md && !lg && !xl,
      })}
      target={Component === 'a' ? '_blank' : undefined}
      rel="noreferrer"
      {...props}
    >
      {label}
    </Component>
  )
}

export default Anchor
