import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'main/types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import * as routerActions from 'react-router-redux'
import { capitalize, displayCurrency } from 'utils'
import { selectors } from '../reducer'
import { get } from 'lodash/fp'
import { GiftCardReceipt } from '../components'
import { compact } from 'lodash'
import SocietyCheckoutLayout from '../../../layout/SocietyCheckoutLayout'

const propTypes = {
  order: Types.giftCardOrder,
  selectedGiftCard: Types.giftCard,
  replace: PropTypes.func.isRequired,
}

const defaultProps = {}

function GiftCardConfirmation({ selectedGiftCard, order, replace }) {
  if (!selectedGiftCard) {
    replace('/gift-cards')
    return null
  }

  const {
    paymentMethod,
    email,
    orderNumber,
    totalPaid,
    purchaseDate,
    lineItems,
    attachmentLink,
  } = order

  const quantity = get('[0].quantity', lineItems) || 0

  const variablePrice = get('[0].variablePrice', lineItems) || 0

  const details = compact([
    ['Receipt Number', orderNumber],
    ['Purchase Date', purchaseDate],
    ['Purchase Total', displayCurrency(totalPaid)],
    paymentMethod && ['Payment Method', capitalize(paymentMethod)],
    ['Email', email],
  ])

  return (
    <SocietyCheckoutLayout
      title="Thank you!"
      summary={
        <GiftCardReceipt
          variablePrice={variablePrice}
          quantity={quantity}
          selectedGiftCard={selectedGiftCard}
        />
      }
      reversed
    >
      <p>Your gift card order has been submitted successfully.</p>

      <div className="p-5 bg-white shadow no-print">
        <p>Your gift cards have been sent to the email provided.</p>
        <ul className="space-y-4">
          {details.map(([label, value], index) => (
            <li key={index} className="mb-0">
              <div>{label}</div>
              <strong>{value}</strong>
            </li>
          ))}
        </ul>
      </div>
      <div className="space-x-5">
        <a
          href={attachmentLink}
          className="button-primary no-print"
          rel="noopener noreferrer"
          target="_blank"
        >
          Download Gift Cards
        </a>
        <button onClick={window.print} className="button-primary no-print">
          Print This Page
        </button>
      </div>
    </SocietyCheckoutLayout>
  )
}

GiftCardConfirmation.propTypes = propTypes
GiftCardConfirmation.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    order: selectors.order(state),
    selectedGiftCard: selectors.selectedGiftCard(state),
  }
}

const mapDispatchToProps = {
  replace: routerActions.replace,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  GiftCardConfirmation
)
