import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Field, Fields, FieldArray } from 'redux-form'
import { Select } from '@launchpadlab/lp-components'
import { range, uniqBy } from 'lodash'
import { EditableMemberFieldsInput } from '../components'
import { selectors } from '../reducer'
import * as actions from '../actions'
import { joinNames } from 'utils'
import SocietyHeading from '../../../layout/SocietyHeading'

const propTypes = {
  changeField: PropTypes.func.isRequired,
  isGrandparentForm: PropTypes.bool,
  numChildren: PropTypes.number.isRequired,
}

const defaultProps = {
  isGrandparentForm: false,
}

// If full name is available for the member based on initial values, prioritize that value
function getFullName({ currentChild, selectedMember }) {
  if (!currentChild || !currentChild.firstName)
    return joinNames(selectedMember.firstName, selectedMember.lastName)

  return joinNames(currentChild.firstName, currentChild.lastName)
}

function getChildMemberOptions({
  currentChild,
  nonPrimaryMembers,
  childMemberOptions,
}) {
  const selectedMember = nonPrimaryMembers.find(
    (member) => member.id === currentChild.id
  )
  if (!selectedMember) return childMemberOptions

  const selectedOption = {
    key: getFullName({ currentChild, selectedMember }),
    value: selectedMember.id,
  }
  return uniqBy([selectedOption, ...childMemberOptions], 'value')
}

/* eslint react/prop-types: 0 */
const ChildrenField = ({
  changeField,
  fields,
  isGrandparentForm,
  nonPrimaryMembers,
  numChildren,
  childMemberOptions,
  removeSelectedChildMember,
  replaceSelectedChildMember,
  saveChildMemberInfo,
}) => {
  return (
    <div>
      <Field
        name="numberOfChildren"
        component={Select}
        options={range(1, numChildren + 1).map((num) => String(num))}
        onChange={(e) => {
          const { value } = e.target
          const numNewFields = value - fields.length
          if (fields.length < value)
            return range(numNewFields).forEach(() =>
              fields.push({ isChild: true })
            )
          const fieldsToRemove = fields.length - value
          if (fields.length > value)
            return range(fieldsToRemove).forEach(() => fields.pop())
        }}
        selected={String(fields.length)}
        placeholder="Select"
      />
      <p>
        Providing children's names will help expedite registering your child for
        education programs and special events.
        {!isGrandparentForm &&
          ' Also great if your children visit on their own or with a friend.'}
      </p>
      {fields.map((field, index) => {
        const currentChild = fields.get(index)
        const childOptions = getChildMemberOptions({
          currentChild,
          nonPrimaryMembers,
          childMemberOptions,
        })

        // Setting the id for an existing child will force React to re-render the correct input (<select>) for existing members
        const key = currentChild.id || index

        return (
          <div className="relative" key={key}>
            <Fields
              prefix={field}
              legend={`Child ${index + 1}`}
              names={[`${field}.id`, `${field}.firstName`, `${field}.lastName`, `${field}.address.email`]}
              component={EditableMemberFieldsInput}
              options={childOptions}
              members={nonPrimaryMembers}
              saveMember={saveChildMemberInfo}
              parse={(value, name) => {
                // Parse the id to a number to facilitate strict comparisons (i.e., ===)
                if (name === `${field}.id` && value) return Number(value)
                return value
              }}
              removeMember={() => {
                // Remove the selected child from the cart
                removeSelectedChildMember(index)

                fields.remove(index)
                const valueToChange = fields.length - 1
                changeField('numberOfChildren', valueToChange || null)
              }}
              replaceMember={replaceSelectedChildMember}
              allowRemove
            />
          </div>
        )
      })}
    </div>
  )
}

function ChildrenFormSection({
  changeField,
  isGrandparentForm,
  nonPrimaryMembers,
  numChildren,
  childMemberOptions,
  removeSelectedChildMember,
  replaceSelectedChildMember,
  saveChildMemberInfo,
}) {
  return (
    <div>
      <SocietyHeading>Children Ages 0-20</SocietyHeading>
      <FieldArray
        name="children"
        component={ChildrenField}
        numChildren={numChildren}
        isGrandparentForm={isGrandparentForm}
        changeField={changeField}
        nonPrimaryMembers={nonPrimaryMembers}
        childMemberOptions={childMemberOptions}
        removeSelectedChildMember={removeSelectedChildMember}
        replaceSelectedChildMember={replaceSelectedChildMember}
        saveChildMemberInfo={saveChildMemberInfo}
      />
    </div>
  )
}

ChildrenFormSection.propTypes = propTypes
ChildrenFormSection.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    childMemberOptions: selectors.otherMemberOptions(state),
  }
}

const mapDispatchToProps = {
  saveChildMemberInfo: actions.saveChildMemberInfo,
  removeSelectedChildMember: actions.removeSelectedChildMember,
  replaceSelectedChildMember: actions.replaceSelectedChildMember,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ChildrenFormSection
)
