import React, { useState } from 'react'
import PropTypes from 'prop-types'
import AppliedGiftCard from './AppliedGiftCard'
import GiftCardCheckbox from './GiftCardCheckbox'
import GiftCardInput from './GiftCardInput'

const propTypes = {
  acceptGiftCard: PropTypes.bool.isRequired,
  appliedAmount: PropTypes.number,
  remainingBalance: PropTypes.number,
  onApply: PropTypes.func.isRequired,
  onApplySuccess: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onApplyFail: PropTypes.func.isRequired,
  onRemoveSuccess: PropTypes.func.isRequired,
  onRemoveFail: PropTypes.func.isRequired,
}

const defaultProps = {
  appliedAmount: 0,
  remainingBalance: null,
}

function GiftCardSection({
  acceptGiftCard,
  appliedAmount,
  remainingBalance,
  onApply,
  onApplySuccess,
  onApplyFail,
  onRemove,
  onRemoveSuccess,
  onRemoveFail,
}) {
  const [isLoading, setIsLoading] = useState(false)
  const [hasGiftCardToApply, setHasGiftCardToApply] = useState(false)
  const hasGiftCardApplied = appliedAmount > 0

  return (
    <div className="conditional-form">
      {hasGiftCardApplied ? (
        <AppliedGiftCard
          appliedAmount={appliedAmount}
          remainingBalance={remainingBalance}
          onRemove={onRemove}
          onRemoveSuccess={onRemoveSuccess}
          onRemoveFail={onRemoveFail}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      ) : (
        <React.Fragment>
          {acceptGiftCard && (
            <React.Fragment>
              <GiftCardCheckbox
                hasGiftCard={hasGiftCardToApply}
                setHasGiftCard={setHasGiftCardToApply}
              />
              {hasGiftCardToApply && (
                <GiftCardInput
                  onApply={onApply}
                  onApplySuccess={onApplySuccess}
                  onApplyFail={onApplyFail}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                />
              )}
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </div>
  )
}

GiftCardSection.propTypes = propTypes
GiftCardSection.defaultProps = defaultProps

export default GiftCardSection
