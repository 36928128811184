import { useEffect } from 'react'
import * as apiActions from 'main/apiActions'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import * as Types from 'main/types'

const TicketTimeListener = ({
  ticketTime,
  activityId,
  ticketTimes,
  dispatch,
}) => {
  useEffect(() => {
    if (
      ticketTime &&
      ticketTime.id &&
      activityId &&
      ticketTime.prices.length === 0
    ) {
      dispatch(apiActions.fetchTicketActivityTime(activityId, ticketTime.id))
    }
  }, [activityId, ticketTime?.id, ticketTimes])

  return null
}

TicketTimeListener.propTypes = {
  ticketTimes: PropTypes.arrayOf(Types.ticketActivityTime),
  ticketTime: Types.ticketActivityTime,
  activityId: PropTypes.string,
  dispatch: PropTypes.func,
}
export default connect()(TicketTimeListener)
