import React, { useEffect } from 'react'

import { connect } from 'react-redux'

import * as apiActions from 'main/apiActions'
import { selectors } from '../reducer'
import { get } from 'utils'
import patronThumbnail from 'images/patron-thumbnail.png'
import SocietyCheckoutLayout from '../../../layout/SocietyCheckoutLayout'
import { MEMBERSHIP_CHECKOUT_STEPS } from '../../types'
import SocietyProductGrid from '../../../layout/SocietyProductGrid'
import * as actions from '../actions'
import ReactGA from 'react-ga4'

function SelectMembership({
  products,
  fetchMembershipTypes,
  setSelectedMembershipType,
  clearGiftCard,
}) {
  useEffect(() => {
    fetchMembershipTypes()
  }, [])

  return (
    <SocietyCheckoutLayout
      progress={{ steps: MEMBERSHIP_CHECKOUT_STEPS, currentStep: 0 }}
      title="Select Membership"
    >
      <SocietyProductGrid
        products={products}
        showAds
        onSelect={({ membership }) => {
          setSelectedMembershipType(membership)
          clearGiftCard()

          ReactGA.event('add_to_cart', {
            currency: 'USD',
            value: membership.cost,
            items: [
              {
                item_id: membership.id,
                item_name: membership.displayName,
                item_category: 'membership',
                item_category2: 'membership',
                price: membership.cost,
                quantity: 1,
              },
            ],
          })
        }}
      />
    </SocietyCheckoutLayout>
  )
}

function mapStateToProps(state) {
  const membershipTypes = selectors.orderedMainMembershipTypes(state)

  if (!membershipTypes)
    return {
      products: [],
    }

  const currentMembership = selectors.currentMembership(state)
  const hasPatronMembership = get(currentMembership, 'patron')

  const products = [
    ...membershipTypes.map((membership) => {
      return {
        price: `$${membership.cost} per year`,
        priceNote: membership.priceNote,
        featured: currentMembership?.id === membership.id,
        featuredLabel: 'Current Membership',
        imageUrl: membership.image,
        description: membership.descriptionText,
        productUrl: membership.learnMoreUrl,
        selectUrl: `/membership/details?activityId=${membership.id}&backPath=activity`,
        signInUrl: `/sign-in?guestFlowDisabled=1&redirectUrl=${encodeURIComponent(
          '/membership'
        )}`,
        name: membership.displayName,
        fullDescription: membership.infoModalText,
        isLoginRequired: true,
        membership,
      }
    }),
    {
      price: '$500+ per year',
      name: 'Patron Membership',
      featured: hasPatronMembership,
      featuredLabel: 'Current Membership',
      imageUrl: patronThumbnail,
      description:
        'A patron membership is a way to provide philanthropic support to your Zoo while enjoying a membership with special benefits such as family admission to the Zoo and Wildlife Safari Park, two additional guests with every visit, exclusive event invitations, addition of a nanny, and more!',
      productUrl: process.env.REACT_APP_PATRON_MEMBERSHIP_URL,
      selectUrl: '/membership/patron',
      selectLabel: 'View Options',
    },
  ]

  return {
    products,
  }
}

const mapDispatchToProps = {
  fetchMembershipTypes: apiActions.fetchMembershipTypes,
  setSelectedMembershipType: actions.setSelectedMembershipType,
  clearGiftCard: actions.clearGiftCard,
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectMembership)
