import React, { useState } from 'react'
import editIcon from 'images/edit-cart.svg'
import removeIcon from 'images/remove-cart.svg'
import displayCurrency from '../../../../utils/displayCurrency'
import RemoveConfirmationModal from './RemoveConfirmationModal'

const RoundedButton = ({ label, icon, onClick }) => (
  <button
    className="w-8 h-8 p-2 transition-colors bg-white border-none rounded-full hover:bg-secondary-50"
    aria-label="label"
    title={label}
    onClick={onClick}
  >
    <img src={icon} />
  </button>
)

const CartLineItemTribute = ({ tribute }) =>
  tribute ? (
    <>
      {tribute.inscription && <div>Inscription: {tribute.inscription}</div>}
      <div className="capitalize">
        {tribute.label}: {tribute.name}
      </div>
    </>
  ) : null

const CartLineItemMailing = ({ mailing }) =>
  mailing ? (
    <div className="capitalize">
      Recipient: {[mailing.recipientName, mailing.recipientTitle].join(', ')}
      <br />
      Mailing {mailing.type} To: {mailing.recipientAddress}
    </div>
  ) : null

const CartLineItemPricing = ({ pricing }) => (
  <div className="flex items-end justify-between capitalize">
    <span className="text-sm md:text-md">
      {pricing.product && pricing.product.amount
        ? `${displayCurrency(pricing.product.amount)} Package`
        : `${displayCurrency(pricing.amount)} Donation`}
      {pricing.schedule === 'monthly' && (
        <>
          <hr /> {pricing.schedule}
        </>
      )}
    </span>
    <span className="text-2xl font-bold md:text-3xl">
      {displayCurrency(pricing.amount)}
    </span>
  </div>
)

const CartLineItemDetail = ({
  name,
  amount,
  onRemove,
  onEdit,
  schedule,
  mailing,
  tribute,
  product,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleRemove = () => {
    setIsModalOpen(true)
  }

  const handleConfirmRemoval = () => {
    setIsModalOpen(false)
    onRemove()
  }

  const handleCancelRemoval = () => {
    setIsModalOpen(false)
  }

  return (
    <div className="p-6 space-y-4 bg-white rounded-md">
      <header className="flex justify-between text-2xl font-bold md:text-2xl">
        <span>{name}</span>

        <section className="space-x-1 md:space-x-4">
          <RoundedButton
            label={`Edit ${name}`}
            icon={editIcon}
            onClick={onEdit}
          />
          <RoundedButton
            label={`Remove ${name}`}
            icon={removeIcon}
            onClick={handleRemove}
          />
        </section>
      </header>
      <section className="space-y-1 text-base">
        <CartLineItemMailing mailing={mailing} />
        <CartLineItemTribute tribute={tribute} />
        <CartLineItemPricing pricing={{ amount, product, schedule }} />
      </section>
      <RemoveConfirmationModal
        isOpen={isModalOpen}
        onConfirm={handleConfirmRemoval}
        onClose={handleCancelRemoval}
        message="Are you sure you want to remove this donation from your cart?"
      />
    </div>
  )
}

export default CartLineItemDetail
