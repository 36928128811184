import React from 'react'
import { compose } from 'redux'
import { propTypes as formPropTypes } from 'redux-form'
import { lpForm } from '@launchpadlab/lp-form'
import { SubmitButton } from '@launchpadlab/lp-components'
import { NormalizedField as Field, FoundationInput } from 'components'
import { validateNested } from 'utils'

function ResetPasswordForm({ handleSubmit, submitting, invalid }) {
  return (
    <form onSubmit={handleSubmit} className="flex flex-col">
      <div className="flex flex-col sm:gap-4 sm:flex-row">
        <Field
          name="password"
          component={FoundationInput}
          label="Password"
          placeholder="Password *"
          inputType="password"
        />
        <Field
          name="password_confirmation"
          component={FoundationInput}
          label="Password"
          placeholder="Password Confirmation *"
          inputType="password"
        />
      </div>

      <SubmitButton
        className="flex self-end px-4 py-1 text-lg font-semibold capitalize transition-colors border-none rounded-full cursor-pointer hover:bg-primary-700 "
        invalid={invalid}
        submitting={submitting}
      >
        Update Password
      </SubmitButton>
    </form>
  )
}

ResetPasswordForm.propTypes = formPropTypes

export default compose(
  lpForm({
    name: 'reset-password',
    validate: validateNested({
      password: { presence: true },
      password_confirmation: { presence: true, equality: 'password' },
    }),
  })
)(ResetPasswordForm)
