import React from 'react'
import PropTypes from 'prop-types'

const propTypes = {
  children: PropTypes.node.isRequired,
  hidden: PropTypes.bool,
}

const defaultProps = {
  hidden: false,
}

function HideableArea({ children, hidden }) {
  const style = hidden ? { display: 'none', ariaHidden: true } : {}
  return <div style={style}>{children}</div>
}

HideableArea.propTypes = propTypes
HideableArea.defaultProps = defaultProps

export default HideableArea
