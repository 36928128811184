import React from 'react'
import ProgressBar from '../../shared/ProgressBar'
import { BackButton } from 'components'
import PropTypes from 'prop-types'
import OrderSummary from './OrderSummary'
import c from 'classnames'

export default function CheckoutPageLayout({
  step,
  children,
  backUrl,
  noSummary,
}) {
  return (
    <section>
      {step !== undefined && (
        <header
          className={c(
            'flex items-center justify-center pt-0 bg-white px-8',
            'lg:px-28',
            'xl:px-48'
          )}
        >
          <ProgressBar currentStep={step} />
        </header>
      )}
      <div
        className={c(
          'flex flex-col-reverse space-y-8 space-y-reverse p-8 my-0',
          'md:flex-row md:space-x-8',
          'lg:space-x-10 lg:mx-20',
          'xl:space-x-20 xl:mx-40'
        )}
      >
        <section className="flex-1 m-0 space-y-5">
          {backUrl && <BackButton to={backUrl} />}
          {children}
        </section>
        {!noSummary && (
          <aside>
            <h3 className="px-6 py-3 m-0 text-xl bg-gray-200">Summary</h3>
            <OrderSummary />
          </aside>
        )}
      </div>
    </section>
  )
}

CheckoutPageLayout.propTypes = {
  step: PropTypes.number,
  children: PropTypes.node.isRequired,
  backUrl: PropTypes.string,
  noSummary: PropTypes.bool,
}
