import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'main/types'
import { displayCurrency, FREE_CHAPERONE_FLAG } from 'utils'

const propTypes = {
  updateTicketLineItemPrice: PropTypes.func.isRequired,
  quantity: PropTypes.number,
  ticketPrice: Types.ticketPrice.isRequired,
  disableEdit: PropTypes.bool,
  setInteractingWithMassInput: PropTypes.func,
}

const defaultProps = {
  quantity: 0,
  disableEdit: false,
  setInteractingWithMassInput: () => {},
}

function TicketPriceMassInput({
  updateTicketLineItemPrice,
  quantity,
  ticketPrice,
  disableEdit,
  setInteractingWithMassInput,
}) {
  // Free Chaperones are automatically calculated, so user should not be able to write in this input
  const isFreeChaperone = ticketPrice.flag === FREE_CHAPERONE_FLAG
  // this initial state value of quantity is only used to set localQuantity
  // on initial render
  const [localQuantity, setLocalQuantity] = useState(quantity.toString())

  // allow updates from parent to update local state
  // if server value changes, it will update localQuantity variable via the props passed in by parent
  useEffect(() => {
    setLocalQuantity(quantity.toString())
  }, [quantity])

  return (
    <div className="flex flex-col items-start">
      <p style={{ marginBottom: 10 }}>
        <strong>
          {ticketPrice.displayName} ({displayCurrency(ticketPrice.price)})
        </strong>
      </p>
      <div>
        <input
          name={ticketPrice.displayName}
          label={ticketPrice.displayName}
          type="number"
          disabled={disableEdit || isFreeChaperone}
          value={localQuantity}
          min="0"
          max="600"
          onBlur={(event) => {
            const submitVal = parseInt(event.target.value) || 0
            updateTicketLineItemPrice(ticketPrice, submitVal)
          }}
          onChange={(event) => {
            const newRawVal = parseInt(event.target.value) || 0
            // limit ticket size to between 0 and 600
            const newUpdateVal = Math.min(Math.max(newRawVal, 0), 600)
            setLocalQuantity(newUpdateVal.toString())
          }}
          onFocus={() => {
            setInteractingWithMassInput(true)
          }}
        />
      </div>
    </div>
  )
}

TicketPriceMassInput.propTypes = propTypes
TicketPriceMassInput.defaultProps = defaultProps

export default TicketPriceMassInput
