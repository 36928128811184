import { handleActions } from 'redux-actions'
import { selectorForSlice } from '@launchpadlab/lp-redux-utils'
import { setFromRequest } from '@launchpadlab/lp-redux-api'
import { LOCATION_CHANGE } from 'react-router-redux'
import * as Types from 'main/types'
import { invert } from 'lodash'
import { set } from 'lodash/fp'
import * as apiActions from 'main/apiActions'
import * as actions from './actions'
import * as LS from 'services/sessionStorage'

const reducerKey = 'giftCard'
const slice = 'root.giftCard'

const initialState = {
  currentFormStep: 0,
  totalFormSteps: Object.keys(Types.GiftCardFormStepRoutes).length,
  giftCards: null,
  selectedGiftCard: null,
  cart: null,
  order: null,
}

const reducer = handleActions(
  {
    [LOCATION_CHANGE]: (state, { payload: { pathname } }) => {
      const formStep = invert(Types.GiftCardFormStepRoutes)[pathname]
      if (!formStep) return state

      return set('currentFormStep', Number(formStep), state)
    },
    ...setFromRequest(apiActions.REQ_GIFTCARDS, 'giftCards'),
    ...setFromRequest(apiActions.REQ_GIFTCARD, 'selectedGiftCard'),
    [apiActions.REQ_GIFT_CARD_CART + '_SUCCESS']: (
      state,
      { payload: { data } }
    ) => {
      LS.setCartToken(data.additionalData.cartToken)
      return set('cart', data.attributes, state)
    },
    [actions.setGiftCardCart]: (state, { payload: { data } }) => {
      LS.setCartToken(data.additionalData.cartToken)
      return set('cart', data.attributes, state)
    },
    [actions.setGiftCardOrder]: (state, { payload: { data } }) => {
      return set('order', data.attributes, state)
    },
    [actions.emptyGiftCardCart]: (state) => {
      LS.clearCartToken()
      return set('cart', {}, state)
    },
  },
  initialState
)

const select = selectorForSlice(slice)

const selectors = {
  currentFormStep: select('currentFormStep'),
  totalFormSteps: select('totalFormSteps'),
  giftCards: select('giftCards.success.data.attributes'),
  selectedGiftCard: select('selectedGiftCard.success.data.attributes'),
  cart: select('cart'),
  order: select('order'),
}

export { reducer, selectors, reducerKey }
