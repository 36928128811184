import React from 'react'
import c from 'classnames'

const Button = ({
  onClick,
  label,
  primary,
  xl,
  lg,
  loading,
  className,
  type,
  disabled,
  size,
  width,
  casing,
  ...props
}) => {
  const isPrimary = primary || type === 'primary'
  const isPrimaryOutline = type === 'primary-outline'
  const isSecondary = type === 'secondary'
  const isDanger = type === 'danger'

  const isExtraLarge = xl || size === 'xl'
  const isLarge = lg || size === 'lg'

  const isFullWidth = width === 'full'

  const isUppercase = casing === 'uppercase'

  return (
    <button
      {...props}
      onClick={onClick}
      className={c(
        className,
        'transition-colors rounded-full cursor-pointer',
        'disabled:bg-gray-200 disabled:cursor-not-allowed disabled:hover:bg-gray-200',
        {
          'text-white border-none font-bold': !isPrimaryOutline,
          'bg-primary hover:bg-primary-400': isPrimary,
          'bg-secondary hover:bg-secondary-400': isSecondary,
          'bg-danger hover:bg-danger-400': isDanger,
          'px-8 py-2 text-xl': isExtraLarge,
          'px-14 py-4 text-lg uppercase': isLarge,
          'w-full': isFullWidth,
          'animate-pulse': loading,
          'text-primary border-primary border-solid tracking-wider bg-white hover:text-white hover:bg-primary':
            isPrimaryOutline,
          uppercase: isUppercase,
        }
      )}
      disabled={disabled || loading}
    >
      <span>{label}</span>
    </button>
  )
}

export default Button
