import React from 'react'
import c from 'classnames'

const CartInfo = ({ level, children }) => {
  return (
    <div
      className={c('p-4 rounded-md h-fit', {
        'bg-secondary-50': level === 'info',
        'bg-danger text-white': level === 'error',
      })}
    >
      {children}
    </div>
  )
}

export default CartInfo
