import React from 'react'
import PropTypes from 'prop-types'

const DownloadIcon = ({ className }) => (
  <svg
    className={className}
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
  >
    <path
      d="M13.2188 5.625H9.5625V11.0169L11.4149 9.16488C11.5212 9.06385 11.6628 9.00835 11.8095 9.01023C11.9562 9.01211 12.0963 9.07121 12.2001 9.17494C12.3038 9.27866 12.3629 9.4188 12.3648 9.56548C12.3666 9.71216 12.3112 9.85377 12.2101 9.96012L9.39762 12.7726C9.29214 12.878 9.14912 12.9372 9 12.9372C8.85088 12.9372 8.70786 12.878 8.60238 12.7726L5.78988 9.96012C5.68885 9.85377 5.63335 9.71216 5.63523 9.56548C5.63711 9.4188 5.69621 9.27866 5.79994 9.17494C5.90366 9.07121 6.0438 9.01211 6.19048 9.01023C6.33716 9.00835 6.47877 9.06385 6.58512 9.16488L8.4375 11.0169V5.625H4.78125C4.25928 5.62556 3.75884 5.83316 3.38975 6.20225C3.02066 6.57134 2.81306 7.07178 2.8125 7.59375V14.9062C2.81306 15.4282 3.02066 15.9287 3.38975 16.2977C3.75884 16.6668 4.25928 16.8744 4.78125 16.875H13.2188C13.7407 16.8744 14.2412 16.6668 14.6102 16.2977C14.9793 15.9287 15.1869 15.4282 15.1875 14.9062V7.59375C15.1869 7.07178 14.9793 6.57134 14.6102 6.20225C14.2412 5.83316 13.7407 5.62556 13.2188 5.625Z"
      fill="currentColor"
    />
    <path
      d="M9.5625 1.6875C9.5625 1.53832 9.50324 1.39524 9.39775 1.28975C9.29226 1.18426 9.14918 1.125 9 1.125C8.85082 1.125 8.70774 1.18426 8.60225 1.28975C8.49676 1.39524 8.4375 1.53832 8.4375 1.6875V5.625H9.5625V1.6875Z"
      fill="currentColor"
    />
  </svg>
)

const DownloadLink = ({ href, title }) => {
  return (
    <a
      href={href}
      className="flex items-center gap-1 text-md text-primary"
      target="_blank"
      rel="noreferrer"
      title={title}
    >
      <DownloadIcon className="w-6 h-6 mb-0.5" />
      {title}
    </a>
  )
}

DownloadIcon.propTypes = {
  className: PropTypes.string,
}

DownloadLink.propTypes = {
  href: PropTypes.string,
  title: PropTypes.string,
}

export default DownloadLink
