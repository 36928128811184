import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router'
import { selectors as userSelectors } from 'main/user/reducer'
import * as effects from 'main/effects'
import * as userActions from 'main/user/actions'
import NavItem from 'layout/NavItem'
import { LogoutIcon } from 'components/ui/Icons'

export const LogInOut = ({
  isLoggedIn,
  onLogout,
  mobileClickHandler,
  router,
  mobile,
}) => {
  const continueUrlEncoded = encodeURIComponent(
    `${router.location.pathname}${router.location.search}`
  )
  const universalLoginUrl = `/sign-in?guestFlowDisabled=1&redirectUrl=${continueUrlEncoded}`

  if (mobile) {
    if (isLoggedIn) {
      return (
        <NavItem
          as={Link}
          label="Sign Out"
          icon={<LogoutIcon />}
          isMobile={true}
          onClick={() => {
            mobileClickHandler()
            onLogout()
          }} //; onLogout()
        />
      )
    } else {
      return (
        <NavItem
          label="Sign In"
          isMobile={true}
          as={Link}
          to={universalLoginUrl}
          className="mb-0 text-primary hover:text-primary-shade-400"
          onClick={() => {
            mobileClickHandler()
            onLogout()
          }}
        />
      )
    }
  } else {
    if (isLoggedIn) {
      return (
        <NavItem
          label="Sign Out"
          as="button"
          onClick={onLogout}
          //style cancelling -- remove once preflight is enabled
          className="mb-0 p-0 bg-transparent border-none text-primary hover:text-primary-shade-400 font-bold "
        />
      )
    } else {
      return (
        <NavItem
          label="Sign In"
          as={Link}
          to={universalLoginUrl}
          className="mb-0 text-primary hover:text-primary-shade-400"
        />
      )
    }
  }
}

const mapStateToProps = (state) => ({
  isLoggedIn: userSelectors.isAuthenticated(state),
})

const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: () =>
      effects.signOut().then(() => dispatch(userActions.clearInfo())),
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(LogInOut)
