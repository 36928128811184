import React from 'react'
import { Redirect, Route } from 'react-router'
import { UnauthorizedRoute, AuthorizedRoute } from 'components'
import { isAuthenticated } from '../auth'
import * as AccountViews from 'main/user/account/views'
import UniversalSignIn from '../user/views/UniversalSignIn'
import UniversalSignUp from '../user/views/UniversalSignUp'

const Routes = (
  <Route>
    <Redirect
      from="/membership/sign-in"
      to="/sign-in"
      query={{ redirectUrl: '/membership' }}
    />
    <Redirect
      from="/ticketing/sign-in"
      to="/sign-in"
      query={{ redirectUrl: '/ticketing' }}
    />
    <Redirect
      from="/ticketing/camps/sign-in"
      to="/sign-in"
      query={{ redirectUrl: '/ticketing/camps' }}
    />
    <UnauthorizedRoute authFunction={isAuthenticated} redirect="my-account">
      <Route path="sign-in" component={UniversalSignIn} />
      <Route path="sign-up" component={UniversalSignUp} />
    </UnauthorizedRoute>
    <AuthorizedRoute authFunction={isAuthenticated} redirect="sign-in">
      <Route path="my-account" component={AccountViews.Info} />
      <Route path="my-account/giving" component={AccountViews.Giving} />
      <Route path="my-account/order-history" component={AccountViews.OrderHistory} />
      <Route path="my-account/membership" component={AccountViews.Membership}/>
    </AuthorizedRoute>
  </Route>
)

export default Routes
