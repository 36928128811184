import React from 'react'
import c from 'classnames'

// Returns a ticketTime object
function TicketTimeRadioInput({ value, onChange, times, name }) {
  return (
    <div role="radiogroup">
      <ol className="flex flex-wrap gap-3">
        {times.map((time) => {
          const checked = value.id === time.id
          return (
            <li key={time.id} className="mb-0">
              <label
                tabIndex="0"
                className={c(
                  'px-2 py-1 m-0 border border-solid transition-colors rounded cursor-pointer border-gray-100 font-thin hover:bg-primary-100',
                  {
                    'bg-primary hover:bg-primary-400 text-white border-primary':
                      checked,
                  }
                )}
              >
                <input
                  id={time.id}
                  name={name}
                  type="radio"
                  value={time.id}
                  onChange={() => onChange(time)}
                  checked={checked}
                  disabled={time.soldOut}
                  className="fixed sr-only"
                />
                <span>{time.displayStartTime}</span>
              </label>
            </li>
          )
        })}
      </ol>
    </div>
  )
}

export default TicketTimeRadioInput
