import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { selectors } from '../reducer'
import * as apiActions from 'main/apiActions'
import SocietyCheckoutLayout from '../../../layout/SocietyCheckoutLayout'
import SocietyProductGrid from '../../../layout/SocietyProductGrid'
import { GIFT_CARD_CHECKOUT_STEPS } from '../../types'

function SelectGiftCard({ fetchGiftCards, products }) {
  useEffect(() => {
    if (!products) fetchGiftCards()
  }, [])

  return (
    <SocietyCheckoutLayout
      title="Select Gift Card"
      progress={{ steps: GIFT_CARD_CHECKOUT_STEPS, currentStep: 0 }}
    >
      <p className="text-xl text-center">
        Begin by <strong>selecting the gift card</strong> you would like to
        provide. You will be asked for additional details in the next step.
      </p>
      <SocietyProductGrid products={products} singleColumn />
    </SocietyCheckoutLayout>
  )
}

function mapStateToProps(state) {
  const giftCards = selectors.giftCards(state)

  if (!giftCards) {
    return { products: undefined }
  }

  const products = giftCards.map((giftCard) => {
    return {
      price: giftCard.isFixedPrice ? giftCard.price : undefined,
      featured: giftCard.featured,
      featuredLabel: giftCard.featuredDisplayLabel,
      imageUrl: giftCard.image,
      description: giftCard.caption,
      productUrl: giftCard.learnMoreUrl,
      selectUrl: `/gift-cards/details?id=${giftCard.id}`,
      name: giftCard.displayName,
      fullDescription: giftCard.infoModalText,
    }
  })

  return {
    products,
  }
}

const mapDispatchToProps = {
  fetchGiftCards: apiActions.fetchGiftCards,
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectGiftCard)
