import React from 'react'
import c from 'classnames'

const CheckBox = ({ name, checked, label, onChange, width }) => {
  const id = `checkbox-${name}`
  const isFullWidth = width === 'full'

  return (
    <label
      key={id}
      htmlFor={id}
      className={c('mt-1 flex items-center', {
        checked,
        'w-full': isFullWidth,
      })}
    >
      <input
        id={id}
        type="checkbox"
        checked={checked}
        name={name}
        onChange={() => onChange(!checked)}
      />
      <span className="text-sm font-medium">{label}</span>
    </label>
  )
}

export default CheckBox
