import React from 'react'
import PropTypes from 'prop-types'

const propTypes = {
  children: PropTypes.node.isRequired,
}

const defaultProps = {}

function FoundationPaymentWrapper({ children }) {
  return <div className="foundation-cart">{children}</div>
}

FoundationPaymentWrapper.propTypes = propTypes
FoundationPaymentWrapper.defaultProps = defaultProps

export default FoundationPaymentWrapper
