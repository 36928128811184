import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { flashSuccessMessage } from 'redux-flash'
import * as effects from 'main/effects'
import { ForgotPasswordForm } from '../forms'
import {
  displaySubmitFailure,
  findFirstErrorField,
  isEmpty,
  flashErrorMessage,
} from 'utils'
import { scroller } from 'react-scroll'

const propTypes = {
  flashSuccessMessage: PropTypes.func.isRequired,
  flashErrorMessage: PropTypes.func.isRequired,
}

const defaultProps = {}

function ForgotPassword({ flashSuccessMessage, flashErrorMessage }) {
  const handleSubmitFail = (errors, dispatch, submitError, { syncErrors }) => {
    if (isEmpty(errors)) {
      flashErrorMessage(
        'Could not send your password update email. Please try again later.',
        { style: 'foundation' }
      )
    } else {
      displaySubmitFailure(errors, dispatch, submitError)
      // Check for sync errors here as onSubmitFail is overridden in props
      if (!isEmpty(syncErrors)) {
        const firstErrorField = findFirstErrorField(syncErrors)
        if (!firstErrorField) return
        return scroller.scrollTo(firstErrorField, { smooth: true })
      }
    }
  }
  return (
    <div className="auth-page">
      <section className="content">
        <h2>Forgot your password? We can help.</h2>
        <ForgotPasswordForm
          onSubmit={effects.accountForgotPassword}
          onSubmitSuccess={() => {
            flashSuccessMessage(
              'You will receive an email with instruction for resetting your password'
            )
          }}
          onSubmitFail={handleSubmitFail}
        />
      </section>
    </div>
  )
}

function mapStateToProps() {
  return {}
}

const mapDispatchToProps = {
  flashSuccessMessage,
  flashErrorMessage,
}

ForgotPassword.propTypes = propTypes

ForgotPassword.defaultProps = defaultProps

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ForgotPassword
)
