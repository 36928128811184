/**
 * Note: This method will only add the appropriate punctuation (commas, decimals, etc.) if the
 * user's browser supports toLocaleString. This method degrades somewhat gracefully in that the
 * appropriate currency symbol will be displayed with a number that has two decimals (e.g. 100.00).
 *
 * @name displayCurrency
 * @param {Number} number - The number to convert into a currency display
 * @param {Object} [options] - Optional options object
 * @param {String} [options.currencyType='USD'] - The currency type used to dictate the format of the display
 * @returns {String} - Returns a string that is formatted per the specified currency Type
 */

// TODO: rip out non-USD formatting logic unless if there's actually a reason not to
function displayCurrency(number, { currencyType = 'USD' } = {}) {
  if (number === null || typeof number !== 'number' || isNaN(number)) return ''

  if (!toLocaleStringSupportsOptions())
    return `${getCurrencySymbol(currencyType)}${number.toFixed(2)}`

  switch (currencyType) {
    default:
      return formatUSD(number)
  }
}

// Private helper functions

// Checks that locale is supported (provided by MDN - https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/toLocaleString)
function toLocaleStringSupportsOptions() {
  return !!(
    typeof Intl == 'object' &&
    Intl &&
    typeof Intl.NumberFormat == 'function'
  )
}

function getCurrencySymbol(currencyType) {
  const currencySymbols = {
    USD: '$',
  }

  return currencySymbols[currencyType] || ''
}

function formatUSD(number) {
  return number.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  })
}

export default displayCurrency
