import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { propTypes as formPropTypes } from 'redux-form'
import { lpForm } from '@launchpadlab/lp-form'
import { PrimaryMemberFormSection, SecondaryMemberFormSection } from '../forms'
import {
  DEFAULT_PRIMARY_MEMBER_VALIDATION_CONSTRAINTS,
  DEFAULT_SECONDARY_MEMBER_VALIDATION_CONSTRAINTS,
} from 'config'
import { validateNested } from 'utils'
import SocietyHeading from '../../../layout/SocietyHeading'

const propTypes = {
  ...formPropTypes,
  secondaryMemberOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
}

const defaultProps = {}

function DualMemberForm({
  change,
  handleSubmit,
  secondaryMemberOptions,
  nonPrimaryMembers,
  primaryEmailEditable,
}) {
  return (
    <form onSubmit={handleSubmit} className="member-form">
      <SocietyHeading>Listed Members</SocietyHeading>
      <PrimaryMemberFormSection primaryEmailEditable={primaryEmailEditable} />
      <SecondaryMemberFormSection
        changeField={(name, val) => change(name, val)}
        secondaryMemberOptions={secondaryMemberOptions}
        nonPrimaryMembers={nonPrimaryMembers}
      />
    </form>
  )
}

DualMemberForm.propTypes = propTypes
DualMemberForm.defaultProps = defaultProps

export default compose(
  lpForm({
    name: 'membership-dual',
    validate: validateNested({
      ...DEFAULT_PRIMARY_MEMBER_VALIDATION_CONSTRAINTS,
      ...DEFAULT_SECONDARY_MEMBER_VALIDATION_CONSTRAINTS,
    }),
    submitFilters: {
      reject: ['children'],
    },
  })
)(DualMemberForm)
