import React, { useEffect, useState } from 'react'
import { selectors } from '../main/membership/reducer'
import { connect } from 'react-redux'
import ModalDialog from './ui/ModalDialog'
import * as actions from '../main/membership/actions'
import RenderedHtml from './RenderedHtml'

const MembershipUpgradeModal = ({
  header = 'Upgrade Today!',
  text = 'Do you want everything a membership has to offer? Upgrade your membership today to include all available add-ons!',
  cover,
  upgradeMembership,
  isUpgraded,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [modalDisplayed, setModalDisplayed] = useState(false)

  useEffect(() => {
    if (!isUpgraded && !modalDisplayed) {
      const timeout = setTimeout(() => {
        setIsOpen(true)
        setModalDisplayed(true)
      }, 400)
      return () => {
        clearTimeout(timeout)
      }
    }
  }, [isUpgraded])

  function handleConfirm() {
    upgradeMembership()
    setIsOpen(false)
  }

  return (
    <ModalDialog
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      closeLabel="No, thank you"
      onConfirm={handleConfirm}
      confirmLabel="Upgrade"
      title={header}
      content={<RenderedHtml>{text}</RenderedHtml>}
      cover={cover}
      compact
    />
  )
}

function mapStateToProps(state) {
  const {
    autoAddHeader: header,
    autoAddText: text,
    autoAddImage: cover,
  } = selectors.selectedMembershipType(state)

  const autoAddOns = selectors.autoAddOns(state)
  const selectedAddOns = selectors.selectedAddOns(state)

  return {
    selectedAddOns,
    autoAddOns,
    header,
    text,
    cover,
  }
}

const mapDispatchToProps = {
  setSelectedAddOns: actions.setSelectedAddOns,
}
function mergeProps(
  { autoAddOns, selectedAddOns, ...stateProps },
  { setSelectedAddOns, ...dispatchProps }
) {
  const selectAddOnIds = new Set(selectedAddOns.map((addOn) => addOn.addOnId))
  const isUpgraded =
    autoAddOns.filter(({ addOnId }) => selectAddOnIds.has(addOnId)).length > 0

  function upgradeMembership() {
    autoAddOns.forEach((addOn) => setSelectedAddOns(addOn))
  }

  return {
    ...stateProps,
    ...dispatchProps,
    isUpgraded,
    upgradeMembership,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(MembershipUpgradeModal)
