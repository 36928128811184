import { createRoutesFromReactChildren } from 'react-router/lib//RouteUtils'

// Allows a react router component to be rendered correctly.
// See https://marmelab.com/blog/2016/09/20/custom-react-router-component.html

function adaptToReactRouter() {
  return (Wrapped) => {
    function Wrapper() {}
    Wrapper.createRouteFromReactElement = ({ props }, parentRoute) =>
      createRoutesFromReactChildren(Wrapped(props), parentRoute).pop()
    return Wrapper
  }
}

export default adaptToReactRouter
