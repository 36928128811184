import React from 'react'
import SharedOrderHistory from 'main/user/views/shared/OrderHistory'
import AccountPageLayout from '../components/AccountPageLayout'

function OrderHistory() {
  return (
    <AccountPageLayout className="space-y-16">
      <SharedOrderHistory header={<h1 className="text-4xl">Order History</h1>}/>
    </AccountPageLayout>
  )
}


export default OrderHistory
