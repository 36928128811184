import { handleActions } from 'redux-actions'
import {
  selectorForSlice,
  setState,
  unsetState,
} from '@launchpadlab/lp-redux-utils'
import { setOnSuccess, handleResponse } from '@launchpadlab/lp-redux-api'
import { isAuthenticated } from '../auth'
import * as LS from 'services/sessionStorage'
import { set } from 'utils'
import * as actions from './actions'
import * as apiActions from 'main/apiActions'

const reducerKey = 'user'
const slice = 'root.user'

const initialState = {
  /* Presence of a token indicates that the user has a session from an ID.me
    verification, but _not_ that they are actually verified.
  */
  hasOAuthSession: !!LS.getOAuthIdentityProfileToken(),
  isAuthenticated: isAuthenticated(),
  isGuestFlowPreferred: LS.getIsGuestFlowPreferred(),
  isVerifiedMilitary: false, // verified by API call on App load
  memberId: LS.getMemberId(),
  oAuthRedirectPath: LS.getOAuthRedirectPath() || undefined,
}

const reducer = handleActions(
  {
    [apiActions.fetchUserOrderHistory]: setOnSuccess('orderHistory'),
    [apiActions.validateIdMeSession]: handleResponse(
      (state) => {
        return set('isVerifiedMilitary', true, state)
      },
      (state) => {
        LS.clearOAuthIdentityProfileToken()
        return {
          ...state,
          hasOAuthSession: false,
          isVerifiedMilitary: false,
        }
      }
    ),
    [actions.setAuthenticated]: (state, { payload: { token } }) => {
      if (!token) {
        LS.clearAuthToken()
        return set('isAuthenticated', false, state)
      }
      LS.setAuthToken(token)
      return set('isAuthenticated', true, state)
    },
    [actions.setUserGuestFlowPreferred]: (state, { payload }) => {
      LS.setGuestFlowPreferred(payload)
      return set('isGuestFlowPreferred', payload, state)
    },
    [actions.setMemberId]: (state, { payload: id }) => {
      if (!id) {
        LS.clearMemberId()
        return set('memberId', null, state)
      }
      LS.setMemberId(id)
      return set('memberId', id, state)
    },
    [actions.setPrimaryMember]: setState('primaryMember'),
    [actions.clearOrderHistory]: unsetState('orderHistory'),
    [actions.setOAuthRedirectPath]: setState(
      'oAuthRedirectPath',
      ({ payload }) => {
        LS.setOAuthRedirectPath(payload)
        return payload
      }
    ),
    [actions.clearOAuthRedirectPath]: setState('oAuthRedirectPath', () => {
      LS.clearOAuthRedirectPath()
      return null
    }),
    [actions.setMilitaryStatus]: setState(
      'isVerifiedMilitary',
      ({ payload: { token } }) => {
        LS.clearOAuthIdentityProfileToken()
        if (token) LS.setOAuthIdentityProfileToken(token)
        return true
      }
    ),
    [actions.clearMilitaryStatus]: setState('isVerifiedMilitary', () => {
      LS.clearOAuthIdentityProfileToken()
      return false
    }),
  },
  initialState
)

const select = selectorForSlice(slice)

const selectors = {
  hasOAuthSession: select('hasOAuthSession'),
  isAuthenticated: select('isAuthenticated'),
  isGuestFlowPreferred: select('isGuestFlowPreferred'),
  isVerifiedMilitary: select('isVerifiedMilitary'),
  memberId: select('memberId'),
  oAuthRedirectPath: select('oAuthRedirectPath'),
  primaryMember: select('primaryMember'),
  orderHistory: select('orderHistory'),
}

export { reducer, selectors, reducerKey }
