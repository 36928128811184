import React from 'react'
import PropTypes from 'prop-types'

const propTypes = {
  hasGiftCard: PropTypes.bool.isRequired,
  setHasGiftCard: PropTypes.func.isRequired,
}

const defaultProps = {}

function GiftCardCheckbox({ hasGiftCard, setHasGiftCard }) {
  return (
    <span className="checkbox">
      <input
        id="gift-card-box"
        type="checkbox"
        onChange={() => setHasGiftCard(!hasGiftCard)}
        checked={hasGiftCard}
      />
      <label htmlFor="gift-card-box">I have a gift card</label>
    </span>
  )
}

GiftCardCheckbox.propTypes = propTypes
GiftCardCheckbox.defaultProps = defaultProps

export default GiftCardCheckbox
