import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { orderBy } from 'lodash'
import * as Types from 'main/types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Spinner } from '@launchpadlab/lp-components'
import { selectors } from '../../reducer'
import * as apiActions from 'main/apiActions'
import * as routerActions from 'react-router-redux'

const propTypes = {
  fetchOrCreateFoundationCart: PropTypes.func.isRequired,
  foundationCart: Types.foundationCart,
  fetchFoundationOrder: PropTypes.func.isRequired,
  foundationOrderCart: Types.foundationCart,
  fetchFoundationProductCategories: PropTypes.func.isRequired,
  foundationProductCategories: PropTypes.arrayOf(
    Types.foundationProductCategory
  ),
  cartToken: PropTypes.string, // optional cartToken to specify for CartAccessor to use
  orderToken: PropTypes.string, // optional orderToken to specify for CartAccessor to use
  isCartDetailsPage: PropTypes.bool,
  replace: PropTypes.func.isRequired,
  children: PropTypes.func,
}

const defaultProps = {
  foundationCart: null,
  cartToken: null,
  foundationOrderCart: null,
  orderToken: null,
  isCartDetailsPage: false,
}

function CartAccessor({
  fetchOrCreateFoundationCart,
  foundationCart,
  foundationOrderCart,
  fetchFoundationOrder,
  fetchFoundationProductCategories,
  foundationProductCategories,
  cartToken,
  orderToken,
  replace,
  isCartDetailsPage,
  children,
}) {
  useEffect(() => {
    if (orderToken) {
      // request order if an order token is passed in
      fetchFoundationOrder(orderToken)
    } else {
      // request or create cart if no order token passed
      fetchOrCreateFoundationCart(cartToken || null)
    }

    // always fetch foundation products
    fetchFoundationProductCategories()
  }, [cartToken, orderToken])

  // use order foundationCart or foundationOrderCart depending on the token passed
  const cart = orderToken ? foundationOrderCart : foundationCart

  const validCart =
    cart &&
    !!cart.foundationLineItems.length &&
    !cart.foundationLineItems.some((lineItem) => !lineItem.valid)

  useEffect(() => {
    // If there is no cart yet, do nothing:
    if (!cart) return
    if (isCartDetailsPage || validCart) return

    // If the cart is not valid and this is not the cart details page, then redirect
    replace(`/give/cart?cartToken=${cart.token}`)
  }, [cart, validCart, isCartDetailsPage])

  if (!(cart && foundationProductCategories.length > 0)) return <Spinner />

  const generalDonationCategoryIds = foundationProductCategories
    .filter((category) => category.donationType == 'general')
    .map((generalCategory) => generalCategory.id)
  const generalDonationLineItems = orderBy(
    cart.foundationLineItems.filter((li) => {
      return generalDonationCategoryIds.includes(li.foundationProductCategoryId)
    }),
    ['foundationProductCategoryId', 'id']
  )
  const fundCategoryIds = foundationProductCategories
    .filter((category) => category.donationType == 'fund')
    .map((fundCategory) => fundCategory.id)
  const fundLineItems = orderBy(
    cart.foundationLineItems.filter((li) => {
      return fundCategoryIds.includes(li.foundationProductCategoryId)
    }),
    ['foundationProductCategoryId', 'id']
  )
  const adoptAnAnimalCategoryIds = foundationProductCategories
    .filter((category) => category.donationType == 'adopt_an_animal')
    .map((animalCategory) => animalCategory.id)
  const adoptAnAnimalLineItems = orderBy(
    cart.foundationLineItems.filter((li) => {
      return adoptAnAnimalCategoryIds.includes(li.foundationProductCategoryId)
    }),
    ['foundationProductCategoryId', 'position', 'id']
  )

  const plaqueCategoryIds = foundationProductCategories
    .filter((category) => category.donationType == 'plaque')
    .map((plaqueCategory) => plaqueCategory.id)
  const plaqueLineItems = orderBy(
    cart.foundationLineItems.filter((li) => {
      return plaqueCategoryIds.includes(li.foundationProductCategoryId)
    }),
    ['foundationProductCategoryId', 'position', 'id']
  )

  return children
    ? children({
        foundationCart: cart,
        foundationProductCategories,
        generalDonationLineItems,
        fundLineItems,
        adoptAnAnimalLineItems,
        plaqueLineItems,
        validCart,
      })
    : null
}

CartAccessor.propTypes = propTypes
CartAccessor.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    foundationCart: selectors.foundationCart(state),
    foundationOrderCart: selectors.foundationOrderCart(state),
    foundationProductCategories: selectors.foundationProductCategories(state),
  }
}

const mapDispatchToProps = {
  fetchOrCreateFoundationCart: apiActions.fetchOrCreateFoundationCart,
  fetchFoundationOrder: apiActions.fetchFoundationOrder,
  fetchFoundationProductCategories: apiActions.fetchFoundationProductCategories,
  replace: routerActions.replace,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  CartAccessor
)
