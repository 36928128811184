import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import * as apiActions from 'main/apiActions'
import { Button, InputError } from '@launchpadlab/lp-components'
import classnames from 'classnames'

const propTypes = {
  validateGiftCard: PropTypes.func.isRequired,
  onApply: PropTypes.func.isRequired,
  onApplySuccess: PropTypes.func.isRequired,
  onApplyFail: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  setIsLoading: PropTypes.func.isRequired,
}

const defaultProps = {}

const validateGiftCardNumberInput = (giftCardNumber) => {
  if (!giftCardNumber) return "Gift card number can't be blank"
}

function GiftCardInput({
  validateGiftCard,
  onApply,
  onApplySuccess,
  onApplyFail,
  isLoading,
  setIsLoading,
}) {
  const [giftCardNumber, setGiftCardNumber] = useState('')
  const [errorMessage, setErrorMessage] = useState(null)
  const hasErrorMessage = !!errorMessage

  const applyGiftCard = () => {
    const errors = validateGiftCardNumberInput(giftCardNumber)
    if (errors) return setErrorMessage(errors)

    setIsLoading(true)
    validateGiftCard(giftCardNumber)
      .then(({ giftCardNumber }) => onApply(giftCardNumber))
      .then((response) => {
        setGiftCardNumber('')
        onApplySuccess(response)
      })
      .catch(onApplyFail)
      .finally(() => setIsLoading(false))
  }

  return (
    <div className="conditional-form-item">
      <div className={classnames({ error: hasErrorMessage })}>
        <label htmlFor="gift-card-number">Gift Card Number</label>
        <input
          type="text"
          id="gift-card-number"
          value={giftCardNumber}
          onChange={(e) => {
            setErrorMessage(validateGiftCardNumberInput(e.target.value))
            setGiftCardNumber(e.target.value)
          }}
          aria-describedby={hasErrorMessage ? 'gift-card-number-error' : null}
        />
        <InputError
          id="gift-card-number-error"
          error={errorMessage}
          touched={hasErrorMessage}
          invalid={hasErrorMessage}
        />
        <Button
          submitting={isLoading}
          onClick={applyGiftCard}
          className="button-green-outline"
        >
          Apply
        </Button>
      </div>
    </div>
  )
}

GiftCardInput.propTypes = propTypes
GiftCardInput.defaultProps = defaultProps

function mapStateToProps() {
  return {}
}

const mapDispatchToProps = {
  validateGiftCard: apiActions.validateGiftCard,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  GiftCardInput
)
