import React from 'react'
import classnames from 'classnames'
import { Input, Select } from '@launchpadlab/lp-components'
function FoundationInput({
  input,
  label,
  className,
  inputType = 'text',
  ...rest
}) {
  // label equal false if the label is not needed
  const labelValue = input.value ? label : false

  switch (inputType) {
    case 'select':
      return (
        <Select
          input={input}
          label={labelValue}
          className={classnames('foundation-input-field', {
            'float-label': labelValue,
            [String(className)]: !!className,
          })}
          {...rest}
        />
      )
    case 'password':
      return (
        <Input
          input={input}
          type="password"
          label={labelValue}
          className={classnames('foundation-input-field', {
            'float-label': labelValue,
            [String(className)]: !!className,
          })}
          {...rest}
        />
      )
    case 'email':
      return (
        <Input
          input={input}
          type="email"
          label={labelValue}
          className={classnames('foundation-input-field', {
            'float-label': labelValue,
            [String(className)]: !!className,
          })}
          {...rest}
        />
      )
    default:
      return (
        <Input
          input={input}
          label={labelValue}
          className={classnames('foundation-input-field', {
            'float-label': labelValue,
            [String(className)]: !!className,
          })}
          {...rest}
        />
      )
  }
}

export default FoundationInput
