import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'main/types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { selectors } from './reducer'
import * as apiActions from 'main/apiActions'
import { Spinner } from '@launchpadlab/lp-components'
import { orderBy, first, get } from 'lodash'
import bannerImage from 'images/foundation-landing.jpg'
import { LandingOption } from './shared'

const propTypes = {
  fetchFoundationProductCategories: PropTypes.func.isRequired,
  foundationProductCategories: PropTypes.arrayOf(
    Types.foundationProductCategory
  ),
}

const defaultProps = {
  foundationProductCategories: [],
}

function FoundationLanding({
  fetchFoundationProductCategories,
  foundationProductCategories,
}) {
  useEffect(() => {
    if (foundationProductCategories.length == 0)
      fetchFoundationProductCategories()
  }, [foundationProductCategories])

  if (foundationProductCategories.length == 0) return <Spinner />

  const activePlaqueCategories = orderBy(
    foundationProductCategories.filter(
      (cat) => !!cat.active && cat.donationType == 'plaque'
    ),
    ['donationType', 'id']
  ).map((category) => ({
    title: category.name,
    description: category.homepageDescription,
    buttonText: `Purchase ${category.name}`,
    link: `/give/packages?categoryId=${category.id}`,
  }))

  const activeFundCategories = orderBy(
    foundationProductCategories.filter(
      (cat) => !!cat.active && cat.donationType == 'fund'
    ),
    ['donationType', 'id']
  ).map((category) => {
    const fundProductId = get(first(category.foundationProducts), 'id')

    return {
      title: category.name,
      description: category.homepageDescription,
      buttonText: `Support The Fund`,
      link: `/give/donate?productId=${fundProductId}`,
    }
  })

  const initialArrayOfOptions = [
    {
      title: 'General Donation',
      description:
        'Not sure where you would like to donate? Make a general donation! We love getting any kind of support wherever we can!',
      buttonText: 'Donate',
      link: '/give/donate',
    },
    {
      title: 'Patron Membership',
      description:
        "Join us as a Patron Member, the true heart and soul of the Zoo's financial support. Not only does your membership advance our global mission, it also gives you access to one-of-a-­kind experiences and privileges.",
      buttonText: 'Join or Renew',
      link: 'https://www.omahazoofoundation.org/become-a-member/',
    },
    {
      title: 'Adopt an Animal',
      description:
        "Become a ZooMama or ZooDaddy to one of the Zoo's adorable wild creatures - or give the gift of Zoo Parenthood to someone you love. Your support helps us provide the best care possible to our wonderful animals.",
      buttonText: 'Choose Your Animal',
      link: '/give/adopt-an-animal',
    },
  ]

  const memorialOptionArray = [
    {
      title: 'Honorary and Memorial Giving',
      description:
        'If you have a loved one with a special connection to Omaha’s Henry Doorly Zoo and Aquarium; establishing a memorial or tribute gift in their name is a meaningful way to honor them or celebrate a special milestone.',
      buttonText: 'Honor a Loved One',
      link: '/give/donate?memorial=true',
    },
  ]

  const finalArrayOfOptions = initialArrayOfOptions.concat(
    activePlaqueCategories,
    memorialOptionArray,
    activeFundCategories
  )
  return (
    <div>
      <div className="title">
        <div className="title__banner-group">
          <h2 className="title__banner-group__zoo-parent">
            Together, we can preserve and enhance the legacy of Omaha's Henry
            Doorly Zoo and Aquarium for future generations
          </h2>
          <div className="title__banner-group__banner-image">
            <img src={bannerImage} alt="" />
          </div>
        </div>
        <div className="title__description-container title__description-container--white">
          <p className="title__description-container__description title__description-container__description--white">
            <strong>
              Whether your interest is in wildlife, educational programming,
              conservation initiatives, research or other areas, a gift to the
              Omaha Zoo Foundation will have an ongoing impact.
            </strong>
          </p>
        </div>
        <div className="title__description-container">
          <div className="title__description-container__description">
            <div className="title__description-container__description__section title__description-container__description__section--title">
              Your Financial Support Will Help Us:
            </div>
            <div className="title__description-container__description__section">
              <ul>
                <li>Build and renovate exhibits</li>
                <li>
                  Expand existing educational programs and launch new ones
                </li>
                <li>
                  Improve the zoo experience by making the grounds more
                  beautiful and accessible
                </li>
                <li>
                  Participate in conservation initiatives that touch the most
                  remote habitats across the globe
                </li>
                <li>Expand the rounds and amenities to accommodate visitors</li>
                <li>
                  Build the future of Omaha's Henry Doorly Zoo and Aquarium by
                  supporting our endowment
                </li>
              </ul>
            </div>
          </div>
        </div>
        {finalArrayOfOptions.map((optionObject, ind) => {
          const whiteBg = !(ind === 0 || ind % 2 === 0) // even indexes, including 0

          return (
            <LandingOption
              title={optionObject.title}
              description={optionObject.description}
              buttonText={optionObject.buttonText}
              link={optionObject.link}
              whiteBg={whiteBg}
              key={ind}
            />
          )
        })}
      </div>
    </div>
  )
}

FoundationLanding.propTypes = propTypes
FoundationLanding.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    foundationProductCategories: selectors.foundationProductCategories(state),
  }
}

const mapDispatchToProps = {
  fetchFoundationProductCategories: apiActions.fetchFoundationProductCategories,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  FoundationLanding
)
