import React from 'react'
import * as Types from 'main/types'
import PropTypes from 'prop-types'
import { RenderedHtml } from 'components'

const propTypes = {
  foundationProduct: Types.foundationProduct.isRequired,
  quantity: PropTypes.number,
  foundationCart: Types.foundationCart,
  removeFoundationProduct: PropTypes.func.isRequired,
  addFoundationProduct: PropTypes.func.isRequired,
}

const defaultProps = {
  quantity: 0,
  foundationCart: null,
}

function foundationProduct({
  foundationProduct,
  quantity,
  foundationCart,
  addFoundationProduct,
  removeFoundationProduct,
}) {
  const hasInventoryNumber =
    foundationProduct.inventoryLimitDisplay == 'number' ||
    (typeof foundationProduct.inventoryLimitReal == 'number' &&
      typeof foundationProduct.currentInventoryBought == 'number')
  const productDisplaysInventory = !!foundationProduct.displayLimitInApp
  const canDisplayInventory = !!hasInventoryNumber && productDisplaysInventory
  const inventoryRemaining =
    canDisplayInventory && foundationProduct.inventoryLimitDisplay
      ? foundationProduct.inventoryLimitDisplay
      : foundationProduct.inventoryLimitReal -
        foundationProduct.currentInventoryBought
  return (
    <div className="select-package__choices">
      <div className="select-package__choices__package-name">
        <p className="select-package__choices__package-name__title">
          {foundationProduct.name}
        </p>
        {/* We didn't use displayCurrency here because we just want the display of dollars (no cents) */}
        <p className="select-package__choices__package-name__price">
          ${foundationProduct.amount}
        </p>
        {canDisplayInventory && (
          <p className="select-package__choices__package-name__available">
            {inventoryRemaining} remaining
          </p>
        )}
      </div>
      <div className="select-package__choices__perk-list">
        <RenderedHtml className="select-package__choices__perk-list__list">
          {foundationProduct.description}
        </RenderedHtml>
      </div>

      <div className="select-package__choices__quantity">
        <button
          type="button"
          className="clickable"
          onClick={() =>
            removeFoundationProduct({ foundationProduct, foundationCart })
          }
          style={{
            marginRight: '10px',
          }}
          aria-label="Remove Item"
        >
          −
        </button>
        <p>{quantity}</p>
        <button
          type="button"
          className="clickable"
          onClick={() =>
            addFoundationProduct({ foundationProduct, foundationCart })
          }
          style={{
            marginLeft: '10px',
          }}
          aria-label="Add Item"
        >
          +
        </button>
      </div>
    </div>
  )
}

foundationProduct.propTypes = propTypes
foundationProduct.defaultProps = defaultProps

export default foundationProduct
