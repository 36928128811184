import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Link } from 'react-router'
import navLogoSq from 'images/logo-fdn-sq.svg'
import hamburgerMenu from 'images/hamburger-menu.svg'
import cart from 'images/cart.svg'
import AccountMenu from 'main/user/account/components/AccountNav'
import { selectors } from '../reducer'
import { connect } from 'react-redux'

const propTypes = {
  isPayment: PropTypes.bool.isRequired,
  isMobileNavOpen: PropTypes.bool.isRequired,
  setIsMobileNavOpen: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  cartToken: PropTypes.string,
  cartItemsCount: PropTypes.number,
}

const defaultProps = {
  cartToken: null,
}

const OmahaZooFoundationLogo = () => (
  <a className="nav-bar__title" href="https://www.omahazoofoundation.org/">
    <img className="nav-bar__logo" src={navLogoSq} alt="Omaha Zoo" />
    OMAHA ZOO FOUNDATION
  </a>
)

function NavBar({
  isPayment,
  isMobileNavOpen,
  setIsMobileNavOpen,
  isMobile,
  cartToken,
  cartItemsCount,
}) {
  const cartLink = cartToken
    ? `/give/cart?cartToken=${cartToken}`
    : '/give/cart'
  if (isPayment) {
    return (
      <div className="nav-bar">
        <Link to="/give/cart/info">
          <img className="nav-bar__logo" src={navLogoSq} alt="Omaha Zoo" />
        </Link>
        <Link className="nav-bar__link nav-bar__link--small" to={cartLink}>
          Return to Cart
        </Link>
      </div>
    )
  }

  return isMobile ? (
    <div
      className={classnames('nav-bar', {
        'nav-bar--mobile-open': isMobileNavOpen,
      })}
    >
      <OmahaZooFoundationLogo />
      <div
        onClick={() => setIsMobileNavOpen(!isMobileNavOpen)}
        className="nav-bar__link nav-bar__link--mobile-hamburger"
      >
        {isMobileNavOpen ? 'X' : <img src={hamburgerMenu} />}
      </div>
    </div>
  ) : (
    <div className="nav-bar">
      <OmahaZooFoundationLogo />
      <a
        className="nav-bar__link"
        href="https://www.omahazoofoundation.org/our-mission/"
      >
        Our Mission
      </a>
      <Link
        className="nav-bar__link"
        href="https://www.omahazoofoundation.org/ways-to-give/"
      >
        Ways to Give
      </Link>
      <a
        className="nav-bar__link"
        href="https://www.omahazoofoundation.org/become-a-member/"
      >
        Become a Member
      </a>
      <Link
        className="nav-bar__link nav-bar__link--donate-btn"
        to="/give/donate"
      >
        Donate
      </Link>
      <Link className="nav-bar__link" to={cartLink}>
        <div className="relative">
          <img className="max-w-40" src={cart} alt="Cart" />
          <p className=" nav-bar__link absolute top-1/4 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-xs lg:text-sm	xl:text-lg	px-2 py-1">
            {cartItemsCount}
          </p>
        </div>
      </Link>
      <AccountMenu />
    </div>
  )
}

NavBar.propTypes = propTypes
NavBar.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    cartItemsCount: selectors.cartItemsCount(state),
  }
}

export default connect(mapStateToProps)(NavBar)
