import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { FoundationInput } from 'components'
import { SubmitButton } from '@launchpadlab/lp-components'
import { scroller } from 'react-scroll'
import { lpForm } from '@launchpadlab/lp-form'
import { modifyProps } from '@launchpadlab/lp-hoc'
import { first, keys, getCountryOptions } from 'utils'
import * as options from 'main/options'
import { Field } from 'redux-form'

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  isUsCustomer: PropTypes.bool.isRequired,
  initialValues: PropTypes.object.isRequired,
}

function PersonalInformationForm({
  handleSubmit,
  invalid,
  submitting,
  initialValues,
  isUsCustomer,
}) {
  // need to check first name because Country Code is always passed in as initial values
  const editing = initialValues?.firstName
  return (
    <form onSubmit={handleSubmit} className="flex flex-col">
      <div className="flex flex-col sm:gap-4 sm:flex-row">
        <Field
          name="firstName"
          component={FoundationInput}
          label="First Name"
          placeholder="First Name"
          readOnly={initialValues?.firstName}
          disabled={initialValues?.firstName}
        />
        <Field
          name="lastName"
          component={FoundationInput}
          label="Last Name"
          placeholder="Last Name"
          readOnly={initialValues?.lastName}
          disabled={initialValues?.lastName}
        />
      </div>
      <div className="flex flex-col sm:gap-4 sm:flex-row">
        <Field
          name="email"
          component={FoundationInput}
          label="Email"
          placeholder="Email"
          readOnly={true}
          disabled={true}
        />
        <Field
          name="phone"
          component={FoundationInput}
          label="Phone"
          placeholder="Phone"
        />
      </div>
      <Field
        name="country"
        component={FoundationInput}
        options={getCountryOptions()}
        label="Country"
        inputType="select"
        placeholder="Country"
      />
      <Field
        name="address_1" // needs to be formatted as snake case to align with API contract
        component={FoundationInput}
        label="Street Address"
        placeholder="Street Address"
      />
      <Field
        name="city"
        component={FoundationInput}
        label="City"
        placeholder="City"
      />

      <div className="flex flex-col sm:gap-4 sm:flex-row">
        {isUsCustomer && (
          <Field
            name="state"
            component={FoundationInput}
            options={options.states}
            label="State"
            inputType="select"
            placeholder="Select State"
          />
        )}
        <Field
          name="zip"
          component={FoundationInput}
          label="Zip / Post Code"
          placeholder="Zip / Post Code"
        />
      </div>

      <SubmitButton
        className="flex self-end px-4 py-1 text-lg font-semibold capitalize transition-colors border-none rounded-full cursor-pointer hover:bg-primary-700 "
        invalid={invalid}
        submitting={submitting}
      >
        {editing ? 'Update Your Information' : 'Add Your Information'}
      </SubmitButton>
    </form>
  )
}

PersonalInformationForm.propTypes = propTypes

const modify = ({ onSubmit }) => {
  return {
    constraints: {
      firstName: { presence: true },
      lastName: { presence: true },
      email: {
        presence: true,
        email: true,
        length: { maximum: 60 },
      },
      phone: { presence: true, length: { maximum: 20 } },
      country: {
        presence: true,
      },
      address_1: {
        presence: {
          message: `^Street address can't be blank`,
        },
        length: { maximum: 50 },
      },
      city: {
        presence: true,
      },
      state: {
        presence: true,
      },
      zip: {
        presence: true,
      },
    },
    onSubmit: (values, dispatch, props) => {
      // if backend validation or recaptcha is needed, should be added here
      return onSubmit(values, dispatch, props)
    },
  }
}

export default compose(
  modifyProps(modify),
  lpForm({
    name: 'account-personal-information',
    onSubmitFail: (params, dispatch, _, { syncErrors }) => {
      const firstErrorField = first(keys(syncErrors))
      return scroller.scrollTo(firstErrorField, {
        smooth: true,
      })
    },
    enableReinitialize: true,
  })
)(PersonalInformationForm)
