import React from 'react'
import Button from '../../../../components/ui/Button'
import { connect } from 'react-redux'
import SocietyPageLayout from '../../../../layout/SocietyPageLayout'
import { compose } from 'redux'
import { withRouter } from 'react-router'
import HelpText from './HelpText'

const MembershipAssociated = compose(
  connect(),
  withRouter
)(({ existingAccountEmail, onClearAll, redirectUrl, email, router }) => {
  function handleSkip() {
    router.push({
      pathname: '/sign-up',
      query: {
        email: email,
        redirectUrl: redirectUrl,
      },
    })
  }

  return (
    <SocietyPageLayout title="Membership associated to another email">
      <section className="p-5 mx-auto space-y-10 bg-white shadow pb-7 sm:w-2/3 text-lg">
        <div>
          Uh-oh! We found your membership, but it's attached to a different
          email address:{' '}
        </div>
        <div className="font-bold">{existingAccountEmail}</div>
        <div>
          To utilize this membership, please Sign In using the existing account.
          If you've forgotten your password, you can reset it with "Forgot
          Password?".
        </div>
        <div className="flex items-center justify-center">
          <div className="sm:w-1/2">
            <Button
              type="primary"
              label="Sign In"
              size="xl"
              casing="uppercase"
              width="full"
              onClick={onClearAll}
            />
          </div>
          <div className="sm:w-1/2">
            <Button
              type="primary-outline"
              label="Sign up for a new account"
              size="xl"
              casing="uppercase"
              width="full"
              onClick={handleSkip}
            />
          </div>
        </div>
      </section>
      <HelpText />
    </SocietyPageLayout>
  )
})
export default MembershipAssociated
