// AUTH

const AUTH_TOKEN_KEY = 'omaha-zoo-token'

export function getAuthToken() {
  return getItem(AUTH_TOKEN_KEY)
}

export function setAuthToken(token) {
  return setItem(AUTH_TOKEN_KEY, token)
}

export function clearAuthToken() {
  return removeItem(AUTH_TOKEN_KEY)
}

// FOUNDATION AUTH

const FOUNDATION_AUTH_TOKEN_KEY = 'omaha-zoo-token-foundation'

export function getFoundationAuthToken() {
  return getItem(FOUNDATION_AUTH_TOKEN_KEY)
}

export function setFoundationAuthToken(token) {
  return setItem(FOUNDATION_AUTH_TOKEN_KEY, token)
}

export function clearFoundationAuthToken() {
  return removeItem(FOUNDATION_AUTH_TOKEN_KEY)
}

// CART TOKEN

const CART_TOKEN_KEY = 'omaha-zoo-cart-token'

export function getCartToken() {
  return getItem(CART_TOKEN_KEY)
}

export function setCartToken(token) {
  return setItem(CART_TOKEN_KEY, token)
}

export function clearCartToken() {
  return removeItem(CART_TOKEN_KEY)
}

// MEMBER ID
// OAUTH

const OAUTH_REDIRECT_PATH_KEY = 'omaha-zoo-oauth-path'

export function getOAuthRedirectPath() {
  return getItem(OAUTH_REDIRECT_PATH_KEY)
}

export function setOAuthRedirectPath(url) {
  return setItem(OAUTH_REDIRECT_PATH_KEY, url)
}

export function clearOAuthRedirectPath() {
  return removeItem(OAUTH_REDIRECT_PATH_KEY)
}

const OAUTH_IDENTITY_TOKEN = 'omaha-zoo-oauth-identity-token'

export function getOAuthIdentityProfileToken() {
  return getItem(OAUTH_IDENTITY_TOKEN)
}

export function setOAuthIdentityProfileToken(token) {
  return setItem(OAUTH_IDENTITY_TOKEN, token)
}

export function clearOAuthIdentityProfileToken() {
  return removeItem(OAUTH_IDENTITY_TOKEN)
}

// Member ID
const MEMBER_ID_KEY = 'omaha-zoo-member-id'

export function getMemberId() {
  return parseInt(getItem(MEMBER_ID_KEY))
}

export function setMemberId(id) {
  return setItem(MEMBER_ID_KEY, id)
}

export function clearMemberId() {
  return removeItem(MEMBER_ID_KEY)
}

// MEMBERSHIP

const MEMBERSHIP_FORM_KEY = 'omaha-zoo-membership'
const ADD_ON_UPSELL_KEY = 'omaha-zoo-auto-add-on-modal'
const MEMBERSHIP_DONATION_KEY = 'omaha-zoo-membership-donation-modal'

export function getMembershipForm() {
  const formJson = getItem(MEMBERSHIP_FORM_KEY)
  return formJson ? JSON.parse(formJson) : null
}

export function setMembershipForm(formObj) {
  return setItem(MEMBERSHIP_FORM_KEY, JSON.stringify(formObj))
}

export function clearMembershipForm() {
  return removeItem(MEMBERSHIP_FORM_KEY)
}

export function getAddOnUpsellModalMemberships() {
  const addOnUpsellShown = getItem(ADD_ON_UPSELL_KEY)
  return addOnUpsellShown ? JSON.parse(addOnUpsellShown) : {}
}

export function setAddOnUpsellModalMemberships(addOnMemberships = {}) {
  return setItem(ADD_ON_UPSELL_KEY, JSON.stringify(addOnMemberships))
}

export function getDonationModalMemberships() {
  const donationMemberships = getItem(MEMBERSHIP_DONATION_KEY)
  return donationMemberships ? JSON.parse(donationMemberships) : {}
}

export function setDonationModalMemberships(donationMemberships = {}) {
  return setItem(MEMBERSHIP_DONATION_KEY, JSON.stringify(donationMemberships))
}

// MEMBERSHIP FORM PROGRESS

const MEMBERSHIP_FORM_STEP_KEY = 'omaha-zoo-form-step'

export function getMembershipFormStep() {
  const step = getItem(MEMBERSHIP_FORM_STEP_KEY)
  return step ? Number(step) : null
}

export function setMembershipFormStep(num) {
  return setItem(MEMBERSHIP_FORM_STEP_KEY, num)
}

export function clearMembershipFormStep() {
  return removeItem(MEMBERSHIP_FORM_STEP_KEY)
}

// TICKETING

const TICKETING_CART_TOKEN_KEY = 'omaha-zoo-ticketing-cart-token'

export function getTicketCartToken() {
  return getItem(TICKETING_CART_TOKEN_KEY)
}

export function setTicketCartToken(token) {
  return setItem(TICKETING_CART_TOKEN_KEY, token)
}

export function clearTicketCartToken() {
  return removeItem(TICKETING_CART_TOKEN_KEY)
}

// DONATION
const DONATION_FORM_KEY = 'omaha-zoo-donation'

export function getDonationForm() {
  const formJson = getItem(DONATION_FORM_KEY)
  return formJson ? JSON.parse(formJson) : {}
}

export function setDonationForm(formObj) {
  return setItem(DONATION_FORM_KEY, JSON.stringify(formObj))
}

/* HELPERS */

// Get item from sessionStorage
function getItem(key) {
  return sessionStorage.getItem(key)
}

// Set item in local storage, or session storage if specified
function setItem(key, value) {
  return sessionStorage.setItem(key, value)
}

// Remove item from local storage and session storage
function removeItem(key) {
  return sessionStorage.removeItem(key)
}

// FOUNDATION

const FOUNDATION_CART_TOKEN_KEY = 'omaha-zoo-foundation-cart-token'

export function getFoundationCartToken() {
  return getItem(FOUNDATION_CART_TOKEN_KEY)
}

export function setFoundationCartToken(token) {
  return setItem(FOUNDATION_CART_TOKEN_KEY, token)
}

export function clearFoundationCartToken() {
  return removeItem(FOUNDATION_CART_TOKEN_KEY)
}

const IS_GUEST_FLOW_PREFERRED_KEY = 'is-guest-flow-preferred'

export function getIsGuestFlowPreferred() {
  return getItem(IS_GUEST_FLOW_PREFERRED_KEY) === 'true'
}

export function setGuestFlowPreferred(value) {
  return setItem(IS_GUEST_FLOW_PREFERRED_KEY, value)
}
