import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@launchpadlab/lp-components'
import { displayCurrency } from 'utils'

const propTypes = {
  appliedAmount: PropTypes.number.isRequired,
  remainingBalance: PropTypes.number.isRequired,
  onRemove: PropTypes.func.isRequired,
  onRemoveSuccess: PropTypes.func.isRequired,
  onRemoveFail: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  setIsLoading: PropTypes.func.isRequired,
}

const defaultProps = {}

function AppliedGiftCard({
  appliedAmount,
  remainingBalance,
  onRemove,
  onRemoveSuccess,
  onRemoveFail,
  isLoading,
  setIsLoading,
}) {
  const removeGiftCard = () => {
    setIsLoading(true)
    onRemove()
      .then((response) => {
        onRemoveSuccess(response)
      })
      .catch(onRemoveFail)
      .finally(() => setIsLoading(false))
  }

  return (
    <div className="remove-item-wrapper">
      <h3>Gift Card</h3>
      <div className="remove-item">
        <div>
          <p className="discount">{displayCurrency(appliedAmount)} Applied</p>
          <p>(remaining balance: {displayCurrency(remainingBalance)})</p>
        </div>
        <Button submitting={isLoading} onClick={removeGiftCard}>
          Remove
        </Button>
      </div>
    </div>
  )
}

AppliedGiftCard.propTypes = propTypes
AppliedGiftCard.defaultProps = defaultProps

export default AppliedGiftCard
