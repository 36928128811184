import React, { useEffect } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import { Spinner } from '@launchpadlab/lp-components'
import * as apiActions from 'main/apiActions'
import { selectors } from '../reducer'
import SocietyCheckoutLayout from '../../../layout/SocietyCheckoutLayout'
import { TICKETING_CHECKOUT_STEPS } from '../../types'
import SocietyProductGrid from '../../../layout/SocietyProductGrid'
import { selectors as userSelectors } from '../../user/reducer'

function SelectTicketCategory({ products, isLoggedIn, fetchData }) {
  useEffect(() => {
    fetchData()
  }, [])
  const login_prompt = isLoggedIn ? (
    ''
  ) : (
    <>
      <br />
      Members should
      <Link
        to={`/sign-in?redirectUrl=${encodeURIComponent('/ticketing')}`}
        className="inline-block mx-2 text-2xl no-underline lowercase text-secondary"
      >
        Sign In
      </Link>
      to book at member rates.
    </>
  )

  return (
    <SocietyCheckoutLayout
      progress={{ steps: TICKETING_CHECKOUT_STEPS, currentStep: 0 }}
      title="Select Ticket"
      introduction={
        <p className="m-0 text-xl md:mx-10 md:text-2xl">
          Begin by selecting the ticket you would like to purchase.
          {login_prompt}
        </p>
      }
    >
      {products ? <SocietyProductGrid products={products} showAds/> : <Spinner />}
    </SocietyCheckoutLayout>
  )
}

function mapStateToProps(state) {
  const categories = selectors.displayedTicketCategories(state)
  const activities = selectors.displayedTicketActivities(state)

  if (!categories || !activities) {
    return {
      products: null,
    }
  }

  const products = categories.map((category) => {
    const firstTicketActivity = activities.find(
      (type) => type.categoryId === category.categoryId
    )

    const selectUrl = category?.categoryIsCampOrClass
    === true
      ? `/ticketing/camps?categoryId=${category.categoryId}`
      : category.categoryIsDirectLink
        ? `/ticketing/details?activityId=${firstTicketActivity.id}`
        : `/ticketing/activity?categoryId=${category.categoryId}`

    return {
      id: category.categoryId,
      featured: category.categoryFeatured,
      featuredLabel: category.categoryFeaturedDisplayLabel,
      imageUrl: category.categoryImage,
      description: category.categoryDescription,
      productUrl: category.categoryLearnMoreUrl,
      selectUrl,
      name: category.categoryName,
    }
  })

  return {
    isLoggedIn: userSelectors.isAuthenticated(state),
    products,
  }
}

const mapDispatchToProps = {
  fetchData: apiActions.fetchTicketActivities,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  SelectTicketCategory
)
