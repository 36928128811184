import React from 'react'

import { Field, reduxForm } from 'redux-form'
import Button from './ui/Button'
import DonationInput from './DonationInput'

const DEFAULT_LEVELS = [
  { label: '$5', value: 5 },
  { label: '$10', value: 10 },
  { label: '$25', value: 25 },
  { label: '$50', value: 50 },
  { label: '$100', value: 100 },
]

function DonationForm({
  form,
  handleSubmit,
  levels = DEFAULT_LEVELS,
  invalid,
  hideSubmit,
  submitting,
  min = 5,
  max = Infinity,
}) {
  return (
    <form onSubmit={handleSubmit} className="space-y-5">
      <Field
        formName={form}
        name="level"
        normalize={false}
        component={({ input: { value, onChange } }) => {
          return (
            <DonationInput
              levels={levels}
              value={value}
              onChange={onChange}
              min={min}
              max={max}
            />
          )
        }}
      />

      {!hideSubmit && (
        <Button
          type="submit"
          label="Donate"
          loading={submitting}
          disabled={invalid}
          primary
          lg
        />
      )}
    </form>
  )
}

export default reduxForm({})(DonationForm)
