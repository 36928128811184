import React from 'react'
import { Route, IndexRoute, Redirect } from 'react-router'
import * as Views from './views'

const Routes = (
  <Route path="donation">
    <IndexRoute component={Views.SelectFund} />
    <Route path="donate" component={Views.DonationDetails} />
    <Route path="confirmation" component={Views.DonationConfirmation} />
    <Redirect from="*" to="/donation" />
  </Route>
)

export default Routes
