import React from 'react'
// import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import { removeNonAsciiChars } from 'utils'

// Adds `normalize` attribute to a redux-form Field in order to remove non-ASCII characters from the input value

// eslint-disable-next-line react/prop-types
function NormalizedField({ normalize = true, ...rest }) {
  const skipNormalizeFn = (value) => value
  const normalizeFn = normalize ? removeNonAsciiChars : skipNormalizeFn
  return <Field {...{ ...rest, normalize: normalizeFn }} />
}

export default NormalizedField
