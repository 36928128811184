import React from 'react'
import { Field, propTypes as formPropTypes } from 'redux-form'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { lpForm } from '@launchpadlab/lp-form'
import { SearchSelectInput } from 'components'
import { modifyProps } from '@launchpadlab/lp-hoc'

const propTypes = {
  ...formPropTypes,
}

function SelectSchoolForm({ handleSubmit, schoolOptions }) {
  return (
    <form onSubmit={handleSubmit} className="space-y-5">
      <Field
        name="school"
        component={SearchSelectInput}
        options={schoolOptions}
      />
    </form>
  )
}

SelectSchoolForm.propTypes = propTypes

const mapDispatchToProps = {}
const modify = () => {
  return {
    constraints: {
      schoolName: {
        presence: true,
      },
      schoolZipCode: {
        presence: true,
      },
    },
  }
}

export default compose(
  connect(null, mapDispatchToProps),
  modifyProps(modify),
  lpForm({
    name: 'select-school',
    enableReinitialize: true,
  })
)(SelectSchoolForm)
