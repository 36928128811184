import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { pure } from 'recompose'
import { runExternalScript } from 'utils'
import * as LS from 'services/sessionStorage'
import HideableArea from './HideableArea'
import { connect } from 'react-redux'
import { selectors as userSelectors } from '../main/user/reducer'

const propTypes = {
  display: PropTypes.oneOf(['popup', 'fullpage']),
  text: PropTypes.string,
  redirectPath: PropTypes.string,
  scope: PropTypes.string,
  state: PropTypes.string,
}

const defaultProps = {
  display: 'fullpage',
  redirectPath: '', // If not provided, then the user will be redirected to wherever they left the site from
  scope: 'military',
  state: null,
  text: '',
}

function getCurrentPath() {
  const { pathname, search } = window.location
  return pathname + search
}

function IdMeWidget({
  display,
  redirectPath,
  scope,
  state,
  text,
  verified,
  verifiedMessage = "You're verified with Id.me!",
}) {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    runExternalScript(process.env.REACT_APP_OAUTH_ID_ME_SCRIPT_URL).then(() => {
      // ID.me relies on a DOM event that is only fired when a SPA initially loads, so we have to manually trigger it
      if (window.BindIDme && document.getElementById('idme-wallet-button')) {
        window.BindIDme()
        LS.setOAuthRedirectPath(redirectPath || getCurrentPath())
      }
      setLoading(false)
    })
  }, [])

  return verified ? (
    <p>{verifiedMessage}</p>
  ) : (
    <HideableArea hidden={loading}>
      <span
        id="idme-wallet-button"
        data-scope={scope}
        data-client-id={process.env.REACT_APP_OAUTH_ID_ME_CLIENT_ID}
        data-redirect={process.env.REACT_APP_OAUTH_ID_ME_REDIRECT_URI}
        data-response="code"
        data-text={text}
        data-state={state}
        data-display={display}
        data-show-verify={true} // required by the ID.me contract
      />
    </HideableArea>
  )
}

IdMeWidget.propTypes = propTypes
IdMeWidget.defaultProps = defaultProps

function mapStateToProps(state) {
  return { verified: userSelectors.isVerifiedMilitary(state) }
}

export default connect(mapStateToProps)(pure(IdMeWidget))
