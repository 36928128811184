import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'main/types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { routerActions } from 'react-router-redux'
import { selectors } from '../../reducer'
import * as actions from '../../actions'
import { Spinner } from '@launchpadlab/lp-components'
import { modifyProps } from '@launchpadlab/lp-hoc'
import * as apiActions from 'main/apiActions'
import classnames from 'classnames'
import { orderBy, get } from 'lodash'
import { AnimalRecipientInfoForm } from '../../adoptAnAnimal/forms'
import { PlaqueRecipientInfoForm } from '../../plaque/forms'
import * as reduxForm from 'redux-form'
import { scroller } from 'react-scroll'
import {
  displaySubmitFailure,
  isEmpty,
  findFirstErrorField,
  first,
  displayCurrency,
} from 'utils'
import * as effects from 'main/effects'

const propTypes = {
  fetchOrCreateFoundationCart: PropTypes.func.isRequired,
  foundationCart: Types.foundationCart,
  setFoundationCart: PropTypes.func.isRequired, // eslint-disable-line react/no-unused-prop-types
  fetchFoundationProductCategories: PropTypes.func.isRequired,
  foundationProductCategories: PropTypes.arrayOf(
    Types.foundationProductCategory
  ),
  location: PropTypes.object.isRequired,
  push: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  submitForm: PropTypes.func.isRequired,
  submitRecipientInfo: PropTypes.func.isRequired,
  submitRecipientInfoSuccess: PropTypes.func.isRequired,
  submitRecipientInfoFailed: PropTypes.func.isRequired,
}

const defaultProps = {
  foundationCart: null,
  foundationProductCategories: [],
}

function RecipientInfo({
  fetchOrCreateFoundationCart,
  fetchFoundationProductCategories,
  foundationCart,
  foundationProductCategories,
  location: {
    query: { categoryId, cartToken, lineItemId, edit = null },
  },
  isSubmitting,
  submitForm,
  submitRecipientInfo,
  submitRecipientInfoSuccess,
  submitRecipientInfoFailed,
  push,
}) {
  const foundationProductCategory = foundationProductCategories.find(
    (category) => category.id == categoryId
  )
  const foundationProducts = orderBy(
    get(foundationProductCategory, 'foundationProducts'),
    'position'
  )

  const lineItem =
    foundationCart &&
    first(
      foundationCart.foundationLineItems.filter((li) => li.id == lineItemId)
    )

  useEffect(() => {
    fetchOrCreateFoundationCart(cartToken)
  }, [cartToken])
  useEffect(() => {
    if (foundationProductCategory) return
    fetchFoundationProductCategories()
  }, [foundationProductCategory])
  if (
    !foundationCart ||
    !lineItem ||
    !foundationProducts ||
    foundationProducts.length == 0
  )
    return <Spinner />

  const lineItemProduct = foundationProducts.find(
    (foundationProduct) =>
      foundationProduct.sfid == lineItem.foundationProductId
  )

  const isAdoptAnAnimal =
    foundationProductCategory.donationType == 'adopt_an_animal'

  const letterShouldBeMailedValue = lineItem.mailToDesignation == 'recipient'
  const recipientCountryValue = lineItem.recipientCountry
    ? lineItem.recipientCountry
    : 'US'
  const PlaqueRecipientInfoFormInitialValues = {
    inscriptionMessageLine1: lineItem.inscriptionMessageLine1,
    inscriptionMessageLine2: lineItem.inscriptionMessageLine2,
    inscriptionMessageLine3: lineItem.inscriptionMessageLine3,
    inscriptionMessageLine4: lineItem.inscriptionMessageLine4,
    inscriptionMessageLine5: lineItem.inscriptionMessageLine5,
    recipientFirstName: lineItem.recipientFirstName,
    recipientLastName: lineItem.recipientLastName,
    recipientCountry: recipientCountryValue,
    recipientAddress1: lineItem.recipientAddress1,
    recipientAddress2: lineItem.recipientAddress2,
    recipientCity: lineItem.recipientCity,
    recipientState: lineItem.recipientState,
    recipientZip: lineItem.recipientZip,
    letterShouldBeMailed: letterShouldBeMailedValue,
  }

  const AnimalRecipientInfoFormInitialValues = lineItem.packageDesignation
    ? {
        packageDesignation: lineItem.packageDesignation,
        adoptAnAnimalDonationType: lineItem.adoptAnAnimalDonationType,
        mailToDesignation: lineItem.mailToDesignation,
        packageDesignatedTo: lineItem.packageDesignatedTo,
        packageDesignatedFrom: lineItem.packageDesignatedFrom,
        recipientFirstName: lineItem.recipientFirstName,
        recipientLastName: lineItem.recipientLastName,
        recipientCountry: lineItem.recipientCountry,
        recipientAddress1: lineItem.recipientAddress1,
        recipientAddress2: lineItem.recipientAddress2,
        recipientCity: lineItem.recipientCity,
        recipientState: lineItem.recipientState,
        recipientZip: lineItem.recipientZip,
      }
    : { recipientCountry: 'US' }

  const orderedLineItemIds = orderBy(
    foundationCart.foundationLineItems.filter(
      (li) => li.foundationProductCategoryId == foundationProductCategory.id
    ),
    ['position', 'id']
  ).map((lineItem) => lineItem.id)
  const lineItemPositionInIds = orderedLineItemIds.indexOf(lineItem.id) + 1
  const totalLineItems = orderedLineItemIds.length
  return (
    <div className="recipient-info grid gap-10 auto-cols-fr auto-rows-fr grid-cols-1 md:grid-cols-2 ">
      <div className="recipient-info__tile max-w-lg">
        <img alt="" src={foundationProductCategory.largeImage.url} />
      </div>
      <div className="recipient-info__body  max-w-lg">
        {totalLineItems && totalLineItems > 1 && !edit && (
          <p className="recipient-info__body__steps">{`${lineItemPositionInIds} out of ${totalLineItems}`}</p>
        )}
        <div className="recipient-info__body__titles">
          <h1 className="recipient-info__body__titles__animal">
            {foundationProductCategory.name}
          </h1>
          <p className="recipient-info__body__titles__package">
            {displayCurrency(parseFloat(lineItemProduct.amount))} Package
          </p>
        </div>
        <hr></hr>
        {isAdoptAnAnimal ? (
          <AnimalRecipientInfoForm
            lineItemId={lineItemId}
            onSubmit={(params) =>
              submitRecipientInfo({ ...params, lineItemId })
            }
            onSubmitFail={submitRecipientInfoFailed}
            onSubmitSuccess={(response) =>
              submitRecipientInfoSuccess(response, edit)
            }
            initialValues={AnimalRecipientInfoFormInitialValues}
          />
        ) : (
          <PlaqueRecipientInfoForm
            lineItemId={lineItemId}
            onSubmit={(params) =>
              submitRecipientInfo({
                ...params,
                lineItemId,
              })
            }
            onSubmitFail={submitRecipientInfoFailed}
            onSubmitSuccess={(response) =>
              submitRecipientInfoSuccess(response, edit)
            }
            initialValues={PlaqueRecipientInfoFormInitialValues}
            lineItemProduct={lineItemProduct}
          />
        )}

        {edit ? (
          <div className="recipient-info__body__button-container">
            <button
              className={classnames('button-secondary', {
                'is-disabled': isSubmitting,
              })}
              onClick={() => push('/give/cart')}
              disabled={isSubmitting}
            >
              Return to Cart
            </button>
            <button
              className={classnames('foundation-button-primary', {
                'is-disabled': isSubmitting,
                'in-progress': isSubmitting,
              })}
              onClick={() => submitForm('recipient-info-form')}
              disabled={isSubmitting}
            >
              Update
            </button>
          </div>
        ) : (
          <div className="recipient-info__body__button-container">
            <button
              className={classnames('foundation-button-primary', {
                'is-disabled': isSubmitting,
                'in-progress': isSubmitting,
              })}
              onClick={() => submitForm('recipient-info-form')}
              disabled={isSubmitting}
            >
              Continue
            </button>
          </div>
        )}
      </div>
    </div>
  )
}

RecipientInfo.propTypes = propTypes
RecipientInfo.defaultProps = defaultProps

function modify({ push, setFoundationCart, foundationCart }) {
  return {
    submitRecipientInfo: ({
      packageDesignation,
      adoptAnAnimalDonationType,
      mailToDesignation,
      packageDesignatedTo,
      packageDesignatedFrom,
      recipientFirstName,
      recipientLastName,
      recipientCountry,
      recipientAddress1,
      recipientAddress2,
      recipientCity,
      recipientState,
      recipientZip,
      inscriptionMessageLine1,
      inscriptionMessageLine2,
      inscriptionMessageLine3,
      inscriptionMessageLine4,
      inscriptionMessageLine5,
      letterShouldBeMailed,
      lineItemId,
    }) => {
      const finalMailToDesignation =
        typeof mailToDesignation != 'undefined'
          ? mailToDesignation
          : letterShouldBeMailed
            ? 'recipient'
            : 'me'

      const lineItem = {
        foundationLineItemId: lineItemId,
        packageDesignation: packageDesignation,
        adoptAnAnimalDonationType,
        mailToDesignation: finalMailToDesignation,
        packageDesignatedTo,
        packageDesignatedFrom,
        recipientFirstName,
        recipientLastName,
        recipientCountry,
        recipient_address_1: recipientAddress1,
        recipient_address_2: recipientAddress2,
        recipientCity,
        recipientState,
        recipientZip,
        inscription_message_line_1: inscriptionMessageLine1,
        inscription_message_line_2: inscriptionMessageLine2,
        inscription_message_line_3: inscriptionMessageLine3,
        inscription_message_line_4: inscriptionMessageLine4,
        inscription_message_line_5: inscriptionMessageLine5,
      }

      return effects.updateFoundationLineItem(lineItem, foundationCart.token)
    },
    submitRecipientInfoSuccess: (response, edit) => {
      setFoundationCart({ success: response })
      const lineItemFromResponse = response.data.attributes
      const categoryIdFromResponse =
        lineItemFromResponse.foundationProductCategoryId

      const orderedLineItemIds = orderBy(
        foundationCart.foundationLineItems.filter(
          (li) => li.foundationProductCategoryId == categoryIdFromResponse
        ),
        ['position', 'id']
      ).map((lineItem) => lineItem.id)
      const lineItemFromResponseIndex = orderedLineItemIds.indexOf(
        lineItemFromResponse.id
      )
      const nextLineItemIndex = lineItemFromResponseIndex + 1
      const nextLineItemId = orderedLineItemIds[nextLineItemIndex]
      if (edit == 'true')
        return push(`/give/cart?cartToken=${foundationCart.token}`)
      return nextLineItemId
        ? push(
            `/give/recipient-information?categoryId=${categoryIdFromResponse}&lineItemId=${nextLineItemId}&cartToken=${foundationCart.token}`
          )
        : // If there is no nextLineItemId, then this lineItem was the last lineItem for this product to be updated, so we go to the cart page:
          push(`/give/cart?cartToken=${foundationCart.token}`)
    },
    submitRecipientInfoFailed: (
      errors,
      dispatch,
      submitError,
      { syncErrors }
    ) => {
      errors['foundationStyleMessage'] = true
      displaySubmitFailure(errors, dispatch, submitError)
      // Check for sync errors here as onSubmitFail is overridden in props
      if (!isEmpty(syncErrors)) {
        const firstErrorField = findFirstErrorField(syncErrors)
        if (!firstErrorField) return
        return scroller.scrollTo(firstErrorField, { smooth: true })
      }
    },
  }
}

function mapStateToProps(state) {
  return {
    foundationCart: selectors.foundationCart(state),
    foundationProductCategories: selectors.foundationProductCategories(state),
    isSubmitting: reduxForm.isSubmitting('recipient-info-form')(state),
  }
}

const mapDispatchToProps = {
  fetchOrCreateFoundationCart: apiActions.fetchOrCreateFoundationCart,
  fetchFoundationProductCategories: apiActions.fetchFoundationProductCategories,
  setFoundationCart: actions.setFoundationCart,
  push: routerActions.push,
  submitForm: reduxForm.submit,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  modifyProps(modify)
)(RecipientInfo)
