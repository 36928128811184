import { createAction } from 'redux-actions'
import { push } from 'react-router-redux'
import * as memberActions from '../membership/actions'
import * as ticketingActions from '../ticketing/actions'
import * as foundationActions from '../foundation/actions'
export const setAuthenticated = createAction('SET_AUTHENTICATED')
export const setMemberId = createAction('SET_MEMBER_ID')
export const setPrimaryMember = createAction('SET_PRIMARY_MEMBER')
export const clearOrderHistory = createAction('CLEAR_ORDER_HISTORY')
export const setMilitaryStatus = createAction('SET_USER_MILITARY_STATUS')
export const clearMilitaryStatus = createAction('CLEAR_MILITARY_STATUS')
export const setOAuthRedirectPath = createAction('SET_OAUTH_REDIRECT_PATH')
export const clearOAuthRedirectPath = createAction('CLEAR_OAUTH_REDIRECT_PATH')
export const setUserGuestFlowPreferred = createAction(
  'SET_USER_GUEST_FLOW_PREFERRED'
)

export function setUser({
  token,
  primaryMemberId,
  primaryMember,
  secondaryMembers,
}) {
  const members = [primaryMember, ...secondaryMembers]

  return function (dispatch) {
    dispatch(setAuthenticated({ token }))
    dispatch(setMemberId(primaryMemberId || primaryMember.id))
    dispatch(memberActions.setRenewal(true))
    dispatch(memberActions.setMembershipDetails(members))
    dispatch(setPrimaryMember(primaryMember))
  }
}

export function clearInfo({ redirectUrl = null } = {}) {
  return function (dispatch) {
    dispatch(setAuthenticated({ token: null }))
    dispatch(setMemberId(null))
    dispatch(memberActions.clearMembershipForm())
    dispatch(ticketingActions.clearTicketingForm())
    dispatch(clearOrderHistory())
    dispatch(foundationActions.setAuthenticated({ token: null }))
    dispatch(push(redirectUrl || '/sign-in'))
  }
}
