import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { push, goBack } from 'react-router-redux'
import { setQuestionnaire } from '../actions'
import SocietyPageLayout from '../../../layout/SocietyPageLayout'
import Button from '../../../components/ui/Button'
import LinkButton from '../../../components/ui/LinkButton'
import c from 'classnames'

const propTypes = {
  push: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
  activities: PropTypes.object.isRequired,
  setQuestionnaire: PropTypes.func.isRequired,
  route: PropTypes.object,
}

const Question = ({
  push,
  goBack,
  setQuestionnaire,
  activities,
  route: { path: questionName },
}) => {
  const fieldTripActivityId = activities.fieldTrip.id

  // Since all question pages have the same structure, we define the question text,
  // and the functions to trigger on submit here, for all question pages.
  const pageConfig = {
    'educational-facility': {
      question: 'Are you an Educational facility?',
      goToNextPage: (isEducationalFacility) => {
        const pushUrl = isEducationalFacility
          ? `/ticketing/details?activityId=${fieldTripActivityId}`
          : '/questionnaire/rental'
        push(pushUrl)
      },
    },
    rental: {
      question: 'Do you want to rent a space?',
      goToNextPage: (isRentingSpace) => {
        if (isRentingSpace) {
          window.location.href =
            'https://www.omahazoo.com/make-an-event-inquiry'
        } else {
          push('/questionnaire/group-size')
        }
      },
    },
    'group-size': {
      question: 'Is your group 40 or more?',
      goToNextPage: (largeGroup) => {
        const pushUrl = largeGroup
          ? '/questionnaire/qualified'
          : '/questionnaire/not-qualified'
        push(pushUrl)
      },
    },
  }

  // we assign the question and onSubmit function
  const { question, goToNextPage } = pageConfig[questionName]
  const nameAttribute = `question-${questionName}`

  const [answer, setAnswer] = useState('')
  const isBlank = answer === ''

  const handleSubmit = (event) => {
    event.preventDefault()
    // capture value of answer before clearing it out
    const responseAnswer = answer
    setAnswer('')
    setQuestionnaire({ [questionName]: responseAnswer })
    goToNextPage(answer)
  }

  const handleGoBack = (event) => {
    event.preventDefault()
    goBack()
  }

  return (
    <SocietyPageLayout>
      <form onSubmit={handleSubmit} className="space-y-20">
        <h1 className="text-5xl">{question}</h1>
        <fieldset className="flex items-center justify-center w-full gap-5">
          {[true, false].map((isTrue) => {
            let checked = isTrue ? answer : !answer

            return (
              <label
                key={`${isTrue}`}
                className={c(
                  'border cursor-pointer border-solid border-gray-500 text-lg font-light rounded px-10 py-5',
                  { 'bg-primary text-white font-bold': checked }
                )}
                htmlFor={`${nameAttribute}-${isTrue}`}
              >
                <input
                  type="radio"
                  value={isTrue}
                  className="fixed sr-only"
                  id={`${nameAttribute}-${isTrue}`}
                  name={nameAttribute}
                  checked={!isBlank && checked}
                  onChange={() => setAnswer(isTrue)}
                />
                <span>{isTrue ? 'Yes' : 'No'}</span>
              </label>
            )
          })}
        </fieldset>
        <div className="flex flex-col items-center gap-5">
          <Button
            type="primary"
            xl
            disabled={isBlank}
            className={isBlank ? 'is-disabled' : ''}
            label="Continue"
          />
          <LinkButton onClick={handleGoBack} label="Back" />
        </div>
      </form>
    </SocietyPageLayout>
  )
}

Question.propTypes = propTypes

const mapDispatchToProps = { push, goBack, setQuestionnaire }
export default compose(connect(null, mapDispatchToProps))(Question)
