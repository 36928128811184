export default function extractSoldOutDates(ticketTimes) {
  const dateGroups = ticketTimes?.length ? ticketTimes.reduce((acc, ticket) => {
    if (!acc[ticket.startDate]) acc[ticket.startDate] = [];
    acc[ticket.startDate].push(ticket);
    return acc;
  }, {}): {};
  const soldOutDates = Object.entries(dateGroups).filter(([date, ticketTimes]) => 
    ticketTimes.every(ticket => ticket.soldOut)
  ).map(([date]) => date);
  return soldOutDates;
}
