export default function mapCurrentUser(accountInfo) {
  return {
    firstName: accountInfo.firstName,
    lastName: accountInfo.lastName,
    email: accountInfo.address.email.toLowerCase(),
    phone: accountInfo.address.phone,
    country: accountInfo.address.country,
    address_1: accountInfo.address.streetAddress,
    city: accountInfo.address.city,
    state: accountInfo.address.state,
    zip: accountInfo.address.zip,
  }
}
