import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { Route } from 'react-router'
import { adaptToReactRouter, routeHooks } from 'utils'

const propTypes = {
  authFunction: PropTypes.func.isRequired,
  redirect: PropTypes.string,
}

const defaultProps = {
  redirect: '/',
}

// A react-router route that requires an auth function to return 'false' before it's entered.

function UnauthorizedRoute({ authFunction, redirect, ...rest }) {
  const changeHandler = routeHooks.excludeAuth(authFunction, {
    redirectUrl: redirect,
  })
  return <Route onEnter={changeHandler} onChange={changeHandler} {...rest} />
}

UnauthorizedRoute.propTypes = propTypes

UnauthorizedRoute.defaultProps = defaultProps

export default compose(adaptToReactRouter())(UnauthorizedRoute)
