import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { scroller } from 'react-scroll'
import { flashSuccessMessage } from 'redux-flash'
import {
  displaySubmitFailure,
  findFirstErrorField,
  isEmpty,
  flashErrorMessage,
} from 'utils'
import { ResetPasswordForm } from '../forms'
import * as effects from 'main/effects'
import * as routerActions from 'react-router-redux'
import { Link } from 'react-router'

const propTypes = {
  location: PropTypes.object.isRequired,
  flashSuccessMessage: PropTypes.func.isRequired,
  flashErrorMessage: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
}

const defaultProps = {}

function ResetPassword({
  location: { query },
  flashSuccessMessage,
  flashErrorMessage,
  push,
}) {
  const resetPasswordToken = query.reset_password_token
  useEffect(() => {
    if (!resetPasswordToken) {
      flashErrorMessage(
        'This reset email has expired. Please request a new one.',
        { style: 'foundation' }
      )
    }
  }, [])

  const handleSubmitFail = (errors, dispatch, submitError, { syncErrors }) => {
    // This will catch the backend error coming from the user model.
    if (isEmpty(errors) || errors.user) {
      flashErrorMessage(
        'Could not process your reset password. Please try again later.',
        { style: 'foundation' }
      )
    } else {
      displaySubmitFailure(errors, dispatch, submitError)
      // Check for sync errors here as onSubmitFail is overridden in props
      if (!isEmpty(syncErrors)) {
        const firstErrorField = findFirstErrorField(syncErrors)
        if (!firstErrorField) return
        return scroller.scrollTo(firstErrorField, { smooth: true })
      }
    }
  }

  return (
    <div className="auth-page">
      <section className="content">
        <h3>Create Your New Password</h3>
        <ResetPasswordForm
          onSubmit={(params) => {
            return effects.accountResetPassword({
              resetPasswordToken,
              ...params,
            })
          }}
          onSubmitSuccess={() => {
            flashSuccessMessage(
              'Password was successfully reset. Please Sign In.'
            )
            push('/give/sign-in')
          }}
          onSubmitFail={handleSubmitFail}
        />
        <p style={{ paddingTop: 20 }}>
          Need to request a new reset password email?{' '}
          <Link to="/give/forgot-password">Click here</Link>
        </p>
      </section>
    </div>
  )
}

function mapStateToProps() {
  return {}
}

const mapDispatchToProps = {
  flashSuccessMessage,
  flashErrorMessage,
  push: routerActions.push,
}

ResetPassword.propTypes = propTypes

ResetPassword.defaultProps = defaultProps

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ResetPassword
)
