import { handleActions } from 'redux-actions'
import { selectorForSlice } from '@launchpadlab/lp-redux-utils'
import { setFromRequest } from '@launchpadlab/lp-redux-api'
import * as apiActions from 'main/apiActions'
import * as actions from './actions'
import * as LS from 'services/sessionStorage'
import { set } from 'lodash/fp'
import { isFoundationAuthenticated } from '../auth'

const reducerKey = 'foundation'
const slice = 'root.foundation'

const initialState = {
  foundationProductCategories: {},
  foundationCart: null,
  order: null,
  isAuthenticated: isFoundationAuthenticated(),
  isGuestFlowPreferred: LS.getIsGuestFlowPreferred(),
  foundationAccountGivingOrders: null,
  foundationAccountInfo: null,
  cartItemsCount: null,
}

const reducer = handleActions(
  {
    ...setFromRequest(
      apiActions.REQ_FOUNDATION_PRODUCT_CATEGORIES,
      'foundationProductCategories'
    ),
    ...setFromRequest(apiActions.REQ_FOUNDATION_ORDER, 'foundationOrder'),
    // after cart is requested, persist the latest cart token to session storage
    [apiActions.REQ_FOUNDATION_CART + '_SUCCESS']: (
      state,
      { payload: { data } }
    ) => {
      const { attributes } = data
      const cartCount = attributes.foundationLineItems?.length
      const updatedState = {
        ...state,
        cartItemsCount: cartCount,
        foundationCart: attributes,
      }
      LS.setFoundationCartToken(attributes.token)
      return updatedState
    },
    ...setFromRequest(
      apiActions.REQ_FOUNDATION_ACCOUNT_GIVING_ORDERS,
      'foundationAccountGivingOrders'
    ),
    ...setFromRequest(
      apiActions.REQ_FOUNDATION_ACCOUNT_INFO,
      'foundationAccountInfo'
    ),
    ...setFromRequest(
      apiActions.REQ_FOUNDATION_UPDATE_ACOUNT_INFO,
      'foundationAccountInfo'
    ),
    [actions.setFoundationCart]: (state, { payload }) => {
      const foundationCart = payload.success.data.additionalData.cart
      LS.setFoundationCartToken(foundationCart.token)
      const updatedState = {
        ...state,
        cartItemsCount: foundationCart.foundationLineItems?.length,
        foundationCart: foundationCart,
      }
      return updatedState
    },
    [actions.setAuthenticated]: (state, { payload: { token } }) => {
      LS.setGuestFlowPreferred(false)
      if (!token) {
        LS.clearFoundationAuthToken()

        return set('isAuthenticated', false, state)
      }
      LS.setFoundationAuthToken(token)
      return set('isAuthenticated', true, state)
    },

    [actions.setGuestFlowPreferred]: (state, { payload }) => {
      LS.setGuestFlowPreferred(payload)
      return set('isGuestFlowPreferred', payload, state)
    },
  },
  initialState
)

const select = selectorForSlice(slice)

const selectors = {
  foundationProductCategories: select(
    'foundationProductCategories.success.data.attributes',
    []
  ),
  foundationOrderCart: select(
    'foundationOrder.success.data.additionalData.cart'
  ),
  foundationCart: select('foundationCart'),
  foundationAccountGivingOrders: select(
    'foundationAccountGivingOrders.success.data.attributes'
  ),
  foundationAccountInfo: select(
    'foundationAccountInfo.success.data.attributes'
  ),
  isAuthenticated: select('isAuthenticated'),

  isGuestFlowPreferred: select('isGuestFlowPreferred'),
  cartItemsCount: select('cartItemsCount'),
}

export { reducer, selectors, reducerKey }
