import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { displayCurrency } from 'utils'
import { connect } from 'react-redux'
import { selectors } from '../../reducer'
import c from 'classnames'
import ReactGA from 'react-ga4'
const TAXES_LABEL = 'Value of goods and services'

const OrderSummaryItem = ({ className, description, amount }) => (
  <div className={c('flex justify-between space-x-4', className)}>
    <span>{description}</span>
    <span>{displayCurrency(amount)}</span>
  </div>
)

const OrderSubmarySubitem = ({ className, ...props }) => (
  <OrderSummaryItem className={c(className, 'text-xs italic')} {...props} />
)

const OrderSummary = ({ total, taxes, items }) => {
  useEffect(() => {
    ReactGA.event('begin_checkout', {
      currency: 'USD',
      value: total,
      items: items?.map((cartItem) => ({
        item_id: cartItem.id,
        item_name: cartItem.name,
        price: cartItem.amount,
        item_category: 'foundation_donation',
        item_category2: cartItem.productType,
        quantity: 1,
      })),
    })
  }, [])
  return (
    <ol className="p-6 space-y-8 bg-white">
      {items.map(({ id, name, amount, taxes, schedule }) => {
        return (
          <li key={id}>
            <OrderSummaryItem description={name} amount={amount} />
            <OrderSubmarySubitem description={TAXES_LABEL} amount={taxes} />
            {schedule !== 'one_time' && (
              <p className="text-xs capitalize">{schedule}</p>
            )}
          </li>
        )
      })}
      <li>
        <hr className="my-5" />
        <OrderSummaryItem
          className="font-bold"
          description="Donation Total"
          amount={total}
        />
        <OrderSubmarySubitem description={TAXES_LABEL} amount={taxes} />
      </li>
    </ol>
  )
}

OrderSummary.propTypes = {
  total: PropTypes.number.isRequired,
  taxes: PropTypes.number.isRequired,
  items: PropTypes.array.isRequired,
}

function mapStateToProps(state) {
  const cart = selectors.foundationCart(state)
  const productCategories = selectors.foundationProductCategories(state)

  if (!cart || !productCategories) {
    return {
      total: 0,
      taxes: 0,
      items: [],
    }
  }

  const items = cart.foundationLineItems
    .sort((a, b) => a.donationType < b.donationType)
    .map(
      ({
        id,
        amount,
        valueGoodsAndServices,
        memorialGiving,
        tributeType,
        foundationProductCategoryId,
        recurringScheduleType,
      }) => {
        const isInMemoryOf = memorialGiving && tributeType === 'in_memory_of'

        const name = memorialGiving
          ? isInMemoryOf
            ? 'Memorial Giving'
            : 'Honorary Giving'
          : productCategories.find((fp) => fp.id == foundationProductCategoryId)
              .name

        return {
          id,
          name,
          amount,
          taxes: valueGoodsAndServices,
          schedule: recurringScheduleType,
        }
      }
    )

  return {
    total: cart.total,
    taxes: cart.totalValueGoodsAndServices,
    items,
  }
}

export default connect(mapStateToProps)(OrderSummary)
