import React, { useState, useEffect } from 'react'
import ModalDialog from '../../../components/ui/ModalDialog'
import Form, {
  FormConstraints,
  UserDefinedFields,
  useUserDefinedForm,
} from '../../../components/Form'
import * as apiActions from 'main/apiActions'
import * as ticketingActions from '../../ticketing/actions'
import { selectors as membershipSelectors } from '../../membership/reducer'
import { selectors as ticketingSelectors } from '../../ticketing/reducer'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { v4 as uuid } from 'uuid'
import { pick } from 'lodash'
import { Spinner } from '@launchpadlab/lp-components'

const FIELDS = {
  firstName: {
    label: 'First Name',
    constraints: [FormConstraints.required, FormConstraints.maxLength(30)],
  },
  lastName: {
    label: 'Last Name',
    constraints: [FormConstraints.required, FormConstraints.maxLength(30)],
  },
}

const INITIAL_VALUES = { firstName: '', lastName: '' }

const TicketAttendeeModal = ({
  eventName,
  isOpen,
  onConfirm,
  onClose,
  udfs,
  udfValues,
  initialValues,
  // From mapStateToProps
  membership,
  fetchCustomerDetails,
  setUserUdfValues,
  userUdfValues,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [showModalSpinner, setShowModalSpinner] = useState(false)
  const [preloadAttendeeValues, setPreloadAttendeeValues] = useState(udfValues)

  const shouldUseCustomerSelect = membership && membership.length > 0

  const members = shouldUseCustomerSelect
    ? membership.map(
        ({ fullName, firstName, lastName, customerId, externalId }) => ({
          label: fullName,
          value: { firstName, lastName, customerId, externalId },
        })
      )
    : undefined

  for (const key of Object.keys(userUdfValues)) {
    const memberExists = members?.find(
      (member) => String(member.value.customerId) === key
    )
    if (!memberExists) {
      const { firstName, lastName, externalId } = userUdfValues[key].member
      members?.push({
        label: firstName + ' ' + lastName,
        value: {
          fullName: firstName + ' ' + lastName,
          firstName,
          lastName,
          externalId,
        },
      })
    }
  }

  const fields = shouldUseCustomerSelect
    ? {
        member: {
          label: 'Attendee',
          type: 'dropdown',
          options: members,
          other: { fields: FIELDS, initialValues: {} },
          constraints: [FormConstraints.required],
          placeholder: eventName
            ? `Choose a child to attend ${eventName}`
            : 'Choose an attendee',
        },
      }
    : FIELDS

  const form = useUserDefinedForm(
    fields,
    shouldUseCustomerSelect
      ? { member: null }
      : { ...INITIAL_VALUES, ...(initialValues || {}) },
    udfs,
    preloadAttendeeValues
  )

  // if the user has selected a member/customer from the dropdown,
  // make an api call to preopulate the udf feilds for the first time
  useEffect(() => {
    if (
      udfValues &&
      udfValues.length === 0 &&
      form.values.member &&
      form.values.member.label !== 'Other'
    ) {
      // if inital values are present if the user opened the form and saved it during this session

      const customerId = form.values.member.value.customerId
      if (customerId && !userUdfValues[form.values.member.label]) {
        setShowModalSpinner(true)
        fetchCustomerDetails(parseInt(customerId))
          .then((response) => {
            let updatedUserUdfValues = { ...userUdfValues }
            updatedUserUdfValues[customerId] = {}
            if (response && response.length > 0) {
              for (const udfResponse of response[0].customerUdFs) {
                updatedUserUdfValues[customerId][udfResponse.udfName] =
                  udfResponse.udfValue
              }
              setUserUdfValues(updatedUserUdfValues)
            }
          })
          .finally(() => {
            setShowModalSpinner(false)
          })
      }
    }
  }, [form.values.member])

  useEffect(() => {
    if (form.values?.member) {
      const customerOrExternalId =
        form.values.member?.value?.customerId ||
        form.values.member?.value?.externalId

      const previousValues = []

      for (const udfFeild of udfs) {
        if (
          userUdfValues[customerOrExternalId] &&
          userUdfValues[customerOrExternalId][udfFeild.fieldName]
        ) {
          previousValues.push({
            fieldName: udfFeild.fieldName,
            value: userUdfValues[customerOrExternalId][udfFeild.fieldName],
          })
        }

        setPreloadAttendeeValues(previousValues)
      }
    }
  }, [userUdfValues, form.values.member])

  async function handleConfirm() {
    setIsLoading(true)

    const values = UserDefinedFields.fromFormToValues(form)

    // update UDF values in session if we are using the customer select
    if (shouldUseCustomerSelect) {
      let updatedUserUdfValues = { ...userUdfValues }

      //ensure a unique id
      const customerOrExternalId =
        form.values.member.value.customerId ||
        (form.values.member.value.externalId ||= uuid())

      updatedUserUdfValues[customerOrExternalId] ??= {}
      for (const [key, v] of Object.entries(values)) {
        updatedUserUdfValues[customerOrExternalId][key] = v
      }
      setUserUdfValues(updatedUserUdfValues)
    }

    const attendee = shouldUseCustomerSelect
      ? form.values.member.value
      : pick(values, ['firstName', 'lastName'])

    await onConfirm(attendee, UserDefinedFields.fromValues(udfs, values))

    form.reset()
    setIsLoading(false)
  }

  function handleClose() {
    onClose()
    form.reset()
  }

  const modalContent = showModalSpinner ? <Spinner /> : <Form {...form} />
  return (
    <ModalDialog
      isOpen={isOpen}
      onConfirm={handleConfirm}
      confirmLabel="Save"
      confirmDisabled={!form.isValid}
      confirmLoading={isLoading}
      onClose={handleClose}
      title="Attendee Details"
      content={modalContent}
    />
  )
}

function mapStateToProps(state) {
  return {
    membership: membershipSelectors.membershipDetails(state), // this is a minsnomer - it is customer data for non-members too
    userUdfValues: ticketingSelectors.userUdfValues(state),
  }
}

const mapDispatchToProps = {
  fetchCustomerDetails: apiActions.fetchCustomerDetails,
  setUserUdfValues: ticketingActions.setUserUdfValues,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  TicketAttendeeModal
)
