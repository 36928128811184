import React, { Fragment } from 'react'
import {
  Field,
  propTypes as formPropTypes,
  formValueSelector,
} from 'redux-form'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { lpForm } from '@launchpadlab/lp-form'
import PropTypes from 'prop-types'
import { scroller } from 'react-scroll'
import { RadioGroup } from '@launchpadlab/lp-components'
import { FoundationInput } from 'components'
import { first } from 'lodash'
import { modifyProps } from '@launchpadlab/lp-hoc'
import { keys, getCountryOptions, setZipConstraints } from 'utils'
import * as options from 'main/options'

const propTypes = {
  ...formPropTypes,
  mailToDesignationValue: PropTypes.string,
  packageDesignationValue: PropTypes.string,
  countryValue: PropTypes.string,
}

const defaultProps = {
  mailToDesignationValue: null,
  packageDesignationValue: null,
  countryValue: 'US',
}

function AnimalRecipientInfoForm({
  handleSubmit,
  mailToDesignationValue,
  packageDesignationValue,
  countryValue,
}) {
  const packageDesignationOptions = [
    {
      key: 'For Me',
      value: 'me',
    },
    {
      key: 'A Gift',
      value: 'gift',
    },
  ]

  const donationTypeOptions = [
    {
      key: 'Zoo Mama',
      value: 'zoo_mama',
    },
    {
      key: 'Zoo Daddy',
      value: 'zoo_daddy',
    },
    {
      key: 'Zoo Parent',
      value: 'zoo_parent',
    },
  ]

  const mailToDesignationOptions = [
    {
      key: 'Mail to Recipient',
      value: 'recipient',
    },
    {
      key: 'Mail to Me',
      value: 'me',
    },
  ]
  return (
    <form onSubmit={handleSubmit}>
      <Field
        className="RadioGroup inline-inputs"
        component={RadioGroup}
        options={packageDesignationOptions}
        label="The package is:"
        name="packageDesignation"
      />
      {packageDesignationValue && packageDesignationValue == 'gift' && (
        <div className="recipient-info__body__package-designation">
          <p>Enter name as you want it to appear on gift tag</p>
          <Field
            name="packageDesignatedTo"
            component={FoundationInput}
            label="To:"
            placeholder="To"
            maxLength="20"
          />
          <Field
            name="packageDesignatedFrom"
            component={FoundationInput}
            label="From:"
            placeholder="From"
            maxLength="20"
          />
        </div>
      )}
      <Field
        className="RadioGroup inline-inputs"
        label="Package Type:"
        name="adoptAnAnimalDonationType"
        component={RadioGroup}
        options={donationTypeOptions}
      />
      <Field
        className="RadioGroup inline-inputs"
        label="Where would you like us to mail this?"
        name="mailToDesignation"
        component={RadioGroup}
        options={mailToDesignationOptions}
      />
      {mailToDesignationValue && mailToDesignationValue != 'me' && (
        <Fragment>
          <div className="flex gap-5">
            <Field
              name="recipientFirstName"
              component={FoundationInput}
              label="Recipient's First Name"
              placeholder="Recipient's First Name"
              maxLength="20"
            />
            <Field
              name="recipientLastName"
              component={FoundationInput}
              label="Recipient's Last Name"
              placeholder="Recipient's Last Name"
              maxLength="20"
            />
          </div>
          <div>
            <Field
              name="recipientCountry"
              component={FoundationInput}
              options={getCountryOptions()}
              label="Country"
              placeholder="Select"
              inputType="select"
            />
          </div>
          <div>
            <Field
              name="recipientAddress1"
              component={FoundationInput}
              label="Recipient's Address"
              placeholder="Recipient's Address"
            />
          </div>
          <div>
            <Field
              name="recipientAddress2"
              component={FoundationInput}
              label="Recipient's Address Line 2"
              placeholder="Recipient's Address Line 2"
            />
          </div>
          <div>
            <Field
              name="recipientCity"
              component={FoundationInput}
              label="City"
              placeholder="City"
            />
            <div className="flex gap-5">
              {countryValue == 'US' && (
                <Field
                  name="recipientState"
                  component={FoundationInput}
                  options={options.states}
                  label="State"
                  placeholder="Select State"
                  inputType="select"
                />
              )}
              <Field
                name="recipientZip"
                component={FoundationInput}
                label="ZIP"
                placeholder="ZIP"
              />
            </div>
          </div>
        </Fragment>
      )}
    </form>
  )
}

AnimalRecipientInfoForm.propTypes = propTypes
AnimalRecipientInfoForm.defaultProps = defaultProps

const modify = ({ countryValue }) => {
  return {
    constraints: {
      packageDesignation: { presence: true },
      adoptAnAnimalDonationType: { presence: true },
      mailToDesignation: { presence: true },
      recipientFirstName: {
        presence: true,
      },
      recipientLastName: {
        presence: true,
      },
      recipientAddress1: {
        presence: true,
      },
      recipientCity: {
        presence: true,
      },
      recipientState: {
        presence: true,
      },
      recipientZip: setZipConstraints(countryValue),
    },
  }
}

const animalRecipientInfoFormSelector = formValueSelector('recipient-info-form')

function mapStateToProps(state) {
  return {
    mailToDesignationValue: animalRecipientInfoFormSelector(
      state,
      'mailToDesignation'
    ),
    packageDesignationValue: animalRecipientInfoFormSelector(
      state,
      'packageDesignation'
    ),
    countryValue: animalRecipientInfoFormSelector(state, 'recipientCountry'),
  }
}

export default compose(
  connect(mapStateToProps, null),
  modifyProps(modify),
  lpForm({
    name: 'recipient-info-form',
    onSubmitFail: (params, dispatch, _, { syncErrors }) => {
      const firstErrorField = first(keys(syncErrors))
      return scroller.scrollTo(firstErrorField, {
        smooth: true,
      })
    },
    enableReinitialize: true,
  })
)(AnimalRecipientInfoForm)
