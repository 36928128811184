import React, { useState } from 'react'
import Form, { useForm } from '../../../../components/Form'
import Button from '../../../../components/ui/Button'
import SocietyHeading from '../../../../layout/SocietyHeading'
import SocietyPageLayout from '../../../../layout/SocietyPageLayout'
import { flashErrorMessage } from 'utils'
import { captureException } from '@sentry/react'
import HelpText from './HelpText'
import { EMAIL_FORM_FIELDS } from '../UniversalSignIn'
import { flashSuccessMessage } from 'redux-flash'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'

import Users from '../../../ticketing/utils/users'

const propTypes = {
  flashSuccessMessage: PropTypes.func.isRequired,
  flashErrorMessage: PropTypes.func.isRequired,
}
const defaultProps = {}

const ForgotPasswordForm = ({
  email,
  redirectUrl,
  flashErrorMessage,
  flashSuccessMessage,
}) => {
  const form = useForm(EMAIL_FORM_FIELDS, { email })
  const [error, setError] = useState(null)

  async function handleSubmit() {
    const { email } = form.values

    if (form.validate()) {
      try {
        await Users.sendForgotPasswordEmail(
          email,
          window.location.origin,
          redirectUrl
        )
        flashSuccessMessage(`A password reset email was sent to ${email}`)
      } catch (error) {
        if (error.status === 422) {
          setError(
            'The password you entered is incorrect. Please review and try again.'
          )
        } else {
          captureException(error)
          flashErrorMessage('Something went wrong. Please review and try again')
        }
      }
    }
  }

  return (
    <SocietyPageLayout title="Welcome to Omaha Zoo">
      <section className="p-5 mx-auto space-y-10 bg-white shadow pb-7 sm:w-2/3">
        <SocietyHeading>Forgot your password? We can help.</SocietyHeading>

        <Form {...form} onSubmit={handleSubmit} />
        <footer className="relative pb-3">
          <Button
            type="primary"
            label="Email Reset Link"
            size="xl"
            width="full"
            onClick={handleSubmit}
          />

          {error && (
            <div className="absolute inset-x-0 text-center top-full text-danger">
              {error}
            </div>
          )}
        </footer>
      </section>
      <HelpText />
    </SocietyPageLayout>
  )
}
function mapStateToProps() {
  return {}
}
ForgotPasswordForm.propTypes = propTypes

ForgotPasswordForm.defaultProps = defaultProps

const mapDispatchToProps = {
  flashSuccessMessage,
  flashErrorMessage,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ForgotPasswordForm
)
