import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { propTypes as formPropTypes } from 'redux-form'
import { lpForm } from '@launchpadlab/lp-form'
import { ButtonArea, Input, SubmitButton } from '@launchpadlab/lp-components'
import { NormalizedField as Field } from 'components'

const propTypes = {
  ...formPropTypes,
  requiresCoupon: PropTypes.bool,
  requiresEmployeeId: PropTypes.bool,
  couponLabel: PropTypes.string,
  disabled: PropTypes.bool,
}

const defaultProps = {
  requiresCoupon: false,
  requiresEmployeeId: false,
  couponLabel: 'Coupon Code',
  disabled: false,
}

function CouponCodeForm({
  handleSubmit,
  invalid,
  pristine,
  submitting,
  error,
  requiresCoupon,
  requiresEmployeeId,
  couponLabel,
  disabled,
}) {
  return (
    <form onSubmit={handleSubmit}>
      {error && <p className="error-message">{error}</p>}
      {requiresEmployeeId && (
        <Field name="coupon.employeeId" label="Employee ID" component={Input} />
      )}
      {requiresCoupon && (
        <Field
          name="coupon.couponCode"
          component={Input}
          label={couponLabel}
          disabled={disabled}
        />
      )}
      <ButtonArea>
        <SubmitButton {...{ invalid, pristine, submitting }}>
          Apply Code
        </SubmitButton>
      </ButtonArea>
    </form>
  )
}

CouponCodeForm.propTypes = propTypes

CouponCodeForm.defaultProps = defaultProps

export default compose(
  lpForm({
    name: 'membership-coupon',
    constraints: {
      coupon: { presence: true },
      employeeId: { presence: true },
    },
    enableReinitialize: true,
  })
)(CouponCodeForm)
