import { handleActions } from 'redux-actions'
import {
  selectorForSlice,
  setState,
  unsetState,
} from '@launchpadlab/lp-redux-utils'
import { setFromRequest } from '@launchpadlab/lp-redux-api'
import { LOCATION_CHANGE } from 'react-router-redux'
import * as Types from 'main/types'
import { invert, get, subtract } from 'lodash'
import { set } from 'lodash/fp'
import * as apiActions from 'main/apiActions'
import * as actions from './actions'
import * as LS from 'services/sessionStorage'
import { createSelector } from 'reselect'

const reducerKey = 'donation'
const slice = 'root.donation'

const initialState = {
  currentFormStep: 0,
  donationForm: LS.getDonationForm(),
  funds: null,
  selectedFund: {},
  totalFormSteps: Object.keys(Types.DonationFormStepRoutes).length,
}

const reducer = handleActions(
  {
    [LOCATION_CHANGE]: (state, { payload: { pathname } }) => {
      const formStep = invert(Types.DonationFormStepRoutes)[pathname]
      if (!formStep) return state

      return set('currentFormStep', Number(formStep), state)
    },
    ...setFromRequest(apiActions.REQ_DONATION_FUNDS, 'funds'),
    ...setFromRequest(apiActions.REQ_DONATION_FUND, 'selectedFund'),
    [actions.setFundId]: setState(
      'donationForm.fundId',
      ({ payload: fundId }, state) => {
        LS.setDonationForm(set('fundId', fundId, state.donationForm))
        return fundId
      }
    ),
    [actions.setLevel]: setState(
      'donationForm.level',
      ({ payload: level }, state) => {
        LS.setDonationForm(set('level', level, state.donationForm))
        return level
      }
    ),
    [actions.setOrder]: setState(
      'donationForm.order',
      ({ payload: { order } }, state) => {
        LS.setDonationForm(set('order', order, state.donationForm))
        return order
      }
    ),
    [actions.setGiftCard]: (state, { payload: total }) => {
      const formObj = {
        ...state.donationForm,
        giftCard: {
          giftCardNumber: get(total, 'giftCardNumber'),
          totalGiftCard: get(total, 'totalGiftCard'),
          giftCardBalance: get(total, 'giftCardBalance'),
        },
      }
      LS.setDonationForm(formObj)
      return set('donationForm', formObj, state)
    },
    [actions.clearGiftCard]: (state) => {
      const formObj = { ...state.donationForm, giftCard: {} }
      LS.setDonationForm(formObj)
      return set('donationForm', formObj, state)
    },
    [actions.clearSelectedFund]: unsetState('selectedFund'),
  },
  initialState
)

const select = selectorForSlice(slice)

const selectors = {
  currentFormStep: select('currentFormStep'),
  donationForm: select('donationForm'),
  funds: select('funds.success.data.attributes'),
  selectedFund: select('selectedFund.success.data.attributes'),
  order: select('donationForm.order'),
  totalFormSteps: select('totalFormSteps'),
  appliedGiftCard: select('donationForm.giftCard'),
}

selectors.appliedGiftCardAmount = createSelector(
  [selectors.appliedGiftCard],
  function (giftCard) {
    return get(giftCard, 'totalGiftCard')
  }
)

selectors.orderTotals = createSelector(
  [selectors.donationForm, selectors.appliedGiftCardAmount],
  function (donationForm, giftCardAmount) {
    const donationAmount = get(donationForm, 'level')
    if (!donationAmount) giftCardAmount = 0
    return {
      donationAmount,
      total: subtract(donationAmount, giftCardAmount),
    }
  }
)

selectors.remainingGiftCardBalance = createSelector(
  [selectors.appliedGiftCard],
  function (giftCard) {
    return get(giftCard, 'giftCardBalance')
  }
)

export { reducer, selectors, reducerKey }
