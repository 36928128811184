import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import { selectors as userSelectors } from 'main/user/reducer'
import SharedHeader from 'layout/SharedHeader'
import NavItem from 'layout/NavItem'
import LogInOut from 'layout/LogInOut'
import navLogo from 'images/logo.svg'

const SocietyHeader = ({ isLoggedIn }) => {
  const isMyAccount = location.pathname.includes('my-account')
  return isMyAccount ? (
    <SharedHeader />
  ) : (
    <header className="flex justify-between gap-5 p-2 bg-white shadow">
      <a href={process.env.REACT_APP_OMAHA_ZOO_URL}>
        <img src={navLogo} className="h-10 md:h-20" alt="Omaha Zoo" />
      </a>
      <nav className="px-3">
        <ul className="flex items-center h-full gap-4">
          {isLoggedIn && (
            <li>
              <NavItem
                label="My Account"
                as={Link}
                to="/my-account"
                className="mb-0 text-primary hover:text-primary-shade-400"
              />{' '}
            </li>
          )}
          <li>
            <LogInOut mobile={false} />
          </li>
        </ul>
      </nav>
    </header>
  )
}

const mapStateToProps = (state) => ({
  isLoggedIn: userSelectors.isAuthenticated(state),
})

const mapDispatchToProps = (dispatch) => {
  return {}
}
export default compose(connect(mapStateToProps, mapDispatchToProps))(
  SocietyHeader
)
