import React from 'react'
import { Route, IndexRoute } from 'react-router'
import * as AdoptAnAnimalViews from './adoptAnAnimal/views'
import * as ProductsViews from './donationProducts/views'
import * as GeneralDonationViews from './generalDonation/views'
import * as AccountViews from '../user/account/views'
import Landing from './Landing'
import * as CartViews from './cart/views'
import Layout from './Layout'
import FoundationPaymentWrapper from './shared/FoundationPaymentWrapper'
import { UnauthorizedRoute } from 'components'
import { isFoundationAuthenticated } from '../auth'

const Routes = (
  <Route path="give" component={Layout}>
    <IndexRoute component={Landing} />
    <Route path="adopt-an-animal" component={AdoptAnAnimalViews.SelectAnimal} />
    <Route
      path="recipient-information"
      component={ProductsViews.RecipientInfo}
    />
    <Route path="packages" component={ProductsViews.SelectProduct} />
    <Route path="donate" component={GeneralDonationViews.DonatePage} />
    <Route path="cart" component={FoundationPaymentWrapper}>
      <IndexRoute component={CartViews.CartDetails} />
      <Route path="info" component={CartViews.DonorDetails} />
      <Route path="payment" component={CartViews.PaymentDetails} />
      <Route path="confirm" component={CartViews.ConfirmationDetails} />
      <Route path="submitted" component={CartViews.SubmissionConfirmation} />
    </Route>

    <Route path="forgot-password" component={AccountViews.ForgotPassword} />
    <Route path="reset-password" component={AccountViews.ResetPassword} />
    <UnauthorizedRoute
      authFunction={isFoundationAuthenticated}
      redirect="/give"
    >
      <Route path="sign-in" component={AccountViews.SignIn} />
      <Route path="create-account" component={AccountViews.CreateAccount} />
    </UnauthorizedRoute>
  </Route>
)

export default Routes
