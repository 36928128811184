import React from 'react'
// import PropTypes from 'prop-types'
import { propTypes as formPropTypes } from 'redux-form'
import { compose } from 'redux'
import { lpForm } from '@launchpadlab/lp-form'
import { Input } from '@launchpadlab/lp-components'
import { NormalizedField as Field } from 'components'

const propTypes = {
  ...formPropTypes,
}

const defaultProps = {}

function AddOnAdditionalGuestForm({ handleSubmit, change, form, submit }) {
  return (
    <form onSubmit={handleSubmit}>
      <Field
        name="fullName"
        className="add-on-field"
        label="Additional Guest 1 - First and Last Name"
        component={Input}
        onChange={(e) => {
          change(form, 'fullName', e.target.value)
          setTimeout(() => submit(form), 1000)
        }}
      />
    </form>
  )
}

AddOnAdditionalGuestForm.propTypes = propTypes

AddOnAdditionalGuestForm.defaultProps = defaultProps

export default compose(
  lpForm({
    constraints: {
      fullName: { presence: true, length: { maximum: 60 } },
    },
  })
)(AddOnAdditionalGuestForm)
