import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import cart from 'images/cart.svg'

const propTypes = {
  setIsMobileNavOpen: PropTypes.func.isRequired,
  cartToken: PropTypes.string,
}

const defaultProps = {
  cartToken: null,
}

function MobileNavMenu({ setIsMobileNavOpen, cartToken }) {
  const cartLink = cartToken
    ? `/give/cart?cartToken=${cartToken}`
    : '/give/cart'
  return (
    <div className="nav-bar-mobile">
      <Link
        className="nav-bar-mobile__link nav-bar-mobile__link--donate-btn"
        onClick={() => setIsMobileNavOpen(false)}
        to="/give/donate"
      >
        Donate
      </Link>
      <a
        className="nav-bar-mobile__link"
        onClick={() => setIsMobileNavOpen(false)}
        href="https://www.omahazoofoundation.org/our-mission/"
      >
        Our Mission
      </a>
      <Link
        className="nav-bar-mobile__link"
        onClick={() => setIsMobileNavOpen(false)}
        to="/give"
      >
        Ways to Give
      </Link>
      <a
        className="nav-bar-mobile__link"
        onClick={() => setIsMobileNavOpen(false)}
        href="https://www.omahazoofoundation.org/become-a-member/"
      >
        Become a Member
      </a>
      <Link
        className="nav-bar-mobile__link"
        onClick={() => setIsMobileNavOpen(false)}
        to={cartLink}
      >
        <img style={{ maxWidth: 40 }} src={cart} />
      </Link>
    </div>
  )
}

MobileNavMenu.propTypes = propTypes
MobileNavMenu.defaultProps = defaultProps

export default MobileNavMenu
