import React, { useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'main/types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { selectors } from '../../reducer'
import * as apiActions from 'main/apiActions'
import * as effects from 'main/effects'
import { modifyProps } from '@launchpadlab/lp-hoc'
import * as actions from '../../actions'
import { Spinner } from '@launchpadlab/lp-components'
import { scroller } from 'react-scroll'
import { routerActions } from 'react-router-redux'
import * as reduxForm from 'redux-form'
import classnames from 'classnames'
import ReactGA from 'react-ga4'
import { displaySubmitFailure, isEmpty, findFirstErrorField } from 'utils'
import { GeneralDonationForm } from '../forms'
import { get, first } from 'lodash'

const propTypes = {
  fetchOrCreateFoundationCart: PropTypes.func.isRequired,
  foundationCart: Types.foundationCart,
  fetchFoundationProductCategories: PropTypes.func.isRequired,
  foundationProductCategories: PropTypes.arrayOf(
    Types.foundationProductCategory
  ),
  location: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  submitForm: PropTypes.func.isRequired,
  submitGeneralDonationInfo: PropTypes.func.isRequired,
  submitGeneralDonationInfoSuccess: PropTypes.func.isRequired,
  submitGeneralDonationInfoFailed: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
}

const defaultProps = {
  foundationProductCategories: [],
  foundationCart: null,
}

function SelectDonation({
  fetchOrCreateFoundationCart,
  fetchFoundationProductCategories,
  foundationCart,
  foundationProductCategories,
  location: {
    query: { productId, cartToken, lineItemId, memorial = false },
  },
  isSubmitting,
  submitForm,
  submitGeneralDonationInfo,
  submitGeneralDonationInfoSuccess,
  submitGeneralDonationInfoFailed,
  push,
}) {
  useEffect(() => {
    fetchOrCreateFoundationCart(cartToken)
  }, [cartToken])
  useEffect(() => {
    if (foundationProductCategories.length == 0)
      fetchFoundationProductCategories()
  }, [foundationProductCategories])
  if (!foundationCart || foundationProductCategories.length == 0)
    return <Spinner />

  useEffect(() => {
    ReactGA.event('view_item', {
      currency: 'USD',
      value: foundationCart?.total,
      items: foundationCart?.foundationLineItems?.map((cartItem) => ({
        item_id: cartItem.id,
        price: cartItem.amount,
        quantity: 1,
        item_category: 'foundation_donation',
        item_category2: cartItem.donationType,
      })),
    })
  }, [foundationCart])

  const lineItem =
    lineItemId &&
    foundationCart.foundationLineItems.find((li) => li.id == lineItemId)

  const designationOptions = foundationProductCategories
    .filter(({ donationType }) => ['general', 'fund'].includes(donationType))
    .sort((c1, _c2) => (c1 === 'general' ? -1 : 0))
    .map(({ foundationProducts }) => foundationProducts[0])
    .filter((product) => !!product)
    .filter(({ id }) => !productId || id == productId)
    .map(({ name, sfid }) => ({ label: name, value: sfid }))

  const defaultProductId = designationOptions[0].value

  const letterShouldBeMailedValue =
    lineItem && lineItem.mailToDesignation == 'recipient'
  const recipientCountryValue =
    lineItem && lineItem.recipientCountry ? lineItem.recipientCountry : 'US'
  const generalDonationFormInitialValues = lineItem
    ? {
        recurringScheduleType: lineItem.recurringScheduleType,
        donationAmount: lineItem.amount,

        foundationProductId: String(lineItem.foundationProductId),
        recipientFirstName: lineItem.recipientFirstName,
        recipientLastName: lineItem.recipientLastName,
        recipientCountry: recipientCountryValue,
        recipientAddress1: lineItem.recipientAddress1,
        recipientAddress2: lineItem.recipientAddress2,
        recipientCity: lineItem.recipientCity,
        recipientState: lineItem.recipientState,
        recipientZip: lineItem.recipientZip,
        letterShouldBeMailed: letterShouldBeMailedValue,
        tributeType: lineItem.tributeType,
        inMemoryOfName: lineItem.inMemoryOfName,
      }
    : {
        donationAmount: 50,
        recurringScheduleType: 'one_time',
        recipientCountry: 'US',
        foundationProductId: defaultProductId,
      }

  return (
    <div className="select-donation-page">
      <GeneralDonationForm
        onSubmit={(params) => {
          return submitGeneralDonationInfo({ ...params, lineItemId })
        }}
        onSubmitFail={submitGeneralDonationInfoFailed}
        onSubmitSuccess={(response) =>
          submitGeneralDonationInfoSuccess(response)
        }
        initialValues={generalDonationFormInitialValues}
        designationOptions={designationOptions}
        memorial={memorial === 'true'}
      />
      {lineItemId ? (
        <Fragment>
          <div className="general-donation__button-container">
            <button
              className={classnames('button-secondary', {
                'is-disabled': isSubmitting,
              })}
              onClick={() => push('/give/cart')}
              disabled={isSubmitting}
            >
              Return to Cart
            </button>
            <button
              className={classnames(
                'foundation-button-primary general-donation__button-container__button',
                {
                  'is-disabled': isSubmitting,
                  'in-progress': isSubmitting,
                }
              )}
              onClick={() => submitForm('foundation-general-donation-form')}
              disabled={isSubmitting}
            >
              Update
            </button>
          </div>
        </Fragment>
      ) : (
        <div className="general-donation__button-container">
          <button
            className={classnames(
              'foundation-button-primary general-donation__button-container__button',
              {
                'is-disabled': isSubmitting,
                'in-progress': isSubmitting,
              }
            )}
            onClick={() => {
              submitForm('foundation-general-donation-form')
            }}
            disabled={isSubmitting}
          >
            Add to Cart
          </button>
        </div>
      )}
    </div>
  )
}

SelectDonation.propTypes = propTypes
SelectDonation.defaultProps = defaultProps

function modify({
  push,
  setFoundationCart,
  foundationCart,
  foundationProductCategories,
  location: {
    query: { memorial = false },
  },
}) {
  return {
    submitGeneralDonationInfo: ({
      recurringScheduleType,
      donationAmount,
      lineItemId,
      tributeType,
      inMemoryOfName,
      letterShouldBeMailed,
      foundationProductId,
      recipientFirstName,
      recipientLastName,
      recipientCountry,
      recipientAddress1,
      recipientAddress2,
      recipientCity,
      recipientState,
      recipientZip,
    }) => {
      const foundationCartToken = foundationCart && foundationCart.token
      const amount = parseInt(donationAmount)

      const finalMailToDesignation =
        typeof letterShouldBeMailed == 'undefined'
          ? null
          : letterShouldBeMailed
            ? 'recipient'
            : 'me'

      const memorialGiving = memorial === 'true'

      const foundationProductIdValue =
        foundationProductId ||
        get(
          first(
            get(
              first(
                foundationProductCategories.filter(
                  (category) => category.donationType == 'general'
                )
              ),
              'foundationProducts'
            )
          ),
          'id'
        )

      const lineItem = lineItemId && {
        recurringScheduleType,
        foundationLineItemId: lineItemId,
        foundationProductId: foundationProductIdValue,
        amount,
        mailToDesignation: finalMailToDesignation,
        recipientFirstName,
        recipientLastName,
        recipientCountry,
        recipient_address_1: recipientAddress1,
        recipient_address_2: recipientAddress2,
        recipientCity,
        recipientState,
        recipientZip,
        memorialGiving,
        tributeType,
        inMemoryOfName,
      }
      return lineItemId
        ? effects.updateFoundationLineItem(lineItem, foundationCartToken)
        : effects.createFoundationLineItem({
            recurringScheduleType,
            foundationProductId: foundationProductIdValue,
            amount,
            mailToDesignation: finalMailToDesignation,
            recipientFirstName,
            recipientLastName,
            recipientCountry,
            recipient_address_1: recipientAddress1,
            recipient_address_2: recipientAddress2,
            recipientCity,
            recipientState,
            recipientZip,
            memorialGiving,
            tributeType,
            inMemoryOfName,
            cartToken: foundationCartToken,
          })
    },
    submitGeneralDonationInfoSuccess: (response) => {
      const foundationCartToken = foundationCart && foundationCart.token
      setFoundationCart({
        success: response,
      })

      ReactGA.event('add_to_cart', {
        currency: 'USD',
        value: foundationCart?.total,
        items: foundationCart?.foundationLineItems?.map((cartItem) => ({
          item_id: cartItem.id,
          price: cartItem.amount,
          quantity: 1,
          item_category: 'foundation_donation',
          item_category2: cartItem.donationType,
        })),
      })
      return push(`/give/cart?cartToken=${foundationCartToken}`)
    },
    submitGeneralDonationInfoFailed: (
      errors,
      dispatch,
      submitError,
      { syncErrors }
    ) => {
      errors['foundationStyleMessage'] = true
      displaySubmitFailure(errors, dispatch, submitError)
      // Check for sync errors here as onSubmitFail is overridden in props
      if (!isEmpty(syncErrors)) {
        const firstErrorField = findFirstErrorField(syncErrors)
        if (!firstErrorField) return
        return scroller.scrollTo(firstErrorField, { smooth: true })
      }
    },
  }
}
function mapStateToProps(state) {
  return {
    foundationProductCategories: selectors.foundationProductCategories(state),
    foundationCart: selectors.foundationCart(state),
    isSubmitting: reduxForm.isSubmitting('foundation-general-donation-form')(
      state
    ),
  }
}

const mapDispatchToProps = {
  fetchFoundationProductCategories: apiActions.fetchFoundationProductCategories,
  fetchOrCreateFoundationCart: apiActions.fetchOrCreateFoundationCart,
  setFoundationCart: actions.setFoundationCart,
  push: routerActions.push,
  submitForm: reduxForm.submit,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  modifyProps(modify)
)(SelectDonation)
