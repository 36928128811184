import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import { noop } from 'lodash'

const propTypes = {
  onClick: PropTypes.func,
  to: PropTypes.string.isRequired,
}

const defaultProps = {
  onClick: noop,
}

function BackButton({ onClick, to }) {
  return (
    <Link to={to} onClick={onClick} className="back-button">
      Back
    </Link>
  )
}

BackButton.propTypes = propTypes

BackButton.defaultProps = defaultProps

export default BackButton
