import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { selectors } from '../reducer'
import { compact } from 'lodash'
import { ButtonArea } from '@launchpadlab/lp-components'

import * as Types from 'main/types'
import { capitalize, displayCurrency } from 'utils'
import philoImage from 'images/coral-reef.jpg'
import * as apiActions from 'main/apiActions'
import { selectors as userSelectors } from 'main/user/reducer'
import { OrderReceipt } from '../components'
import SocietyCheckoutLayout from 'layout/SocietyCheckoutLayout'
import MembershipWalletButtons from 'main/membership/components/MembershipWalletButtons'

const propTypes = {
  membershipDetails: PropTypes.shape({
    primaryMember: Types.member.isRequired,
    secondaryMember: Types.secondaryMember,
    children: PropTypes.array,
  }),
  membershipForm: PropTypes.object,
  order: Types.order.isRequired,
  memberId: PropTypes.number,
  fetchMemberDetails: PropTypes.func.isRequired,
  primaryMembershipId: PropTypes.number,
}

const defaultProps = {
  memberId: null,
  primaryMembershipId: null,
}

function Confirmation({
  membershipDetails: { primaryMember = {} },
  membershipForm: { isRenewal },
  order: {
    paymentMethod,
    donationReceipt,
    donationTotal,
    fnboApplied,
    fnboDiscount,
    membershipStarts,
    membershipEnds,
    orderDate,
    orderNumber,
    totalPaid,
    totalGiftCard,
  },
  memberId,
  fetchMemberDetails,
  primaryMembershipId,
}) {
  // fetch member details after membership is purchased; this will set isLoggedIn true
  useEffect(() => {
    if (!primaryMembershipId) fetchMemberDetails(memberId)
  }, [primaryMembershipId, memberId])

  const details = compact([
    ['Membership Id', primaryMember.memberNumber],
    ['Receipt Number', orderNumber],
    donationReceipt && ['Donation Receipt', donationReceipt],
    ['Purchase Date', orderDate],
    donationTotal && ['Donation Amount', displayCurrency(donationTotal)],
    totalGiftCard && ['Gift Card Redeemed', displayCurrency(totalGiftCard)],
    ['Purchase Total', displayCurrency(totalPaid)],
    fnboApplied && ['FNBO Credit Card Discount', displayCurrency(fnboDiscount)],
    paymentMethod && ['Payment Method', capitalize(paymentMethod)],
    ['Email', primaryMember.email],
  ])

  return (
    <SocietyCheckoutLayout
      title="Thank You!"
      summary={<OrderReceipt />}
      idme={false}
      reversed
    >
      <p className="text-xl no-print">Welcome to the Omaha Zoo Family.</p>

      <div className="p-5 bg-white shadow no-print">
        <p>
          <strong className="font-bold">Never Forget Your Card!</strong> Add
          your membership card to your digital wallet for fast and easy check
          in.
        </p>
        <div className="space-x-3">
          <MembershipWalletButtons />
        </div>
      </div>

      <div className="p-5 bg-white shadow no-print">
        <p>A copy of your purchase has been sent to the email provided.</p>
        <p>
          Your membership is valid from{' '}
          <strong>
            {membershipStarts} - {membershipEnds}
          </strong>
          .
        </p>
        {isRenewal ? (
          <p>
            Please continue to use your current membership card for admission to
            Omaha’s Zoo.
          </p>
        ) : (
          <p>
            Your temporary member card has been sent to you by email. For new
            members, your permanent membership card will be mailed within 2
            weeks.
          </p>
        )}
        <ul className="space-y-4">
          {details.map(([label, value], index) => (
            <li key={index} className="mb-0">
              <div>{label}</div>
              <strong>{value}</strong>
            </li>
          ))}
        </ul>
      </div>

      <div className="flex h-64 bg-white shadow">
        <div
          className="h-full aspect-square"
          style={{ backgroundImage: `url(${philoImage})` }}
        />
        <div className="p-5 text-lg font-roboto">
          Your membership purchase supports conservation efforts across the
          globe and enables us to:
          <ul className="mt-4 ml-5 space-y-2 text-base list-disc">
            <li className="mb-0">
              Plant 1.5 million trees in Madagascar, building up habitats of
              threatened species
            </li>
            <li className="mb-0">
              Prevent amphibian extinction in Puerto Rico
            </li>
            <li className="mb-0">
              Seed thousands of endangered corals onto reefs in Curaçao &amp;
              Mexico
            </li>
          </ul>
        </div>
      </div>

      <ButtonArea className="print-buttons">
        <button onClick={window.print} className="button-primary no-print">
          Print This Page
        </button>
      </ButtonArea>
    </SocietyCheckoutLayout>
  )
}

Confirmation.propTypes = propTypes

Confirmation.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    membershipDetails: selectors.selectedMembershipDetails(state),
    membershipForm: selectors.membershipForm(state),
    order: selectors.order(state),
    memberId: userSelectors.memberId(state),
    primaryMembershipId: selectors.primaryMembershipId(state),
  }
}

const mapDispatchToProps = {
  fetchMemberDetails: apiActions.fetchMemberDetails,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  Confirmation
)
