import React, { useState } from 'react'
import SocietyPageLayout from '../../../layout/SocietyPageLayout'
import Form, { FormConstraints, useForm } from '../../../components/Form'
import Button from '../../../components/ui/Button'

import { connect } from 'react-redux'
import { flashErrorMessage } from 'utils'
import * as Sentry from '@sentry/react'
import VerifyEmail from './VerifyEmail'
import Users from '../../ticketing/utils/users'
import { selectors as membershipSelectors } from '../../membership/reducer'

const FIELDS = {
  adults: {
    type: 'array',
    maxLength: 1,
    minLength: 1,

    label: 'Adults',
    labelSrOnly: true,
    itemLabel: 'Adult',

    fields: {
      firstName: {
        label: 'First Name',
        constraints: [FormConstraints.required, FormConstraints.maxLength(30)],
      },
      lastName: {
        label: 'Last Name',
        constraints: [FormConstraints.required, FormConstraints.maxLength(30)],
      },
      email: {
        label: 'Email',
        constraints: [FormConstraints.required, FormConstraints.email],
      },
    },

    constraints: [FormConstraints.required],
  },

  password: {
    label: 'Password',
    constraints: [FormConstraints.required],
    placeholder: 'Add a password',
    type: 'password',
    cascadeValidationTo: ['passwordConfirmation'],
  },

  passwordConfirmation: {
    label: 'Confirm Password',
    constraints: [FormConstraints.required, FormConstraints.match('password')],
    placeholder: 'Repeat your password',
    type: 'password',
  },
}

const LAYOUT = [
  {
    type: 'section',
    children: [
      {
        key: 'adults',
        layout: [
          { type: 'inline', children: ['firstName', 'lastName'] },
          'email',
        ],
      },
    ],
  },
  {
    type: 'section',
    title: 'Create a Password',
    children: ['password', 'passwordConfirmation'],
  },
]

const UniversalSignUp = ({ dispatch, location, membership }) => {
  const { email, redirectUrl } = location.query

  const [primary = {}] = membership.adults

  const form = useForm(FIELDS, {
    adults: {
      0: { email, ...primary },
    },
  })

  const [user, setUser] = useState(user)

  async function handleSubmit() {
    const {
      adults: { 0: primary },
      password,
      passwordConfirmation,
    } = form.values

    try {
      const user = await Users.createUserAndCustomer({
        primary,
        password,
        passwordConfirmation,
        membershipId: membership.id,
        redirectOrigin: window.location.origin,
        redirectUrl,
      })

      setUser(user)
    } catch (error) {
      let message = 'Something went wrong. Please try again later.'

      if (error.status < 500) {
        message = error.response.errors.message
      } else {
        Sentry.captureException(error)
      }

      dispatch(flashErrorMessage(message))
    }
  }

  return !user ? (
    <SocietyPageLayout title="Create Your Account">
      <div className="w-3/5 mx-auto space-y-10">
        <Form {...form} layout={LAYOUT} onSubmit={handleSubmit} />
        <Button
          width="full"
          label="Create Account"
          type="primary"
          size="xl"
          casing="uppercase"
          disabled={!form.isValid}
          onClick={handleSubmit}
        />
      </div>
    </SocietyPageLayout>
  ) : (
    <VerifyEmail email={user.address.email} />
  )
}

function mapStateToProps(state) {
  const members = membershipSelectors.membershipDetails(state)
  const primaryMember = membershipSelectors.primaryMember(state)

  if (!members) return { membership: { adults: [], children: [] } }

  const membershipId = primaryMember.id

  const adults = members
    .filter((member) => !member.isChild)
    .sort((a, b) => b.isPrimary - a.isPrimary)
    .map(({ firstName, lastName, email }) => ({ firstName, lastName, email }))

  return {
    membership: { adults, id: membershipId },
  }
}

export default connect(mapStateToProps)(UniversalSignUp)
