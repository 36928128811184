import React from 'react'
import { Link } from 'react-router'
import { compose } from 'redux'
import { propTypes as formPropTypes } from 'redux-form'
import { lpForm } from '@launchpadlab/lp-form'
import { SubmitButton } from '@launchpadlab/lp-components'
import { NormalizedField as Field, FoundationInput } from 'components'
import { validateNested } from 'utils'

const propTypes = {
  ...formPropTypes,
}

const defaultProps = {}

function CreateAccountForm({ handleSubmit, submitting, invalid }) {
  return (
    <form onSubmit={handleSubmit}>
      <Field
        name="primary_email"
        component={FoundationInput}
        label="Email"
        placeholder="Email *"
        inputType="email"
      />
      <Field
        name="password"
        component={FoundationInput}
        label="Password"
        placeholder="Password *"
        inputType="password"
      />
      <Field
        name="password_confirmation"
        component={FoundationInput}
        label="Password"
        placeholder="Password Confirmation *"
        inputType="password"
      />
      <SubmitButton {...{ submitting, invalid }}>Create Account</SubmitButton>
      <p style={{ paddingTop: 20 }}>
        <Link to="/give/sign-in">Already have an account? Sign In</Link>
      </p>
    </form>
  )
}

CreateAccountForm.propTypes = propTypes
CreateAccountForm.defaultProps = defaultProps

export default compose(
  lpForm({
    name: 'create-account',
    validate: validateNested({
      primary_email: { presence: true },
      password: { presence: true },
      password_confirmation: { presence: true, equality: 'password' },
    }),
  })
)(CreateAccountForm)
