import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'main/types'
import { Fields, propTypes as formPropTypes } from 'redux-form'
import { compose } from 'recompose'
import { lpForm } from '@launchpadlab/lp-form'
import { EditableMemberFieldsInput } from '../components'

const propTypes = {
  ...formPropTypes,
  members: PropTypes.arrayOf(Types.member),
  memberOptions: PropTypes.arrayOf(PropTypes.object),
}

const defaultProps = {
  memberOptions: [],
}

function AddOnNannyForm({ handleSubmit, members, memberOptions }) {
  return (
    <form onSubmit={handleSubmit}>
      <Fields
        className="add-on-field"
        prefix="nanny"
        legend="Nanny"
        names={['nanny.id', 'nanny.firstName', 'nanny.lastName', 'nanny.address.email']}
        component={EditableMemberFieldsInput}
        options={memberOptions}
        members={members}
        parse={(value, name) => {
          // Parse the id to a number to facilitate strict comparisons (i.e., ===)
          if (name === 'nanny.id' && value) return Number(value)
          return value
        }}
        isRequired
      />
    </form>
  )
}

AddOnNannyForm.propTypes = propTypes
AddOnNannyForm.defaultProps = defaultProps

// manual constraints added to nanny form onChange in AddOn.js, those must be updated if lpForm
// constraints here change
export default compose(
  lpForm({
    name: 'membership-nanny',
    constraints: {
      'nanny.firstName': {
        presence: true,
        length: { maximum: 30 },
      },
      'nanny.lastName': {
        presence: true,
        length: { maximum: 30 },
      },
      'nanny.id': {
        presence: {
          message: '^Please select an existing member or add a new member',
        },
      },
    },
  })
)(AddOnNannyForm)
