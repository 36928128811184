export default function mapGiftCardCartData({
  paymentResponse,
  giftCardValues,
  selectedGiftCard,
  giftCardCart,
}) {
  const price =
    Number(selectedGiftCard.fixedPrice) + Number(giftCardValues.variablePrice)

  // Use parseInt rather than Number here because parseInt(null) returns Nan
  // while Number(null) returns 0
  const variablePriceForCart = parseInt(giftCardValues.variablePrice) || null

  // There's only ever 1 priceSelection (price on selectedGiftCard) for Gift Cards
  const defaultPriceSelection = selectedGiftCard.prices[0]

  const quantityForCart = Number(giftCardValues.quantity)

  const newPriceSelection = {
    ...defaultPriceSelection,
    variablePrice: variablePriceForCart,
    fixedPrice: selectedGiftCard.fixedPrice,
    price: price,
    quantity: quantityForCart,
  }

  const products = [
    {
      id: selectedGiftCard.id,
      type: 'GiftCardPrice',
      displayName: selectedGiftCard.displayName,
      priceSelections: [newPriceSelection],
    },
  ]

  const customerDetails = {
    cardToken: paymentResponse.cardToken,
    cardType: paymentResponse.cardType,
    lastFourDigits: paymentResponse.lastFourDigits,
    expirationDate: paymentResponse.expirationDate,
    firstName: paymentResponse.firstName,
    lastName: paymentResponse.lastName,
    email: paymentResponse.email,
    phone: paymentResponse.phone,
    streetAddress1: paymentResponse.streetAddress,
    streetAddress2: paymentResponse.streetAddress2,
    zip: paymentResponse.zip,
  }

  return {
    cart: {
      ...giftCardCart,
      cardToken: paymentResponse.cardToken,
      customerDetails: customerDetails,
      products: products,
    },
  }
}
