import * as effects from 'main/effects'
import ReactGA from 'react-ga4'

const TicketingCart = {
  REMOTE: {
    createOrUpdateTicketLineItem(
      ticketCartToken,
      ticketTime,
      ticketActivity,
      { bookingUdfs } = {}
    ) {
      return effects.createOrUpdateTicketLineItem({
        ticketCartToken,
        productId: ticketActivity.id,
        productType: ticketActivity.productType,
        eventId: ticketTime.id,
        eventName: ticketTime.displayName,
        startDatetime: ticketTime.startDatetime,
        endDatetime: ticketTime.endDatetime,
        baseFeeId: ticketTime.baseFeeId,
        baseFeePrice: ticketTime.baseFeePrice,
        baseFeeCostRateId: ticketTime.baseFeeCostRateId,
        parentLineItemId: null,
        bookingUdfs,
      })
    },

    async addLineItemPrice(
      ticketCartToken,
      ticketPrice,
      ticketTime,
      ticketActivity,
      lineItemPrice,
      attendee,
      attendeeUdfs
    ) {
      ReactGA.event('add_to_cart', {
        currency: 'USD',
        value: ticketPrice.price,
        items: [
          {
            item_id: ticketActivity.id,
            item_name: ticketActivity.displayName,
            item_category: ticketActivity.productType,
            item_category2: ticketActivity.categoryName,
            price: ticketPrice.price,
            quantity: 1,
          },
        ],
      })

      if (!lineItemPrice) {
        const { data } =
          await TicketingCart.REMOTE.createOrUpdateTicketLineItem(
            ticketCartToken,
            ticketTime,
            ticketActivity
          )

        return await effects.createTicketLineItemPrice({
          ticketCartToken,
          ticketLineItemId: data.attributes.id,
          ticketPriceId: ticketPrice.id,
          costRateId: ticketPrice.costRateId,
          price: ticketPrice.price,
          alternatePriceId: ticketPrice.alternatePriceId,
          alternatePrice: ticketPrice.alternatePrice,
          alternateCostRateId: ticketPrice.alternateCostRateId,
          attendee,
          attendeeUdfs,
        })
      } else {
        return TicketingCart.REMOTE.updateLineItemPrice(
          ticketCartToken,
          lineItemPrice,
          { quantity: lineItemPrice.quantity + 1, attendee, attendeeUdfs }
        )
      }
    },

    removeLineItemPrice(ticketCartToken, lineItemPrice, lineItem) {
      ReactGA.event('remove_from_cart', {
        currency: 'USD',
        value: lineItemPrice.price,
        items: [
          {
            item_id: lineItem.productId,
            item_name: lineItem.displayName,
            item_category: 'ticket',
            price: lineItemPrice.price,
            quantity: 1,
          },
        ],
      })

      if (lineItemPrice.quantity > 1) {
        return TicketingCart.REMOTE.updateLineItemPrice(
          ticketCartToken,
          lineItemPrice,
          { quantity: lineItemPrice.quantity - 1 }
        )
      } else if (lineItem.lineItemPrices.length === 1) {
        return effects.destroyTicketLineItem(
          ticketCartToken,
          lineItemPrice.ticketingLineItemId
        )
      } else {
        return effects.destroyTicketLineItemPrice({
          ticketCartToken,
          ticketLineItemId: lineItemPrice.ticketingLineItemId,
          ticketLineItemPriceId: lineItemPrice.id,
        })
      }
    },

    updateLineItemPrice(
      ticketCartToken,
      lineItemPrice,
      { quantity, attendee, attendeeUdfs } = {}
    ) {
      return effects.updateTicketLineItemPrice({
        ticketCartToken,
        ticketLineItemId: lineItemPrice.ticketingLineItemId,
        ticketLineItemPriceId: lineItemPrice.id,
        quantity,
        attendee,
        attendeeUdfs,
      })
    },
  },
}

export default TicketingCart
