import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { toggle, togglePropTypes } from '@launchpadlab/lp-hoc'
import { Spinner } from '@launchpadlab/lp-components'
import { UntimedPrices } from '../components'
import * as Types from 'main/types'
import * as apiActions from 'main/apiActions'
import { selectors } from '../reducer'
import { selectors as apiSelectors } from '@launchpadlab/lp-redux-api'
import SocietyProductCard from '../../../layout/SocietyProductCard'

const propTypes = {
  addOn: Types.ticketAddOn.isRequired,
  ...togglePropTypes('addOnActive'),
  lineItemId: PropTypes.string.isRequired,
  fetchUntimedPrices: PropTypes.func.isRequired,
  untimedPricesFetchCompleted: PropTypes.bool.isRequired,
  startOpen: PropTypes.bool,
}

function UntimedAddOn({
  addOn,
  addOnActive,
  toggleAddOnActive,
  lineItemId,
  untimedPrices,
  fetchUntimedPrices,
  untimedPricesFetchCompleted,
  startOpen,
}) {
  useEffect(() => {
    if (addOnActive && !untimedPrices) {
      fetchUntimedPrices(addOn.id, addOn.type)
    }
  }, [addOnActive])

  useEffect(() => {
    if (startOpen && !addOnActive) {
      toggleAddOnActive()
    }
  }, [])

  return (
    <SocietyProductCard
      id={addOn.id}
      name={addOn.displayName}
      description={addOn.caption}
      imageUrl={addOn.activityImage}
      productUrl={addOn.learnMoreUrl}
      fullDescription={addOn.infoModalText}
      isLoginRequired={addOn.requireLogin}
      horizontal
    >
      {untimedPricesFetchCompleted ? (
        <UntimedPrices
          addOn={addOn}
          untimedPrices={untimedPrices}
          lineItemId={lineItemId}
        />
      ) : (
        <Spinner />
      )}
    </SocietyProductCard>
  )
}

UntimedAddOn.propTypes = propTypes

const mapDispatchToProps = {
  fetchUntimedPrices: apiActions.fetchUntimedPrices,
}

function mapStateToProps(state, props) {
  return {
    untimedPrices: selectors.untimedPricesForAddOn(state, props),
    untimedPricesFetchCompleted: apiSelectors.isSuccess(
      state,
      apiActions.REQ_UNTIMED_PRICES
    ),
  }
}
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  toggle('addOnActive')
)(UntimedAddOn)
