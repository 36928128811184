import React, { Fragment } from 'react'
import {
  Field,
  propTypes as formPropTypes,
  formValueSelector,
} from 'redux-form'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { lpForm } from '@launchpadlab/lp-form'
import PropTypes from 'prop-types'
import * as Types from 'main/types'
import { scroller } from 'react-scroll'
import { Checkbox } from '@launchpadlab/lp-components'
import { FoundationInput } from 'components'
import { first } from 'lodash'
import { modifyProps } from '@launchpadlab/lp-hoc'
import { keys, getCountryOptions, setZipConstraints } from 'utils'
import * as options from 'main/options'

const propTypes = {
  ...formPropTypes,
  letterShouldBeMailedValue: PropTypes.bool,
  countryValue: PropTypes.string,
  lineItemProduct: Types.foundationProduct.isRequired,
}

const defaultProps = {
  letterShouldBeMailedValue: null,
  countryValue: 'US',
}

function setInscriptionCharacterLimits(lineItemProduct, lineNumber) {
  var characterLimit = lineItemProduct[`inscriptionLimitLine${lineNumber}`]
  const constraints = () => {
    if (lineNumber == 1)
      return { presence: true, length: { maximum: characterLimit } }
    else {
      return { length: { maximum: characterLimit } }
    }
  }
  return constraints
}

function PlaqueRecipientInfoForm({
  handleSubmit,
  letterShouldBeMailedValue,
  countryValue,
  lineItemProduct,
}) {
  // We don't want to display the inscription if its line limit is nil or 0:
  const inscriptionLine1Present = !!lineItemProduct.inscriptionLimitLine1
  const inscriptionLine2Present = !!lineItemProduct.inscriptionLimitLine2
  const inscriptionLine3Present = !!lineItemProduct.inscriptionLimitLine3
  const inscriptionLine4Present = !!lineItemProduct.inscriptionLimitLine4
  const inscriptionLine5Present = !!lineItemProduct.inscriptionLimitLine5
  return (
    <form onSubmit={handleSubmit}>
      {inscriptionLine1Present && (
        <Field
          name="inscriptionMessageLine1"
          component={FoundationInput}
          label={`Inscription Line 1 (${lineItemProduct.inscriptionLimitLine1} Characters)`}
          placeholder={`Inscription Line 1 (${lineItemProduct.inscriptionLimitLine1} Characters)`}
        />
      )}
      {inscriptionLine2Present && (
        <Field
          name="inscriptionMessageLine2"
          component={FoundationInput}
          label={`Inscription Line 2 (${lineItemProduct.inscriptionLimitLine2} Characters)`}
          placeholder={`Inscription Line 2 (${lineItemProduct.inscriptionLimitLine2} Characters)`}
        />
      )}
      {inscriptionLine3Present && (
        <Field
          name="inscriptionMessageLine3"
          component={FoundationInput}
          label={`Inscription Line 3 (${lineItemProduct.inscriptionLimitLine3} Characters)`}
          placeholder={`Inscription Line 3 (${lineItemProduct.inscriptionLimitLine3} Characters)`}
        />
      )}
      {inscriptionLine4Present && (
        <Field
          name="inscriptionMessageLine4"
          component={FoundationInput}
          label={`Inscription Line 4 (${lineItemProduct.inscriptionLimitLine4} Characters)`}
          placeholder={`Inscription Line 4 (${lineItemProduct.inscriptionLimitLine4} Characters)`}
        />
      )}
      {inscriptionLine5Present && (
        <Field
          name="inscriptionMessageLine5"
          component={FoundationInput}
          label={`Inscription Line 5 (${lineItemProduct.inscriptionLimitLine5} Characters)`}
          placeholder={`Inscription Line 5 (${lineItemProduct.inscriptionLimitLine5} Characters)`}
        />
      )}

      <Field
        name="letterShouldBeMailed"
        component={Checkbox}
        label="This is a gift. I would like you to send notification to:"
      />
      {letterShouldBeMailedValue && (
        <Fragment>
          <div className="flex gap-5">
            <Field
              name="recipientFirstName"
              component={FoundationInput}
              label="Recipient's First Name"
              placeholder="Recipient's First Name"
              maxLength="20"
            />
            <Field
              name="recipientLastName"
              component={FoundationInput}
              label="Recipient's Last Name"
              placeholder="Recipient's Last Name"
              maxLength="20"
            />
          </div>
          <Field
            name="recipientCountry"
            component={FoundationInput}
            options={getCountryOptions()}
            label="Country"
            inputType="select"
            placeholder="Select"
          />
          <Field
            name="recipientAddress1"
            component={FoundationInput}
            label="Recipient's Address"
            placeholder="Recipient's Address"
          />
          <Field
            name="recipientAddress2"
            component={FoundationInput}
            label="Recipient's Address Line 2"
            placeholder="Recipient's Address Line 2"
          />
          <Field
            name="recipientCity"
            component={FoundationInput}
            label="City"
            placeholder="City"
          />
          <div className="flex gap-5">
            {countryValue == 'US' && (
              <Field
                name="recipientState"
                component={FoundationInput}
                options={options.states}
                label="State"
                placeholder="Select State"
                inputType="select"
              />
            )}
            <Field
              name="recipientZip"
              component={FoundationInput}
              label="ZIP"
              placeholder="ZIP"
            />
          </div>
        </Fragment>
      )}
    </form>
  )
}

PlaqueRecipientInfoForm.propTypes = propTypes
PlaqueRecipientInfoForm.defaultProps = defaultProps

const modify = ({ countryValue, lineItemProduct }) => {
  return {
    constraints: {
      recipientFirstName: {
        presence: true,
      },
      recipientLastName: {
        presence: true,
      },
      recipientAddress1: {
        presence: true,
      },
      recipientCity: {
        presence: true,
      },
      recipientState: {
        presence: true,
      },
      recipientZip: setZipConstraints(countryValue),
      inscriptionMessageLine1: setInscriptionCharacterLimits(
        lineItemProduct,
        1
      ),
      inscriptionMessageLine2: setInscriptionCharacterLimits(
        lineItemProduct,
        2
      ),
      inscriptionMessageLine3: setInscriptionCharacterLimits(
        lineItemProduct,
        3
      ),

      inscriptionMessageLine4: setInscriptionCharacterLimits(
        lineItemProduct,
        4
      ),
      inscriptionMessageLine5: setInscriptionCharacterLimits(
        lineItemProduct,
        5
      ),
    },
  }
}

const PlaqueRecipientInfoFormSelector = formValueSelector('recipient-info-form')

function mapStateToProps(state) {
  return {
    letterShouldBeMailedValue: PlaqueRecipientInfoFormSelector(
      state,
      'letterShouldBeMailed'
    ),
    countryValue: PlaqueRecipientInfoFormSelector(state, 'recipientCountry'),
  }
}

export default compose(
  connect(mapStateToProps, null),
  modifyProps(modify),
  lpForm({
    name: 'recipient-info-form',
    onSubmitFail: (params, dispatch, _, { syncErrors }) => {
      const firstErrorField = first(keys(syncErrors))
      return scroller.scrollTo(firstErrorField, {
        smooth: true,
      })
    },
    enableReinitialize: true,
  })
)(PlaqueRecipientInfoForm)
