/**
 * @name getTicketingRedirectUrl
 * @param {Object} query - Object containing query parameters from Router
 * @returns {String} - Returns the path to redirect to based on query parameters
 * or any empty string. If an id is present, the redirect path will go directly
 * to that ticketing activity.
 */

import { compact } from 'lodash'

function getTicketingRedirectUrl({ activityId, cartToken = null } = {}) {
  if (!activityId) return null

  const qs = compact([
    `activityId=${activityId}`,
    cartToken && `cartToken=${cartToken}`,
  ]).join('&')

  return `/ticketing/details?${qs}`
}

export default getTicketingRedirectUrl
