import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'main/types'
import {
  Input,
  InputError,
  omitLabelProps,
  serializeOptions,
} from '@launchpadlab/lp-components'
import { set } from 'lodash/fp'

const propTypes = {
  options: PropTypes.arrayOf(Types.donationLevel).isRequired,
}

const defaultProps = {}

function RadioGroupLevelSelection(props) {
  const {
    input: { value, onChange, name, onBlur },
    meta: { invalid, error, touched },
    options,
    formName,
    ...rest
  } = omitLabelProps(props)
  const optionObjects = serializeOptions(options)

  return (
    <fieldset role="radiogroup" {...rest}>
      <ol className="flex items-center gap-5">
        {optionObjects.map((option) => {
          const checked = value.id === option.id
          return (
            <li key={option.id} className="flex items-center gap-2 mb-0">
              <input
                id={`${formName}.${name}.${option.id}`}
                name={`${formName}.${name}`}
                type="radio"
                value={option}
                onChange={() => onChange(option)}
                checked={checked}
              />
              <label
                htmlFor={`${formName}.${name}.${option.id}`}
                className="m-0 whitespace-nowrap"
              >
                {option.displayName}
                {option.customAmount && ':'}
              </label>

              {option.customAmount && (
                <Input
                  aria-label="Enter custom amount"
                  label={false}
                  type="number"
                  className="mb-0"
                  input={{
                    name: `${formName}.${name}.${option.id}.customAmount`,
                    value: `${(value.customAmount && value.amount) || ''}`,
                    onChange: (e) =>
                      onChange(set('amount', Number(e.target.value), option)),
                  }}
                  onBlur={() => onBlur(value)}
                  meta={{}}
                  step="1"
                  disabled={!checked}
                />
              )}
            </li>
          )
        })}
      </ol>

      <InputError {...{ error, invalid, touched }} />
    </fieldset>
  )
}

RadioGroupLevelSelection.propTypes = propTypes
RadioGroupLevelSelection.defaultProps = defaultProps

export default RadioGroupLevelSelection
