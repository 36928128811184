import * as ReduxFlash from 'redux-flash'

const CONTACT_MESSAGES = {
  foundation:
    ' Please review and try again or call our office at 402-738-2073 for assistance.',
  society:
    ' Please try again in a few minutes. If you continue to experience problems, please call 402-733-8401 to complete your transaction.',
  default: ' If you continue to experience problems, please call 402-733-8401.',
}

function flashErrorMessage(message, { style, ...options } = {}) {
  const extraMessage = CONTACT_MESSAGES[style] || CONTACT_MESSAGES.default
  return ReduxFlash.flashErrorMessage(message + extraMessage, options)
}

export default flashErrorMessage
