// Finds secondary member if selected by `id` (i.e., selected existing member)
// or, returns secondary member object if new member
export function findSecondaryMember(params, membershipDetails = []) {
  if (!params || !params.secondaryMember) return

  const existingMember = membershipDetails.find(
    (member) => params.secondaryMember.id === member.id
  )

  if (!existingMember) return params.secondaryMember

  // Include updated information captured for the secondary member
  return {
    ...existingMember,
    ...params.secondaryMember,
  }
}

// Finds child members if selected by `id` (i.e., selected existing member)
// or, returns child object if new member
export function findChildren(params, membershipDetails) {
  if (!params || !params.children) return []

  return params.children.map((child) => {
    if (!child.id) return child

    const existingChild =
      membershipDetails.find((member) => member.id === child.id) || {}

    // Include updated information captured for each child
    return {
      ...existingChild,
      ...child,
    }
  })
}

// Returns true if any children are missing the `fullName` attr
export function isMissingName(memberArray) {
  if (!memberArray.length) return false
  const missingNames = memberArray.filter(hasIncompleteName)

  return !!missingNames.length
}

// ----- PRIVATE -----
function hasIncompleteName(member) {
  // Only look at existing members
  if (!member) return false

  return !member.firstName || !member.lastName
}
