import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@launchpadlab/lp-components'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import * as LS from 'services/sessionStorage'
import * as routerActions from 'react-router-redux'
import * as effects from 'main/effects'
import { CartAccessor } from '../components'
import editIcon from 'images/edit-cart.svg'
import * as Sentry from '@sentry/react'
import CheckoutPageLayout from '../components/CheckoutPageLayout'
import { flashErrorMessage } from 'utils'
import ReactGA from 'react-ga4'

const propTypes = {
  push: PropTypes.func.isRequired,
  flashErrorMessage: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
}

const defaultProps = {}

const DataPanel = ({ title, children }) => (
  <section className="space-y-2">
    <header className="font-bold">{title}</header>
    <div className="space-y-2">{children}</div>
  </section>
)

const ReviewSection = ({ title, editUrl, children }) => (
  <section className="space-y-4">
    <header className="flex items-center justify-between text-xl font-bold">
      {title}
      <Link to={editUrl}>
        <img className="w-4 h-4" src={editIcon} />
      </Link>
    </header>
    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">{children}</div>
  </section>
)
function ConfirmationDetails({
  push,
  flashErrorMessage,
  location: {
    query: { cartToken },
  },
}) {
  const [isLoading, setIsLoading] = useState(false)
  return (
    <CartAccessor cartToken={cartToken}>
      {({ foundationCart }) => {
        const personalDetail = foundationCart.foundationPersonalDetail || {}
        const paymentDetail = foundationCart.foundationPaymentDetail || {}
        const foundationCartToken = foundationCart.token

        return (
          <CheckoutPageLayout step={2} cartToken={cartToken}>
            <h2>Review Details</h2>
            <ReviewSection
              title="Your Information"
              editUrl={`/give/cart/info?cartToken=${foundationCartToken}`}
            >
              <DataPanel title="Phone">{personalDetail.phone}</DataPanel>
              <DataPanel title="Email">{personalDetail.email}</DataPanel>
              <DataPanel title="Mailing Address">
                <div>
                  {personalDetail.firstName} {personalDetail.lastName}
                </div>
                <div>{personalDetail.address1}</div>
                <div>
                  {personalDetail.city}, {personalDetail.state}
                </div>
                <div>{personalDetail.zip}</div>
              </DataPanel>
            </ReviewSection>
            <hr />
            <ReviewSection
              title="Payment Information"
              editUrl={`/give/cart/payment?cartToken=${foundationCartToken}`}
            >
              <DataPanel title="Credit Card">
                <div>
                  {paymentDetail.firstName} {paymentDetail.lastName}
                </div>
                <div>Ending in {paymentDetail.lastFourDigits}</div>
              </DataPanel>

              <DataPanel title="Billing Address">
                <div>
                  {paymentDetail.firstName} {paymentDetail.lastName}
                </div>
                <div>
                  {paymentDetail.address1} {paymentDetail.address2}
                </div>
                <div>
                  {paymentDetail.city}, {paymentDetail.state}{' '}
                </div>
                <div> {paymentDetail.zip}</div>
              </DataPanel>
            </ReviewSection>

            <Button
              submitting={isLoading}
              disabled={isLoading}
              className="foundation-button-primary"
              onClick={() => {
                setIsLoading(true)
                effects
                  .createFoundationOrder({
                    foundationCartToken: foundationCart.token,
                  })
                  .then((order) => {
                    const orderToken = order.data.attributes.token
                    ReactGA.event('purchase', {
                      currency: 'USD',
                      value: foundationCart.total,
                      items: foundationCart.foundationLineItems?.map(
                        (cartItem) => ({
                          item_id: cartItem.foundationProductCategoryId,
                          item_name: cartItem.donationType,
                          price: cartItem.amount,
                          item_category: 'foundation_donation',
                          quantity: 1,
                        })
                      ),
                    })

                    LS.clearFoundationCartToken()
                    push(`/give/cart/submitted?orderToken=${orderToken}`)
                  })
                  .catch((err) => {
                    Sentry.captureException(err)
                    const msg =
                      (err && err.errors && err.errors.message) ||
                      'Something went wrong. Please try again in a moment.'
                    flashErrorMessage(msg, { style: 'foundation' })
                  })
                  .finally(() => setIsLoading(false))
              }}
            >
              Confirm Donation
            </Button>
          </CheckoutPageLayout>
        )
      }}
    </CartAccessor>
  )
}

ConfirmationDetails.propTypes = propTypes
ConfirmationDetails.defaultProps = defaultProps

function mapStateToProps() {
  return {}
}

const mapDispatchToProps = {
  push: routerActions.push,
  flashErrorMessage,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ConfirmationDetails
)
