import React, { useState } from 'react'
import Form, { useForm } from '../../../../components/Form'
import Button from '../../../../components/ui/Button'
import SocietyHeading from '../../../../layout/SocietyHeading'
import { connect } from 'react-redux'
import SocietyPageLayout from '../../../../layout/SocietyPageLayout'
import { captureException } from '@sentry/react'
import { Link } from 'react-router'
import Users from '../../../ticketing/utils/users'
import { CREATE_USER_FORM_FIELDS } from '../UniversalSignIn'

const CreateUserForm = connect()(({
  email,
  redirectUrl,
  membershipId,
  membershipLastName,
  handleClearEmail,
  handleCreateUser,
}) => {
  const form = useForm(CREATE_USER_FORM_FIELDS, {})
  const [errorMessage, setErrorMessage] = useState(null)

  async function handleSubmit() {
    const { password, passwordConfirmation } = form.values

    if (form.validate()) {
      try {
        await Users.createUser({
          email,
          password,
          passwordConfirmation,
          redirectOrigin: window.location.origin,
          redirectUrl,
          membershipId,
          membershipLastName,
        })

        handleCreateUser()
      } catch (error) {
        if (error.status < 500) {
          setErrorMessage(error.response.errors.message)
        } else {
          setErrorMessage('Something went wrong. Please try again later.')
          captureException(error)
        }
      }
    }
  }

  return (
    <SocietyPageLayout title="Create Your Account">
      <section className="p-5 mx-auto space-y-10 bg-white shadow pb-7 sm:w-2/3">
        <SocietyHeading>Create a password</SocietyHeading>
        We've found a record associated with this email. Please create a
        password to continue.
        <section className="space-y-5">
          <div className="space-y-1">
            <label className="block text-base">Email</label>
            <div>{email}</div>
          </div>

          <Form {...form} onSubmit={handleSubmit} />
        </section>
        <footer className="relative pb-3">
          <Button
            type="primary"
            label="Continue"
            size="xl"
            width="full"
            onClick={handleSubmit}
          />

          {errorMessage && (
            <div className="absolute inset-x-0 text-center top-full text-danger">
              {errorMessage}
            </div>
          )}
        </footer>
      </section>
      <section className="p-5 mx-auto space-y-10 pb-7 sm:w-2/3">
        <Link onClick={handleClearEmail}>Sign in with a different user</Link>
      </section>
    </SocietyPageLayout>
  )
})

export default CreateUserForm
