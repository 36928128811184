import { isEmpty } from '.'

export default function mapDonationOrderData({
  paymentResponse = {},
  fundId,
  level,
  state,
  suburb,
  country,
  zip,
  giftCard = {},
  firstName,
  lastName,
  phone,
  email,
}) {
  const customerInfo = isEmpty(paymentResponse)
    ? {
        firstName,
        lastName,
        phone,
        email,
      }
    : {
        ...paymentResponse,
        city: suburb, // Centaman API ticketing contact creation only accepts city
        state,
        suburb,
        country,
        firstName,
        lastName,
        streetAddress1: paymentResponse.streetAddress,
        streetAddress2: paymentResponse.streetAddress2,
        zip,
      }

  const orderInfo = {
    donationFundId: fundId,
    donationAmount: level || 0,
  }

  return {
    order: {
      giftCardNumber: giftCard.giftCardNumber || null,
      orderInfo,
      customerInfo,
      cardToken: paymentResponse.cardToken,
    },
  }
}
