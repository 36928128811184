import React from 'react'
import { format } from 'date-fns'
import Barcode from 'react-jsbarcode'

import societyLogo from 'images/logo.svg'
import MembershipWalletButtons from 'main/membership/components/MembershipWalletButtons'

const formatMembershipDate = (date) => format(date, 'MMMM Do, YYYY')
const formatExpirationpDate = (date) => format(date, 'MM.DD.YY')

const Summary = ({ currentMembership }) => {
  return (
    <div className="p-5 bg-white shadow">
      <h2>Membership Details</h2>
      <div className="text-lg font-bold">
        {currentMembership.package} Membership
      </div>
      <div className="text-sm italic">
        Valid from{' '}
        <span className="underline">
          {formatMembershipDate(currentMembership.joinDate)}
        </span>{' '}
        through{' '}
        <span className="underline">
          {formatMembershipDate(currentMembership.expirationDate)}
        </span>
      </div>
    </div>
  )
}

const Card = ({ primaryMember }) => {
  return (
    <div className="p-5 bg-white shadow">
      <h2>Membership Details</h2>
      <div className="border-dashed border-2 max-w-md w-full rounded-lg">
        <div className="clear-right p-3">
          <div
            className=" float-left w-40 h-20 justify-center bg-contain bg-center bg-no-repeat mr-4"
            style={{ backgroundImage: `url(${societyLogo})` }}
          />

          <div className="mx-4 p-2 text-md h-20 text-left">
            <div className="p-2 font-bold text-lg">
              {primaryMember.firstName} {primaryMember.lastName}
            </div>
            <div className="">
              {primaryMember.currentMembership.package} Membership
            </div>
          </div>
        </div>

        <div className="flex justify-center items-center ">
          <Barcode
            value={primaryMember.memberNumber}
            options={{ format: 'code128', height: 40, width: 3 }}
            renderer="svg"
          />
        </div>
        <div className="text-right m-4">
          Expires:{' '}
          {formatExpirationpDate(
            primaryMember.currentMembership.expirationDate
          )}
        </div>
      </div>

      <MembershipWalletButtons />
    </div>
  )
}

const MembershipCard = ({ primaryMember }) => {
  if (!primaryMember?.currentMembership) {
    return null
  }
  if (process.env.REACT_APP_MEMBER_CARD === 'true') {
    return (
      <Card
        primaryMember={primaryMember}
        currentMembership={primaryMember.currentMembership}
      />
    )
  } else {
    return <Summary currentMembership={primaryMember.currentMembership} />
  }
}

export default MembershipCard
