/**
 * @name runExternalScript
 * @param {String} src - The url of the script to run (must be external)
 * @param {Object} [options] - Optional properties to add to the script
 * @param {Boolean} [options.asyncAttr=false] - Execute the script asynchronously (async is a reserved word)
 * @param {Boolean} [options.deferAttr=false] - Execute after page has finished parsing
 * @param {Function} [options.onloadFunction] - Function to execute onload (not inherently guaranteed to work with a particular external script. Make sure to check that the function(s) required are loaded on the window)
 * @returns {Promise} - Returns a Promise based on the loading state of the script
 */

function runExternalScript(src, { asyncAttr = false, deferAttr = false } = {}) {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.src = src
    script.defer = deferAttr
    script.async = asyncAttr
    script.onload = resolve
    script.onerror = reject

    document.head.appendChild(script)
  })
}

export default runExternalScript
