import { combineReducers } from 'redux'
import {
  reducer as membershipReducer,
  reducerKey as membershipReducerKey,
} from './membership'
import {
  reducer as ticketingReducer,
  reducerKey as ticketingReducerKey,
} from './ticketing'
import { reducer as userReducer, reducerKey as userReducerKey } from './user'
import {
  reducer as donationReducer,
  reducerKey as donationReducerKey,
} from './donation'
import {
  reducer as giftCardReducer,
  reducerKey as giftCardReducerKey,
} from './giftCard'
import {
  reducer as questionnaireReducer,
  reducerKey as questionnaireReducerKey,
} from './questionnaire'
import {
  reducer as foundationReducer,
  reducerKey as foundationReducerKey,
} from './foundation'
import {
  reducer as productAdReducer,
  reducerKey as productAdReducerKey,
} from './ads'
const reducerKey = 'root'

const reducer = combineReducers({
  [membershipReducerKey]: membershipReducer,
  [ticketingReducerKey]: ticketingReducer,
  [userReducerKey]: userReducer,
  [donationReducerKey]: donationReducer,
  [giftCardReducerKey]: giftCardReducer,
  [questionnaireReducerKey]: questionnaireReducer,
  [foundationReducerKey]: foundationReducer,
  [productAdReducerKey]: productAdReducer
})

export { reducer, reducerKey }
