import React from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import checkmark from 'images/checkmark.svg'

const propTypes = {
  currentStep: PropTypes.number.isRequired,
}

const LABELS = ['Donor Information', 'Payment Method', 'Review Details']

function ProgressBar({ currentStep }) {
  return (
    <ol
      className={c(
        'flex flex-col justify-between w-full mx-0 my-2 space-y-2',
        'sm:space-x-2 sm:flex-row sm:space-y-0',
        'xl:justify-center xl:space-x-24'
      )}
      role="progressbar"
      aria-valuemin="0"
      aria-valuemax="4"
      aria-valuenow={currentStep + 1}
      aria-valuetext={`Step ${currentStep + 1} of 4: ${LABELS[currentStep]}`}
    >
      {LABELS.map((label, step) => {
        const complete = step < currentStep
        const current = step === currentStep

        return (
          <li
            key={step}
            className={c('mb-0 text-base space-x-1 whitespace-nowrap', {
              'font-bold': current,
            })}
          >
            {complete ? (
              <img src={checkmark} className="inline w-4 h-4" />
            ) : (
              <span>{step + 1}.</span>
            )}
            <span>{label}</span>
          </li>
        )
      })}
    </ol>
  )
}

ProgressBar.propTypes = propTypes

export default ProgressBar
