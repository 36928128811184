import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'main/types'
import * as effects from 'main/effects'
import SocietyOrderSummary from '../../../layout/SocietyOrderSummary'
import { compact } from 'lodash'

const propTypes = {
  amount: PropTypes.number,
  selectedFund: Types.donationFund.isRequired,
  subtotal: PropTypes.number.isRequired,
  donationForm: PropTypes.object.isRequired,
  setGiftCard: PropTypes.func.isRequired,
  total: PropTypes.number,
  appliedGiftCardAmount: PropTypes.number,
}

const defaultProps = {
  amount: 0,
  className: '',
  total: 0,
  appliedGiftCardAmount: 0,
}

function OrderReceipt({
  amount,
  selectedFund: { displayName },
  subtotal,
  donationForm,
  setGiftCard,
  total,
  appliedGiftCardAmount,
}) {
  useEffect(() => {
    if (amount > 0) {
      effects
        .updateDonationCheckout(donationForm)
        .then((response) => setGiftCard(response))
    }
  }, [amount])

  const summary = compact([
    { label: 'Subtotal', amount: subtotal },
    appliedGiftCardAmount && {
      label: 'Gift Card:',
      amount: -appliedGiftCardAmount,
    },
    { label: 'Total', amount: total, highlight: true },
  ])

  const items = [
    {
      label: displayName,
      subtotal: amount,
    },
  ]

  return <SocietyOrderSummary summary={summary} items={items} />
}

OrderReceipt.propTypes = propTypes
OrderReceipt.defaultProps = defaultProps

export default OrderReceipt
