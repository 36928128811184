import { flip } from 'lodash'

// Route hook builders
// We use "flip" so that we can use the same hook for onEnter and onChange.

function requireAuth(authFunction, options = {}) {
  return flip((callback, replace, nextState) => {
    if (!authFunction()) {
      replace({
        pathname: options.redirectUrl || '/sign-in',
        state: { redirectUrl: nextState.location.pathname },
      })
    }
  })
}

function excludeAuth(authFunction, options = {}) {
  return flip((callback, replace) => {
    if (authFunction()) replace(options.redirectUrl || '/')
    callback()
  })
}

export default { requireAuth, excludeAuth }
