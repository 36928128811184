import React from 'react'
import { Link } from 'react-router'
import { displayCurrency } from 'utils'
import c from 'classnames'

function SocietyOrderSummary({ items, summary, readOnly, onRemove }) {
  return (
    <section className="space-y-5">
      <ol>
        {items.map(
          (
            { id, label, date, prices, addOns, editUrl, subtotal, notes },
            index
          ) => {
            return (
              <li key={index} className="flex gap-5">
                <section className="flex-grow space-y-2">
                  <header className="space-y-1 leading-snug">
                    <span className="font-semibold">{label}</span>
                    {date && <div className="text-sm italic">{date}</div>}
                    {notes &&
                      notes.map(([label, note]) => (
                        <div key={label} className="text-xs italic">
                          {label && (
                            <span className="font-bold">{label}: </span>
                          )}
                          {note}
                        </div>
                      ))}
                  </header>
                  {prices && (
                    <ol className="space-y-2">
                      {prices.map(({ id, label, value, quantity, note }) => (
                        <li key={id} className="mb-0 text-sm">
                          {label} - {displayCurrency(value)}{' '}
                          {quantity && `ea x${quantity}`}
                          {note && (
                            <div className="text-xs italic font-bold">
                              {note}
                            </div>
                          )}
                        </li>
                      ))}
                    </ol>
                  )}

                  {addOns && (
                    <ol>
                      {addOns.map(({ id, prices, editUrl }) => (
                        <li key={id}>
                          <ol>
                            {prices.map(
                              ({ id, label, value, date, quantity }) => (
                                <li
                                  key={id}
                                  className="px-3 space-y-0.5 py-1 mb-0 text-sm border-l-2 border-solid border-primary-300"
                                >
                                  <div>{label}</div>
                                  <div className="italic">Starts at {date}</div>
                                  <div>
                                    {displayCurrency(value)}{' '}
                                    {quantity && `ea x${quantity}`}
                                  </div>

                                  {!readOnly && editUrl && (
                                    <Link
                                      to={editUrl}
                                      aria-label="Edit Ticket"
                                      className="block font-normal text-gray-600 transition-colors hover:text-gray-700"
                                    >
                                      Edit
                                    </Link>
                                  )}
                                </li>
                              )
                            )}
                          </ol>
                        </li>
                      ))}
                    </ol>
                  )}
                  {!readOnly && (
                    <section className="space-x-2">
                      {editUrl && (
                        <Link
                          to={editUrl}
                          aria-label="Edit Ticket"
                          className="font-normal text-gray-600 transition-colors hover:text-gray-700"
                        >
                          Edit
                        </Link>
                      )}
                      {onRemove && (
                        <Link
                          aria-label="Remove Ticket"
                          onClick={() => onRemove(id)}
                          className="font-normal transition-colors text-danger hover:text-danger-shade-300"
                        >
                          Remove
                        </Link>
                      )}
                    </section>
                  )}
                </section>
                <div>{displayCurrency(subtotal)}</div>
              </li>
            )
          }
        )}
      </ol>
      <hr />
      <ol className="space-y-4">
        {summary.map(({ label, amount, strVal, highlight }) => (
          <li
            key={label}
            className={c('flex justify-between gap-16 mb-0', {
              'font-bold': highlight,
            })}
          >
            <span>{label}</span>
            <span>
              {displayCurrency(amount)}
              {strVal}
            </span>
          </li>
        ))}
      </ol>
    </section>
  )
}

export default SocietyOrderSummary
