import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'main/types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import * as reduxForm from 'redux-form'
import { selectors } from '../reducer'
import * as routerActions from 'react-router-redux'
import * as apiActions from 'main/apiActions'
import { Spinner } from '@launchpadlab/lp-components'
import { get } from 'lodash/fp'
import * as actions from '../actions'
import * as effects from 'main/effects'
import {
  displaySubmitFailure,
  isEmpty,
  findFirstErrorField,
  splitFullName,
} from 'utils'
import { scroller } from 'react-scroll'
import ReactGA from 'react-ga4'
import { GiftCardPaymentForm } from '../forms'
import { GiftCardReceipt } from '../components'
import { modifyProps } from '@launchpadlab/lp-hoc'
import { GpiWrapper, GpiSubmit } from 'components/Gpi'
import SocietyCheckoutLayout from '../../../layout/SocietyCheckoutLayout'
import { GIFT_CARD_CHECKOUT_STEPS } from '../../types'

const propTypes = {
  selectedGiftCard: Types.giftCard,
  giftCardCart: Types.giftCardCart,
  fetchGiftCard: PropTypes.func.isRequired,
  fetchGiftCardCart: PropTypes.func.isRequired,
  setGiftCardCart: PropTypes.func.isRequired, // eslint-disable-line react/no-unused-prop-types
  setGiftCardOrder: PropTypes.func.isRequired, // eslint-disable-line react/no-unused-prop-types
  customerCountry: PropTypes.string,
  variablePrice: PropTypes.number,
  quantity: PropTypes.number,
  submitForm: PropTypes.func.isRequired,
  changeForm: PropTypes.func.isRequired,
  emptyGiftCardCart: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  isFormSubmitting: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  submitPaymentAndOrder: PropTypes.func.isRequired,
}

const defaultProps = {}

function GiftCardDetails({
  selectedGiftCard,
  giftCardCart,
  fetchGiftCard,
  fetchGiftCardCart,
  customerCountry,
  variablePrice,
  quantity,
  submitForm,
  changeForm,
  emptyGiftCardCart,
  push,
  submitPaymentAndOrder,
  isFormSubmitting,
  location,
}) {
  useEffect(() => {
    const giftCardId = location.query.id || get('id', selectedGiftCard)

    // Do not call API if the correct giftCard already exists
    if (selectedGiftCard && selectedGiftCard.id === Number(giftCardId)) return

    // The 0 fallback ensures we always call the API with an ID param
    fetchGiftCard(giftCardId || 0)
  }, [selectedGiftCard, location])

  useEffect(() => {
    if (!isEmpty(giftCardCart)) return

    fetchGiftCardCart()
  }, [giftCardCart])

  useEffect(() => {
    // Clear the cart if you are on a new giftCardDetails page
    const giftCardCartProductId = get('products[0].id', giftCardCart)
    if (giftCardCartProductId && selectedGiftCard) {
      if (giftCardCartProductId === selectedGiftCard.id) return
      emptyGiftCardCart()
    }
  }, [selectedGiftCard, giftCardCart])

  // The initial values for the form should be the
  // variablePrice and quantity on the giftCardCart if they exist
  const priceSelection = get('products[0].priceSelections[0]', giftCardCart)

  const priceSelectionForCart = priceSelection || {
    variablePrice: null,
    quantity: 1,
  }

  if (!selectedGiftCard) return <Spinner />

  const handleSubmit = ({
    cardToken,
    expirationDate,
    lastFourDigits,
    cardType,
    // maskedCardNumber, // not currently used but could be sent to server
  }) => {
    // https://redux-form.com/8.3.0/docs/api/props.md/#-code-change-field-string-value-any-function-code-

    // update the form's card token with the temporary payment token from GPI
    // and add any other payment-related fields that our server needs to know about
    changeForm('giftcard-payment', 'cardToken', cardToken)
    changeForm('giftcard-payment', 'expirationDate', expirationDate)
    changeForm('giftcard-payment', 'lastFourDigits', lastFourDigits)
    changeForm('giftcard-payment', 'cardType', cardType)
    // submit the form, which triggers other form validations and then ultimately submits to our server
    submitForm('giftcard-payment')
  }
  return (
    <SocietyCheckoutLayout
      title="Gift Card Details"
      progress={{ steps: GIFT_CARD_CHECKOUT_STEPS, currentStep: 1 }}
      backUrl="/gift-cards"
      summary={
        <GiftCardReceipt
          variablePrice={variablePrice}
          quantity={quantity}
          selectedGiftCard={selectedGiftCard}
        />
      }
      ContinueButton={<GpiSubmit />}
    >
      <GpiWrapper onTokenSuccess={handleSubmit} isDisabled={isFormSubmitting}>
        <p>
          You have chosen the <strong>{selectedGiftCard.displayName}</strong>.
          Please complete your gift card details below{' '}
        </p>
        <GiftCardPaymentForm
          customerCountry={customerCountry}
          selectedGiftCard={selectedGiftCard}
          onSubmit={(params) => submitPaymentAndOrder(params)}
          onSubmitSuccess={(response) => {
            ReactGA.event('purchase', response.data.attributes.analyticsDetails)
            emptyGiftCardCart()
            push(`/gift-cards/confirmation`)
          }}
          onSubmitFail={(errors, dispatch, submitError, { syncErrors }) => {
            displaySubmitFailure(errors, dispatch, submitError)
            if (!isEmpty(syncErrors)) {
              const firstErrorField = findFirstErrorField(syncErrors)
              if (!firstErrorField) return

              return scroller.scrollTo(firstErrorField, { smooth: true })
            }
          }}
          initialValues={{
            ...priceSelectionForCart,
            address: { country: 'US' },
          }}
        />
      </GpiWrapper>
    </SocietyCheckoutLayout>
  )
}

GiftCardDetails.propTypes = propTypes
GiftCardDetails.defaultProps = defaultProps

function mapStateToProps(state) {
  const formValueSelector = reduxForm.formValueSelector('giftcard-payment')

  return {
    selectedGiftCard: selectors.selectedGiftCard(state),
    giftCardCart: selectors.cart(state),
    customerCountry: reduxForm.formValueSelector('giftcard-payment')(
      state,
      'address.country'
    ),
    variablePrice: Number(formValueSelector(state, 'variablePrice')),
    quantity: Number(formValueSelector(state, 'quantity')),
    isFormSubmitting: reduxForm.isSubmitting('giftcard-payment')(state),
  }
}

const mapDispatchToProps = {
  fetchGiftCard: apiActions.fetchGiftCard,
  fetchGiftCardCart: apiActions.fetchGiftCardCart,
  setGiftCardCart: actions.setGiftCardCart,
  setGiftCardOrder: actions.setGiftCardOrder,
  submitForm: reduxForm.submit,
  changeForm: reduxForm.change,
  emptyGiftCardCart: actions.emptyGiftCardCart,
  push: routerActions.push,
}

function modify({
  selectedGiftCard,
  giftCardCart,
  setGiftCardCart,
  setGiftCardOrder,
}) {
  return {
    submitPaymentAndOrder: ({
      quantity,
      variablePrice,
      address: { streetAddress, streetAddress2, zip },
      recaptcha,
      ...rest
    }) => {
      const paymentResponse = {
        streetAddress,
        streetAddress2,
        zip,
        ...splitFullName(rest.nameOnCard),
        ...rest,
      }

      const giftCardValues = {
        quantity,
        variablePrice,
      }

      return effects
        .updateGiftCardCartWithPayment({
          paymentResponse,
          giftCardValues,
          selectedGiftCard,
          giftCardCart,
        })
        .then((response) => {
          setGiftCardCart(response)
          return effects.createGiftCardOrder(recaptcha)
        })
        .then((response) => {
          setGiftCardOrder(response)
          return response
        })
    },
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  modifyProps(modify)
)(GiftCardDetails)
