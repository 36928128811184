import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import c from 'classnames'
import { Spinner } from '@launchpadlab/lp-components'
import SocietyProductCard from './SocietyProductCard'
import ProductAdCard from './ProductAdCard'
import * as apiActions from 'main/apiActions'
import { selectors } from 'main/ads'


const SocietyProductGrid = ({ products, singleColumn, onSelect, fetchAds, ads=[], showAds=false }) => {
  const autoColumns = !singleColumn

  useEffect(() => {
    if (ads.length === 0) {
      fetchAds()
    }
  }, [])

  if(showAds && ads.length > 0) {
    products.splice(2, 0, {ads});
  }

  return !products ? (
    <Spinner />
  ) : (
    <ul
      className={c('grid gap-10 auto-cols-fr auto-rows-fr ', {
        'grid-cols-1': singleColumn,
        'grid-cols-1 md:grid-cols-2 lg:grid-cols-3': autoColumns,
      })}
    >
      {products.map((product) => {
        if (product.ads) {
          return (<li key={product.description} className="flex h-full mb-0">
          <ProductAdCard
            ads={product.ads}
            horizontal={singleColumn}
            onSelect={() => (onSelect ? onSelect(product) : null)}
          />
        </li>)
        }else {
          return (<li key={product.name} className="flex h-full mb-0">
          <SocietyProductCard
            {...product}
            horizontal={singleColumn}
            onSelect={() => (onSelect ? onSelect(product) : null)}
          />
        </li>)
        }

      })}
    </ul>
  )
}

const mapStateToProps = (state) => {
  return {
    ads: selectors.ads(state)
  } 
}

const mapDispatchToProps = {
  fetchAds: apiActions.fetchAds,
}

export default connect(mapStateToProps, mapDispatchToProps)(SocietyProductGrid)
