import React, { useEffect, useState } from 'react'
import { MobileListener } from 'components'
import classnames from 'classnames'
import NavItem from 'layout/NavItem'
import { PawIcon, CardIcon, TicketIcon, HeartIcon } from 'components/ui/Icons'
import { Link } from 'react-router'
import LogInOut from 'layout/LogInOut'
import hamburgerMenu from 'images/hamburger-menu.svg'
import navLogo from 'images/logo.svg'

const OmahaZooLogo = () => (
  <a className="nav-bar__title" href="https://www.omahazoo.com/">
    <img className="nav-bar__logo" src={navLogo} alt="Omaha Zoo" />
  </a>
)

const SharedHeader = () => {
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false)
  return (
    <MobileListener mobileWidthPx={900}>
      {({ isMobile }) => {
        useEffect(() => {
          if (!isMobile) {
            setIsMobileNavOpen(false)
          }
        }, [isMobile])

        return (
          <div
            className={classnames('theme-foundation foundation', {
              'foundation--mobile-nav-open': isMobileNavOpen,
            })}
          >
            {!isMobile && (
              <header className="flex justify-between gap-5 p-2 bg-white shadow">
                <a href={process.env.REACT_APP_OMAHA_ZOO_URL}>
                  <img src={navLogo} className="h-10 md:h-20" alt="Omaha Zoo" />
                </a>
                <nav className="px-3">
                  <ul className="flex items-center h-full gap-4">
                    <li>
                      <NavItem
                        as={Link}
                        to="/membership"
                        className="mb-0 menu-green"
                        label="Memberships"
                        icon={<PawIcon />}
                      />
                    </li>
                    <li>
                      <NavItem
                        as={Link}
                        to="/gift-cards"
                        className="mb-0 menu-blue"
                        label="Gift Cards"
                        icon={<CardIcon />}
                      />
                    </li>
                    <li>
                      <NavItem
                        as={Link}
                        to="/ticketing"
                        className="mb-0 menu-yellow"
                        label="Tickets"
                        icon={<TicketIcon />}
                      />
                    </li>
                    <li>
                      <NavItem
                        as="a"
                        href="https://www.omahazoofoundation.org/ways-to-give/"
                        className="mb-0 menu-red"
                        label="Donate"
                        icon={<HeartIcon />}
                      />
                    </li>
                    <li>
                      <LogInOut mobile={false} />
                    </li>
                  </ul>
                </nav>
              </header>
            )}
            {isMobile && (
              <div className="nav-bar">
                <OmahaZooLogo />
                <div
                  onClick={() => setIsMobileNavOpen(!isMobileNavOpen)}
                  className="nav-bar__link nav-bar__link--mobile-hamburger "
                >
                  {isMobileNavOpen ? 'X' : <img src={hamburgerMenu} />}
                </div>
              </div>
            )}
            {isMobileNavOpen && (
              <div className="nav-bar-mobile">
                <NavItem
                  as={Link}
                  to="/membership"
                  label="Memberships"
                  icon={<PawIcon />}
                  isMobile={true}
                  onClick={() => setIsMobileNavOpen(false)}
                />
                <NavItem
                  as={Link}
                  to="/gift-cards"
                  label="Gift Cards"
                  icon={<CardIcon />}
                  isMobile={true}
                  onClick={() => setIsMobileNavOpen(false)}
                />
                <NavItem
                  as={Link}
                  to="/ticketing"
                  label="Tickets"
                  icon={<TicketIcon />}
                  isMobile={true}
                  onClick={() => setIsMobileNavOpen(false)}
                />
                <NavItem
                  as="a"
                  href="https://www.omahazoofoundation.org/ways-to-give/"
                  label="Donate"
                  onClick={() => setIsMobileNavOpen(false)}
                  isMobile={true}
                  icon={<HeartIcon />}
                />
                <LogInOut
                  mobile={true}
                  mobileClickHandler={() => setIsMobileNavOpen(false)}
                />
              </div>
            )}
          </div>
        )
      }}
    </MobileListener>
  )
}

export default SharedHeader
