import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Spinner } from '@launchpadlab/lp-components'
import * as effects from 'main/effects'
import { displayCurrency, flashErrorMessage } from 'utils'
import { Link } from 'react-router'
import TrashIcon from 'images/trash.svg'
import { format, parse } from 'date-fns'
import AccountPageLayout from '../components/AccountPageLayout'
import c from 'classnames'
import RemoveConfirmationModal from 'main/foundation/cart/components/RemoveConfirmationModal'
import DownloadLink from 'components/ui/DownloadLink'

const AddCircleIcon = ({ className }) => (
  <svg
    className={className}
    viewBox="0 0 33 32"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
  >
    <path
      d="M16.5 3C9.33187 3 3.5 8.83187 3.5 16C3.5 23.1681 9.33187 29 16.5 29C23.6681 29 29.5 23.1681 29.5 16C29.5 8.83187 23.6681 3 16.5 3ZM21.5 17H17.5V21C17.5 21.2652 17.3946 21.5196 17.2071 21.7071C17.0196 21.8946 16.7652 22 16.5 22C16.2348 22 15.9804 21.8946 15.7929 21.7071C15.6054 21.5196 15.5 21.2652 15.5 21V17H11.5C11.2348 17 10.9804 16.8946 10.7929 16.7071C10.6054 16.5196 10.5 16.2652 10.5 16C10.5 15.7348 10.6054 15.4804 10.7929 15.2929C10.9804 15.1054 11.2348 15 11.5 15H15.5V11C15.5 10.7348 15.6054 10.4804 15.7929 10.2929C15.9804 10.1054 16.2348 10 16.5 10C16.7652 10 17.0196 10.1054 17.2071 10.2929C17.3946 10.4804 17.5 10.7348 17.5 11V15H21.5C21.7652 15 22.0196 15.1054 22.2071 15.2929C22.3946 15.4804 22.5 15.7348 22.5 16C22.5 16.2652 22.3946 16.5196 22.2071 16.7071C22.0196 16.8946 21.7652 17 21.5 17Z"
      fill="currentColor"
    />
  </svg>
)

const ViewAllButton = ({ onClick }) => (
  <button
    onClick={onClick}
    className={c(
      'border-solid border self-end rounded-full text-secondary font-light border-secondary px-8 py-2',
      //style cancelling -- remove once preflight is enabled
      'p-0 bg-transparent no-underline text-gray-700 font-bold'
    )}
  >
    View all
  </button>
)

const RecurringDonationCard = ({
  foundationProductName,
  scheduleType,
  amount,
  cardDescription,
  cardLastFour,
  onRemove,
}) => {
  const [isConfirmingRemoval, setIsConfirmingRemoval] = useState(false)

  const handleRemove = () => {
    setIsConfirmingRemoval(true)
  }

  const handleRemoveCancel = () => {
    setIsConfirmingRemoval(false)
  }

  const handleRemoveConfirm = () => {
    onRemove()
    setIsConfirmingRemoval(false)
  }
  return (
    <div className="flex items-center gap-10 p-5 bg-white rounded-md shadow-sm">
      <section className="flex-grow">
        <header className="text-2xl">{foundationProductName}</header>

        <div className="flex items-center gap-2 capitalize whitespace-nowrap">
          {displayCurrency(parseFloat(amount))}
          <hr className="inline-block h-5 m-0 border-r-0 border-gray-700 border-solid border-l-1" />
          {scheduleType}
        </div>

        <div className="text-sm whitespace-nowrap">
          <span className="uppercase">{cardDescription}</span> ending in{' '}
          {cardLastFour}
        </div>
      </section>
      <button
        aria-label={`Remove ${foundationProductName} of ${amount} dollars ${scheduleType}`}
        onClick={handleRemove}
        className={c(
          'flex-shrink cursor-pointer rounded-full h-8 w-8 hover:bg-gray-100 transition-colors underline',
          //style cancelling -- remove once preflight is enabled
          'p-0 bg-transparent border-none text-gray-700 font-bold'
        )}
      >
        <img src={TrashIcon} className="w-6 h-6" />
      </button>

      <RemoveConfirmationModal
        isOpen={isConfirmingRemoval}
        onClose={handleRemoveCancel}
        onConfirm={handleRemoveConfirm}
        message="Are you sure you want to remove this recurring donation?"
      />
    </div>
  )
}

RecurringDonationCard.propTypes = {
  foundationProductName: PropTypes.string.isRequired,
  scheduleType: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  cardDescription: PropTypes.string.isRequired,
  cardLastFour: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired,
}

const RecurringDonationList = ({ onError }) => {
  const [items, setItems] = useState()

  async function fetchRecurringSchedules() {
    setItems(undefined)
    const { data } = await effects.getFoundationRecurringSchedules()
    setItems(data.attributes)
  }

  useEffect(() => {
    fetchRecurringSchedules()
  }, [])

  async function cancelRecurringSchedule(id) {
    try {
      await effects.cancelFoundationRecurringSchedule(id)
      fetchRecurringSchedules()
    } catch (error) {
      onError('There was some error cancelling the recurring donation')
    }
  }

  return items ? (
    <ul className="grid grid-cols-1 gap-5 lg:grid-cols-2 auto-cols-max auto-rows-max">
      {items.map(({ id, ...props }) => (
        <li key={id} className="mb-0 ">
          <RecurringDonationCard
            onRemove={() => cancelRecurringSchedule(id)}
            {...props}
          />
        </li>
      ))}
      <li className="mb-0">
        <Link
          className="flex items-center justify-center h-full gap-2 p-5 text-xl no-underline bg-white rounded-md shadow-sm text-secondary"
          to="/give/donate"
        >
          <AddCircleIcon className="w-6 h-6" />
          Add Donation
        </Link>
      </li>
    </ul>
  ) : (
    <div className="recurring-donations">
      <Spinner />
    </div>
  )
}

const TaxDocumentList = () => {
  const [taxDocumentYears, setTaxDocumentYears] = useState()
  const [taxDocumentsCount, setTaxDocumentsCount] = useState(2)

  async function fetchTaxDocumentYears() {
    setTaxDocumentYears(undefined)
    const data = await effects.getFoundationDonationYears()
    setTaxDocumentYears(data.taxYears)
  }

  useEffect(() => {
    fetchTaxDocumentYears()
  }, [])

  const isShowingAllTaxDocuments =
    taxDocumentYears && taxDocumentsCount >= taxDocumentYears.length

  return !taxDocumentYears ? (
    <Spinner />
  ) : taxDocumentYears.length > 0 ? (
    <section className="flex flex-col space-y-4">
      <ol className="space-y-4">
        {taxDocumentYears
          .slice(0, taxDocumentsCount)
          .map(({ year, url }, index) => (
            <li
              key={index}
              className="flex justify-between py-1 mb-0 text-lg font-bold border-b-2 border-gray-900 border-solid"
            >
              {year}
              <DownloadLink href={url} title="Download receipt" />
            </li>
          ))}
      </ol>
      {!isShowingAllTaxDocuments && (
        <ViewAllButton
          onClick={() => setTaxDocumentsCount(taxDocumentsCount.length)}
        />
      )}
    </section>
  ) : (
    <div className="view-section">
      <p style={{ marginBottom: 0 }}>
        You do not have any tax documents available!
      </p>
    </div>
  )
}

const PastDonationList = () => {
  const [pastDonations, setPastDonations] = useState()
  const [pastDonationShowCount, setPastDonationShowCount] = useState(6)

  async function fetchPastDonations() {
    setPastDonations(undefined)
    const data = await effects.getFoundationOrders()

    setPastDonations(
      data.map(({ donationType, total, purchaseDate, pdfUrl }) => ({
        type:
          donationType.length === 1 ? donationType[0] : 'Multiple Donations',
        date: format(parse(purchaseDate, 'DD-MM-YY'), 'MM/DD/YYYY'),
        total,
        receiptUrl: pdfUrl,
      }))
    )
  }

  useEffect(() => {
    fetchPastDonations()
  }, [])

  const isShowingAllPastDonations =
    pastDonations && pastDonationShowCount >= pastDonations.length

  return !pastDonations ? (
    <Spinner />
  ) : pastDonations.length > 0 ? (
    <section className="flex flex-col space-y-4">
      <ol className="space-y-4">
        {pastDonations
          .slice(0, pastDonationShowCount)
          .map(({ type, date, total }, index) => (
            <li key={index}>
              <div className="text-lg">{date}</div>
              <div className="flex justify-between border-b-2 border-gray-900 border-solid">
                <div className="flex items-center gap-2 py-1 text-base">
                  {type}
                  <hr className="inline-block h-5 m-0 border-r-0 border-gray-700 border-solid border-l-1" />
                  <span>${total}</span>
                </div>
              </div>
            </li>
          ))}
      </ol>
      {!isShowingAllPastDonations && (
        <ViewAllButton
          onClick={() => setPastDonationShowCount(pastDonations.length)}
        />
      )}
    </section>
  ) : (
    <div className="view-section">
      <p style={{ marginBottom: 0 }}>
        You do not have any previous donations to show yet!
      </p>
    </div>
  )
}

function Giving({ flashErrorMessage }) {
  const handleError = (message) =>
    flashErrorMessage(message, { style: 'foundation' })

  return (
    <AccountPageLayout className="space-y-16">
      <h1 className="text-4xl">Giving</h1>
      <section>
        <h2 className="text-3xl">Recurring Donations</h2>
        <RecurringDonationList onError={handleError} />
      </section>

      <section>
        <h2 className="text-3xl">Tax documents</h2>
        <TaxDocumentList onError={handleError} />
      </section>

      <section>
        <h2 className="text-3xl">Giving History</h2>
        <PastDonationList />
      </section>
    </AccountPageLayout>
  )
}

Giving.propTypes = {
  flashErrorMessage: PropTypes.func.isRequired,
}

const mapDispatchToProps = { flashErrorMessage }

export default compose(connect(null, mapDispatchToProps))(Giving)
