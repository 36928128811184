import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { NavBar, MobileNavMenu } from './shared'
import { MobileListener } from '../../components'
import SharedHeader from 'layout/SharedHeader'
const propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired,
}

const defaultProps = {}

function Layout({ children, location }) {
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false)
  // only match subpaths of the cart that are not submitted to show payment version of NavBar
  const isPayment =
    location.pathname.includes('give/cart/') &&
    !location.pathname.includes('give/cart/submitted')

  const isAccount = location.pathname.includes('give/my-account')
  const cartTokenFromUrl = location.query.cartToken
  if (isAccount) {
    return (
      <div>
        <SharedHeader />
        <main className="theme-foundation foundation">{children}</main>
      </div>
    )
  } else {
    return (
      <MobileListener mobileWidthPx={900}>
        {({ isMobile }) => {
          useEffect(() => {
            if (!isMobile) {
              setIsMobileNavOpen(false)
            }
          }, [isMobile])

          return (
            <div
              className={classnames('theme-foundation foundation', {
                'foundation--mobile-nav-open': isMobileNavOpen,
              })}
            >
              <NavBar
                isPayment={isPayment}
                isMobileNavOpen={isMobileNavOpen}
                setIsMobileNavOpen={setIsMobileNavOpen}
                isMobile={isMobile}
                cartToken={cartTokenFromUrl}
              />
              {isMobileNavOpen && (
                <MobileNavMenu
                  isPayment={isPayment}
                  setIsMobileNavOpen={setIsMobileNavOpen}
                  cartToken={cartTokenFromUrl}
                />
              )}
              <main>{children}</main>
            </div>
          )
        }}
      </MobileListener>
    )
  }
}

Layout.propTypes = propTypes
Layout.defaultProps = defaultProps

export default Layout
