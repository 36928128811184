import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import * as routerActions from 'react-router-redux'
import { Link } from 'react-router'
import classnames from 'classnames'
import { Spinner } from '@launchpadlab/lp-components'
import { OrderReceipt, UntimedAddOn } from '../components'
import * as apiActions from 'main/apiActions'
import { selectors } from '../reducer'
import * as Types from 'main/types'
import { formatDate } from 'utils'
import SocietyCheckoutLayout from '../../../layout/SocietyCheckoutLayout'
import { TICKETING_CHECKOUT_STEPS } from '../../types'
import SocietyProductCard from '../../../layout/SocietyProductCard'

const propTypes = {
  fetchTicketAddOnCategories: PropTypes.func.isRequired,
  ticketAddOnCategories: PropTypes.arrayOf(Types.ticketAddOnCategory),
  ticketCart: Types.ticketCart,
  fetchOrCreateTicketCart: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  push: PropTypes.func.isRequired,
}

const defaultProps = {
  ticketAddOnCategories: null,
}

function AddOnCategoryDetails({
  ticketAddOnCategories,
  fetchTicketAddOnCategories,
  location: {
    query: {
      activityId,
      cartToken,
      lineItemId,
      addOnCategoryId,
      editLineItemId,
    },
  },
  ticketCart,
  fetchOrCreateTicketCart,
  push,
}) {
  const backUrl = `/ticketing/add-ons?activityId=${activityId}&cartToken=${cartToken}&lineItemId=${lineItemId}`
  const forwardUrl = `/ticketing/cart?cartToken=${cartToken}&activityId=${activityId}&lineItemId=${lineItemId}&addOnCategoryId=${addOnCategoryId}&backPath=add-ons-activity`

  useEffect(() => {
    // if correct query data not provided, push to earlier in the flow
    if (!(activityId && cartToken && lineItemId)) {
      push('/ticketing')
    } else if (!addOnCategoryId) {
      push(backUrl)
    }
    // always fetch a new set of AddOnCategories for the current activityId
    // to avoid the redux store keeping old data from a different activity
    fetchTicketAddOnCategories(parseInt(activityId)).then((resp) => {
      // if the response doesn't have add on categories, skip add ons section
      if (!(resp && resp.data && resp.data.attributes.length > 0)) {
        push(forwardUrl)
      }
    })
  }, [])

  useEffect(() => {
    cartToken && fetchOrCreateTicketCart(cartToken)
  }, [cartToken, fetchOrCreateTicketCart])
  let selectedCategoryId = addOnCategoryId && parseInt(addOnCategoryId)
  let selectedProductToEditId
  if (!!ticketCart && !!editLineItemId && !!ticketAddOnCategories) {
    const selectedParentLineItem = ticketCart.lineItems.find(
      (lin) => lin.id === parseInt(lineItemId)
    )
    const selectedEditLineItem = selectedParentLineItem.addOnLineItems.find(
      (lin) => lin.id === parseInt(editLineItemId)
    )
    // You may not have a selectedEditLineItem here if you removed
    // all the tickets for the line item you are editing
    if (selectedEditLineItem) {
      const selectedCategory = ticketAddOnCategories.find((cat) =>
        cat.products.find((pro) => pro.id === selectedEditLineItem.productId)
      )
      const selectedProductToEdit = selectedCategory.products.find(
        (pro) => pro.id === selectedEditLineItem.productId
      )
      selectedCategoryId = selectedCategory.id
      selectedProductToEditId = selectedProductToEdit.id
    }
  }
  const ticketAddOnCategory =
    ticketAddOnCategories &&
    ticketAddOnCategories.find((tadc) => tadc.id === selectedCategoryId)
  if (!ticketAddOnCategory || !ticketCart) return <Spinner />
  const parentLineItem = ticketCart.lineItems.find(
    (lineItem) => lineItem.id === parseInt(lineItemId)
  )
  const onlyProduct = ticketAddOnCategory.products.length < 2

  return (
    <SocietyCheckoutLayout
      progress={{ steps: TICKETING_CHECKOUT_STEPS, currentStep: 2 }}
      backUrl={backUrl}
      title="Select Add-Ons"
      summary={<OrderReceipt />}
      onContinue={forwardUrl}
    >
      <p>
        Below are the add-on options for{' '}
        <strong>{ticketAddOnCategory.name}</strong> on{' '}
        {formatDate(parentLineItem.startDate)} at{' '}
        {parentLineItem.displayStartTime}.
      </p>
      <ol>
        {ticketAddOnCategory.products.map((addOn) => {
          const preSelected =
            selectedProductToEditId && addOn.id === selectedProductToEditId
          const searchParams = new URLSearchParams([
            ['activityId', addOn.id],
            ['cartToken', ticketCart.token],
            ['backUrl', location.pathname + location.search],
            ['parentActivityId', activityId],
          ]).toString()
          return (
            <li key={addOn.id}>
              {addOn.type === 'TimedActivity' ? (
                <SocietyProductCard
                  id={addOn.id}
                  name={addOn.displayName}
                  description={addOn.caption}
                  imageUrl={addOn.activityImage}
                  productUrl={addOn.learnMoreUrl}
                  fullDescription={addOn.infoModalText}
                  isLoginRequired={addOn.requireLogin}
                  horizontal
                  selectUrl={`/ticketing/details?${searchParams}`}
                />
              ) : (
                <UntimedAddOn
                  startOpen={onlyProduct || preSelected}
                  addOn={addOn}
                  lineItemId={lineItemId}
                />
              )}
            </li>
          )
        })}
      </ol>

      <Link
        className={classnames(
          'button-primary',
          'button-next-step',
          'button-green-outline'
        )}
        to={backUrl}
      >
        {'Save & return to add-ons'}
      </Link>
    </SocietyCheckoutLayout>
  )
}

AddOnCategoryDetails.propTypes = propTypes
AddOnCategoryDetails.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    ticketAddOnCategories: selectors.ticketAddOnCategories(state),
    ticketCart: selectors.ticketCart(state),
  }
}

const mapDispatchToProps = {
  fetchTicketAddOnCategories: apiActions.fetchTicketAddOnCategories,
  fetchOrCreateTicketCart: apiActions.fetchOrCreateTicketCart,
  push: routerActions.push,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  AddOnCategoryDetails
)
