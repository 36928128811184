import React from 'react'
import { Link } from 'react-router'
import { compose } from 'redux'
import { propTypes as formPropTypes } from 'redux-form'
import { lpForm } from '@launchpadlab/lp-form'
import { SubmitButton } from '@launchpadlab/lp-components'
import { NormalizedField as Field, FoundationInput } from 'components'
import { logInFoundationUser } from 'main/foundation/actions'
import {
  displaySubmitFailure,
  findFirstErrorField,
  isEmpty,
  flashErrorMessage,
} from 'utils'
import { scroller } from 'react-scroll'
import * as effects from 'main/effects'
import PropTypes from 'prop-types'

const TProps = { ...formPropTypes, redirectUrl: PropTypes.string }

function SignInForm({
  handleSubmit: onSubmit,
  submitting,
  invalid,
  redirectUrl,
}) {
  const handleSubmit = onSubmit((data) => {
    return effects
      .accountSignIn(data)
      .then((resp) => ({ ...resp, redirectUrl }))
  })

  return (
    <form onSubmit={handleSubmit}>
      <Field
        name="primary_email"
        component={FoundationInput}
        label="Email"
        placeholder="Email *"
        inputType="email"
      />
      <Field
        name="password"
        component={FoundationInput}
        label="Password"
        placeholder="Password *"
        inputType="password"
      />
      <p style={{ textAlign: 'right' }}>
        <Link to="/give/forgot-password">Forgot Password?</Link>
      </p>
      <SubmitButton {...{ submitting, invalid }}>Sign In</SubmitButton>
      <p style={{ paddingTop: 20 }}>
        <Link to="/give/create-account">Don't have an account? Sign Up</Link>
      </p>
    </form>
  )
}

SignInForm.propTypes = TProps

export default compose(
  lpForm({
    name: 'sign-in',
    constraints: {
      primary_email: { presence: true },
      password: { presence: true },
    },

    onSubmitSuccess({ redirectUrl, ...res }, dispatch) {
      dispatch(
        logInFoundationUser({
          token: res.data.attributes.token,
          redirectUrl,
        })
      )
    },

    onSubmitFail(errors, dispatch, submitError, { syncErrors }) {
      if (submitError && submitError.errors) {
        displaySubmitFailure(submitError.errors, dispatch, submitError)
      } else if (isEmpty(errors)) {
        dispatch(
          flashErrorMessage(
            'Could not access your account. Please try again later.',
            { style: 'foundation' }
          )
        )
      } else {
        displaySubmitFailure(errors, dispatch, submitError)
        // Check for sync errors here as onSubmitFail is overridden in props
        if (!isEmpty(syncErrors)) {
          const firstErrorField = findFirstErrorField(syncErrors)
          if (!firstErrorField) return
          return scroller.scrollTo(firstErrorField, { smooth: true })
        }
      }
    },
  })
)(SignInForm)
