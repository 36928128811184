import React from 'react'
import useModal from '../../../hooks/useModal'
import c from 'classnames'
import TicketAttendeeModal from '../components/TicketAttendeeModal'
import TicketingCart from '../utils/ticketingCart'
import * as actions from '../actions'
import { selectors } from '../reducer'
import { connect } from 'react-redux'
import ModalDialog from '../../../components/ui/ModalDialog'

const AddPersonIcon = (props) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.5 12C15.9745 12 18.1608 9.66984 18.375 6.80531C18.4814 5.36625 18.03 4.02422 17.1038 3.02719C16.1873 2.04234 14.9062 1.5 13.5 1.5C12.0825 1.5 10.8005 2.03906 9.89062 3.01781C8.97047 4.00734 8.52187 5.35219 8.625 6.80438C8.83547 9.66937 11.0212 12 13.5 12Z"
      fill="currentColor"
    />
    <path
      d="M23.2209 20.6138C22.8253 18.4191 21.5902 16.5755 19.6495 15.2817C17.9259 14.1328 15.742 13.5 13.5 13.5C11.258 13.5 9.07406 14.1328 7.35047 15.2812C5.40984 16.575 4.17469 18.4186 3.77906 20.6133C3.68859 21.1162 3.81141 21.6136 4.11609 21.9778C4.25429 22.1438 4.42781 22.2768 4.62399 22.3672C4.82016 22.4575 5.03404 22.5029 5.25 22.5H21.75C21.9661 22.5031 22.1801 22.4578 22.3765 22.3676C22.5728 22.2773 22.7465 22.1443 22.8848 21.9783C23.1886 21.6141 23.3114 21.1167 23.2209 20.6138Z"
      fill="currentColor"
    />
    <path
      d="M4.875 13.5V11.625H6.75C6.94891 11.625 7.13968 11.546 7.28033 11.4053C7.42098 11.2647 7.5 11.0739 7.5 10.875C7.5 10.6761 7.42098 10.4853 7.28033 10.3447C7.13968 10.204 6.94891 10.125 6.75 10.125H4.875V8.25C4.875 8.05109 4.79598 7.86032 4.65533 7.71967C4.51468 7.57902 4.32391 7.5 4.125 7.5C3.92609 7.5 3.73532 7.57902 3.59467 7.71967C3.45402 7.86032 3.375 8.05109 3.375 8.25V10.125H1.5C1.30109 10.125 1.11032 10.204 0.96967 10.3447C0.829018 10.4853 0.75 10.6761 0.75 10.875C0.75 11.0739 0.829018 11.2647 0.96967 11.4053C1.11032 11.546 1.30109 11.625 1.5 11.625H3.375V13.5C3.375 13.6989 3.45402 13.8897 3.59467 14.0303C3.73532 14.171 3.92609 14.25 4.125 14.25C4.32391 14.25 4.51468 14.171 4.65533 14.0303C4.79598 13.8897 4.875 13.6989 4.875 13.5Z"
      fill="currentColor"
    />
  </svg>
)

const PersonIcon = (props) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.5 12C15.9745 12 18.1608 9.66984 18.375 6.80531C18.4814 5.36625 18.03 4.02422 17.1038 3.02719C16.1873 2.04234 14.9062 1.5 13.5 1.5C12.0825 1.5 10.8005 2.03906 9.89062 3.01781C8.97047 4.00734 8.52187 5.35219 8.625 6.80438C8.83547 9.66937 11.0212 12 13.5 12Z"
      fill="currentColor"
    />
    <path
      d="M23.2209 20.6138C22.8253 18.4191 21.5902 16.5755 19.6495 15.2817C17.9259 14.1328 15.742 13.5 13.5 13.5C11.258 13.5 9.07406 14.1328 7.35047 15.2812C5.40984 16.575 4.17469 18.4186 3.77906 20.6133C3.68859 21.1162 3.81141 21.6136 4.11609 21.9778C4.25429 22.1438 4.42781 22.2768 4.62399 22.3672C4.82016 22.4575 5.03404 22.5029 5.25 22.5H21.75C21.9661 22.5031 22.1801 22.4578 22.3765 22.3676C22.5728 22.2773 22.7465 22.1443 22.8848 21.9783C23.1886 21.6141 23.3114 21.1167 23.2209 20.6138Z"
      fill="currentColor"
    />
  </svg>
)

const EditIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" {...props}>
    <path
      fill="currentColor"
      d="M180-180h44l443-443-44-44-443 443v44Zm614-486L666-794l42-42q17-17 42-17t42 17l44 44q17 17 17 42t-17 42l-42 42Zm-42 42L248-120H120v-128l504-504 128 128Zm-107-21-22-22 44 44-22-22Z"
    />
  </svg>
)

const DeleteIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" {...props}>
    <path
      fill="currentColor"
      d="m249-207-42-42 231-231-231-231 42-42 231 231 231-231 42 42-231 231 231 231-42 42-231-231-231 231Z"
    />
  </svg>
)

const TicketAttendee = ({
  ticketCart,
  ticketActivity,
  lineItemPrice,
  lineItem,
  setTicketCart,
}) => {
  const editModal = useModal()
  const removalModal = useModal()

  async function handleConfirmEdit(attendee, attendeeUdfs) {
    setTicketCart({
      success: await TicketingCart.REMOTE.updateLineItemPrice(
        ticketCart.token,
        lineItemPrice,
        { attendee, attendeeUdfs }
      ),
    })
    editModal.close()
  }

  async function handleConfirmRemove() {
    setTicketCart({
      success: await TicketingCart.REMOTE.removeLineItemPrice(
        ticketCart.token,
        lineItemPrice,
        lineItem
      ),
    })
    removalModal.close()
  }

  const attendee = lineItemPrice.attendee

  return (
    <div>
      {attendee ? (
        <div
          className={c(
            'flex items-center w-full gap-3 justify-between h-14',
            'text-lg font-normal bg-white text-primary'
          )}
        >
          <span className="inline-flex h-full gap-3 p-3">
            <PersonIcon className="w-6 h-6 mt-1" />
            <span className="mt-0.5">
              {attendee.firstName} {attendee.lastName}
            </span>
          </span>
          <div>
            <button
              className={c(
                'h-14 p-3 aspect-square ',
                'bg-transparent border-none cursor-pointer',
                'hover:bg-gray-100 transition-colors'
              )}
              aria-label={`Edit attendee details of ${attendee.firstName} ${attendee.lastName}`}
              onClick={editModal.open}
            >
              <EditIcon className="w-6 h-6" />
            </button>
            <button
              className={c(
                'h-14 p-3 aspect-square ',
                'bg-transparent border-none cursor-pointer',
                'hover:bg-gray-100 transition-colors'
              )}
              aria-label={`Remove attendee ${attendee.firstName} ${attendee.lastName}`}
              onClick={removalModal.open}
            >
              <DeleteIcon className="w-6 h-6 text-danger" />
            </button>
          </div>
          <ModalDialog
            type="danger"
            isOpen={removalModal.isOpen}
            onClose={removalModal.close}
            onConfirm={handleConfirmRemove}
            title="Remove Attendee"
            content={`Are you sure you want to remove ${attendee.firstName} ${attendee.lastName} as ticket holder?`}
            confirmLabel="Remove Attendee"
            closeLabel="Cancel"
            compact
          />
        </div>
      ) : (
        <button
          className={c(
            'flex items-center w-full gap-3 p-3',
            'text-lg font-semibold border-primary-600 border border-solid border-opacity-100 border-2 text-primary-400 cursor-pointer',
            'hover:bg-gray-100 transition-colors'
          )}
          onClick={editModal.open}
        >
          <AddPersonIcon className="w-6 h-6" />
          <span className="mt-0.5">Add attendee</span>
        </button>
      )}
      {ticketActivity && lineItemPrice && ticketActivity.attendeeUdfs && (
        <TicketAttendeeModal
          isOpen={editModal.isOpen}
          onConfirm={handleConfirmEdit}
          onClose={editModal.close}
          udfs={ticketActivity.attendeeUdfs} // these are the actual form feilds themselves
          udfValues={lineItemPrice.attendeeUdfs} // these are if the form has been opened/saved before
          initialValues={lineItemPrice.attendee} // this is the previously selected attendee name/id
          eventName={lineItemPrice.displayName}
        />
      )}
    </div>
  )
}

function mapStateToProps(state) {
  return { ticketCart: selectors.ticketCart(state) }
}

const mapDispatchToProps = {
  setTicketCart: actions.setTicketCart,
}

export default connect(mapStateToProps, mapDispatchToProps)(TicketAttendee)
