import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { selectors as foundationSelectors } from 'main/foundation/reducer'
import { selectors as membershipSelectors } from 'main/membership/reducer'
import {
  getFoundationAccountInfo,
  fetchMemberDetails,
} from 'main/apiActions'
import { flashErrorMessage } from 'utils'
import { Spinner } from '@launchpadlab/lp-components'
import { Link } from 'react-router'
import c from 'classnames'
import homeIcon from 'images/ozf-nav-home.svg'
import givingIcon from 'images/ozf-nav-giving.svg'
import OrderHistoryIcon from 'images/ozf-nav-order-history.svg'
import MembershipIcon from 'images/people-group-solid.svg'
import SocietyHeader from 'layout/SocietyHeader'

const AccountNavItem = ({
  as: Component,
  className,
  icon,
  label,
  ...props
}) => (
  <li className="mb-0">
    <Component
      className={c(
        'text-lg cursor-pointer hover:text-secondary transition-colors',
        'flex items-center gap-2',
        className
      )}
      {...props}
    >
      <img src={icon} className="w-6 h-6" />
      {label}
    </Component>
  </li>
)

const AccountPageLayout = ({
  getFoundationAccountInfo,
  accountInfo,
  fetchMemberDetails,
  memberDetails,
  children,
  className,
  flashErrorMessage,
}) => {
  useEffect(() => {
    if (!accountInfo) {
      getFoundationAccountInfo().catch(() =>
        flashErrorMessage(
          'There was an error fetching your Foundation account data.',
          {
            style: 'foundation',
          }
        )
      )
    }
    if (!memberDetails) {
      fetchMemberDetails().catch(() =>
        flashErrorMessage('There was an error fetching your membership data.', {
          style: 'foundation',
        })
      )
    }
  }, [])

  // Beware of the early return
  if (!accountInfo) return <Spinner />

  const { firstName } = accountInfo

  return (
    <div className="theme-society">
      <SocietyHeader />
      <div className="flex flex-col md:flex-row">
        <aside className="p-5 space-y-5 bg-white shadow-sm md:w-1/4 md:space-y-10 md:p-10">
          <header className="text-2xl font-bold md:w-3/4">
            Welcome, {firstName}
          </header>
          <nav>
            <ul className="space-y-4">
              <AccountNavItem
                as={Link}
                to="/my-account"
                icon={homeIcon}
                label="Account Information"
              />
              <AccountNavItem
                as={Link}
                to="/my-account/membership"
                icon={MembershipIcon}
                label="Membership"
              />
              <AccountNavItem
                as={Link}
                to="/my-account/order-history"
                icon={OrderHistoryIcon}
                label="Order History"
              />
              <AccountNavItem
                as={Link}
                to="/my-account/giving"
                icon={givingIcon}
                label="Giving"
              />
            </ul>
          </nav>
        </aside>
        <section className={c('flex-grow p-5 md:p-10', className)}>
          {children}
        </section>
      </div>
    </div>
  )
}

function mapStateToProps(state) {
  const accountInfo = foundationSelectors.foundationAccountInfo(state)
  const membershipDetails = membershipSelectors.membershipDetails(state)
  return { accountInfo, membershipDetails }
}

const mapDispatchToProps = {
  getFoundationAccountInfo,
  fetchMemberDetails,
  flashErrorMessage,
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountPageLayout)
