import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'main/types'
import { compose } from 'recompose'
import { propTypes as formPropTypes } from 'redux-form'
import { lpForm } from '@launchpadlab/lp-form'
import {
  PrimaryMemberFormSection,
  SecondaryMemberFormSection,
} from '.'
import { validateNested } from 'utils'
import {
  DEFAULT_PRIMARY_MEMBER_VALIDATION_CONSTRAINTS,
  DEFAULT_SECONDARY_MEMBER_VALIDATION_CONSTRAINTS,
} from 'config'
import SocietyHeading from '../../../layout/SocietyHeading'

const propTypes = {
  ...formPropTypes,
  numChildren: PropTypes.number.isRequired,
  nonPrimaryMembers: PropTypes.arrayOf(Types.member),
}

const defaultProps = {}

function PatronDeclindBenefitsMemberForm({
  handleSubmit,
  nonPrimaryMembers,
  secondaryMemberOptions,
  primaryEmailEditable,
}) {
  return (
    <form onSubmit={handleSubmit} className="member-form">
      <SocietyHeading>Listed Members</SocietyHeading>
      <PrimaryMemberFormSection primaryEmailEditable={primaryEmailEditable} />
      <SecondaryMemberFormSection
        secondaryMemberOptions={secondaryMemberOptions}
        nonPrimaryMembers={nonPrimaryMembers}
      />
    </form>
  )
}

PatronDeclindBenefitsMemberForm.propTypes = propTypes
PatronDeclindBenefitsMemberForm.defaultProps = defaultProps

export default compose(
  lpForm({
    name: 'membership-patron-declined-benefits',
    validate: validateNested({
      ...DEFAULT_PRIMARY_MEMBER_VALIDATION_CONSTRAINTS,
      ...DEFAULT_SECONDARY_MEMBER_VALIDATION_CONSTRAINTS,
      'children[].firstName': { length: { maximum: 30 } },
      'children[].lastName': { length: { maximum: 30 } },
    }),
  })
)(PatronDeclindBenefitsMemberForm)
