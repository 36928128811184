import React from 'react'
import PropTypes from 'prop-types'

const propTypes = {
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
}

const defaultProps = {}

function ToggleInput({ name, onClick, checked }) {
  return (
    <div>
      <input name={name} type="checkbox" onClick={onClick} checked={checked} />
      <label className="label sellers-code" htmlFor="sellerCodeActive">
        I have a Seller's Code
      </label>
    </div>
  )
}

ToggleInput.propTypes = propTypes

ToggleInput.defaultProps = defaultProps

export default ToggleInput
