import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { member } from 'main/types'

const propTypes = {
  onChange: PropTypes.func.isRequired,
  members: PropTypes.arrayOf(member),
  memberSelected: PropTypes.object,
}

const defaultProps = {}

function MemberList({ onChange, members, memberSelected }) {
  const DEFAULT_DROPDOWN_VALUE = 'Select member'
  const [memberDropdownValue, setMemberDropdownValue] = useState(
    DEFAULT_DROPDOWN_VALUE
  )
  // preselecting a member from the dropdown if member has been selected.
  useEffect(() => {
    if (memberSelected) {
      setMemberDropdownValue(memberSelected.customerId)
    }
  }, [memberSelected])

  // Adding the full name as value. When editing an attendee the only attributes that we can use to preselect an attendee are the first and last name,
  // and we can derive the full name from these
  const memberOptions =
    members &&
    members.map((member) => {
      const { customerId, fullName } = member
      return { key: fullName, value: customerId }
    })

  const handleChange = ({ currentTarget: { value: customerId } }) => {
    const intCustomerId = parseInt(customerId)

    const member = members.find((member) => member.customerId === intCustomerId)

    setMemberDropdownValue(intCustomerId)
    onChange(member)
  }

  return (
    <React.Fragment>
      <label>Select a member from the list (Optional)</label>
      <select onChange={handleChange} value={memberDropdownValue}>
        <option disabled value={DEFAULT_DROPDOWN_VALUE}>
          Select member
        </option>
        {memberOptions.map((option) => (
          <option key={option.key} value={option.value}>
            {option.key}
          </option>
        ))}
      </select>
    </React.Fragment>
  )
}

MemberList.propTypes = propTypes

MemberList.defaultProps = defaultProps

export default MemberList
