// eslint-disable-no-useless-escape
const foreignZipConstraints = {
  presence: true,
  format: {
    pattern: /^[0-9A-Za-z s -]+$/,
    message: 'can only contain alphanumeric characters, spaces, or dashes',
  },
  length: { maximum: 20 },
}

const usZipConstraints = {
  presence: true,
  numericality: true,
  length: { maximum: 20 },
}

function setZipConstraints(countryCode = 'US') {
  return countryCode === 'US' ? usZipConstraints : foreignZipConstraints
}

export default setZipConstraints
