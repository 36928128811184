import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import * as reduxForm from 'redux-form'
import { flashSuccessMessage } from 'redux-flash'
import { PersonalInformationForm, ResetPasswordForm } from '../forms'
import * as apiActions from 'main/apiActions'
import { updateFoundationUserPassword } from 'main/effects'
import { selectors } from 'main/foundation/reducer'
import { mapCurrentUser, flashErrorMessage } from 'utils'
import AccountPageLayout from '../components/AccountPageLayout'
import MembershipCard from 'main/user/components/MembershipCard'
import { selectors as membershipSelectors } from 'main/membership/reducer'
import { selectors as userSelectors } from 'main/user/reducer'

const propTypes = {
  flashSuccessMessage: PropTypes.func.isRequired,
  flashErrorMessage: PropTypes.func.isRequired,
  customerCountry: PropTypes.string,
  accountInfo: PropTypes.object,
  updateFoundationAccountInfo: PropTypes.func.isRequired,
}

const defaultProps = {
  accountInfo: null,
}

function AccountInfo({
  flashSuccessMessage,
  flashErrorMessage,
  customerCountry,
  accountInfo,
  updateFoundationAccountInfo,
  primaryMember
}) {
  const initialValues = {
    ...accountInfo,
    country: accountInfo?.country || "US",
  }

  return (
    <AccountPageLayout className="space-y-10">
      <h1>Account Information</h1>
      <MembershipCard primaryMember={primaryMember}/>
      <section>
        <h2>Your Information</h2>
        <PersonalInformationForm
          initialValues={initialValues}
          isUsCustomer={customerCountry === 'US'}
          onSubmit={(formData) =>
            updateFoundationAccountInfo(formData)
              .then(() => {
                flashSuccessMessage('Profile updated successfully')
              })
              .catch(() => {
                flashErrorMessage(
                  'There was some error updating your profile',
                  { style: 'foundation' }
                )
              })
          }
        />
      </section>
      <section>
        <h2>Change Password</h2>
        <ResetPasswordForm
          insetInPage={true}
          onSubmit={(formData) =>
            updateFoundationUserPassword(formData)
              .then(() => {
                flashSuccessMessage('Password updated successfully')
              })
              .catch(() => {
                flashErrorMessage('We could not update your password', {
                  style: 'foundation',
                })
              })
          }
        />
      </section>
    </AccountPageLayout>
  )
}

AccountInfo.propTypes = propTypes
AccountInfo.defaultProps = defaultProps

function mapStateToProps(state) {
  const getAccountInfo = (state) => {
    const accountInfo = selectors.foundationAccountInfo(state)

    if (accountInfo) {
      return mapCurrentUser(accountInfo)
    }
  }
  
  return {
    customerCountry: reduxForm.formValueSelector(
      'account-personal-information'
    )(state, 'country'),
    accountInfo: getAccountInfo(state),
    primaryMember: userSelectors.primaryMember(state) || membershipSelectors.primaryMember(state),
  }
}

const mapDispatchToProps = {
  flashSuccessMessage,
  flashErrorMessage,
  updateFoundationAccountInfo: apiActions.updateFoundationAccountInfo,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  AccountInfo
)
