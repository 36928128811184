import React from 'react'
import BackButton from '../components/BackButton'
import IdMeWidget from '../components/IdMeWidget'
import SocietyPageLayout from './SocietyPageLayout'
import Button from '../components/ui/Button'
import { isString } from 'lodash'
import ButtonLink from '../components/ui/ButtonLink'
import SocietyProgressBar from './SocietyProgressBar'

const SocietyCheckoutLayout = ({
  children,
  progress,
  title,
  introduction,
  backUrl: backUrlOverride,
  className,
  reversed,
  summary,
  idme = true,
  onContinue,
  continueDisabled,
  ContinueButton,
  ...props
}) => {
  const isFirstStep = progress && progress.currentStep === 0
  const backUrl = backUrlOverride
    ? backUrlOverride
    : !isFirstStep && progress
      ? progress.steps[progress.currentStep - 1].url
      : undefined

  return (
    <SocietyPageLayout
      title={title}
      introduction={introduction}
      className={className}
      reversed={reversed}
      header={progress && <SocietyProgressBar {...progress} />}
      rightAside={
        summary && (
          <aside className="flex flex-col justify-center flex-shrink-0 p-5 space-y-5 filter drop-shadow sawtooth-bottom lg:w-1/4 lg:sticky lg:top-0">
            <header className="text-4xl text-center font-amatic">
              Order Summary
            </header>
            <hr />
            {summary}
            <hr />
            {idme && (
              <IdMeWidget
                text="Military service members may be eligible for discounted rates!"
                verifiedMessage="Military discount applied!"
              />
            )}
            {ContinueButton
              ? ContinueButton
              : onContinue &&
                (isString(onContinue) ? (
                  <ButtonLink
                    label="Continue"
                    primary
                    xl
                    disabled={continueDisabled}
                    to={onContinue}
                  />
                ) : (
                  <Button
                    label="Continue"
                    primary
                    xl
                    disabled={continueDisabled}
                    onClick={onContinue}
                  />
                ))}
          </aside>
        )
      }
      {...props}
    >
      {backUrl && <BackButton to={backUrl} />}
      {children}
    </SocietyPageLayout>
  )
}

export default SocietyCheckoutLayout
