import React from 'react'
import classnames from 'classnames'
const NavItem = ({
  as: Component,
  className,
  label,
  icon,
  to,
  isMobile = false,
  ...props
}) => (
  <Component
    to={to}
    {...props}
    className={classnames(
      'flex flex-row ',
      className,
      isMobile
        ? 'nav-bar-mobile__link justify-center'
        : ' text-lg tracking-tight cursor-pointer transition-colors no-underline uppercase '
    )}
  >
    {icon && (
      <div className={classnames(isMobile ? 'px-2 h-5 w-5' : 'px-2 h-3 w-3')}>
        {icon}
      </div>
    )}
    {label}
  </Component>
)

export default NavItem
