import React from 'react'
import { Route, IndexRoute } from 'react-router'
import * as Views from './views'

const Routes = (
  <Route path="gift-cards">
    <IndexRoute component={Views.SelectGiftCard} />
    <Route path="details" component={Views.GiftCardDetails} />
    <Route path="confirmation" component={Views.GiftCardConfirmation} />
  </Route>
)

export default Routes
