import React from 'react'

const FieldTripPaymentInfoCard = () => (
  <div className="p-4 space-y-6 rounded-md bg-blue-light:">
    <p>
      School group payment is due at time of check-in at the Zoo. Cash, check,
      and all major credit cards are accepted. Invoicing is available.
    </p>
    <p className="italic font-bold">
      Your total below has been updated to reflect the total amount due
    </p>
  </div>
)

export default FieldTripPaymentInfoCard
