import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { selectors } from '../reducer'
import * as apiActions from 'main/apiActions'
import { Spinner } from '@launchpadlab/lp-components'
import * as actions from '../actions'
import SocietyCheckoutLayout from '../../../layout/SocietyCheckoutLayout'
import SocietyProductGrid from '../../../layout/SocietyProductGrid'
import { DONATION_CHECKOUT_STEPS } from '../../types'

function SelectFund({ fetchDonationFunds, products, clearSelectedFund }) {
  useEffect(() => {
    if (!products) fetchDonationFunds()
    clearSelectedFund()
  }, [])

  return !products ? (
    <Spinner />
  ) : (
    <SocietyCheckoutLayout
      title="Select Donation Fund"
      progress={{ steps: DONATION_CHECKOUT_STEPS, currentStep: 0 }}
    >
      <p className="text-xl text-center">
        Begin by <strong>selecting the fund</strong> you would like to
        contribute to. You will be asked for additional details in the next
        step.
      </p>
      <SocietyProductGrid products={products} singleColumn />
    </SocietyCheckoutLayout>
  )
}

function mapStateToProps(state) {
  const funds = selectors.funds(state)

  if (!funds) {
    return { products: undefined }
  }

  const products = funds.map((fund) => {
    return {
      priceNote: fund.priceNote,
      featured: fund.featured,
      featuredLabel: fund.featuredDisplayLabel,
      imageUrl: fund.image,
      description: fund.caption,
      productUrl: fund.learnMoreUrl,
      selectUrl: `/donation/donate?id=${fund.id}`,
      name: fund.displayName,
      fullDescription: fund.infoModalText,
    }
  })

  return {
    products,
  }
}

const mapDispatchToProps = {
  fetchDonationFunds: apiActions.fetchDonationFunds,
  clearSelectedFund: actions.clearSelectedFund,
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectFund)
