import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { lpForm } from '@launchpadlab/lp-form'
import { Input, Select } from '@launchpadlab/lp-components'
import { Field, propTypes as formPropTypes } from 'redux-form'
import { RecaptchaInput, GiftCardSection, HideableArea } from 'components'
import * as options from 'main/options'
import { modifyProps } from '@launchpadlab/lp-hoc'
import { getCountryOptions, setZipConstraints, validateRecaptcha } from 'utils'
import { GpiForm } from 'components/Gpi'
import SocietyHeading from '../../../layout/SocietyHeading'
import DonationTermsCheckbox from '../../../components/DonationTermsCheckbox'

const propTypes = {
  ...formPropTypes,
  isUsCustomer: PropTypes.bool,
  appliedGiftCardAmount: PropTypes.number,
  remainingGiftCardBalance: PropTypes.number,
  onGiftCardApply: PropTypes.func.isRequired,
  onGiftCardApplySuccess: PropTypes.func.isRequired,
  onGiftCardApplyFail: PropTypes.func.isRequired,
  onGiftCardRemove: PropTypes.func.isRequired,
  onGiftCardRemoveSuccess: PropTypes.func.isRequired,
  onGiftCardRemoveFail: PropTypes.func.isRequired,
}

const defaultProps = {
  appliedGiftCardAmount: PropTypes.number,
  remainingGiftCardBalance: PropTypes.number,
}

function DonationPaymentForm({
  handleSubmit,
  isUsCustomer,
  skipBillingInfo,
  appliedGiftCardAmount,
  remainingGiftCardBalance,
  onGiftCardApply,
  onGiftCardApplySuccess,
  onGiftCardApplyFail,
  onGiftCardRemove,
  onGiftCardRemoveSuccess,
  onGiftCardRemoveFail,
}) {
  const formTitle = skipBillingInfo ? 'Customer Details' : 'Payment Details'
  return (
    <form id="donation-payment" onSubmit={handleSubmit} className="member-form">
      <SocietyHeading>{formTitle}</SocietyHeading>
      <GiftCardSection
        acceptGiftCard={!skipBillingInfo}
        appliedAmount={appliedGiftCardAmount}
        remainingBalance={remainingGiftCardBalance}
        onApply={onGiftCardApply}
        onApplySuccess={onGiftCardApplySuccess}
        onApplyFail={onGiftCardApplyFail}
        onRemove={onGiftCardRemove}
        onRemoveSuccess={onGiftCardRemoveSuccess}
        onRemoveFail={onGiftCardRemoveFail}
      />

      <Field
        name="nameOnCard"
        component={Input}
        label={skipBillingInfo ? 'Full Name' : 'Name on Card'}
      />

      {
        // The normal way to hide or not-hide components would be to
        // render or not render based on skipBillingInfo
        // However the GpiForm uses a third party script
        // which gets thrown off when we were rendering and not-rendering.
        // So the solution was to use this HideableArea component which
        // just adds a display: none when skipBillingInfo: true
      }

      <HideableArea hidden={skipBillingInfo}>
        <div>
          <GpiForm />

          <Field
            name="address.country"
            component={Select}
            options={getCountryOptions()}
            label="Country"
            placeholder="Select"
          />
          <Field
            name="address.streetAddress"
            component={Input}
            label="Street Address"
          />
          <Field
            name="address.streetAddress2"
            component={Input}
            label="Street Address 2"
          />
          <Field name="address.suburb" component={Input} label="City" />
          <div className="flex items-center gap-5">
            {isUsCustomer && (
              <Field
                name="address.state"
                component={Select}
                options={options.states}
                label="State"
                placeholder="Select"
              />
            )}
            <Field
              name="address.zip"
              component={Input}
              label="Zip / Post Code"
            />
          </div>
        </div>
      </HideableArea>

      <Field name="email" component={Input} />
      <Field name="phone" component={Input} type="tel" />
      <Field
        className="m-0"
        aria-label="terms and conditions"
        name="termsAndConditions"
        component={({ input: { value, onChange }, meta }) => (
          <DonationTermsCheckbox
            checked={value}
            onChange={onChange}
            error={
              (!meta.pristine || meta.submitFailed) &&
              meta.error &&
              meta.error[0]
            }
          />
        )}
        label={false}
        required
      />

      <div className="mt-5">
        <Field
          name="recaptcha"
          label={false}
          component={RecaptchaInput}
          validate={validateRecaptcha}
        />
      </div>
    </form>
  )
}

DonationPaymentForm.propTypes = propTypes
DonationPaymentForm.defaultProps = defaultProps

const modify = ({ customerCountry, onSubmit, skipBillingInfo }) => {
  return {
    constraints: {
      nameOnCard: { presence: true },
      email: {
        presence: true,
        email: true,
        length: { maximum: 60 },
      },
      phone: { presence: true, length: { maximum: 20 } },
      'address.streetAddress': () => {
        if (skipBillingInfo) return null
        return {
          presence: true,
          length: { maximum: 50 },
        }
      },
      'address.suburb': () => {
        if (skipBillingInfo) return null
        return {
          presence: true,
          length: { maximum: 30 },
        }
      },
      'address.country': () => {
        if (skipBillingInfo) return null
        return { presence: true }
      },
      'address.state': () => {
        if (skipBillingInfo) return null
        return { presence: true }
      },
      'address.zip': () => {
        if (skipBillingInfo) return null
        return setZipConstraints(customerCountry)
      },
      termsAndConditions: {
        presence: {
          message: '^You must accept the terms and conditions',
        },
        inclusion: {
          within: [true],
          message: '^You must accept the terms and conditions',
        },
      },
    },
    isUsCustomer: customerCountry === 'US',
    onSubmit: (values, dispatch, props) => {
      return onSubmit(values, dispatch, props)
    },
  }
}

export default compose(
  modifyProps(modify),
  lpForm({ name: 'donation-payment' })
)(DonationPaymentForm)
