import React from 'react'
import SocietyPageLayout from '../../../layout/SocietyPageLayout'
import Users from '../../ticketing/utils/users'
import LinkButton from '../../../components/ui/LinkButton'

const VerifyEmail = ({ email, redirectUrl }) => {
  return (
    <SocietyPageLayout title="Verify Email">
      <section className="mx-auto space-y-10 text-center lg:w-3/5">
        <p className="mb-0 text-xl">
          An email was sent to <span className="text-primary">{email}</span>.
          <br />
          Please click the link in your email to verify your account. If you
          have not received the email after one minute, please check your spam
          folder or{' '}
          <LinkButton
            label="resend the email verification"
            onClick={() =>
              Users.sendVerificationEmail(
                email,
                window.location.origin,
                redirectUrl
              )
            }
          />
          .
        </p>
      </section>
    </SocietyPageLayout>
  )
}

export default VerifyEmail
