import React from 'react'
import ButtonLink from '../components/ui/ButtonLink'

function NoMembership() {
  return (
    <div className="flex flex-col items-center justify-center p-10 m-10 bg-white">
      <h1>Membership not found</h1>
      <p>
        The membership you were looking for couldn't be found or is currently
        unavailable.
      </p>
      <ButtonLink secondary to="/membership" label=" Go Back" />
    </div>
  )
}

export default NoMembership
