import React from 'react'
import TermsCheckbox from './TermsCheckbox'

function CovidTermsCheckbox({ productType, ...props }) {
  return (
    <TermsCheckbox
      label="COVID-19 Terms and Conditions"
      id="covidTerms"
      {...props}
    >
      The holder of this {productType} assumes all risks and danger arising out
      of or relating to the risk of contracting COVID-19 and agrees that the
      Omaha Zoological Society, its directors, officers, employees, and agents
      are not liable for any losses, damages, or injuries (including death) from
      such causes, and agrees that he or she understands that the foregoing
      waiver means the holder of this {productType} gives up the right to bring
      any claims for losses, damages, or injuries (including death), including
      but not limited to claims of negligence against the Omaha Zoological
      Society, its directors, officers, employees, or its agents.
    </TermsCheckbox>
  )
}

export default CovidTermsCheckbox
