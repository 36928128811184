import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Spinner } from '@launchpadlab/lp-components'
import * as apiActions from 'main/apiActions'
import { selectors } from '../reducer'
import * as Types from 'main/types'
import { formatDate } from 'utils'
import * as routerActions from 'react-router-redux'
import SocietyCheckoutLayout from '../../../layout/SocietyCheckoutLayout'
import { TICKETING_CHECKOUT_STEPS } from '../../types'
import SocietyProductCard from '../../../layout/SocietyProductCard'
import { OrderReceipt } from '../components'

const propTypes = {
  fetchTicketAddOnCategories: PropTypes.func.isRequired,
  ticketAddOnCategories: PropTypes.arrayOf(Types.ticketAddOnCategory),
  ticketCart: Types.ticketCart,
  fetchOrCreateTicketCart: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  push: PropTypes.func.isRequired,
}

const defaultProps = {
  ticketAddOnCategories: null,
}

function SelectAddOnCategory({
  ticketAddOnCategories,
  fetchTicketAddOnCategories,
  location: {
    query: { activityId, cartToken, lineItemId },
  },
  ticketCart,
  fetchOrCreateTicketCart,
  push,
}) {
  const backUrl = `/ticketing/details?activityId=${activityId}&cartToken=${cartToken}`
  const forwardUrl = `/ticketing/add-ons-activity?&activityId=${activityId}&cartToken=${cartToken}&lineItemId=${lineItemId}`
  const cartPreviewUrl = `/ticketing/cart?cartToken=${cartToken}&activityId=${activityId}&lineItemId=${lineItemId}&backPath=add-ons`
  // if add-ons are found to be invalid for this ticket activity, push user to cart preview
  // and do not allow the back button to try to push the user back to the add-ons experience
  const skipAddOnsCartPreviewUrl = `/ticketing/cart?cartToken=${cartToken}&activityId=${activityId}`

  useEffect(() => {
    // if correct query data not provided, push to earlier in the flow
    if (!activityId) {
      push('/ticketing')
    } else if (!(lineItemId && cartToken)) {
      push(backUrl)
    }
    // always fetch a new set of AddOnCategories for the current activityId
    // to avoid the redux store keeping old data from a different activity
    fetchTicketAddOnCategories(parseInt(activityId)).then((resp) => {
      // if the response doesn't have add on categories, skip add ons section
      if (!(resp && resp.data && resp.data.attributes.length > 0)) {
        push(skipAddOnsCartPreviewUrl)
      }
    })
  }, [])

  useEffect(() => {
    cartToken && fetchOrCreateTicketCart(cartToken)
  }, [cartToken, fetchOrCreateTicketCart])

  if (!ticketAddOnCategories || !ticketCart) return <Spinner />
  const parentLineItem = ticketCart.lineItems.find(
    (lineItem) => lineItem.id === parseInt(lineItemId)
  )

  return (
    <SocietyCheckoutLayout
      progress={{ steps: TICKETING_CHECKOUT_STEPS, currentStep: 2 }}
      backUrl={backUrl}
      title="Select Add-Ons"
      summary={<OrderReceipt />}
      onContinue={cartPreviewUrl}
    >
      <p>
        Below are optional additional add-ons for{' '}
        <strong>
          {formatDate(parentLineItem.startDate)} at{' '}
          {parentLineItem.displayStartTime}
        </strong>{' '}
        that you can include with your ticket purchase.
      </p>
      <ul className="space-y-5">
        {ticketAddOnCategories.map(
          ({ id, name, description, categoryImage }) => (
            <li className="mb-0" key={id}>
              <SocietyProductCard
                id={id}
                name={name}
                description={description}
                imageUrl={categoryImage}
                selectUrl={`${forwardUrl}&addOnCategoryId=${id}`}
                horizontal
              />
            </li>
          )
        )}
      </ul>
    </SocietyCheckoutLayout>
  )
}

SelectAddOnCategory.propTypes = propTypes
SelectAddOnCategory.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    ticketAddOnCategories: selectors.ticketAddOnCategories(state),
    ticketCart: selectors.ticketCart(state),
  }
}

const mapDispatchToProps = {
  fetchTicketAddOnCategories: apiActions.fetchTicketAddOnCategories,
  fetchOrCreateTicketCart: apiActions.fetchOrCreateTicketCart,
  push: routerActions.push,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  SelectAddOnCategory
)
