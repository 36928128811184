import React from 'react'

const HelpText = () => {
  return (
    <section className="p-5 mx-auto space-y-5 sm:w-2/3 text-gray-500">
      We’ve revamped our login process to streamline your experience. If you’re
      experiencing account issues, please email:{' '}
      <a href="mailto:support@omahazoo.com" className="text-primary">
        support@omahazoo.com
      </a>
    </section>
  )
}

export default HelpText
