import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'main/types'
import { displayCurrency } from 'utils'
import SocietyOrderSummary from '../../../layout/SocietyOrderSummary'
import { compact } from 'lodash'

const propTypes = {
  variablePrice: PropTypes.number,
  quantity: PropTypes.number,
  selectedGiftCard: Types.giftCard.isRequired,
}

const defaultProps = {
  variablePrice: 0,
  quantity: 0,
  className: '',
}

function GiftCardReceipt({
  variablePrice,
  quantity,
  selectedGiftCard: { fixedPrice, displayName },
}) {
  const totalPrice = Number(fixedPrice) + variablePrice

  const subtotal = quantity <= 0 ? 0 : totalPrice * quantity

  const items = [
    {
      label: displayName,
      subtotal,
      notes: [
        ['Unitary value', `${displayCurrency(totalPrice)} ea x${quantity}`],
      ],
      prices: compact([
        fixedPrice && { label: 'Base value', value: fixedPrice },
        variablePrice && { label: 'Funds', value: variablePrice },
      ]),
    },
  ]

  const summary = compact([
    { label: 'Subtotal', amount: subtotal },
    { label: 'Total', amount: subtotal, highlight: true },
  ])

  return <SocietyOrderSummary summary={summary} items={items} />
}

GiftCardReceipt.propTypes = propTypes
GiftCardReceipt.defaultProps = defaultProps

export default GiftCardReceipt
