import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { FoundationInput, RecaptchaInput } from 'components'
import { scroller } from 'react-scroll'
import * as options from 'main/options'
import { lpForm } from '@launchpadlab/lp-form'
import { modifyProps } from '@launchpadlab/lp-hoc'
import { first, keys, validateRecaptcha } from 'utils'
import { GpiForm, GpiWrapper, GpiSubmit } from 'components/Gpi'
import CheckBox from '../../../../components/ui/CheckBox'
import { connect } from 'react-redux'
import { selectors } from '../../reducer'
import c from 'classnames'
import { Field } from 'redux-form'

const propTypes = {
  submit: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
}

function PaymentForm({ change, submit, submitting }) {
  const handleSubmit = ({
    cardToken,
    expirationDate,
    lastFourDigits,
    // maskedCardNumber, // not currently used but could be sent to server
    // cardType, // not currently used but could be sent to server
  }) => {
    // change and submit functions gets injected via lp-form because it is a wrapper around Redux Form
    // https://redux-form.com/8.3.0/docs/api/props.md/#-code-change-field-string-value-any-function-code-

    // update the form's card token with the temporary payment token from GPI
    // and add any other payment-related fields that our server needs to know about
    change('billingInfo.cardToken', cardToken)
    change('billingInfo.expirationDate', expirationDate)
    change('billingInfo.lastFourDigits', lastFourDigits)
    // submit the form, which triggers other form validations and then ultimately submits to our server
    submit()
  }

  const [skipBillingAddress, setSkipBillingAddress] = useState(true)

  return (
    <GpiWrapper
      onTokenSuccess={handleSubmit}
      isFoundation={true}
      isDisabled={!!submitting}
      gpiConfigType="foundation"
    >
      <form>
        <div className="flex gap-5">
          <Field
            name="billingInfo.firstName"
            component={FoundationInput}
            label="First Name"
            placeholder="First Name"
          />

          <Field
            name="billingInfo.lastName"
            component={FoundationInput}
            label="Last Name"
            placeholder="Last Name"
          />
        </div>
        <GpiForm />
        <div>
          <CheckBox
            label="My billing address is the same as my contact information"
            checked={skipBillingAddress}
            onChange={setSkipBillingAddress}
            name="skip-billing-address"
            width="full"
          />
          <div className={c({ 'sr-only': skipBillingAddress })}>
            <Field
              name="billingInfo.address1"
              component={FoundationInput}
              label="Street Address"
              placeholder="Street Address"
            />
            <div className="flex gap-5">
              <Field
                name="billingInfo.address2"
                component={FoundationInput}
                label="Street Address 2"
                placeholder="Street Address 2"
              />
              <Field
                name="billingInfo.city"
                component={FoundationInput}
                label="City"
                placeholder="City"
              />
            </div>
            <div className="flex gap-5">
              <Field
                name="billingInfo.state"
                component={FoundationInput}
                options={options.states}
                label="State"
                inputType="select"
                placeholder="Select State"
              />
              <Field
                name="billingInfo.zip"
                component={FoundationInput}
                label="Zip / Post Code"
                placeholder="Zip / Post Code"
              />
            </div>
          </div>
          <Field
            name="recaptcha"
            label={false}
            component={RecaptchaInput}
            validate={validateRecaptcha}
          />
          <GpiSubmit />
        </div>
      </form>
    </GpiWrapper>
  )
}

PaymentForm.propTypes = propTypes

const modify = ({ onSubmit }) => {
  return {
    constraints: {
      'billingInfo.firstName': { presence: true },
      'billingInfo.lastName': { presence: true },
      'billingInfo.address1': {
        presence: {
          message: `^Street address can't be blank`,
        },
        length: { maximum: 50 },
      },
      'billingInfo.city': {
        presence: true,
      },
      'billingInfo.state': {
        presence: true,
      },
      'billingInfo.zip': {
        presence: true,
      },
    },
    onSubmit: (values, dispatch, props) => {
      // if backend validation or recaptcha is needed, should be added here
      return onSubmit(values, dispatch, props)
    },
  }
}

function mapStateToProps(state) {
  return {
    isAuthenticated: selectors.isAuthenticated(state),
  }
}

export default compose(
  connect(mapStateToProps),
  modifyProps(modify),
  lpForm({
    name: 'foundation-cart-payment',
    onSubmitFail: (params, dispatch, _, { syncErrors }) => {
      const firstErrorField = first(keys(syncErrors))
      return scroller.scrollTo(firstErrorField, {
        smooth: true,
      })
    },
    enableReinitialize: true,
  })
)(PaymentForm)
