import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { Route } from 'react-router'
import { adaptToReactRouter, routeHooks } from 'utils'

const propTypes = {
  authFunction: PropTypes.func.isRequired,
  redirect: PropTypes.string.isRequired,
}

const defaultProps = {}

// A react-router route that requires an auth function to return 'true' before it's entered.

function AuthorizedRoute({ authFunction, redirect, ...rest }) {
  const changeHandler = routeHooks.requireAuth(authFunction, {
    redirectUrl: redirect,
  })
  return <Route onEnter={changeHandler} onChange={changeHandler} {...rest} />
}

AuthorizedRoute.propTypes = propTypes

AuthorizedRoute.defaultProps = defaultProps

export default compose(adaptToReactRouter())(AuthorizedRoute)
