/**
 * This function displays submit failures based on the onSubmitFail callback from Redux-Form
 * https://redux-form.com/7.4.2/docs/api/reduxform.md/#-code-onsubmitfail-function-code-optional-
 *
 * @name displaySubmitFailure
 * @param {Object} errors - Errors that caused submit to fail
 * @param {Function} dispatch - Redux dispatch function
 * @param {Object} submitError - Errors that caused submit to fail. If the only errors are related internally to redux-form validations, this will be null
 */

import { flashErrorMessage } from 'utils'

// TODO: make this NOT be a flash message/let it persist and be close-able
function displaySubmitFailure(errors, dispatch, submitError) {
  if (!submitError && errors)
    return dispatch(
      flashErrorMessage(
        `Please review the information provided and correct all ${
          errors['foundationStyleMessage'] ? '' : 'form '
        }errors below.`
      )
    )
  const message = getErrorMessage(errors)
  return dispatch(flashErrorMessage(message))
}

// ----- PRIVATE -----
function getErrorMessage(errors) {
  if (errors.message) return errors.message
  if (errors instanceof Array && errors.length)
    return errors.map((error) => error.message).join(', ')
  const message = errors._error
    ? errors._error
    : errors['foundationPaymentMessage']
      ? 'There was an error in your credit card information.'
      : 'An error occured while saving ticketing details.'

  return message
}

export default displaySubmitFailure
