import React, { useMemo } from 'react'
import { connect } from 'react-redux'
import * as flashActions from 'redux-flash'
import { compact } from 'lodash'
import { isEmpty, displaySubmitFailure } from 'utils'

import * as effects from 'main/effects'
import AccountPageLayout from '../components/AccountPageLayout'
import { selectors as membershipSelectors } from 'main/membership/reducer'
import * as membershipActions from 'main/membership/actions'
import { selectors as userSelectors } from 'main/user/reducer'
import { NonPrimaryMembershipsForm } from 'main/user/account/forms'
import MembershipCard from 'main/user/components/MembershipCard'

function Membership({
  primaryMember,
  secondaryMember,
  childMembers,
  nannyMember,
  guestMembers,
  setMembershipDetails,
  flashSuccessMessage,
}) {

  const initialValues = useMemo(() => ({
    primaryMember: {
      ...primaryMember,
      id: primaryMember?.id,
      isPrimary: primaryMember?.isPrimary,
      firstName: primaryMember?.firstName,
      lastName: primaryMember?.lastName,
      dateOfBirth: primaryMember?.dateOfBirth || '',
      email: primaryMember?.email,
      phone: primaryMember?.phone,
      address: {
        streetAddress1: primaryMember?.address.streetAddress,
        city: primaryMember?.address.suburb,
        state: primaryMember?.address.state,
        zip: primaryMember?.address.zip,
        country: primaryMember?.address.country || 'US',
      },
      allowEditName: primaryMember?.allowEditName, //api unifinished, always returns false
      allowEditAddress: primaryMember?.allowEditAddress, //api unifinished, always returns true
      allowEditPhone: primaryMember?.allowEditPhone, //api unifinished, always returns true
      allowEditEmail: primaryMember?.allowEditEmail, // is true if email is blank
    },
    ...(!isEmpty(secondaryMember) && {
      secondaryMember: {
        id: secondaryMember?.id,
        isSecondary: secondaryMember?.isSecondary,
        firstName: secondaryMember?.firstName,
        lastName: secondaryMember?.lastName,
        dateOfBirth: secondaryMember?.dateOfBirth || '',
        currentMembershipType: secondaryMember?.currentMembershipType,
        allowEditName: secondaryMember?.allowEditName,
      },
    }),
    childMembers: childMembers?.map((childMember) => ({
      id: childMember?.id,
      isChild: childMember?.isChild,
      firstName: childMember?.firstName,
      lastName: childMember?.lastName,
      dateOfBirth: childMember?.dateOfBirth || '',
      currentMembershipType: childMember?.currentMembershipType,
      allowEditName: childMember?.allowEditName,
    })),
    ...(!isEmpty(nannyMember) && {
      nannyMember: {
        id: nannyMember?.id,
        isNanny: nannyMember?.isNanny,
        firstName: nannyMember?.firstName,
        lastName: nannyMember?.lastName,
        dateOfBirth: nannyMember?.dateOfBirth || '',
        currentMembershipType: nannyMember?.currentMembershipType,
        allowEditName: nannyMember?.allowEditName,
      },
    }),
    guestMembers: guestMembers?.map((guestMember) => ({
      id: guestMember?.id,
      firstName: guestMember?.firstName,
      lastName: guestMember?.lastName,
      dateOfBirth: guestMember?.dateOfBirth || '',
      allowEditName: guestMember?.allowEditName,
    })),
  }), [  primaryMember,
    secondaryMember,
    childMembers,
    nannyMember,
    guestMembers])

  return (
    <AccountPageLayout className="space-y-10">
      <h1>Membership</h1>
      <MembershipCard primaryMember={primaryMember}/>
      <h2>Members</h2>
      <NonPrimaryMembershipsForm
        initialValues={initialValues}
        onSubmit={(values) => {
          const members = compact([
            values.primaryMember,
            values.secondaryMember,
            ...values.childMembers,
            values.nannyMember,
            ...values.guestMembers,
          ])
          return effects.updateMembers(members)
        }}
        onSubmitSuccess={({ data: { attributes } }) => {
          setMembershipDetails(attributes)
          flashSuccessMessage(
            'Your account information was updated successfully!'
          )
        }}
        onSubmitFail={displaySubmitFailure}
      />
    </AccountPageLayout>
  )
}

function mapStateToProps(state) {
  return {
    primaryMember:
      userSelectors.primaryMember(state) ||
      membershipSelectors.primaryMember(state),
    secondaryMember: membershipSelectors.secondaryMember(state),
    childMembers: membershipSelectors.childMembers(state),
    nannyMember: membershipSelectors.nannyMember(state),
    guestMembers: membershipSelectors.guestMembers(state),
  }
}

const mapDispatchToProps = {
  setMembershipDetails: membershipActions.setMembershipDetails,
  flashSuccessMessage: flashActions.flashSuccessMessage,
}

export default connect(mapStateToProps, mapDispatchToProps)(Membership)
