import React from 'react'
import { compose } from 'redux'
import { Field, propTypes as formPropTypes } from 'redux-form'
import { lpForm } from '@launchpadlab/lp-form'
import { modifyProps } from '@launchpadlab/lp-hoc'
import { Input } from '@launchpadlab/lp-components'

const propTypes = {
  ...formPropTypes,
}

function WaiverEmailsModal({ handleSubmit }) {
  return (
    <form onSubmit={handleSubmit}>
      <Field name="emails" component={Input} label="Emails" />
    </form>
  )
}

WaiverEmailsModal.propTypes = propTypes

const modify = () => {
  return {
    constraints: {
      emails: {
        presence: true,
        format: {
          pattern: /^(.+@.+\..+)+$/,
          message: 'must be a comma-separated list of emails',
        },
      },
    },
  }
}

export default compose(
  modifyProps(modify),
  lpForm({
    name: 'waiver-emails',
    enableReinitialize: true,
  })
)(WaiverEmailsModal)
