import React from 'react'
import { CreateAccountForm } from '../forms'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { flashSuccessMessage } from 'redux-flash'
import * as effects from 'main/effects'
import {
  displaySubmitFailure,
  findFirstErrorField,
  isEmpty,
  flashErrorMessage,
} from 'utils'
import { scroller } from 'react-scroll'
import * as routerActions from 'react-router-redux'

const propTypes = {
  flashSuccessMessage: PropTypes.func.isRequired,
  flashErrorMessage: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
}

const defaultProps = {}

function CreateAccount({ flashSuccessMessage, flashErrorMessage, push }) {
  const handleSubmitFail = (errors, dispatch, submitError, { syncErrors }) => {
    // catch when errors are coming from the user model
    if (isEmpty(errors) || errors.user) {
      flashErrorMessage(
        'Could not create your account. Please try again later.',
        { style: 'foundation' }
      )
    } else {
      displaySubmitFailure(errors, dispatch, submitError)
      // Check for sync errors here as onSubmitFail is overridden in props
      if (!isEmpty(syncErrors)) {
        const firstErrorField = findFirstErrorField(syncErrors)
        if (!firstErrorField) return
        return scroller.scrollTo(firstErrorField, { smooth: true })
      }
    }
  }

  return (
    <div className="auth-page">
      <section className="content">
        <h2>Create Your Account</h2>
        <CreateAccountForm
          onSubmit={effects.accountCreateAccount}
          onSubmitSuccess={() => {
            flashSuccessMessage(
              'Account created sucessfully. Please sign in to access your account'
            )
            push('/give/sign-in')
          }}
          onSubmitFail={handleSubmitFail}
        />
      </section>
    </div>
  )
}

function mapStateToProps() {
  return {}
}

const mapDispatchToProps = {
  flashSuccessMessage,
  flashErrorMessage,
  push: routerActions.push,
}

CreateAccount.propTypes = propTypes

CreateAccount.defaultProps = defaultProps

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  CreateAccount
)
