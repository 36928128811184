import React from 'react'
import { connect } from 'react-redux'
import { WaiverEmailsForm } from '../forms'
import * as effects from 'main/effects'
import { flashSuccessMessage } from 'redux-flash'
import checkmark from 'images/checkmark-green.svg'
import SocietyHeading from '../../../layout/SocietyHeading'
import ModalDialog from '../../../components/ui/ModalDialog'
import useModal from '../../../hooks/useModal'
import Anchor from '../../../components/ui/Anchor'
import LinkButton from '../../../components/ui/LinkButton'
import { submit } from 'redux-form'
import { flashErrorMessage } from 'utils'

const Waiver = ({
  clickedWaivers,
  onWaiverClicked,
  lineItem,
  flashSuccessMessage,
  flashErrorMessage,
  submitWaiverEmailsForm,
}) => {
  const modal = useModal()
  const clicked = clickedWaivers.indexOf(lineItem.id) >= 0
  const activityId = lineItem.productId
  const lineItemId = lineItem.id

  const handleSubmitWaiverEmails = ({ emails }) => {
    effects
      .sendWaiverEmails({ activityId, emails: emails.split(',') })
      .then(() => {
        flashSuccessMessage('Emails sent!')
        onWaiverClicked(lineItemId)
      })
      .catch(() => {
        flashErrorMessage(
          'There was an issue submitting your request. Please try again later.'
        )
      })
      .finally(() => {
        modal.close()
      })
  }
  return (
    <div className="flex flex-col items-center justify-between gap-5 p-3 border border-gray-200 border-solid rounded sm:flex-row">
      <div className="flex items-center gap-3 text-lg">
        {clicked && <img src={checkmark} className="w-5 h-5" />}
        {lineItem.eventName}
      </div>
      <div className="flex gap-5">
        <Anchor
          onClick={() => onWaiverClicked(lineItem.id)}
          href={lineItem.waiverUrl}
          rel="noreferrer"
          target="_blank"
          label={clicked ? 'Sign Another' : 'Sign Now'}
          primary
        />

        <LinkButton onClick={modal.open} label="Send by Email" neutral />
      </div>

      <ModalDialog
        isOpen={modal.isOpen}
        onClose={modal.close}
        onConfirm={submitWaiverEmailsForm}
        title="Email Waivers"
        content={<WaiverEmailsForm onSubmit={handleSubmitWaiverEmails} />}
      />
    </div>
  )
}

function Waivers({
  ticketLineItems,
  onWaiverClicked,
  clickedWaivers,
  flashSuccessMessage,
  submitWaiverEmailsForm,
}) {
  return (
    <div className="p-5 bg-white shadow-xl">
      <SocietyHeading>Attendee Waivers</SocietyHeading>
      <p>
        A waiver will need to be signed for every member of your party attending
        the following events. If you have authority to sign, you may do so now.
        If not, please email it to the responsible adult to sign. Waivers can
        only be signed by those 18 years and older.
      </p>
      {ticketLineItems.map((lineItem) => (
        <Waiver
          key={lineItem.id}
          clickedWaivers={clickedWaivers}
          onWaiverClicked={onWaiverClicked}
          lineItem={lineItem}
          flashSuccessMessage={flashSuccessMessage}
          submitWaiverEmailsForm={submitWaiverEmailsForm}
        />
      ))}
    </div>
  )
}

const mapDispatchToProps = {
  flashSuccessMessage,
  flashErrorMessage,
  submitWaiverEmailsForm: () => submit('waiver-emails'),
}

export default connect(undefined, mapDispatchToProps)(Waivers)
