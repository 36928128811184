import { handleActions } from 'redux-actions'
import { selectorForSlice } from '@launchpadlab/lp-redux-utils'
import { setFromRequest } from '@launchpadlab/lp-redux-api'
import * as apiActions from 'main/apiActions'

const reducerKey = 'questionnaire'
const slice = 'root.questionnaire'

const initialState = {
  activities: {},
}

const select = selectorForSlice(slice)

const reducer = handleActions(
  {
    ...setFromRequest(apiActions.REQ_QUESTIONNAIRE_ACTIVITIES, 'activities'),
  },
  initialState
)

const selectors = {
  activities: select('activities.success.data.attributes', []),
}

export { reducer, selectors, reducerKey }
