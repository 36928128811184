import { handleActions } from 'redux-actions'
import {
  selectorForSlice,
} from '@launchpadlab/lp-redux-utils'
import { setFromRequest } from '@launchpadlab/lp-redux-api'
import * as apiActions from 'main/apiActions'

const reducerKey = 'productAds'
const slice = 'root.productAds'

const initialState = {
  ads: undefined
}

const reducer = handleActions(
  {
    ...setFromRequest(apiActions.REQ_ADS, 'ads'),
  },
  initialState
)

const select = selectorForSlice(slice)

const selectors = {
  ads: select('ads.success'),
}

export { reducer, selectors, reducerKey }
