const trim = (str) => {
  if (!str) return ''
  return str.trim()
}

function joinNames(...names) {
  const combined = names.map(trim).join(' ')
  if (!combined) return

  return combined.trim()
}

export default joinNames
