import React from 'react'
import PropTypes from 'prop-types'
import Button from '../../../components/ui/Button'
import CheckBox from '../../../components/ui/CheckBox'
import Select from 'react-select'
import { uniq, range } from 'lodash'
import pluralize from 'pluralize'

const propTypes = {
  camps: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  filters: PropTypes.object,
}

const AGE_OPTIONS = range(3, 19).map((n) => ({ label: n, value: n }))

function getMonthName(monthNumber) {
  const date = new Date()
  date.setMonth(monthNumber - 1)
  return date.toLocaleString('en-US', { month: 'long' })
}

function CampsFilter(props) {
  const { camps = [], filters = {}, onChange, onClear } = props

  const dateOptions = uniq(
    camps.map(({ startDate }) => parseInt(startDate.split('-')[1]))
  )
    .sort()
    .map((value) => ({ label: getMonthName(value), value }))

  const lengthOptions = uniq(camps.map(({ lengthInDays }) => lengthInDays))
    .sort()
    .map((value) => ({ label: pluralize('day', value, true), value }))

  return (
    <aside className="h-full p-5 mb-0 bg-white shadow lg:w-1/5 flex-shrink-0">
      <h3 className="text-xl font-bold">Narrow Your Search</h3>
      <label className="w-full text-sm font-medium">
        Ages
        <Select
          name="age"
          className="mt-1 mb-5"
          options={AGE_OPTIONS}
          isMulti
          value={filters.age}
          placeholder="Any"
          onChange={(values) => onChange({ ...filters, age: values })}
        />
      </label>
      <label className="w-full text-sm font-medium">
        Dates
        <Select
          name="date"
          className="mt-1 mb-5"
          options={dateOptions}
          isMulti
          value={filters.date}
          placeholder="Any"
          onChange={(values) => onChange({ ...filters, date: values })}
        />
      </label>
      <label className="w-full text-sm font-medium">
        Length of Camp
        <Select
          name="length"
          className="mt-1 mb-5"
          options={lengthOptions}
          isMulti
          value={filters['length']}
          placeholder="Any"
          onChange={(values) => onChange({ ...filters, length: values })}
        />
      </label>
      <CheckBox
        name="available"
        checked={filters.available}
        label="Show only available dates"
        onChange={(value) => onChange({ ...filters, available: value })}
      />
      <Button
        onClick={onClear}
        className="w-full mt-5 text-lg bg-transparent border-0 text-primary font-roboto"
        label="Clear Filters"
      />
    </aside>
  )
}

CampsFilter.propTypes = propTypes

export default CampsFilter
