import React from 'react'
import c from 'classnames'

const LinkButton = ({
  className,
  label,
  onClick,
  primary,
  neutral,
  secondary,
  danger,
  sm,
  md,
  lg,
  xl,
  ...props
}) => {
  return (
    <button
      {...props}
      className={c(
        className,
        'underline cursor-pointer transition-colors',
        {
          'text-primary hover:text-primary-400': primary,
          'text-gray-900 hover:text-primary-800': neutral,
          'text-secondary hover:text-secondary-400': secondary,
          'text-danger hover:text-danger-400': danger,
          'text-xl': lg,
          'text-sm': sm,
          'text-lg': md,
          'text-2xl': xl,
          'text-base': !sm && !md && !lg && !xl,
        },
        //style cancelling -- remove once preflight is enabled
        'p-0 bg-transparent border-none text-gray-700 font-bold'
      )}
      onClick={onClick}
    >
      {label}
    </button>
  )
}

export default LinkButton
