/**
 * Traverses an object of errors looking for a leaf node to indicate which field the screen should be scrolled to.
 *
 * @name findFirstErrorField
 * @param {Object} syncErrors - Object of errors from redux-form
 * @returns {String} - Returns the fully qualified path for a key that does not have an object as a child. This designates the form field
 * @example
 *
 * const syncErrors = {
 *  ticketHolders: [{
 *    attendeeUdfs: {}
 *  }, {
 *    attendeeUdfs: {
 *      waiver: ["can't be blank"]
 *    }
 *  }],
 *}
 *
 * const syncErrorsNoArray = {
 *   address: {
 *    streetAddress: ["can't be blank"]
 *  },
 *  name: ["can't be blank"],
 * }
 *
 * findFirstErrorField(syncErrors) // 'ticketholders[1].attendeeUdfs.waiver'
 * findFirstErrorField(syncErrorsNoArray) // 'address.streetAddress'
 */

export default function findFirstErrorField(syncErrors, path = []) {
  for (const key in syncErrors) {
    const error = syncErrors[key]

    // If the value is an array of strings, that means the error message(s) have been located
    if (Array.isArray(error) && error.every((e) => typeof e === 'string')) {
      path.push(key)
      return path.join('.')
    } else if (Array.isArray(error)) {
      for (const index in error) {
        path.push(`${key}[${index}]`)

        // Check the value returned by each iteration and return the path (if it exists)
        const pathInArray = findFirstErrorField(error[index], path)
        if (pathInArray) return pathInArray
      }
    } else if (typeof error === 'object' && Object.keys(error).length > 0) {
      path.push(key)
      return findFirstErrorField(error, path)
    }
  }

  // This will get executed if no errors are found for a specific array index and "resets" the path
  path.pop()
}
