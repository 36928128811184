import React from 'react'
import { UdfFieldTypes } from 'main/types'
import editIcon from 'images/edit-icon.png'
import useModal from '../../../hooks/useModal'
import { connect } from 'react-redux'
import TicketingCart from '../utils/ticketingCart'
import { UserDefinedFields } from '../../../components/Form'
import * as actions from '../actions'
import BookingDetailsModal from './BookingDetailsModal'
import { flashErrorMessage } from 'utils'

function BookingDetails({
  ticketCart,
  ticketActivity,
  ticketActivityTime,
  lineItem,
  modal: modalOverride,
  setTicketCart,
  flashErrorMessage,
}) {
  const modal = useModal(modalOverride)

  const currentBookingUdfs = (lineItem && lineItem.bookingUdfs) || []

  const { bookingUdfs: udfs } = ticketActivity

  async function handleConfirmEdit(udfValues) {
    try {
      const resp = await TicketingCart.REMOTE.createOrUpdateTicketLineItem(
        ticketCart.token,
        ticketActivityTime,
        ticketActivity,
        { bookingUdfs: UserDefinedFields.fromValues(udfs, udfValues) }
      )

      setTicketCart({
        success: {
          data: {
            attributes: resp.data.additionalData.cart,
            type: 'ticketing_cart',
          },
        },
      })
    } catch (e) {
      console.error(e)
      flashErrorMessage('Something went wrong. Please try again later.')
    } finally {
      modal.close()
    }
  }

  function findValue(fieldName) {
    const selectedUdf = currentBookingUdfs.find(
      (udf) => udf.fieldName === fieldName
    )

    return selectedUdf ? selectedUdf.value : ''
  }

  function displayValue({ fieldName, inputType }) {
    const value = findValue(fieldName)
    return inputType === UdfFieldTypes.DATE
      ? new Date(value).toDateString()
      : value
  }

  const currentBookingUdfsPresent = currentBookingUdfs.some(
    (udf) => udf.value.length
  )

  return (
    <>
      {currentBookingUdfsPresent && (
        <section className="p-5 space-y-5 bg-white">
          <header className="flex justify-between text-3xl font-amatic">
            <span>Booking Details</span>
            <button
              className="inline-flex items-center justify-center w-8 h-8 p-2 bg-white border-none rounded-full cursor-pointer hover:bg-gray-100"
              onClick={modal.open}
            >
              <img className="w-5 h-5" src={editIcon} />
            </button>
          </header>
          <ol>
            {udfs.map((udf) => {
              const labelId = `udf-label-${udf.fieldName}`
              return (
                <li key={udf.fieldName}>
                  <label id={labelId}>{udf.label}</label>
                  <div aria-labelledby={labelId} className="text-base">
                    {displayValue(udf)}
                  </div>
                </li>
              )
            })}
          </ol>
        </section>
      )}
      <BookingDetailsModal
        isOpen={modal.isOpen}
        onClose={modal.close}
        onConfirm={handleConfirmEdit}
        udfs={udfs}
        udfValues={currentBookingUdfs}
      />
    </>
  )
}

const mapDispatchToProps = {
  setTicketCart: actions.setTicketCart,
  flashErrorMessage,
}

export default connect(undefined, mapDispatchToProps)(BookingDetails)
