import { handleActions } from 'redux-actions'
import { LOCATION_CHANGE } from 'react-router-redux'
import { setFromRequest } from '@launchpadlab/lp-redux-api'
import {
  selectorForSlice,
  setState,
  unsetState,
} from '@launchpadlab/lp-redux-utils'
import { createSelector } from 'reselect'
import { invert, pick, uniqBy } from 'lodash'
import { set } from 'lodash/fp'
import * as apiActions from 'main/apiActions'
import * as actions from './actions'
import * as LS from 'services/sessionStorage'
import * as Types from 'main/types'
import z from 'zod'

const TicketTime = z.object({
  activityId: z.number(),
  ageMax: z.nullable(z.number()),
  ageMin: z.nullable(z.number()),
  baseFeeCostRateId: z.nullable(z.number()),
  baseFeeId: z.nullable(z.number()),
  baseFeePrice: z.nullable(z.number()),
  capacity: z.number(),
  centamanId: z.number(),
  displayEndTime: z.string(),
  displayName: z.string(),
  displayStartTime: z.string(),
  displayTime: z.string(),
  endDate: z.string(),
  endDatetime: z.string(),
  endTime: z.string(),
  id: z.number(),
  lengthInDays: z.nullable(z.number()),
  prices: z.array(z.any()),
  soldOut: z.boolean(),
  startDate: z.string(),
  startDatetime: z.string(),
  startTime: z.string(),
  vacancy: z.number(),
})

const reducerKey = 'ticketing'
const slice = 'root.ticketing'

const initialState = {
  currentFormStep: LS.getMembershipFormStep() || 0,
  ticketTimes: undefined,
  ticketActivities: undefined,
  untimedPrices: {},
  schools: [],
  disableTicketPriceActions: false,
  userUdfValues: {},
}

const select = selectorForSlice(slice)

const categoryFields = [
  'categoryName',
  'categoryId',
  'categoryImage',
  'categoryFeatured',
  'categoryFeaturedDisplayLabel',
  'categoryLearnMoreUrl',
  'categoryDescription',
  'categoryIsDirectLink',
  'categoryIsCampOrClass',
]

const reducer = handleActions(
  {
    ...setFromRequest(apiActions.REQ_TICKET_ACTIVITIES, 'ticketActivities'),
    ...setFromRequest(
      apiActions.REQ_TICKET_ADD_ON_CATEGORIES,
      'ticketAddOnCategories'
    ),
    // after cart is requested, persist the latest cart token to session storage
    [apiActions.REQ_TICKET_CART + '_SUCCESS']: (
      state,
      { payload: { data } }
    ) => {
      LS.setTicketCartToken(data.attributes.token)
      return set('ticketCart', { success: { data: data } }, state)
    },
    [actions.setTicketCart]: (state, { payload }) => {
      LS.setTicketCartToken(payload.success.data.attributes.token)
      return set('ticketCart', payload, state)
    },
    [apiActions.REQ_TICKET_TIMES + '_SUCCESS']: (
      state,
      { payload: { data } }
    ) => {
      const Data = z.object({
        type: z.literal('activity_times'),
        attributes: z.array(TicketTime),
        additionalData: z.object({
          cartToken: z.nullable(z.string()),
        }),
      })

      const { attributes } = Data.parse(data)

      return set('ticketTimes', attributes, state)
    },
    [apiActions.REQ_TICKET_TIME + '_SUCCESS']: (
      state,
      { payload: { data } }
    ) => {
      const Data = z.object({
        type: z.literal('activity_time'),
        attributes: TicketTime,
        additionalData: z.object({
          cartToken: z.nullable(z.string()),
        }),
      })

      const { attributes } = Data.parse(data)

      const updatedTimes = state.ticketTimes.map((time) =>
        time.id === attributes.id ? attributes : time
      )

      return set('ticketTimes', updatedTimes, state)
    },
    [apiActions.REQ_UNTIMED_PRICES + '_SUCCESS']: (
      state,
      { payload: { data } }
    ) => {
      const currentUntimedPrices = state.untimedPrices
      // We ultimately create an object with the productId as
      // a key for the prices array. Sometime that looks like:
      // { productId1: [price1, price2], productId2: [price3, price 4] }
      const firstUntimedPrice = data.attributes[0]
      const productId = firstUntimedPrice && firstUntimedPrice.productId
      const newUntimedPrices = firstUntimedPrice
        ? {
            [productId]: data.attributes,
          }
        : {}
      const finalUntimedPrices = Object.assign(
        {},
        currentUntimedPrices,
        newUntimedPrices
      )

      return set('untimedPrices', finalUntimedPrices, state)
    },
    [apiActions.REQ_SCHOOLS + '_SUCCESS']: (
      state,
      {
        payload: {
          data: { attributes },
        },
      }
    ) => {
      return set('schools', attributes, state)
    },
    [LOCATION_CHANGE]: (state, { payload: { pathname } }) => {
      const formStep = invert(Types.TicketFormStepRoutes)[pathname]
      if (!formStep) return state

      return set('currentFormStep', Number(formStep), state)
    },
    [actions.clearTicketingForm]: () => {
      LS.clearMemberId()
      LS.clearAuthToken()
      LS.clearMembershipFormStep()
      return { ...initialState }
    },
    [actions.clearTicketTimes]: unsetState('ticketTimes'),
    [actions.setCurrentFormStep]: (state, { payload }) => {
      LS.setMembershipFormStep(payload)
      return set('currentFormStep', payload, state)
    },

    [actions.setDisableTicketPriceActions]: setState(
      'disableTicketPriceActions',
      ({ payload }) => {
        return payload
      }
    ),
    [actions.setUserUdfValues]: setState('userUdfValues', ({ payload }) => {
      return payload
    }),

    ...setFromRequest(apiActions.REQ_CUSTOMER_DETAILS, 'customerDetails'),
  },
  initialState
)

const selectors = {
  currentFormStep: select('currentFormStep'),
  ticketAddOnCategories: select(
    'ticketAddOnCategories.success.data.attributes'
  ),
  ticketTimes: select('ticketTimes'),
  ticketActivities: select('ticketActivities.success.data.attributes'),
  untimedPrices: select('untimedPrices'),
  schools: select('schools'),
  ticketCart: select('ticketCart.success.data.attributes'),
  disableTicketPriceActions: select('disableTicketPriceActions'),
  userUdfValues: select('userUdfValues'),
  customerDetails: select('customerDetails.success.data.attributes'),
}

// subset of ticketActivities that are flagged to be displayed on main ticketing path page
selectors.displayedTicketActivities = createSelector(
  [selectors.ticketActivities],
  function (ticketActivities) {
    if (!ticketActivities) return null
    return ticketActivities.filter((ta) => !!ta.display)
  }
)

// unique subset of the categories associated with ticket activities
// that can be shown on the main ticketing path
selectors.displayedTicketCategories = createSelector(
  [selectors.displayedTicketActivities],
  function (displayedTicketActivities) {
    if (!displayedTicketActivities) return null
    return uniqBy(
      displayedTicketActivities.map((type) => pick(type, categoryFields)),
      'categoryId'
    )
  }
)

const getUntimedPrices = (state, props) => {
  const productId = props.addOn.id
  const untimedPrices = state.root.ticketing.untimedPrices[productId]
  // untimedPrices should be undefined or an array
  if (!untimedPrices) {
    return null
  }

  return untimedPrices
}

selectors.untimedPricesForAddOn = createSelector(
  [getUntimedPrices],
  (getUntimedPrices) => {
    return getUntimedPrices
  }
)

export { reducer, selectors, reducerKey }
