export default function mapFoundationLineItemData({
  recurringScheduleType = 'one_time',
  foundationProductId = null,
  amount,
  inMemoryOfName = null,
  mailToDesignation = null,
  recipientFirstName = null,
  recipientLastName = null,
  recipientCountry = null,
  recipient_address_1 = null,
  recipient_address_2 = null,
  recipientCity = null,
  recipientState = null,
  recipientZip = null,
  memorialGiving = null,
  tributeType = null,
  cartToken,
}) {
  return {
    foundationLineItem: {
      recurringScheduleType,
      foundationProductId,
      amount,
      inMemoryOfName,
      mailToDesignation,
      recipientFirstName,
      recipientLastName,
      recipientCountry,
      recipient_address_1,
      recipient_address_2,
      recipientCity,
      recipientState,
      recipientZip,
      memorialGiving,
      tributeType,
    },
    cartToken,
  }
}
