import React from 'react'
import PropTypes from 'prop-types'
import DOMPurify from 'dompurify'
import c from 'classnames'

const propTypes = {
  children: PropTypes.string,
}

const defaultProps = {}

function RenderedHtml({ children, className, ...rest }) {
  return (
    <div
      {...rest}
      className={c(className, 'rendered-html')}
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(children, {
          ALLOWED_TAGS: [
            'blockquote',
            'strong',
            'em',
            'u',
            'a',
            'br',
            'pre',
            'sub',
            'sup',
            'span',
            'div',
            'img',
            'p',
            'ul',
            'ol',
            'li',
          ],
        }),
      }}
    />
  )
}

RenderedHtml.propTypes = propTypes
RenderedHtml.defaultProps = defaultProps

export default RenderedHtml
