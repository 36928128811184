import React from 'react'
import PropTypes from 'prop-types'
import { DateInput } from '@launchpadlab/lp-components'
import moment from 'moment'

const propTypes = {
  placeholderText: PropTypes.string,
  showMonthDropdown: PropTypes.bool,
  showYearDropdown: PropTypes.bool,
  dropdownMode: PropTypes.string,
  maxDate: PropTypes.instanceOf(Date),
}

const defaultProps = {
  placeholderText: 'mm/dd/yyyy',
  showMonthDropdown: true,
  showYearDropdown: true,
  dropdownMode: 'select',
  maxDate: undefined,
}

function BirthDateInput({
  placeholderText,
  showMonthDropdown,
  showYearDropdown,
  dropdownMode,
  maxDate,
  ...rest
}) {
  return (
    <DateInput
      placeholderText={placeholderText}
      showMonthDropdown={showMonthDropdown}
      showYearDropdown={showYearDropdown}
      dropdownMode={dropdownMode}
      maxDate={moment(maxDate)}
      {...rest}
    />
  )
}

BirthDateInput.propTypes = propTypes
BirthDateInput.defaultProps = defaultProps

export default BirthDateInput
