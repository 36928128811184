import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Link } from 'react-router'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { modifyProps } from '@launchpadlab/lp-hoc'
import * as effects from 'main/effects'
import * as actions from '../actions'
import { Spinner } from '@launchpadlab/lp-components'
import ReactGA from 'react-ga4'
import * as apiActions from 'main/apiActions'
import * as Types from 'main/types'
import OrderReceipt from '../components/OrderReceipt'
import { selectors } from '../reducer'
import FieldTripPaymentInfoCard from '../components/FieldTripPaymentInfoCard'
import SocietyCheckoutLayout from '../../../layout/SocietyCheckoutLayout'
import { TICKETING_CHECKOUT_STEPS } from '../../types'

const propTypes = {
  location: PropTypes.shape({ query: PropTypes.object }),
  ticketCart: Types.ticketCart,
  fetchOrCreateTicketCart: PropTypes.func.isRequired,
  setTicketCart: PropTypes.func.isRequired,
  removeTicketLineItem: PropTypes.func.isRequired,
}

const defaultProps = {}

function ViewCart({
  location: {
    query: { cartToken, activityId, backPath, lineItemId, addOnCategoryId },
  },
  ticketCart,
  fetchOrCreateTicketCart,
}) {
  useEffect(() => {
    ReactGA.event('begin_checkout', {
      currency: 'USD',
      value: ticketCart?.total,
      items: ticketCart?.lineItems?.map((cartItem) => ({
        item_id: cartItem.productId,
        item_name: cartItem.displayName,
        item_category: cartItem.productType,
        price: Number(
          cartItem.lineItemPrices
            ?.reduce((total, item) => total + item.price, 0)
            .toFixed(2)
        ),
        quantity: Number(
          cartItem.lineItemPrices?.reduce(
            (total, item) => total + item.quantity,
            0
          )
        ),
      })),
    })
  }, [])

  useEffect(() => {
    cartToken && fetchOrCreateTicketCart(cartToken)
  }, [cartToken, fetchOrCreateTicketCart])

  if (!ticketCart) return <Spinner />

  const subpath = backPath || 'details'
  const backUrl = `/ticketing/${subpath}?cartToken=${cartToken}${
    activityId ? `&activityId=${activityId}` : ''
  }${lineItemId ? `&lineItemId=${lineItemId}` : ''}${
    addOnCategoryId ? `&addOnCategoryId=${addOnCategoryId}` : ''
  }`
  const forwardUrl = `/ticketing/pay${location.search}`

  const isFieldTripBooking = Boolean(ticketCart.schoolName)

  return (
    <SocietyCheckoutLayout
      progress={{ steps: TICKETING_CHECKOUT_STEPS, currentStep: 3 }}
      backUrl={backUrl}
      title="Shopping Cart"
      className="space-y-10"
    >
      {isFieldTripBooking && <FieldTripPaymentInfoCard />}
      <section className="p-5 mb-5 bg-white filter drop-shadow sawtooth-bottom">
        <OrderReceipt />
      </section>
      <section className="space-y-5">
        <Link
          className={classnames('button-primary', 'button-next-step')}
          to={forwardUrl}
        >
          Continue
        </Link>
        <Link
          className={classnames(
            'button-primary',
            'button-next-step',
            'button-green-outline'
          )}
          to="/ticketing"
        >
          Add Another Item
        </Link>
      </section>
    </SocietyCheckoutLayout>
  )
}

ViewCart.propTypes = propTypes
ViewCart.defaultProps = defaultProps

function mapStateToProps(state) {
  return { ticketCart: selectors.ticketCart(state) }
}

const mapDispatchToProps = {
  fetchOrCreateTicketCart: apiActions.fetchOrCreateTicketCart,
  setTicketCart: actions.setTicketCart,
}

function modify() {
  return {
    removeTicketLineItem: ({ ticketCartToken, ticketLineItemId }) =>
      effects.destroyTicketLineItem(ticketCartToken, ticketLineItemId),
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  modifyProps(modify)
)(ViewCart)
