import React from 'react'
// import PropTypes from 'prop-types'
import { propTypes as formPropTypes } from 'redux-form'
import { compose } from 'redux'
import { lpForm } from '@launchpadlab/lp-form'
import { Input, SubmitButton } from '@launchpadlab/lp-components'
import { NormalizedField as Field } from 'components'

const propTypes = {
  ...formPropTypes,
}

const defaultProps = {}

function CouponCodeForm({ handleSubmit, error, submitting }) {
  return (
    <form onSubmit={handleSubmit} className="p-5 bg-white shadow">
      {error && <p className="error-message">{error}</p>}
      <Field name="coupon" label="Coupon Code" component={Input} />
      <SubmitButton {...{ submitting }}>Apply</SubmitButton>
    </form>
  )
}

CouponCodeForm.propTypes = propTypes

CouponCodeForm.defaultProps = defaultProps

export default compose(
  lpForm({
    name: 'coupon-code',
  })
)(CouponCodeForm)
