import React from 'react'
import Form, { useForm } from '../../../../components/Form'
import Button from '../../../../components/ui/Button'
import SocietyHeading from '../../../../layout/SocietyHeading'
import SocietyPageLayout from '../../../../layout/SocietyPageLayout'
import Users from '../../../ticketing/utils/users'
import HelpText from './HelpText'
import { EMAIL_FORM_FIELDS } from '../UniversalSignIn'

const EmailForm = ({
  onUserExists,
  onMembersipNotFound,
  onMembersipExists,
  guestFlowDisabled,
  setUserGuestFlowPreferred,
}) => {
  const form = useForm(EMAIL_FORM_FIELDS, {})

  async function handleSubmit() {
    const { email } = form.values

    if (form.validate()) {
      const { user, emailVerified, centamanCustomerId, newUserNotMember } =
        await Users.verify(email)

      if (user) {
        onUserExists(email, { emailVerified, centamanCustomerId })
      } else if (centamanCustomerId !== null && !newUserNotMember) {
        onMembersipExists(email, { centamanCustomerId })
      } else {
        onMembersipNotFound(email, {
          centamanCustomerId,
          newUserNotMember,
        })
      }
    }
  }

  return (
    <SocietyPageLayout title="Welcome to Omaha Zoo">
      <section className="p-5 mx-auto space-y-10 pb-7 sm:w-2/3 text-gray-500 font-bold">
        Have an Omaha Zoo Account?
      </section>
      <section className="p-5 mx-auto space-y-10 bg-white shadow pb-7 sm:w-2/3">
        <SocietyHeading>Sign In or Sign Up</SocietyHeading>
        <Form {...form} onSubmit={handleSubmit} />

        <footer className="relative pb-3">
          <Button
            type="primary"
            label="Continue"
            size="xl"
            width="full"
            onClick={handleSubmit}
          />
        </footer>
      </section>
      {!guestFlowDisabled && (
        <section className="mx-auto pb-7 sm:w-2/3">
          <hr data-content="OR" />
          <Button
            type="primary-outline"
            label="Continue as Guest"
            size="xl"
            width="full"
            onClick={() => setUserGuestFlowPreferred(true)}
          />
        </section>
      )}
      <HelpText />
    </SocietyPageLayout>
  )
}

export default EmailForm
