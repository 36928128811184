import * as Sentry from '@sentry/react'

const sentryUrl = process.env.REACT_APP_SENTRY_URL
const apiURL = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}`

export function initializeSentry() {
  if (!sentryUrl)
    // eslint-disable-next-line no-console
    return console.warn(
      'WARNING: Sentry not configured. "REACT_APP_SENTRY_URL" environment variable must be defined.'
    )

  return Sentry.init({
    dsn: sentryUrl,
    release: process.env.NODE_ENV,
    environment: process.env.REACT_APP_SENTRY_ENV,
    integrations: [
      new Sentry.BrowserTracing(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: true,
        maskAllInputs: true,
        networkDetailAllowUrls: [window.location.origin, apiURL],
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,
    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', apiURL,],

    // This sets the sample rate to be 1%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.01,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 0.1,
  })
}

export function logException(err) {
  return Sentry.withScope((scope) => {
    scope.setExtra('debug', false)
    Sentry.captureException(err)
  })
}
