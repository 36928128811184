import React from 'react'
import { Link } from 'react-router'
import { compose } from 'redux'
import { propTypes as formPropTypes } from 'redux-form'
import { lpForm } from '@launchpadlab/lp-form'
import { SubmitButton } from '@launchpadlab/lp-components'
import { NormalizedField as Field, FoundationInput } from 'components'

const propTypes = {
  ...formPropTypes,
}

const defaultProps = {}

function ForgotPasswordForm({ handleSubmit, submitting, invalid }) {
  return (
    <form onSubmit={handleSubmit}>
      <Field
        name="primary_email"
        component={FoundationInput}
        label="Email"
        placeholder="Email *"
        inputType="email"
      />
      <SubmitButton {...{ submitting, invalid }}>Send Reset Link</SubmitButton>
      <p style={{ paddingTop: 20 }}>
        <Link to="/give/sign-in">Remembered your password? Sign In</Link>
      </p>
    </form>
  )
}

ForgotPasswordForm.propTypes = propTypes
ForgotPasswordForm.defaultProps = defaultProps

export default compose(
  lpForm({
    name: 'forgot-password',
    constraints: {
      primary_email: { presence: true },
    },
  })
)(ForgotPasswordForm)
