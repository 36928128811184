import * as LS from 'services/sessionStorage'

// Auth helpers

export function isAuthenticated() {
  return !!LS.getAuthToken()
}

export function isFoundationAuthenticated() {
  return !!LS.getFoundationAuthToken()
}
