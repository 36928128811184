import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { selectors } from '../reducer'
import * as actions from '../actions'
import * as apiActions from 'main/apiActions'
import bannerImage from 'images/patron-banner.png'
import SocietyCheckoutLayout from 'layout/SocietyCheckoutLayout'
import { MEMBERSHIP_CHECKOUT_STEPS } from '../../types'
import SocietyProductGrid from 'layout/SocietyProductGrid'
import CheckBox from 'components/ui/CheckBox'

function SelectPatronLevel({
  patronMembershipTypes,
  patronDeclinedBenefitsMembershipTypes,
  primaryMembershipId,
  setSelectedMembershipType,
  fetchMembershipTypes,
}) {
  useEffect(() => {
    if (!patronMembershipTypes) {
      fetchMembershipTypes()
    }
  }, [])

  const [declineBenefits, setDecineBenefits] = useState(false)

  const perks = [
    'Admission to the Zoo, Lozier Theater, Stingray Beach and Wildlife Safari Park for two named members and up to six children or grandchildren (0–20)',
    'Wristbands for rides on train, tram, Skyfari, and carousel for the number of guests entering on your membership each day',
    'Zoo and Wildlife Safari Park admission for two additional guests with every visit',
    'Addition of nanny or babysitter',
    'Member education discount',
    'Concessions and gift shop discounts',
    'Foundation event invitations',
    'Priority registration/early notification for select education programs',
    'Priority registration for events',
    'Special patron line at admissions during peak season',
    'Cocktails & Conversation events (spring and fall)',
    'Exclusive advance previews of special exhibits and events',
  ]
  const membershipTypes = declineBenefits
    ? patronDeclinedBenefitsMembershipTypes
    : patronMembershipTypes
  const products = memberTypesToProducts(membershipTypes, primaryMembershipId)

  return (
    <SocietyCheckoutLayout
      progress={{ steps: MEMBERSHIP_CHECKOUT_STEPS, currentStep: 0 }}
      title="Select Patron Level"
      backUrl="/membership"
    >
      <div className="mx-auto mb-10 bg-white shadow lg:w-2/3">
        <div
          className="bg-contain bg-no-repeat bg-center h-64"
          style={{ backgroundImage: `url(${bannerImage})` }}
        />
        <h2 className="mx-5 mt-5 mb-0">
          BENEFITS HAVE CHANGED IN 2024. PLEASE REVIEW THE FOLLOWING:
        </h2>
        <ul className="pb-5 px-5 mx-5 space-y-4 list-disc">
          {perks.map((perk, index) => (
            <li key={index} className="mb-0">
              {perk}
            </li>
          ))}
        </ul>
      </div>
      {patronDeclinedBenefitsMembershipTypes &&
        patronDeclinedBenefitsMembershipTypes.length > 0 && (
          <div className="mx-auto mb-10 bg-white shadow lg:w-2/3">
            <div className="p-5">
              <h2 className="text-4xl font-amatic">
                DECLINE PATRON MEMBERSHIP BENEFITS
              </h2>
              <p>
                As a Patron member, you have the choice to receive your
                membership benefits or donate the full amount to the zoo. If you
                prefer to donate, simply check the box below to decline your
                benefits. Your decision will be reflected in your tax receipt.
              </p>
              <CheckBox
                label="I want to decline my Patron membership benefits and donate the full amount to the zoo."
                checked={declineBenefits}
                onChange={setDecineBenefits}
                name="decline-benefits"
                width="full"
              />
            </div>
          </div>
        )}

      <div className="mx-auto lg:w-2/3">
        <SocietyProductGrid
          products={products}
          singleColumn
          onSelect={({ membership }) => {
            setSelectedMembershipType(membership)
          }}
        />
      </div>
    </SocietyCheckoutLayout>
  )
}

function memberTypesToProducts(membershipTypes, primaryMembershipId) {
  if (!membershipTypes) return []

  return membershipTypes.map((membership) => {
    const isCurrentMembership = membership.id === primaryMembershipId

    return {
      price: `$${membership.cost} per year`,
      name: membership.displayName,
      priceNote: membership.priceNote,
      featured: isCurrentMembership,
      featuredDisplayLabel: 'Current Level',
      imageUrl: membership.image,
      description: membership.descriptionText,
      productUrl: membership.learnMoreUrl,
      selectUrl: `/membership/details?activityId=${membership.id}&backPath=activity`,
      signInUrl: `/sign-in?guestFlowDisabled=1&redirectUrl=${encodeURIComponent(
        '/membership'
      )}`,
      fullDescription: membership.infoModalText,
      isLoginRequired: true,
      membership,
    }
  })
}

function mapStateToProps(state) {
  const patronMembershipTypes = selectors.orderedPatronMembershipTypes(state)
  const patronDeclinedBenefitsMembershipTypes =
    selectors.orderedPatronDeclinedBenefitsMembershipTypes(state)
  const primaryMembershipId = selectors.primaryMembershipId(state)

  return {
    patronMembershipTypes,
    patronDeclinedBenefitsMembershipTypes,
    primaryMembershipId,
  }
}

const mapDispatchToProps = {
  fetchMembershipTypes: apiActions.fetchMembershipTypes,
  setSelectedMembershipType: actions.setSelectedMembershipType,
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectPatronLevel)
