import { useEffect } from 'react'
import * as apiActions from 'main/apiActions'
import { selectors } from '../reducer'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import * as Types from 'main/types'

const TicketActivitiesListener = ({ activities, fetchTicketActivities }) => {
  useEffect(() => {
    if (!activities) {
      fetchTicketActivities()
    }
  }, [activities])

  return null
}

function mapStateToProps(state) {
  return {
    activities: selectors.ticketActivities(state),
  }
}

const mapDispatchToProps = {
  fetchTicketActivities: apiActions.fetchTicketActivities,
}

TicketActivitiesListener.propTypes = {
  fetchTicketActivities: PropTypes.func,
  activities: PropTypes.arrayOf(Types.ticketActivity),
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TicketActivitiesListener)
