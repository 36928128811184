import React from 'react'
import { Link } from 'react-router'
import c from 'classnames'

const ButtonLink = ({
  label,
  href,
  className,
  primary,
  primaryOutline,
  secondary,
  sm,
  md,
  lg,
  xl,
  width,
  disabled,
  ...props
}) => {
  const isFitWidth = width === 'fit'
  const isFixedWidth = width === 'fixed'

  return (
    <Link
      to={href}
      className={c(
        'block whitespace-nowrap no-underline rounded-full shadow-md transition-colors text-center',
        {
          'bg-primary hover:bg-primary-400 text-white': primary && !disabled,
          'bg-secondary hover:bg-secondary-400 text-white': secondary && !disabled,
          'bg-gray-200 cursor-not-allowed hover:bg-gray-200 pointer-events-none text-white':
            disabled,
          'text-primary border-2 border-primary border-solid tracking-wider bg-white hover:text-white hover:bg-primary':
            primaryOutline,
          'border-none': !primaryOutline,
          'px-5 py-2 text-base': !sm && !md && !lg && !xl,
          'px-14 py-4 text-lg uppercase': lg,
          'px-8 py-2 text-xl': xl,
          'w-fit': isFitWidth,
          'w-40 text-center': isFixedWidth,
        },
        className
      )}
      {...props}
    >
      {label}
    </Link>
  )
}

export default ButtonLink
