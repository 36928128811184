import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'main/types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Spinner } from '@launchpadlab/lp-components'
import * as routerActions from 'react-router-redux'
import { connectQuery } from '@launchpadlab/lp-hoc'
import * as effects from 'main/effects'
import { flashSuccessMessage } from 'redux-flash'
import { flashErrorMessage } from 'utils'
import { get } from 'lodash'
import { selectors as userSelectors } from './user/reducer'
import * as userActions from './user/actions'
import * as Sentry from '@sentry/react'
import SocietyPageLayout from '../layout/SocietyPageLayout'

const propTypes = {
  clearMilitaryStatus: PropTypes.func.isRequired,
  clearOAuthRedirectPath: PropTypes.func.isRequired,
  code: PropTypes.string,
  error: PropTypes.string,
  errorDescription: PropTypes.string,
  flashErrorMessage: PropTypes.func.isRequired,
  flashSuccessMessage: PropTypes.func.isRequired,
  oAuthRedirectPath: PropTypes.string,
  replace: PropTypes.func.isRequired,
  setMilitaryStatus: PropTypes.func.isRequired,
}

const defaultProps = {
  code: '',
  error: '',
  errorDescription: '',
  oAuthRedirectPath: '/',
}

const DEFAULT_ERROR_MESSAGE =
  'Oops! Something went wrong when verifying your identity. Please try again.'

function getIdMeErrorMessage({ error, errorDescription }) {
  if (error === Types.IdMeErrorCode.ACCESS_DENIED)
    return "We're unable to confirm your military affiliation because you did not grant us access to your ID.me profile."
  return errorDescription || DEFAULT_ERROR_MESSAGE
}

/**
 * This view will render as the callback_uri that ID.me points the browser to
 * once the user is done entering their information. If the user denies ID.me
 * access to their information, the same callback_uri will be used, but with
 * and error code and/or information error message.
 *
 * For example:
 *
 * Success - `/idme/callback?code=12331AADHu13`
 * Failure - `/idme/callback?error=access_denied&error_description=The+resource+owner+or+authorization+server+denied+the+request
 */

function IdMeCallback({
  clearMilitaryStatus,
  clearOAuthRedirectPath,
  error,
  errorDescription,
  flashErrorMessage,
  flashSuccessMessage,
  oAuthRedirectPath,
  replace,
  setMilitaryStatus,
  code,
}) {
  useEffect(() => {
    if (code) {
      effects
        .verifyIdMe(code)
        .then((res) => {
          flashSuccessMessage('Identity verified with ID.me!')
          setMilitaryStatus(res)
        })
        .catch((error) => {
          const message = get(error, 'errors.message', DEFAULT_ERROR_MESSAGE)
          flashErrorMessage(message)
          Sentry.captureException(error)
          clearMilitaryStatus()
        })
        .finally(() => {
          replace(oAuthRedirectPath)
        })
    } else {
      const message = getIdMeErrorMessage({ error, errorDescription })
      flashErrorMessage(message)
      clearMilitaryStatus()
      replace(oAuthRedirectPath)
    }

    return clearOAuthRedirectPath
  }, [])

  return (
    <SocietyPageLayout>
      <Spinner aria-hidden />
      <p>Verifying your information...</p>
    </SocietyPageLayout>
  )
}

IdMeCallback.propTypes = propTypes
IdMeCallback.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    oAuthRedirectPath: userSelectors.oAuthRedirectPath(state),
  }
}

const mapDispatchToProps = {
  clearMilitaryStatus: userActions.clearMilitaryStatus,
  clearOAuthRedirectPath: userActions.clearOAuthRedirectPath,
  flashErrorMessage,
  flashSuccessMessage,
  replace: routerActions.replace,
  setMilitaryStatus: userActions.setMilitaryStatus,
}

export default compose(
  connectQuery(['code', 'error', 'errorDescription']),
  connect(mapStateToProps, mapDispatchToProps)
)(IdMeCallback)
