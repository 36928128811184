// App-wide config (routes, env vars) goes here.

export const PRIMARY_MEMBER_FIRST_NAME_MAX_LENGTH = 27
export const PRIMARY_MEMBER_LAST_NAME_MAX_LENGTH = 21
export const NEW_MEMBER_ID = 0

export const DEFAULT_PRIMARY_MEMBER_VALIDATION_CONSTRAINTS = {
  'primaryMember.firstName': {
    presence: true,
    length: { maximum: PRIMARY_MEMBER_FIRST_NAME_MAX_LENGTH },
  },
  'primaryMember.lastName': {
    presence: true,
    length: { maximum: PRIMARY_MEMBER_LAST_NAME_MAX_LENGTH },
  },
  'primaryMember.email': {
    presence: true,
    email: true,
    length: { maximum: 60 },
  },
}
export const DEFAULT_SECONDARY_MEMBER_VALIDATION_CONSTRAINTS = {
  'secondaryMember.firstName': { length: { maximum: 30 } },
  'secondaryMember.lastName': { length: { maximum: 30 } },
}
