import React from 'react'
import { Route, Router, browserHistory, IndexRedirect } from 'react-router'
import initializeStore from './store'
import { Provider } from 'react-redux'
import { syncHistoryWithStore } from 'react-router-redux'
import { initializeGA } from 'services/googleAnalytics'
import Layout from './Layout'
import { routes as MembershipRoutes } from './membership'
import { routes as TicketingRoutes } from './ticketing'
import { routes as UserRoutes } from './user'
import { routes as DonationRoutes } from './donation'
import { routes as GiftCardRoutes } from './giftCard'
import { routes as QuestionnaireRoutes } from './questionnaire'
import { routes as FoundationRoutes } from './foundation'

import NoActivity from './NoActivity'
import NotFound from './NotFound'
import NoMembership from './NoMembership'
import IdMeCallback from './IdMeCallback'

initializeGA()
const store = initializeStore()

// Make the routing information available in the store
const history = syncHistoryWithStore(browserHistory, store)

// Scroll to top of page on route change
function onRouteChange(previousState, nextState, replace, callback) {
  if (previousState.location !== nextState.location) {
    document.getElementById('root').scrollTo(0, 0)
  }
  return callback()
}

const Routes = (
  // React Redux `Provider` component to enable the `connect` function to connect to the Redux store.
  <Provider store={store}>
    <Router history={history}>
      <Route onChange={onRouteChange}>
        <Route path="/" component={Layout}>
          <IndexRedirect to="/membership" />
          {MembershipRoutes}
          {TicketingRoutes}
          {DonationRoutes}
          {GiftCardRoutes}
          {UserRoutes}
          {QuestionnaireRoutes}
          {FoundationRoutes}
        </Route>
        <Route path="/no-activity" component={NoActivity} />
        <Route path="/no-membership" component={NoMembership} />
        <Route path="/idme/callback" component={IdMeCallback} />
        <Route path="*" component={NotFound} />
      </Route>
    </Router>
  </Provider>
)

export default Routes
