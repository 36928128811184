import { useCallback, useState } from 'react'

export default function useModal(modalOverride) {
  const [isOpen, setIsOpen] = useState(false)

  const open = useCallback(() => {
    setIsOpen(true)
  }, [])

  const close = useCallback(() => {
    setIsOpen(false)
  })

  return modalOverride || { isOpen, open, close }
}
