import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import * as actions from '../../actions'
import { Link } from 'react-router'
import * as effects from 'main/effects'
import { CartAccessor } from '../components'
import { selectors } from '../../reducer'
import CheckoutPageLayout from '../components/CheckoutPageLayout'
import CartInfo from '../components/CartInfo'
import CartLineItemDetail from '../components/CartLineItemDetail'
import { routerActions } from 'react-router-redux'
import { compact } from 'lodash'
import { flashErrorMessage } from 'utils'

const propTypes = {
  items: PropTypes.array.isRequired,
  setFoundationCart: PropTypes.func.isRequired,
  flashErrorMessage: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  push: PropTypes.func.isRequired,
}

function CartDetails({
  items,
  setFoundationCart,
  flashErrorMessage,
  location: {
    query: { cartToken },
  },
  push,
}) {
  const destroyLineItem = (id) => {
    return effects
      .destroyFoundationLineItem(id, cartToken)
      .then((resp) => setFoundationCart({ success: resp }))
      .catch(() =>
        flashErrorMessage('Could not remove item. Please try again later.', {
          style: 'foundation',
        })
      )
  }

  const isAnyAdoptAnAnimal = items.some(
    (item) => item.donationType === 'adopt_an_animal'
  )

  const isCartEmpty = items.length === 0
  const isCartValid = items.every((item) => item.valid)

  return (
    <CheckoutPageLayout cartToken={cartToken} noSummary>
      <CartAccessor cartToken={cartToken} />
      <div className="mx-auto lg:w-2/3">
        <h2>Cart</h2>
        <section className="space-y-2">
          {isCartEmpty && (
            <CartInfo level="info">
              Your cart is not yet valid for checkout. Please select a donation
              from our Ways to Give page and fill out the needed information to
              proceed.
            </CartInfo>
          )}
          {!isCartValid && (
            <CartInfo level="error">
              Your cart is not yet valid for checkout. Please review the items
              below highlighted in red, and click the pencil icon to fill in the
              appropriate information to proceed.
            </CartInfo>
          )}

          {isAnyAdoptAnAnimal && isCartValid && (
            <CartInfo level="info">
              <div className="space-y-4 text-center">
                <div>Thank you for adopting an animal!</div>
                <Link
                  to={`/give/adopt-an-animal?cartToken=${cartToken}`}
                  className="inline-block px-4 py-3 font-normal text-white no-underline rounded-full w-fit bg-secondary hover:bg-secondary-700"
                >
                  Adopt Another Animal
                </Link>
              </div>
            </CartInfo>
          )}
        </section>
        <ol className="my-6 space-y-3">
          {items.map(({ id, editUrl, ...item }) => {
            return (
              <li key={id} className="m-0">
                <CartLineItemDetail
                  onRemove={() => destroyLineItem(id)}
                  onEdit={() => push(editUrl)}
                  {...item}
                />
              </li>
            )
          })}
        </ol>
        <Link
          to={`/give/cart/info??cartToken=${cartToken}`}
          className="block foundation-button-primary large"
        >
          Checkout
        </Link>
      </div>
    </CheckoutPageLayout>
  )
}

CartDetails.propTypes = propTypes

function mapStateToProps(state) {
  const cart = selectors.foundationCart(state)
  const productCategories = selectors.foundationProductCategories(state)

  if (!cart || !productCategories) {
    return {
      items: [],
    }
  }

  const items = cart.foundationLineItems
    .sort((a, b) => a.donationType < b.donationType)
    .map(
      ({
        id,
        amount,
        memorialGiving,
        tributeType,
        foundationProductId,
        foundationProductCategoryId,
        recurringScheduleType,
        valid,
        donationType,
        recipientAddress1,
        recipientAddress2,
        recipientCity,
        recipientState,
        recipientZip,
        mailToDesignation,
        inMemoryOfName,
        inscriptionMessageLine1,
        inscriptionMessageLine2,
        inscriptionMessageLine3,
        inscriptionMessageLine4,
        packageDesignation,
        packageDesignatedTo,
        adoptAnAnimalDonationType,
      }) => {
        const isTribute = memorialGiving && tributeType
        const isInMemoryOf = isTribute && tributeType === 'in_memory_of'

        const productCategory = productCategories.find(
          (fp) => fp.id == foundationProductCategoryId
        )

        const {
          id: productCategoryId,
          name: productCategoryName,
          foundationProducts: products = [],
        } = productCategory || {}

        const name = memorialGiving
          ? isInMemoryOf
            ? 'Memorial Giving'
            : 'Honorary Giving'
          : productCategoryName

        const isMailedProduct =
          donationType === 'adopt_an_animal' ||
          donationType === 'plaque' ||
          memorialGiving

        const editPath = isMailedProduct ? 'recipient-information' : 'donate'

        const editUrl = `/give/${editPath}?categoryId=${productCategoryId}&lineItemId=${id}&cartToken=${cart.token}&memorial=${memorialGiving}&edit=true`

        const mailingType =
          donationType === 'general' || donationType === 'fund'
            ? 'letter'
            : 'package'

        const recipientName =
          packageDesignation === 'me' || mailToDesignation == 'me'
            ? 'Me'
            : packageDesignatedTo

        const recipientTitle =
          adoptAnAnimalDonationType === undefined
            ? undefined
            : adoptAnAnimalDonationType === 'zoo_mama'
              ? 'Zoo Mama'
              : adoptAnAnimalDonationType === 'zoo_daddy'
                ? 'Zoo Daddy'
                : 'Zoo Parent'

        const recipientAddress =
          mailToDesignation === 'me'
            ? 'Me'
            : compact([
                recipientAddress1,
                recipientAddress2,
                recipientCity,
                recipientState,
                recipientZip,
              ]).join(', ')

        const mailing = isMailedProduct
          ? {
              type: mailingType,
              recipientAddress,
              recipientName,
              recipientTitle,
            }
          : undefined

        const tribute = isTribute
          ? {
              label: isInMemoryOf ? 'In Memory Of' : 'In Honor Of',
              name: inMemoryOfName,
              inscription: compact([
                inscriptionMessageLine1,
                inscriptionMessageLine2,
                inscriptionMessageLine3,
                inscriptionMessageLine4,
              ]).join('/'),
            }
          : undefined

        const product = products.find((p) => p.sfid == foundationProductId)

        return {
          id,
          name,
          amount,
          schedule: recurringScheduleType,
          valid,
          editUrl,
          mailing,
          tribute,
          product,
        }
      }
    )

  return {
    items,
  }
}

const mapDispatchToProps = {
  flashErrorMessage,
  setFoundationCart: actions.setFoundationCart,
  push: routerActions.push,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  CartDetails
)
