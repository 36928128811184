import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import * as reduxForm from 'redux-form'
import { modifyProps, onMount, onUpdate, waitFor } from '@launchpadlab/lp-hoc'
import { Spinner } from '@launchpadlab/lp-components'
import ReactGA from 'react-ga4'
import * as Types from 'main/types'
import { find, flashErrorMessage } from 'utils'
import {
  DualMemberForm,
  GrandparentMemberForm,
  HouseholdMemberForm,
  IndividualMemberForm,
  PatronDeclinedBenefitsForm,
} from '../forms'
import * as actions from '../actions'
import * as userActions from '../../user/actions'
import { selectors as userSelectors } from '../../user/reducer'
import * as effects from 'main/effects'
import { selectors } from '../reducer'
import { IdMeWidget } from 'components'
import { OrderReceipt } from '../components'
import { findChildren, findSecondaryMember, isMissingName } from '../helpers'
import * as apiActions from 'main/apiActions'
import * as routerActions from 'react-router-redux'
import { selectors as apiSelectors } from '@launchpadlab/lp-redux-api'
import SocietyCheckoutLayout from '../../../layout/SocietyCheckoutLayout'
import {
  MEMBERSHIP_CHECKOUT_STEPS,
  MEMBERSHIP_PATRON_DECLINE_BENEFITS_CHECKOUT_STEPS,
  getMembershipFormType,
} from '../../types'
import ModalDialog from '../../../components/ui/ModalDialog'

const propTypes = {
  childrenMissing: PropTypes.bool,
  formName: PropTypes.string.isRequired,
  initialValues: PropTypes.object,
  invalid: PropTypes.bool.isRequired,
  isVerifiedMilitary: PropTypes.bool.isRequired,
  isRenewal: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  membershipDetails: PropTypes.arrayOf(Types.member),
  namesMissing: PropTypes.bool,
  nonPrimaryMembers: PropTypes.arrayOf(Types.member),
  secondaryMemberOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    })
  ),
  selectedMembershipType: Types.membershipType.isRequired,
  submitForm: PropTypes.func.isRequired,
  updateFamilyMembershipDetails: PropTypes.func.isRequired,
  updateIndividualMembershipDetails: PropTypes.func.isRequired,
  updateMembershipDetailsFailed: PropTypes.func.isRequired,
  updateMembershipDetailsSucceeded: PropTypes.func.isRequired,
  updateMembershipDetailsPatronBenefitsDeclinedSucceeded: PropTypes.func.isRequired,
}

const defaultProps = {
  childrenMissing: false,
  currentMembershipDetails: [],
  namesMissing: false,
}

// Checks to see if a membership requires Military verification via ID.me to continue
function checkIfUserCanEnterMembershipDetails(type, isVerifiedMilitary) {
  if (type !== Types.MembershipTypes.MILITARY) return true
  return isVerifiedMilitary
}

function MembershipDetails({
  childrenMissing,
  formName,
  initialValues,
  invalid,
  isVerifiedMilitary,
  isRenewal,
  isSubmitting,
  membershipDetails,
  namesMissing,
  nonPrimaryMembers,
  secondaryMemberOptions,
  selectedMembershipType,
  submitForm,
  updateFamilyMembershipDetails,
  updateIndividualMembershipDetails,
  updateMembershipDetailsFailed,
  updateMembershipDetailsSucceeded,
  updateMembershipDetailsPatronBenefitsDeclinedSucceeded,
}) {
  const isLoading = isRenewal && !membershipDetails
  const backUrl =
    selectedMembershipType.membershipType === Types.MembershipTypes.PATRON
      ? '/membership/patron'
      : '/membership'
  const canEnterDetails = checkIfUserCanEnterMembershipDetails(
    selectedMembershipType.membershipType,
    isVerifiedMilitary
  )
  const formType = getMembershipFormType(selectedMembershipType.membershipType)
  const [ignoreIncompleteMemberNames, setIgnoreIncompleteMemberNames] =
    useState(false)

  const [isMemberConfirmationModalOpen, setIsMemberConfirmationModalOpen] =
    useState(false)

  let primaryEmailEditable = initialValues?.primaryMember?.allowEditEmail
  primaryEmailEditable ??= true

  useEffect(() => {
    ReactGA.event('view_item', {
      currency: 'USD',
      value: selectedMembershipType?.cost,
      item_name: selectedMembershipType?.displayName,
    })
  }, [])

  function confirmMemberNames() {
    if (!ignoreIncompleteMemberNames && (namesMissing || childrenMissing)) {
      setIsMemberConfirmationModalOpen(true)
      return
    }
    return submitForm(formName)
  }

  const steps =
    formType === Types.MembershipTypes.PATRON_DECLINE_BENEFITS
      ? MEMBERSHIP_PATRON_DECLINE_BENEFITS_CHECKOUT_STEPS
      : MEMBERSHIP_CHECKOUT_STEPS

  return (
    <SocietyCheckoutLayout
      progress={{ steps, currentStep: 1 }}
      backUrl={backUrl}
      title="Membership Details"
      summary={<OrderReceipt readOnly />}
      onContinue={() => {
        // Skip checking names if there are errors; submit form to surface them to the user
        if (invalid) return submitForm(formName)
        return confirmMemberNames()
      }}
      continueDisabled={isSubmitting || !canEnterDetails}
      idme={false}
    >
      <div className="font-roboto">
        {selectedMembershipType.membershipType ===
          Types.MembershipTypes.MILITARY && !isVerifiedMilitary ? (
          <>
            <div>
              You have chosen the{' '}
              <strong>{selectedMembershipType.displayName} Membership</strong>.
              Please confirm your military identification below in order to
              continue with this purchase.
            </div>
            <IdMeWidget />
          </>
        ) : (
          <>
            You have chosen the{' '}
            <strong>{selectedMembershipType.displayName} Membership</strong>.
            Please enter your membership details below.
          </>
        )}
      </div>
      <div className="p-5 bg-white">
        {canEnterDetails && (
          <>
            {isLoading ? (
              <Spinner />
            ) : (
              <>
                {formType === Types.MembershipTypes.DUAL && (
                  <DualMemberForm
                    onSubmit={updateFamilyMembershipDetails}
                    onSubmitSuccess={updateMembershipDetailsSucceeded}
                    onSubmitFail={updateMembershipDetailsFailed}
                    initialValues={initialValues}
                    secondaryMemberOptions={secondaryMemberOptions}
                    nonPrimaryMembers={nonPrimaryMembers}
                    primaryEmailEditable={primaryEmailEditable}
                  />
                )}
                {formType === Types.MembershipTypes.GRANDPARENT && (
                  <GrandparentMemberForm
                    onSubmit={updateFamilyMembershipDetails}
                    onSubmitSuccess={updateMembershipDetailsSucceeded}
                    onSubmitFail={updateMembershipDetailsFailed}
                    initialValues={initialValues}
                    numChildren={selectedMembershipType.maxChildren}
                    nonPrimaryMembers={nonPrimaryMembers}
                    secondaryMemberOptions={secondaryMemberOptions}
                    primaryEmailEditable={primaryEmailEditable}
                  />
                )}
                {formType === Types.MembershipTypes.HOUSEHOLD && (
                  <HouseholdMemberForm
                    name={`membership-${selectedMembershipType.membershipType}`}
                    onSubmit={updateFamilyMembershipDetails}
                    onSubmitSuccess={updateMembershipDetailsSucceeded}
                    onSubmitFail={updateMembershipDetailsFailed}
                    initialValues={initialValues}
                    numChildren={selectedMembershipType.maxChildren}
                    secondaryMemberOptions={secondaryMemberOptions}
                    nonPrimaryMembers={nonPrimaryMembers}
                    primaryEmailEditable={primaryEmailEditable}
                  />
                )}
                {formType === Types.MembershipTypes.INDIVIDUAL && (
                  <IndividualMemberForm
                    onSubmit={updateIndividualMembershipDetails}
                    onSubmitSuccess={updateMembershipDetailsSucceeded}
                    onSubmitFail={updateMembershipDetailsFailed}
                    initialValues={initialValues}
                    primaryEmailEditable={primaryEmailEditable}
                  />
                )}
                {formType === Types.MembershipTypes.PATRON_DECLINE_BENEFITS && (
                  <PatronDeclinedBenefitsForm
                    name={`membership-${selectedMembershipType.membershipType}`}
                    onSubmit={updateFamilyMembershipDetails}
                    onSubmitSuccess={updateMembershipDetailsPatronBenefitsDeclinedSucceeded}
                    onSubmitFail={updateMembershipDetailsFailed}
                    initialValues={initialValues}
                    secondaryMemberOptions={secondaryMemberOptions}
                    nonPrimaryMembers={nonPrimaryMembers}
                    primaryEmailEditable={primaryEmailEditable}
                  />
                )}
              </>
            )}
          </>
        )}
      </div>

      <ModalDialog
        isOpen={isMemberConfirmationModalOpen}
        onConfirm={() => {
          setIgnoreIncompleteMemberNames(true)
          setIsMemberConfirmationModalOpen(false)
          submitForm(formName)
        }}
        onClose={() => setIsMemberConfirmationModalOpen(false)}
        title="Are you sure?"
        confirmLabel="Yes"
        closeLabel="No"
        content={
          namesMissing && childrenMissing
            ? 'Some of your members appear to be missing names and you have not added any children to your membership. Are you sure you want to continue?'
            : namesMissing
              ? 'Some of your members appear to be missing names. Are you sure you want to continue?'
              : childrenMissing
                ? 'You have not added any children to your membership. Are you sure you want to continue?'
                : undefined
        }
        compact
      />
    </SocietyCheckoutLayout>
  )
}

MembershipDetails.propTypes = propTypes
MembershipDetails.defaultProps = defaultProps

function mapStateToProps(state) {
  const selectedMembershipType = selectors.selectedMembershipType(state)
  const formName =
    selectedMembershipType && selectedMembershipType.membershipType
      ? `membership-${selectedMembershipType.membershipType}`
      : ''
  return {
    selectedMembershipType: selectors.selectedMembershipType(state),
    initialValues: selectors.membershipDetailsInitialValues(state),
    isRenewal: selectors.isRenewal(state),
    formName,
    membershipForm: selectors.membershipForm(state),
    membershipDetails: selectors.membershipDetails(state),
    memFormValues: reduxForm.getFormValues(formName)(state) || {},
    nonPrimaryMembers: selectors.nonPrimaryMembers(state),
    secondaryMemberOptions: selectors.secondaryMemberOptions(state),
    membershipTypeFetchFailed: apiSelectors.isFailure(
      state,
      apiActions.REQ_MEMBERSHIP_TYPE
    ),
    isSubmitting: reduxForm.isSubmitting(formName)(state),
    invalid: reduxForm.isInvalid(formName)(state),
    isVerifiedMilitary: userSelectors.isVerifiedMilitary(state),
  }
}

const mapDispatchToProps = {
  flashErrorMessage,
  fetchMembershipType: apiActions.fetchMembershipType,
  goToAddOns: actions.goToAddOns,
  goToPayment: actions.goToPayment,
  replace: routerActions.replace,
  submitForm: reduxForm.submit,
  setAuthenticated: userActions.setAuthenticated,
  setMembershipDetails: actions.setSelectedMembershipDetails,
  setSelectedMembershipType: actions.setSelectedMembershipType,
  setMemberId: userActions.setMemberId,
}

function onComponentDidMount({
  fetchMembershipType,
  location: { query },
  setSelectedMembershipType,
  selectedMembershipType,
}) {
  const membershipTypeId = query.id || selectedMembershipType.id

  // Do not call the API if the correct membership type is already selected
  if (
    selectedMembershipType.id &&
    selectedMembershipType.id === membershipTypeId
  )
    return

  // Fetch membership and re-use existing action handler logic in reducer
  return fetchMembershipType(membershipTypeId || 0)
    .then(({ data: { attributes } }) => setSelectedMembershipType(attributes))
    .catch(() => null)
}

function redirectOnFailure({ replace, membershipTypeFetchFailed }) {
  if (membershipTypeFetchFailed) replace('/no-membership')
}

function membershipTypeSelected({ selectedMembershipType }) {
  return selectedMembershipType && selectedMembershipType.membershipType
}

function modifyMembershipTypeValues({
  selectedMembershipType,
  membershipDetails,
  memFormValues,
}) {
  const isFamilyMembership =
    selectedMembershipType.membershipType === Types.MembershipTypes.HOUSEHOLD ||
    selectedMembershipType.membershipType === Types.MembershipTypes.GRANDPARENT
  const primaryMember = find(memFormValues, 'isPrimary')
  const childrenMissing =
    isFamilyMembership && memFormValues.numberOfChildren < 1
  const secondaryMember = findSecondaryMember(memFormValues, membershipDetails)
  const children = findChildren(memFormValues, membershipDetails)

  return {
    childrenMissing,
    membershipDetails,
    namesMissing: isMissingName([...children, secondaryMember, primaryMember]),
    primaryMember,
  }
}

function modifyUpdateHandlers({
  goToAddOns,
  goToPayment,
  membershipDetails,
  membershipForm,
  setMembershipDetails,
  setMemberId,
  flashErrorMessage,
}) {
  return {
    updateIndividualMembershipDetails: (params) => {
      return Promise.resolve(setMembershipDetails(params))
        .then(() =>
          effects.createMembers({
            ...membershipForm,
            membershipDetails: params,
          })
        )
        .then((response) => {
          const primaryMember = find(response.data.attributes, 'isPrimary')
          setMemberId(primaryMember.id)
          return setMembershipDetails({ ...params, primaryMember })
        })
    },
    updateFamilyMembershipDetails: (params) => {
      const secondaryMember = findSecondaryMember(params, membershipDetails)
      const children = findChildren(params, membershipDetails)
      const updatedMemDetails = { ...params, secondaryMember, children }

      return Promise.resolve(setMembershipDetails(updatedMemDetails))
        .then(() =>
          effects.createMembers({
            ...membershipForm,
            membershipDetails: updatedMemDetails,
          })
        )
        .then((response) => {
          const primaryMember = find(response.data.attributes, 'isPrimary')
          setMemberId(primaryMember.id)
          return setMembershipDetails({ ...updatedMemDetails, primaryMember })
        })
    },
    updateMembershipDetailsSucceeded: () => goToAddOns(),
    updateMembershipDetailsPatronBenefitsDeclinedSucceeded: () => goToPayment(),
    updateMembershipDetailsFailed: (error) => {
      if (!error) return
      if (error.message) flashErrorMessage(error.message)
    },
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  onMount(onComponentDidMount),
  onUpdate(redirectOnFailure),
  waitFor(membershipTypeSelected, Spinner),
  modifyProps(modifyMembershipTypeValues),
  modifyProps(modifyUpdateHandlers)
)(MembershipDetails)
