import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { format } from 'date-fns'
import { isNil } from 'lodash'
import { Spinner } from '@launchpadlab/lp-components'
import { displayCurrency } from 'utils'
import * as apiActions from 'main/apiActions'
import SocietyHeading from 'layout/SocietyHeading'
import LinkButton from 'components/ui/LinkButton'
import ModalDialog from 'components/ui/ModalDialog'
import DownloadLink from 'components/ui/DownloadLink'
import { selectors } from '../../reducer'
import MobileWalletButtons from 'components/MobileWalletButtons'

function OrderHistory({ fetchUserOrderHistory, orderHistory, header }) {
  useEffect(() => {
    fetchUserOrderHistory()
  }, [])

  const [selectedOrder, setSelectedOrder] = useState()

  if (isNil(orderHistory)) return <Spinner />

  return (
    <div>
      {header ? header : <SocietyHeading>Order History</SocietyHeading>}
      <ol className="space-y-5">
        {orderHistory.map((order) => {
          const {
            receipt,
            transactionDate,
            total,
            paymentStatus,
            pdfUrl,
            iosLink,
            androidLink,
          } = order
          return (
            <li
              className="border-b-2 border-gray-700 border-solid"
              key={receipt}
            >
              <div className="flex justify-between p-2 mb-0">
                <div>
                  <LinkButton
                    label={receipt}
                    primary
                    onClick={() => setSelectedOrder(order)}
                  />
                  <div className="text-sm italic">
                    {format(transactionDate, 'MMMM Do, YYYY')}
                  </div>
                </div>
                <div className="text-right flex flex-row">
                  <MobileWalletButtons
                    iosLink={iosLink}
                    androidLink={androidLink}
                    size="sm"
                    className="hidden sm:block"
                  />
                  <div className="pl-5">
                    <div>
                      <span className="font-bold">
                        {displayCurrency(total)}{' '}
                      </span>
                      <span className="text-sm uppercase">{paymentStatus}</span>
                    </div>
                    <div>
                      {pdfUrl && (
                        <DownloadLink href={pdfUrl} title="Download receipt" />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <MobileWalletButtons
                    iosLink={iosLink}
                    androidLink={androidLink}
                    size="sm"
                    className="sm:hidden"
                  />
            </li>
          )
        })}
      </ol>
      <ModalDialog
        isOpen={selectedOrder}
        onClose={() => setSelectedOrder(undefined)}
        title="Customer Receipt Information"
        onConfirm={window.print}
        confirmLabel="Print receipt"
        content={
          selectedOrder && (
            <div className="font-roboto">
              <div className="flex justify-between space-x-3">
                <span className="font-bold">Receipt Number:</span>
                <span className="font-light">{selectedOrder.receipt}</span>
              </div>
              <div className="flex justify-between space-x-3">
                <span className="font-bold">Receipt Date:</span>
                <span className="font-light">
                  {format(selectedOrder.transactionDate, 'MMMM Do, YYYY')}
                </span>
              </div>
              <ol className="py-5 my-5 space-y-2 border-t-2 border-b-2 border-gray-100 border-solid">
                {selectedOrder.lineItems.map(
                  ({ description, quantity, price, total }, index) => (
                    <li className="flex justify-between mb-0" key={index}>
                      <div>
                        {description}
                        {quantity > 1 &&
                          ` ea ${displayCurrency(price)} x${quantity}`}
                      </div>
                      <div>{displayCurrency(total)}</div>
                    </li>
                  )
                )}
              </ol>
              <div className="flex justify-between space-x-3">
                <span className="font-bold">Total amount</span>
                <span className="font-light">
                  {displayCurrency(selectedOrder.total)}
                </span>
              </div>
            </div>
          )
        }
      />
    </div>
  )
}

function mapStateToProps(state) {
  return {
    orderHistory: selectors.orderHistory(state),
  }
}

const mapDispatchToProps = {
  fetchUserOrderHistory: apiActions.fetchUserOrderHistory,
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderHistory)
