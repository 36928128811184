import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@launchpadlab/lp-components'

const propTypes = {
  coupon: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired,
  onRemoveSuccess: PropTypes.func.isRequired,
  onRemoveFail: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  setIsLoading: PropTypes.func.isRequired,
}

const defaultProps = {}

function AppliedCoupon({
  coupon,
  onRemove,
  onRemoveSuccess,
  onRemoveFail,
  isLoading,
  setIsLoading,
}) {
  const removeCoupon = () => {
    setIsLoading(true)
    onRemove()
      .then((response) => {
        onRemoveSuccess(response)
      })
      .catch(onRemoveFail)
      .finally(() => setIsLoading(false))
  }

  return (
    <div className="remove-item-wrapper">
      <div className="remove-item">
        <div>
          <p className="discount">Coupon Applied: {coupon}</p>
        </div>
        <Button submitting={isLoading} onClick={removeCoupon}>
          Remove
        </Button>
      </div>
    </div>
  )
}

AppliedCoupon.propTypes = propTypes
AppliedCoupon.defaultProps = defaultProps

export default AppliedCoupon
