import { useEffect } from 'react'
import { withRouter } from 'react-router'
import * as apiActions from 'main/apiActions'
import { selectors } from '../reducer'
import { connect } from 'react-redux'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import * as Types from 'main/types'

const TicketCartListener = ({
  location,
  router,
  ticketCart,
  fetchOrCreateTicketCart,
}) => {
  const { cartToken } = location.query
  useEffect(() => {
    if (!cartToken || !ticketCart) {
      // eslint-disable-next-line no-inner-declarations
      async function initializeTicketCart(token) {
        const { data } = await fetchOrCreateTicketCart(token)
        const responseCartToken = data.additionalData.cartToken

        const searchData = new URLSearchParams(location.search)
        searchData.set('cartToken', responseCartToken)

        router.replace(`${location.pathname}?${searchData.toString()}`)
      }

      initializeTicketCart(cartToken)
    }
  }, [cartToken, ticketCart])

  return null
}

function mapStateToProps(state) {
  return {
    ticketCart: selectors.ticketCart(state),
  }
}

const mapDispatchToProps = {
  fetchOrCreateTicketCart: apiActions.fetchOrCreateTicketCart,
}
TicketCartListener.propTypes = {
  location: PropTypes.object,
  ticketCart: Types.ticketCart,
  fetchOrCreateTicketCart: PropTypes.func,
  router: PropTypes.object,
}
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(TicketCartListener)
