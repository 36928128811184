import React, { useState } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { uniqBy, intersection, xor } from 'lodash'
import { Spinner } from '@launchpadlab/lp-components'
import { onMount } from '@launchpadlab/lp-hoc'
import * as apiActions from 'main/apiActions'
import { selectors } from '../reducer'
import classNames from 'classnames'
import SocietyCheckoutLayout from '../../../layout/SocietyCheckoutLayout'
import { TICKETING_CHECKOUT_STEPS } from '../../types'
import SocietyProductGrid from '../../../layout/SocietyProductGrid'
import getTicketingRedirectUrl from '../../../utils/getTicketingRedirectUrl'

function SelectTicketActivity({ tags, products, selectedCategory }) {
  const [selectedTags, setSelectedTags] = useState([])

  const selectedProducts =
    selectedTags.length === 0
      ? products
      : products.filter((activity) => {
          let tagIds = activity.tags.map((tag) => tag.id)
          return !!intersection(tagIds, selectedTags).length
        })

  const toggleTag = (tagId) => {
    // Returns the symmetrical difference, all elements that are not common to the arrays.
    // xor([1,2,3], [4]) => [1,2,3,4] No common element is found. Returns all of them together.
    // xor([1,2,3,4], [4]) => [1,2,3] A common element is found. Returns the rest, removing 4 from the array
    const newSelectedTags = xor(selectedTags, [tagId])
    setSelectedTags(newSelectedTags)
  }

  return !selectedCategory ? (
    <Spinner />
  ) : (
    <SocietyCheckoutLayout
      progress={{ steps: TICKETING_CHECKOUT_STEPS, currentStep: 0 }}
      title={`Select ${selectedCategory.categoryName}`}
      introduction={
        <p className="m-0 text-xl md:mx-10 md:text-2xl">
          You have chosen <strong>{selectedCategory.categoryName}</strong>.
          Please select the event you would like to attend below.
        </p>
      }
      backUrl="/ticketing"
    >
      {tags.length !== 0 && (
        <div className="tag-section">
          <h2>Filter by tags</h2>
          <div className="tag-container">
            {tags.map((tag) => (
              <div
                key={tag.id}
                className={classNames('tag', {
                  'tag-selected': selectedTags.includes(tag.id),
                })}
                onClick={() => toggleTag(tag.id)}
              >
                {tag.name}
              </div>
            ))}
          </div>
        </div>
      )}

      {selectedProducts ? (
        <SocietyProductGrid products={selectedProducts} singleColumn />
      ) : (
        <Spinner />
      )}
    </SocietyCheckoutLayout>
  )
}

function mapStateToProps(state, props) {
  const {
    location: {
      query: { categoryId },
    },
  } = props

  const categories = selectors.displayedTicketActivities(state)
  const activities = selectors.displayedTicketActivities(state)

  if (!categories || !activities)
    return {
      products: [],
      tags: [],
      selectedCategory: undefined,
    }

  const selectedCategory = categories.find(
    (category) => category.categoryId === parseInt(categoryId)
  )

  const selectedActivities = activities
    .filter((type) => type.categoryId === parseInt(categoryId))
    .sort((a, b) => a.position - b.position)

  const products = selectedActivities.map((category) => {
    const encodedSignInRedirectPath = encodeURIComponent(
      getTicketingRedirectUrl({
        activityId: category.id,
      })
    )

    return {
      id: category.id,
      featured: category.featured,
      featuredLabel: category.featuredDisplayLabel,
      imageUrl: category.activityImage,
      description: category.caption,
      productUrl: category.learnMoreUrl,
      selectUrl: `/ticketing/details?activityId=${category.id}&backPath=activity`,
      signInUrl: `/sign-in?redirectUrl=${encodedSignInRedirectPath}`,
      name: category.displayName,
      fullDescription: category.infoModalText,
      isLoginRequired: category.requireLogin,
    }
  })

  const tags = uniqBy(
    selectedActivities.flatMap((activity) => activity.tags),
    'id'
  )

  return {
    products,
    tags,
    selectedCategory,
  }
}

const mapDispatchToProps = {
  fetchTicketActivities: apiActions.fetchTicketActivities,
}

function fetchTicketActivities({ fetchTicketActivities, ticketCategories }) {
  if (!ticketCategories) fetchTicketActivities()
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  onMount(fetchTicketActivities)
)(SelectTicketActivity)
