import React, { useState } from 'react'
import Form, { FormConstraints, useForm } from '../../../../components/Form'
import Button from '../../../../components/ui/Button'
import SocietyHeading from '../../../../layout/SocietyHeading'
import * as actions from '../../actions'
import { connect } from 'react-redux'
import SocietyPageLayout from '../../../../layout/SocietyPageLayout'
import { flashErrorMessage } from 'utils'
import { captureException } from '@sentry/react'
import HelpText from './HelpText'
import { Link } from 'react-router'

import Users from '../../../ticketing/utils/users'

const PASSWORD_FORM_FIELDS = {
  password: {
    label: 'Password',
    constraints: [FormConstraints.required],
    placeholder: 'Password',
    type: 'password',
  },
}

const PasswordForm = connect()(({
  email,
  dispatch,
  handleClearEmail,
  handleForgotPassword,
}) => {
  const form = useForm(PASSWORD_FORM_FIELDS, {})
  const [error, setError] = useState(null)

  async function handleSubmit() {
    const { password } = form.values

    if (form.validate()) {
      try {
        const account = await Users.authenticate({ email, password })

        dispatch(actions.setUser(account))
      } catch (error) {
        if (error.status === 401) {
          setError(
            'The password you entered is incorrect. Please review and try again.'
          )
        } else {
          captureException(error)
          dispatch(
            flashErrorMessage(
              'Something went wrong. Please review and try again'
            )
          )
        }
      }
    }
  }

  return (
    <SocietyPageLayout title="Welcome Back">
      <section className="p-5 mx-auto space-y-10 bg-white shadow pb-7 sm:w-2/3">
        <SocietyHeading>Sign In</SocietyHeading>

        <section className="space-y-5">
          <div className="space-y-1">
            <label className="block text-base">Email</label>
            <div>{email}</div>
          </div>

          <Form {...form} onSubmit={handleSubmit} />
        </section>

        <footer className="relative pb-3">
          <Button
            type="primary"
            label="Continue"
            size="xl"
            width="full"
            onClick={handleSubmit}
          />

          {error && (
            <div className="absolute inset-x-0 text-center top-full text-danger">
              {error}
            </div>
          )}
        </footer>
        <div className="text-right space-y-5 text-primary space-x-4">
          <Link onClick={handleClearEmail}>Sign in with a different user</Link>
          <Link onClick={handleForgotPassword}>Forgot Password?</Link>
        </div>
      </section>
      <HelpText />
    </SocietyPageLayout>
  )
})

export default PasswordForm
