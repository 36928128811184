import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import * as apiActions from 'main/apiActions'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { selectors } from '../reducer'
import * as effects from 'main/effects'
import * as Types from 'main/types'
import { Spinner } from '@launchpadlab/lp-components'
import * as actions from '../actions'
import * as routerActions from 'react-router-redux'
import { SelectSchoolForm } from '../forms'
import SocietyCheckoutLayout from '../../../layout/SocietyCheckoutLayout'
import { TICKETING_CHECKOUT_STEPS } from '../../types'
import { OrderReceipt } from '../components'
import SocietyHeading from '../../../layout/SocietyHeading'
import { submit } from 'redux-form'
import { flashErrorMessage } from 'utils'

const propTypes = {
  location: PropTypes.object.isRequired,
  fetchSchools: PropTypes.func.isRequired,
  flashErrorMessage: PropTypes.func.isRequired,
  submitSelectSchoolForm: PropTypes.func.isRequired,
  fetchOrCreateTicketCart: PropTypes.func.isRequired,
  schools: PropTypes.arrayOf(Types.school),
  ticketCart: Types.ticketCart,
  setTicketCart: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
}

const defaultProps = {
  schools: [],
  ticketCart: null,
}

function SelectSchool({
  location,
  fetchSchools,
  fetchOrCreateTicketCart,
  setTicketCart,
  schools,
  ticketCart,
  push,
  flashErrorMessage,
  submitSelectSchoolForm,
}) {
  const cartToken = location.query.cartToken
  const activityId = location.query.activityId

  useEffect(() => {
    fetchSchools()
    if (!ticketCart) {
      fetchOrCreateTicketCart(cartToken)
    }
  }, [])

  if (!ticketCart || schools.length < 1 || !activityId) return <Spinner />

  const options = schools.map((school) => ({
    value: school.id,
    label: `${school.name} ${school.zip ? `— ${school.zip}` : ''}`,
  }))

  const onSchoolSelect = (params) => {
    const { school } = params
    const forwardUrl = `/ticketing/details?activityId=${activityId}&cartToken=${ticketCart.token}`

    if (!school) {
      return flashErrorMessage('You must choose a school from the dropdown')
    }

    effects
      .updateTicketCart(ticketCart.token, {
        school_id: school.value,
        partialPayment: true,
      })
      .then((resp) => {
        setTicketCart({ success: resp })
        return push(forwardUrl)
      })
      .catch((params) => {
        const schoolErrorMessage =
          (params &&
            params.errors &&
            params.errors.message &&
            `${params.errors.message}`) ||
          'Something went wrong setting your school. Please try again later.'
        return flashErrorMessage(schoolErrorMessage)
      })
  }

  return (
    <SocietyCheckoutLayout
      progress={{ steps: TICKETING_CHECKOUT_STEPS, currentStep: 1 }}
      title="Select School"
      backUrl="/ticketing"
      summary={<OrderReceipt />}
      onContinue={submitSelectSchoolForm}
    >
      <p>You have chosen School Field Trip. Please choose your school below.</p>

      <SocietyHeading htmlFor="schools">Select School</SocietyHeading>
      <SelectSchoolForm onSubmit={onSchoolSelect} schoolOptions={options} />
      <p>
        If your school isn't listed, please contact{' '}
        <a
          href="mailto:educate@omahazoo.com"
          className="text-primary text-base"
        >
          educate@omahazoo.com
        </a>
        .
      </p>
    </SocietyCheckoutLayout>
  )
}

SelectSchool.propTypes = propTypes
SelectSchool.defaultProps = defaultProps

const mapDispatchToProps = {
  fetchSchools: apiActions.fetchSchools,
  fetchOrCreateTicketCart: apiActions.fetchOrCreateTicketCart,
  setTicketCart: actions.setTicketCart,
  push: routerActions.push,
  submitSelectSchoolForm: () => submit('select-school'),
  flashErrorMessage,
}

function mapStateToProps(state) {
  return {
    schools: selectors.schools(state),
    ticketCart: selectors.ticketCart(state),
  }
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  SelectSchool
)
