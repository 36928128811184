import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import * as Types from 'main/types'
import * as apiActions from 'main/apiActions'
import { selectors } from '../reducer'
import { TicketPrice } from '../components'

const propTypes = {
  ticketCart: Types.ticketCart,
  fetchOrCreateTicketCart: PropTypes.func.isRequired,
  addOn: Types.ticketAddOn.isRequired,
  lineItemId: PropTypes.string.isRequired,
  disableTicketPriceActions: PropTypes.bool.isRequired,
  untimedPrices: PropTypes.arrayOf(Types.ticketPrice),
}

const defaultProps = {
  ticketCart: null,
}

const EmptyState = () => (
  <p className="explanation-text">
    Sorry! There are no products available for the chosen add-on.
  </p>
)

function UntimedPrices({
  ticketCart,
  fetchOrCreateTicketCart,
  addOn,
  lineItemId,
  untimedPrices,
  disableTicketPriceActions,
}) {
  useEffect(() => {
    if (!ticketCart) {
      fetchOrCreateTicketCart()
    }
  }, [])

  // The parent LineItem:
  const currentCartLineItem =
    ticketCart &&
    ticketCart.lineItems.find((lin) => lin.id === parseInt(lineItemId))

  const currentCartAddOnLineItem =
    currentCartLineItem &&
    currentCartLineItem.addOnLineItems.find((lin) => lin.productId === addOn.id)

  return (
    <div>
      {untimedPrices && untimedPrices.length > 0 ? (
        <div>
          <div>
            <h2>Select Ticket Amount</h2>
            {/* {ticketActivity.showTicketsRemaining && (
                // TODO - implement number of tickets left to select
                // <p>{numTicketsLeftToSelect} Tickets Left</p>
              )} */}
          </div>
          {untimedPrices.map((price) => {
            const existingUntimedPrices =
              currentCartAddOnLineItem &&
              currentCartAddOnLineItem.lineItemPrices.filter(
                (lip) => lip.priceId === price.id
              )
            return (
              <TicketPrice
                key={price.id}
                ticketPrice={price}
                ticketActivity={addOn}
                ticketActivityTime={null}
                lineItem={currentCartAddOnLineItem}
                quantity={
                  existingUntimedPrices ? existingUntimedPrices.length : 0
                }
                disableAdd={disableTicketPriceActions}
                disableRemove={disableTicketPriceActions}
              />
            )
          })}
        </div>
      ) : (
        <EmptyState />
      )}
    </div>
  )
}

UntimedPrices.propTypes = propTypes
UntimedPrices.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    ticketCart: selectors.ticketCart(state),
    disableTicketPriceActions: selectors.disableTicketPriceActions(state),
  }
}

const mapDispatchToProps = {
  fetchOrCreateTicketCart: apiActions.fetchOrCreateTicketCart,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  UntimedPrices
)
