import React, { useEffect, useState } from 'react'
import { FormConstraints } from '../../../components/Form'
import * as actions from '../actions'
import { connect } from 'react-redux'
import { selectors as userSelectors } from '../reducer'
import VerifyEmail from './VerifyEmail'
import Users from '../../ticketing/utils/users'
import { compose } from 'redux'
import CreateUserForm from './UniversalSignIn/CreateUserForm'
import EmailForm from './UniversalSignIn/EmailForm'
import ForgotPasswordForm from './UniversalSignIn/ForgotPasswordForm'
import MembershipAssociated from './UniversalSignIn/MembershipAssociated'
import PasswordForm from './UniversalSignIn/PasswordForm'
import ResetPasswordForm from './UniversalSignIn/ResetPasswordForm'
import VerifyMembership from './UniversalSignIn/VerifyMembership'

export const CREATE_USER_FORM_FIELDS = {
  password: {
    label: 'Password',
    constraints: [FormConstraints.required],
    placeholder: 'Password',
    type: 'password',
  },
  passwordConfirmation: {
    label: 'Confirm Password',
    constraints: [FormConstraints.required, FormConstraints.match('password')],
    placeholder: 'Password',
    type: 'password',
  },
}

export const EMAIL_FORM_FIELDS = {
  email: {
    label: 'Email',
    constraints: [FormConstraints.required, FormConstraints.email],
    placeholder: 'example@omahazoo.com',
  },
}

const UniversalSignIn = ({
  location,
  router,
  isAuthenticated,
  isGuestFlowPreferred,
  setUserGuestFlowPreferred,
}) => {
  const { redirectUrl = '/ticketing', guestFlowDisabled = false } =
    location.query

  const [centamanCustomerId, setCentamanCustomerId] = useState(null)
  const [email, setEmail] = useState()
  const [existingAccountEmail, setExistingAccountEmail] = useState(null)
  const [existingUser, setExistingUser] = useState()
  const [forgotPassword, setForgotPassword] = useState(false)
  const [memberLastName, setMemberLastName] = useState(null)
  const [memberNumber, setMemberNumber] = useState(null)
  const [verified, setVerified] = useState()
  const [newUserNotMember, setNewUserNotMember] = useState(null)

  useEffect(() => {
    if (isAuthenticated || (isGuestFlowPreferred && !guestFlowDisabled)) {
      router.push(redirectUrl)
    }
  }, [router, isAuthenticated, isGuestFlowPreferred, redirectUrl])

  if (location.query.resetPasswordToken) {
    // if we've been sent a reset password token, show the reset password form
    return (
      <ResetPasswordForm
        resetPasswordToken={location.query.resetPasswordToken}
      />
    )
  } else if (!email) {
    // if we don't have an email address, show the email form
    return (
      <EmailForm
        guestFlowDisabled={!!guestFlowDisabled}
        onUserExists={(email, { emailVerified, centamanCustomerId }) => {
          setEmail(email)
          setExistingUser(true)
          setCentamanCustomerId(centamanCustomerId)
          setVerified(emailVerified)
          if (!emailVerified) {
            Users.sendVerificationEmail(
              email,
              window.location.origin,
              redirectUrl
            )
          }
        }}
        onMembersipExists={(email, { centamanCustomerId }) => {
          setCentamanCustomerId(centamanCustomerId)
          setEmail(email)
          setExistingUser(false)
          setVerified(false)
        }}
        onMembersipNotFound={(
          email,
          { centamanCustomerId, newUserNotMember }
        ) => {
          setCentamanCustomerId(centamanCustomerId)
          setEmail(email)
          setExistingUser(false)
          setVerified(false)
          setNewUserNotMember(newUserNotMember)
        }}
        setUserGuestFlowPreferred={setUserGuestFlowPreferred}
      />
    )
  } else if (forgotPassword) {
    // if the user clicked the forgot password link
    return <ForgotPasswordForm email={email} redirectUrl={redirectUrl} />
  } else if (
    (centamanCustomerId === null || newUserNotMember) &&
    !existingAccountEmail
  ) {
    // no active membership found in centaman

    return (
      <VerifyMembership
        email={email}
        centamanCustomerId={centamanCustomerId}
        redirectUrl={redirectUrl}
        onMembershipVerified={({
          centamanCustomerId,
          memberNumber,
          membershipLastName,
        }) => {
          setCentamanCustomerId(centamanCustomerId)
          setMemberNumber(memberNumber)
          setMemberLastName(membershipLastName)
          setNewUserNotMember(null)
        }}
        onMemberUserExists={(email) => {
          setExistingAccountEmail(email)
          setNewUserNotMember(null)
        }}
        onCustomerNotMember={() => {
          setNewUserNotMember(null)
        }}
      />
    )
  } else if (existingAccountEmail) {
    // when the membership has already been associated with a different email
    return (
      <MembershipAssociated
        existingAccountEmail={existingAccountEmail}
        email={email}
        onClearAll={() => {
          //clear all states
          setCentamanCustomerId(null)
          setEmail(null)
          setExistingAccountEmail(null)
          setExistingUser(null)
          setMemberLastName(null)
          setForgotPassword(false)
          setMemberNumber(null)
          setVerified(null)
        }}
        redirectUrl={redirectUrl}
      />
    )
  } else if (!existingUser) {
    // if we found a customer in centaman but not a User
    return (
      <CreateUserForm
        email={email}
        membershipId={memberNumber}
        membershipLastName={memberLastName}
        redirectUrl={redirectUrl}
        handleClearEmail={() => setEmail(null)}
        handleCreateUser={() => setExistingUser(true)}
      />
    )
  } else if (!verified) {
    return <VerifyEmail email={email} redirectUrl={redirectUrl} />
  } else {
    return (
      <PasswordForm
        email={email}
        handleForgotPassword={() => setForgotPassword(true)}
        handleClearEmail={() => setEmail(null)}
      />
    )
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: userSelectors.isAuthenticated(state),
  isGuestFlowPreferred: userSelectors.isGuestFlowPreferred(state),
})

const mapDispatchToProps = {
  setUserGuestFlowPreferred: actions.setUserGuestFlowPreferred,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  UniversalSignIn
)
