import Routes from 'main/routes'
import ReactDOM from 'react-dom'
import 'scss/application.scss'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/material.css'
import 'scss/tailwind.css'
import 'react-datepicker/dist/react-datepicker.css'
import { initializeSentry } from 'services/sentry'

initializeSentry()
ReactDOM.render(Routes, document.getElementById('root'))
