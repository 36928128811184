import React, { useState } from 'react'
import ModalDialog from './ui/ModalDialog'
import CheckMarkIcon from 'images/checkmark.svg'
import LinkButton from './ui/LinkButton'

function TermsCheckbox({ label, id, checked, onChange, error, children }) {
  const [isOpen, setIsOpen] = useState()

  return (
    <div className="space-y-2">
      <div className="flex items-center">
        <label className="h-auto m-0 cursor-pointer " id={id}>
          <input
            type="checkbox"
            className="fixed sr-only"
            checked={checked}
            aria-labelledby={id}
            onChange={(event) => onChange(event.target.checked)}
          />
          <span className="inline-flex items-center justify-center p-1 mr-4 border border-gray-200 border-solid rounded w-7 h-7">
            {checked && (
              <img
                src={CheckMarkIcon}
                className="h-4 mr-0.5 mb-0.5 aspect-square"
              />
            )}
          </span>
        </label>
        <span>
          I have read and agreed to the{' '}
          <LinkButton
            label={label}
            onClick={(event) => {
              event.preventDefault()
              setIsOpen(true)
            }}
            secondary
            sm
          />
          .
        </span>
      </div>
      <div className="text-xs text-danger">{error}</div>
      <ModalDialog
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onConfirm={() => {
          onChange(true)
          setIsOpen(false)
        }}
        title={label}
        content={children}
      />
    </div>
  )
}

export default TermsCheckbox
