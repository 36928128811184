import React from 'react'
import Select from 'react-select'

function SearchSelectInput(props) {
  const { input, options } = props

  const fontStyle = {
    fontFamily: '"Roboto", sans-serif',
    fontSize: '0.92857rem',
    color: '#000',
  }

  const placeholderStyle = {
    fontStyle: 'italic',
  }
  const customStyles = {
    placeholder: (provided) => ({
      ...provided,
      ...fontStyle,
      ...placeholderStyle,
    }),
    option: (provided) => ({ ...provided, ...fontStyle }),
    control: (provided) => ({
      ...provided,
      ...fontStyle,
      border: '1px solid #5d5d5d',
      boxShadow: '0',
      '&:hover': { borderColor: '#5d5d5d' },
    }),
  }

  return (
    <Select
      {...input}
      onChange={(value) => {
        input.onChange(value)
      }}
      onBlur={() => {
        // we want to do nothing onBlur so we don't
        // have onBlur conflict with onChange
      }}
      options={options}
      styles={customStyles}
      className={'search-select-schools'}
      placeholder="Start typing school name"
    />
  )
}

export default SearchSelectInput
