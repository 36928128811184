import React from 'react'
import ButtonLink from '../components/ui/ButtonLink'

function NoActivity() {
  return (
    <div className="flex flex-col items-center justify-center p-10 m-10 bg-white">
      <h1>Animal not found. Come look at the Zoo!</h1>
      <p>
        The event you were looking for couldn't be found or is currently
        unavailable.
      </p>
      <ButtonLink secondary to="/ticketing" label=" Go Back" />
    </div>
  )
}

export default NoActivity
