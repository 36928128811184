import * as Types from '../main/types'
import { get } from 'lodash'

export default function mapOrderData(data) {
  const {
    addOns = [],
    coupon = {},
    membershipDetails = {},
    membershipType = {},
    sellerCode = {},
    paymentResponse = {},
    donation = {},
    giftCard = {},
  } = data
  const primaryMember = { ...membershipDetails.primaryMember, isPrimary: true }
  const secondaryMember =
    membershipType.membershipType !== Types.MembershipTypes.INDIVIDUAL
      ? {
          ...get(membershipDetails, 'secondaryMember'),
          firstName:
            get(membershipDetails, 'secondaryMember.firstName') ||
            `${primaryMember.firstName}(2)`,
          lastName:
            get(membershipDetails, 'secondaryMember.lastName') ||
            primaryMember.lastName,
          isSecondary: true,
        }
      : {}
  const listedMembers = [primaryMember, secondaryMember]
  const children = mapChildArray(membershipDetails.children, primaryMember)
  const orderInfo = {
    addOns,
    children,
    coupon: coupon.couponCode,
    employeeId: coupon.employeeId,
    fnboDiscount: data.fnboDiscount,
    isRenewal: data.isRenewal,
    listedMembers,
    membershipTypeId: membershipType.id,
    primaryMemberId: primaryMember.id,
    primaryMemberNumber: primaryMember.memberNumber,
    sellerCode: sellerCode.sellerCode,
    donationAmount: Number(donation.amount) || 0,
  }

  return {
    order: {
      giftCardNumber: giftCard.giftCardNumber || null,
      orderInfo,
      cardToken: paymentResponse.cardToken,
      customerInfo: paymentResponse,
    },
  }
}

function mapChildArray(childArray = [], primaryMember) {
  if (!primaryMember.id) return childArray
  return childArray.map((child, idx) => ({
    ...child,
    firstName: child.firstName || `Child-${idx + 1}`,
    lastName: child.lastName || `${primaryMember.id} ${primaryMember.lastName}`,
  }))
}
