import React from 'react'
import { Route, IndexRoute } from 'react-router'
import * as Views from './views'

const Routes = (
  <Route path="ticketing">
    <IndexRoute component={Views.SelectCategory} />
    <Route path="activity" component={Views.SelectTicketActivity} />
    <Route path="schools" component={Views.SelectSchool} />
    <Route path="camps" component={Views.SelectCamps} />
    <Route path="camps/attendees" component={Views.RegisterAttendees} />
    <Route
      path="details"
      component={(props) => {
        // force re-mount component for every distinct editing request
        const key = props.location.query.editLineItemId
        return <Views.TicketActivityDetails key={key} {...props} />
      }}
    />
    <Route path="add-ons" component={Views.SelectAddOnCategory} />
    <Route
      path="add-ons-activity"
      component={(props) => {
        // force re-mount component for every distinct editing request
        const key = props.location.query.editLineItemId
        return <Views.AddOnCategoryDetails key={key} {...props} />
      }}
    />
    <Route path="cart" component={Views.ViewCart} />
    <Route path="pay" component={Views.PaymentDetails} />
    <Route path="confirmation" component={Views.Confirmation} />
  </Route>
)

export default Routes
