import React, { useEffect, useState, useCallback, createContext } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { gpiSubmitStyles } from './GpiSubmit'
import { flashErrorMessage } from 'utils'

const propTypes = {
  onTokenSuccess: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  flashErrorMessage: PropTypes.func.isRequired,
  submitBtnText: PropTypes.string,
  gpiFormHasBeenRendered: PropTypes.bool, // allow the GPI scripts to wait until their corresponding forms are rendered
  isFoundation: PropTypes.bool, // use foundation styling
  isDisabled: PropTypes.bool, // whether the GPI payments experience should be disabled to the user
  gpiConfigType: PropTypes.oneOf(['ticketing', 'membership', 'foundation']), // which API key to use in configuring GPI
}

const defaultProps = {
  submitBtnText: 'Submit',
  gpiFormHasBeenRendered: true,
  isFoundation: false,
  isDisabled: false,
  gpiConfigType: 'ticketing',
}

export const PaymentFormContext = createContext({})

// Our GPI Wrapper can be embedded anywhere in the application and is responsible for
// (1) Loading the payment scripts
// (2) Configuring and initializing the payment form fields
// (3) Providing childen components with important information on the status of payment entry via Context
// NOTE: the form elements from ./GpiForm must be rendered BEFORE the GPI JavaScript is run
// if there is a conditional statement that determines whether the payment fields should be rendered,
// wait to render our wrapper component until the condition has been met or set `formIsRendered` to false
// and then switch it to true once the condition is met that will also render the ./GpiForm component
function GpiWrapper({
  onTokenSuccess,
  submitBtnText,
  children,
  flashErrorMessage,
  gpiFormHasBeenRendered,
  isFoundation,
  isDisabled,
  gpiConfigType,
}) {
  // keep track of whether the GPI Global Payments Script has been loaded on the page
  const [gpiLoaded, setGpiLoaded] = useState(false)
  const [numberValid, setNumberValid] = useState(false)
  const [expirationValid, setExpirationValid] = useState(false)
  const [cvvValid, setCvvValid] = useState(false)
  const [hasBeenSubmitted, setHasBeenSubmitted] = useState(false)
  const [formErrors, setFormErrors] = useState({})
  const [windowGpiForm, setWindowGpiForm] = useState(null)

  // memo-ized function to load the GPI Global Payments Script
  const loadGpiFunc = useCallback(() => {
    // Replace any existing gpi script on remount (potentially move to unmount)
    const script = document.getElementById('gpi-script')
    if (script) script.parentNode.removeChild(script)

    const gpiScript = document.createElement('script')
    gpiScript.setAttribute('id', 'gpi-script')
    gpiScript.setAttribute(
      'src',
      `https://js.paygateway.com/secure_payment/v1/globalpayments.js`
    )
    gpiScript.async = false
    gpiScript.onload = () => {
      setGpiLoaded(true)
    }

    document.head.appendChild(gpiScript)
  }, [setGpiLoaded])

  // Load the GPI Global Payments Script once the form that it manipulates has been rendered to the DOM
  useEffect(() => {
    if (gpiFormHasBeenRendered) {
      loadGpiFunc()
    }
  }, [loadGpiFunc, gpiFormHasBeenRendered])

  // Once the GPI Global Payments Script is loaded and the function is loaded into the window
  // configure the GlobalPayments script with our credentials and initialize the form fields
  const globalPaymentsExists = !!window.GlobalPayments
  useEffect(() => {
    if (gpiLoaded && globalPaymentsExists) {
      window.GlobalPayments.configure({
        'X-GP-Api-Key': process.env.REACT_APP_GPI_API_KEY,
        'X-GP-Environment':
          process.env.REACT_APP_GPI_ENV === 'production' ? 'prod' : 'test',
        enableAutocomplete: true,
      })
      let gpiForm = window.GlobalPayments.ui.form({
        fields: {
          'card-number': {
            target: '#card-number',
            placeholder: '•••• •••• •••• ••••',
          },
          'card-expiration': {
            target: '#card-expiration',
            placeholder: 'MM / YYYY',
          },
          'card-cvv': {
            target: '#card-cvv',
            placeholder: '•••',
          },
          submit: {
            target: '#submit',
            text: submitBtnText,
          },
        },

        styles: {
          input: {
            margin: '0',
            width: '100%',
            'background-color': '#ffffff',
            border: '1px solid lightgray',
            'border-radius': '2px',
            'box-sizing': 'border-box',
            'font-family': '"Roboto", sans-serif',
            'font-weight': '400',
            'font-size': '13px',
            padding: '13px',
          },
          ...gpiSubmitStyles({ isFoundation }),
        },
      })
      setWindowGpiForm(gpiForm)

      // NOTE: all `.on` listener event functions get memo-ized with the current values of state
      // at the time they get *initially* run. Be careful to NOT use any changing state variables
      // inside of these functions. Functions or variables that DO NOT change are OK to use.

      // success pathway of CC tokenization
      gpiForm.on('token-success', (resp) => {
        setHasBeenSubmitted(true)
        const cardToken = resp.temporary_token
        const expirationDate = `${resp.card.expiry_month} / ${resp.card.expiry_year}`
        const maskedCardNumber = resp.card.masked_card_number
        const lastFourDigits = maskedCardNumber.slice(
          maskedCardNumber.length - 4,
          maskedCardNumber.length
        )
        const cardType = resp.card.type

        onTokenSuccess({
          cardToken,
          expirationDate,
          maskedCardNumber,
          lastFourDigits,
          cardType,
        })
      })

      // failure pathways of CC tokenization
      gpiForm.on('token-error', () => {
        setHasBeenSubmitted(true)
        flashErrorMessage('Something went wrong. Please review and try again')
      })
      gpiForm.on('error', () => {
        setHasBeenSubmitted(true)
        flashErrorMessage('Something went wrong. Please review and try again')
      })

      // realtime checks for CC validity
      gpiForm.on('card-number-test', (resp) => {
        setNumberValid(!!resp.valid)
      })

      gpiForm.on('card-expiration-test', (resp) => {
        setExpirationValid(!!resp.valid)
      })
      gpiForm.on('card-cvv-test', (resp) => {
        setCvvValid(!!resp.valid)
      })
    }
  }, [gpiLoaded, globalPaymentsExists, gpiConfigType])

  useEffect(() => {
    if (windowGpiForm && gpiLoaded) {
      const submitStyles = gpiSubmitStyles({
        isFoundation,
        isDisabled,
      })

      windowGpiForm.addStylesheet(submitStyles)
    }
  }, [windowGpiForm, gpiLoaded, isDisabled])

  // update the form errors state whenever an underlying data attribute status changes
  useEffect(() => {
    setFormErrors({
      'card-number': numberValid ? null : 'Please enter a valid card number',
      'card-expiration': expirationValid
        ? null
        : 'Please enter a valid card expiration date',
      'card-cvv': cvvValid ? null : 'Please enter a valid card CVV code',
    })
  }, [numberValid, expirationValid, cvvValid])

  // PaymentFormContext comes with a several keys:
  // (1) `formErrors`: an object with error messages for any invalid payment fields
  // (2) `hasBeenSubmitted`: a boolean indicating whether the form has been submitted by the user
  // (3) `arePaymentFieldsLoading`: a boolean indicating whether the payment form JavaScript is loading
  // (4) `isDisabled`: a boolean indicating whether the GPI payments experience is currently disabled to the user
  return (
    <PaymentFormContext.Provider
      value={{
        formErrors,
        hasBeenSubmitted,
        arePaymentFieldsLoading: !gpiLoaded,
        isDisabled,
      }}
    >
      {children}
    </PaymentFormContext.Provider>
  )
}

GpiWrapper.propTypes = propTypes
GpiWrapper.defaultProps = defaultProps

const mapDispatchToProps = {
  flashErrorMessage,
}
function mapStateToProps() {
  return {}
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(GpiWrapper)
