import React, { useState } from 'react'
import ModalDialog from '../../../components/ui/ModalDialog'
import Form, {
  useUserDefinedForm,
  UserDefinedFields,
} from '../../../components/Form'

function BookingDetailsModal({ isOpen, onConfirm, onClose, udfs, udfValues }) {
  const [isLoading, setIsLoading] = useState(false)

  const form = useUserDefinedForm({}, {}, udfs, udfValues)

  async function handleConfirm() {
    setIsLoading(true)
    await onConfirm(UserDefinedFields.fromFormToValues(form))
    setIsLoading(false)
  }

  function handleClose() {
    onClose()
    form.reset()
  }

  return (
    <ModalDialog
      isOpen={isOpen}
      onConfirm={handleConfirm}
      confirmDisabled={!form.isValid}
      confirmLoading={isLoading}
      onClose={handleClose}
      title="Booking Details"
      content={<Form {...form} />}
    />
  )
}

export default BookingDetailsModal
