import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { lpForm } from '@launchpadlab/lp-form'
import { Input } from '@launchpadlab/lp-components'
import { Field, FormSection, FieldArray } from 'redux-form'
import { validateNested } from 'utils'
import { format } from 'date-fns'
import { BirthDateInput } from 'components'
import SocietyHeading from 'layout/SocietyHeading'
import Button from 'components/ui/Button'

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  initialValues: PropTypes.object.isRequired,
}

const defaultProps = {}

const ChildMember = ({ fields }) => {
  return fields.map((member, idx) => (
    <div className="p-5 bg-white shadow" key={idx}>
      <fieldset>
        <SocietyHeading>{`${fields.get(idx).currentMembershipType} ${
          fields.length > 1 ? idx + 1 : ''
        }`}</SocietyHeading>
        <div className="flex flex-col gap-5 md:flex-row">
          <Field
            name={`${member}.firstName`}
            label="First Name"
            component={Input}
            disabled={!fields.get(idx).allowEditName}
          />
          <Field
            name={`${member}.lastName`}
            label="Last Name"
            component={Input}
            disabled={!fields.get(idx).allowEditName}
          />
        </div>
        <Field
          name={`${member}.dateOfBirth`}
          label="Birth Date"
          component={BirthDateInput}
          parse={(val) => (val ? format(val, 'YYYY-MM-DD') : val)}
        />
      </fieldset>
    </div>
  ))
}

const GuestMember = ({ fields }) => {
  return fields.map((member, idx) => (
    <div className="p-5 text-center bg-white rounded" key={idx}>
      <fieldset>
        <SocietyHeading>
          Guest {fields.length > 1 ? idx + 1 : ''}
        </SocietyHeading>
        <div className="flex flex-col gap-5 md:flex-row">
          <Field
            name={`${member}.firstName`}
            label="First Name"
            component={Input}
            disabled={!fields.get(idx).allowEditName}
          />
          <Field
            name={`${member}.lastName`}
            label="Last Name"
            component={Input}
            disabled={!fields.get(idx).allowEditName}
          />
        </div>
        <Field
          name={`${member}.dateOfBirth`}
          label="Birth Date"
          component={BirthDateInput}
          parse={(val) => (val ? format(val, 'YYYY-MM-DD') : val)}
        />
      </fieldset>
    </div>
  ))
}

function NonPrimaryMembersForm({ handleSubmit, submitting, initialValues }) {
  return (
    <form onSubmit={handleSubmit} noValidate className="space-y-5">
      {initialValues.secondaryMember && (
        <div className="p-5 bg-white shadow">
          <FormSection name="secondaryMember">
            <fieldset>
              <SocietyHeading>
                {initialValues.secondaryMember.currentMembershipType}
              </SocietyHeading>
              <div className="flex flex-col gap-5 md:flex-row">
                <Field
                  name="firstName"
                  label="First Name"
                  component={Input}
                  disabled={!initialValues.secondaryMember.allowEditName}
                />
                <Field
                  name="lastName"
                  label="Last Name"
                  component={Input}
                  disabled={!initialValues.secondaryMember.allowEditName}
                />
              </div>
              <Field
                name="dateOfBirth"
                label="Birth Date"
                component={BirthDateInput}
                parse={(val) => (val ? format(val, 'YYYY-MM-DD') : val)}
              />
            </fieldset>
          </FormSection>
        </div>
      )}
      <FieldArray name="childMembers" component={ChildMember} />
      {initialValues.nannyMember && (
        <div className="p-5 bg-white shadow">
          <FormSection name="nannyMember">
            <fieldset>
              <SocietyHeading>
                {initialValues.nannyMember.currentMembershipType}
              </SocietyHeading>
              <div className="flex flex-col gap-5 md:flex-row">
                <Field
                  name="firstName"
                  label="First Name"
                  component={Input}
                  disabled={!initialValues.nannyMember.allowEditName}
                />
                <Field
                  name="lastName"
                  label="Last Name"
                  component={Input}
                  disabled={!initialValues.nannyMember.allowEditName}
                />
              </div>
              <Field
                name="dateOfBirth"
                label="Birth Date"
                component={BirthDateInput}
                parse={(val) => (val ? format(val, 'YYYY-MM-DD') : val)}
              />
            </fieldset>
          </FormSection>
        </div>
      )}
      <FieldArray name="guestMembers" component={GuestMember} />
      <Button
        submitting={submitting}
        primary
        xl
        label="Update"
        className="w-full"
      />
    </form>
  )
}

NonPrimaryMembersForm.propTypes = propTypes
NonPrimaryMembersForm.defaultProps = defaultProps

export default compose(
  lpForm({
    name: 'AccountInfoForm',
    validate: validateNested({
      'primaryMember.firstName': {
        presence: true,
        length: { maximum: 30 },
      },
      'primaryMember.lastName': {
        presence: true,
        length: { maximum: 30 },
      },
      'primaryMember.email': {
        presence: true,
        email: true,
        length: { maximum: 60 },
      },
      'secondaryMember.firstName': { presence: true, length: { maximum: 30 } },
      'secondaryMember.lastName': { presence: true, length: { maximum: 30 } },
      'nannyMember.firstName': { presence: true, length: { maximum: 30 } },
      'nannyMember.lastName': { presence: true, length: { maximum: 30 } },
      'childMembers[].firstName': { presence: true, length: { maximum: 30 } },
      'childMembers[].lastName': { presence: true, length: { maximum: 30 } },
      'guestMembers[].firstName': { presence: true, length: { maximum: 30 } },
      'guestMembers[].lastName': { presence: true, length: { maximum: 30 } },
    }),
  })
)(NonPrimaryMembersForm)
