import { createAction } from 'redux-actions'
import { push } from 'react-router-redux'
export const setFoundationCart = createAction('SET_FOUNDATION_CART')
export const setAuthenticated = createAction('SET_AUTHENTICATED')
export const setGuestFlowPreferred = createAction('SET_GUEST_FLOW_PREFERRED')

export function logInFoundationUser({ token, redirectUrl = null } = {}) {
  return function (dispatch) {
    dispatch(setAuthenticated({ token }))
    dispatch(push(redirectUrl || '/my-account'))
  }
}
