import React from 'react'
import { displayCurrency, displaySubmitFailure } from 'utils'
import SocietyHeading from '../layout/SocietyHeading'
import LinkButton from './ui/LinkButton'
import DonationForm from './DonationForm'
import RenderedHtml from './RenderedHtml'

function DonationCheckoutDisplay({
  addDonation,
  donation,
  header,
  coverImg,
  levels,
  text,
  removeDonation,
  min,
  max,
}) {
  return (
    <div className="p-5 bg-white shadow">
      {coverImg && <img className="object-cover h-64" src={coverImg} />}
      <SocietyHeading>{header} (Optional)</SocietyHeading>
      <RenderedHtml>{text}</RenderedHtml>

      {donation && donation.amount ? (
        <div className="space-y-2">
          <div className="space-x-2">
            <span className="font-bold">Selected donation amount:</span>
            <span>{displayCurrency(donation.amount)}</span>
          </div>

          <LinkButton label="Remove" onClick={removeDonation} danger />
        </div>
      ) : (
        <DonationForm
          form="donation-payment"
          levels={levels}
          initialValues={{ level: 5 }}
          onSubmit={addDonation}
          onSubmitFail={displaySubmitFailure}
          min={min}
          max={max}
        />
      )}
    </div>
  )
}

export default DonationCheckoutDisplay
