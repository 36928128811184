import React, { useEffect } from 'react'
import { SignInForm } from '../forms'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { flashSuccessMessage } from 'redux-flash'
import { flashErrorMessage } from 'utils'

const propTypes = {
  flashSuccessMessage: PropTypes.func.isRequired,
  flashErrorMessage: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  redirectUrl: PropTypes.string,
}

const defaultProps = {}

function SignIn({
  flashSuccessMessage,
  flashErrorMessage,
  location: { search },
  redirectUrl,
}) {
  useEffect(() => {
    if (search.includes('confirmation_success')) {
      flashSuccessMessage('Your email has been confirmed. Please sign in.')
    } else if (search.includes('confirmation_error')) {
      flashErrorMessage('There was a problem confirming your email.', {
        style: 'foundation',
        timeout: false,
      })
    }
  }, [])

  return (
    <section className="auth-page">
      <div className="content">
        <h2>Sign In to your Omaha Zoo account</h2>
        <SignInForm redirectUrl={redirectUrl} />
      </div>
    </section>
  )
}

function mapStateToProps() {
  return {}
}

const mapDispatchToProps = {
  flashSuccessMessage,
  flashErrorMessage,
}

SignIn.propTypes = propTypes
SignIn.defaultProps = defaultProps

export default compose(connect(mapStateToProps, mapDispatchToProps))(SignIn)
