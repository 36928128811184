import React from 'react'
import { withRouter } from 'react-router'
import Button from '../components/ui/Button'

function NotFound({ router }) {
  return (
    <div className="flex flex-col items-center justify-center p-10 m-10 bg-white">
      <h1>Page Not Found</h1>
      <p>Looks like the page you were looking for doesn't exist.</p>
      <Button type="secondary" onClick={router.goBack} label="Go Back" xl />
    </div>
  )
}

export default withRouter(NotFound)
