import React from 'react'
import c from 'classnames'
import { isString } from 'lodash'
import SocietyHeader from './SocietyHeader'

const SocietyPageLayout = ({
  children,
  title,
  introduction,
  className,
  reversed,
  leftAside,
  rightAside,
  header,
}) => {
  return (
    <div className="space-y-7 theme-society">
      <SocietyHeader />
      <div className="px-5 pb-20 space-y-5 md:px-10 lg:px-20 xl:px-32">
        <header className="space-y-10">
          {header}
          <section className="space-y-5 text-center">
            {title && <h1 className="m-0 text-5xl md:text-7xl">{title}</h1>}
            {introduction &&
              (isString(introduction) ? (
                <p className="m-0 text-xl md:mx-10 md:text-2xl">
                  {introduction}
                </p>
              ) : (
                introduction
              ))}
          </section>
        </header>
        <div
          className={c(
            'flex items-stretch gap-10 lg:items-start flex-col lg:flex-row',
            {
              'flex-col lg:flex-row': !reversed,
              'flex-col-reverse lg:flex-row-reverse': reversed,
            }
          )}
        >
          {leftAside}
          <main className={c('flex-grow space-y-5 font-roboto', className)}>
            {children}
          </main>
          {rightAside}
        </div>
      </div>
    </div>
  )
}

export default SocietyPageLayout
