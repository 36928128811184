import React, { useEffect } from 'react'
import SocietyCheckoutLayout from '../../../layout/SocietyCheckoutLayout'
import { TICKETING_CHECKOUT_STEPS } from '../../types'
import { OrderReceipt, TicketPrice } from '../components'
import SocietyHeading from '../../../layout/SocietyHeading'
import { connect } from 'react-redux'
import { selectors } from '../reducer'
import TicketCartListener from '../components/TicketCartListener'
import { Spinner } from '@launchpadlab/lp-components/lib/indicators'
import { first, groupBy, sumBy } from 'lodash'
import TicketActivitiesListener from '../components/TicketActivitiesListener'
import TicketActivityTimesListener from '../components/TicketActivityTimesListener'
import TicketAttendees from '../components/TicketAttendees'

const RegisterAttendees = ({
  location: {
    query: { cartToken, categoryId },
  },
  router,
  times,
  activities,
  ticketCart,
}) => {
  useEffect(() => {
    if (!cartToken) {
      router.push('/ticketing')
    }
  }, [cartToken])

  function activityUDFBlank(id) {
    if (activities) {
      const activity = activities.filter((activity) => activity.id === id)[0]
      return activity && activity.attendeeUdfs.length === 0
    }
    return true
  }

  const isContinueDisabled =
    ticketCart &&
    !ticketCart.lineItems.every((lineItem) =>
      lineItem.lineItemPrices.every(
        (lineItemPrice) =>
          lineItemPrice.attendeeUdfs.length > 0 ||
          activityUDFBlank(lineItem.productId)
      )
    )

  return (
    <SocietyCheckoutLayout
      progress={{ steps: TICKETING_CHECKOUT_STEPS, currentStep: 1 }}
      summary={<OrderReceipt />}
      title="Register Attendees"
      backUrl={`/ticketing/camps?cartToken=${cartToken}&categoryId=${categoryId}`}
      onContinue={`/ticketing/cart?cartToken=${cartToken}&backPath=camps/attendees`}
      continueDisabled={isContinueDisabled}
    >
      <TicketActivityTimesListener kind="category" id={categoryId} />
      <TicketActivitiesListener />
      <TicketCartListener />

      {ticketCart && times ? (
        <>
          <p className="text-xl font-bold">
            Please complete your ticket details below
          </p>
          <SocietyHeading>Add Attendees</SocietyHeading>
          <ol className="space-y-10">
            {ticketCart.lineItems.map((lineItem) => {
              const { id, displayName, lineItemPrices } = lineItem

              const groupedLineItemPrices = Object.values(
                groupBy(lineItemPrices, 'priceId')
              )

              const time = times.find((t) => t.id === lineItem.eventId)

              return (
                time && (
                  <li key={id} className="mb-0 space-y-3">
                    <header className="text-2xl font-bold">
                      {displayName}
                    </header>
                    {groupedLineItemPrices.map((priceGroup) => {
                      const price = time.prices.find(
                        (p) => p.id === first(priceGroup).priceId
                      )
                      const { ageMin, ageMax, soldOut, vacancy } = time
                      const ageRangeText = `Ages ${ageMin} - ${ageMax}`

                      const activity = activities?.find(
                        ({ id }) => id === time.activityId
                      )

                      return (
                        <>
                          <TicketPrice
                            ticketPrice={{ ...price, displayName: 'Child' }}
                            ticketActivity={activity}
                            ticketActivityTime={time}
                            lineItem={lineItem}
                            vacancy={soldOut ? 0 : vacancy}
                            restriction={ageRangeText}
                            quantity={sumBy(priceGroup, 'quantity')}
                            skipAttendees
                          />
                          <TicketAttendees
                            ticketActivity={activity}
                            lineItem={lineItem}
                            lineItemPrices={priceGroup}
                          />
                        </>
                      )
                    })}
                  </li>
                )
              )
            })}
          </ol>
        </>
      ) : (
        <Spinner />
      )}
    </SocietyCheckoutLayout>
  )
}

function mapStateToProps(state) {
  const ticketCart = selectors.ticketCart(state)
  const activities = selectors.ticketActivities(state)

  const times = selectors.ticketTimes(state)

  if (!times) {
    return {
      activities,
      ticketCart,
    }
  }

  return {
    times,
    activities,
    ticketCart,
  }
}

export default connect(mapStateToProps)(RegisterAttendees)
