import React, { useState } from 'react'
import PropTypes from 'prop-types'
import AppliedCoupon from './AppliedCoupon'
import CouponCodeForm from './CouponCodeForm'

const propTypes = {
  coupon: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func.isRequired,
  onSubmitFail: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onRemoveSuccess: PropTypes.func.isRequired,
  onRemoveFail: PropTypes.func.isRequired,
}

const defaultProps = {}

function CouponSection({
  coupon,
  onSubmit,
  onSubmitSuccess,
  onSubmitFail,
  onRemove,
  onRemoveSuccess,
  onRemoveFail,
}) {
  const [isLoading, setIsLoading] = useState(false)

  return (
    <div className="conditional-form">
      {coupon ? (
        <AppliedCoupon
          coupon={coupon}
          onRemove={onRemove}
          onRemoveSuccess={onRemoveSuccess}
          onRemoveFail={onRemoveFail}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      ) : (
        <CouponCodeForm
          onSubmit={onSubmit}
          onSubmitSuccess={onSubmitSuccess}
          onSubmitFail={onSubmitFail}
        />
      )}
    </div>
  )
}

CouponSection.propTypes = propTypes
CouponSection.defaultProps = defaultProps

export default CouponSection
