import React from 'react'
import c from 'classnames'

const Step = ({ name, isCurrent, isCompleted }) => (
  <li className="relative flex flex-col items-center py-1 mb-0">
    <div
      className={c(
        'absolute text-sm font-normal tracking-wider uppercase -top-full whitespace-nowrap',
        { 'font-bold': isCurrent }
      )}
    >
      {name}
    </div>
    <div
      className={c(
        'inline-block p-2 rounded-full border-solid shadow-inner border-gray-300 border-2',
        {
          'border-2 border-secondary bg-white': isCurrent,
          'bg-secondary': isCompleted,
        }
      )}
    />
  </li>
)

const SocietyProgressBar = ({ steps, currentStep }) => {
  return (
    <ol className="items-center hidden gap-5 mx-10 md:flex pt-14 sm:mx-20">
      {steps.map(({ url, name }, index) => {
        const isCurrent = index === currentStep
        const isCompleted = index < currentStep
        return (
          <React.Fragment key={url}>
            <Step name={name} isCurrent={isCurrent} isCompleted={isCompleted} />
            {index !== steps.length - 1 && (
              <li
                role="presentation"
                className={c(
                  'w-full h-1 mb-0 border border-solid shadow-inner border-gray-50',
                  { 'bg-secondary': isCompleted }
                )}
              />
            )}
          </React.Fragment>
        )
      })}
    </ol>
  )
}

export default SocietyProgressBar
