import React from 'react'
import { compose } from 'redux'
import { propTypes as formPropTypes } from 'redux-form'
import { lpForm } from '@launchpadlab/lp-form'
import PrimaryMemberFormSection from './PrimaryMemberFormSection'
import { DEFAULT_PRIMARY_MEMBER_VALIDATION_CONSTRAINTS } from 'config'
import { validateNested } from 'utils'
import SocietyHeading from '../../../layout/SocietyHeading'

const propTypes = {
  ...formPropTypes,
}

const defaultProps = {}

function IndividualMemberForm({ handleSubmit, primaryEmailEditable }) {
  return (
    <form onSubmit={handleSubmit} className="member-form">
      <SocietyHeading>Listed Members</SocietyHeading>
      <PrimaryMemberFormSection primaryEmailEditable={primaryEmailEditable} />
    </form>
  )
}

IndividualMemberForm.propTypes = propTypes

IndividualMemberForm.defaultProps = defaultProps

export default compose(
  lpForm({
    name: 'membership-individual',
    validate: validateNested(DEFAULT_PRIMARY_MEMBER_VALIDATION_CONSTRAINTS),
    initialValuesFilters: {
      reject: ['secondaryMember', 'children'],
    },
    enableReinitialize: true,
  })
)(IndividualMemberForm)
