import { first } from 'lodash'

function splitFullName(name = '') {
  const nameFields = name.trim().split(' ')
  const firstName = first(nameFields)
  const lastName = nameFields.slice('1').join(' ')
  return { firstName, lastName }
}

export default splitFullName
