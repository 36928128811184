import joinNames from './joinNames'

// Returns concatenated names for addOn members (e.g., Nanny)
function displayAddOnNames(names) {
  if (!names) return ''

  return names
    .map((name) => joinNames(name.firstName, name.lastName))
    .join(', ')
}

export default displayAddOnNames
