import React, { useMemo, useState } from 'react'
import {
  compact,
  first,
  isEmpty,
  isString,
  isUndefined,
  range,
  times,
} from 'lodash'
import c from 'classnames'
import DatePicker from 'react-datepicker'
import Select from 'react-select'
import { format, parse } from 'date-fns'
import CheckBox from './ui/CheckBox'
import RadioSelect from './ui/RadioSelect'

const EMAIL_REGEX =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

const FormConstraints = {
  required(value, label) {
    return !value || (isString(value) && !value.trim())
      ? `${label} can't be blank`
      : null
  },

  maxLength(threshold) {
    return (value, label) =>
      isString(value) && value.length > threshold
        ? `${label} is too long (maximum is ${threshold} characters)`
        : null
  },

  requiredTrue(value, label) {
    return value === true ? `${label} must be checked` : null
  },

  email(value, label) {
    return !value || !value.match(EMAIL_REGEX)
      ? `${label} should be a valid email address`
      : null
  },

  match(key) {
    return (value, label, { fields, values }) =>
      value !== values[key] ? `${label} must match ${fields[key].label}` : null
  },

  validate(key, { fields, values }) {
    const field = fields[key]
    const value = values[key]

    const { label, constraints, type, other } = field

    if (type === 'dropdown' && other) {
      if (value && value.label === 'Other') {
        return Object.values(
          FormConstraints.validateAll(other.fields, value.value)
        ).flat()
      }
    }

    if (type === 'array' && value) {
      const { fields, minLength } = field

      const validateItem = (index) =>
        value[index] &&
        Object.values(FormConstraints.validateAll(fields, value[index])).flat()

      const errors = range(minLength).map(validateItem).flat()

      return { [key]: errors }
    }

    const relatedErrors = (field.cascadeValidationTo || []).reduce(
      (acc, k) =>
        values[k]
          ? { acc, ...FormConstraints.validate(k, { fields, values }) }
          : {},
      {}
    )

    return constraints && Array.isArray(constraints)
      ? {
          [key]: compact(
            constraints.map((constraint) =>
              constraint(value, label, { fields, values })
            )
          ),
          ...relatedErrors,
        }
      : {}
  },

  validateAll(fields, values) {
    return Object.keys(fields).reduce(
      (acc, key) => ({
        ...acc,
        ...FormConstraints.validate(key, { fields, values }),
      }),
      {}
    )
  },

  isValid(fields, values) {
    return Object.values(FormConstraints.validateAll(fields, values)).every(
      isEmpty
    )
  },
}

const UserDefinedFields = {
  toConstraints(udf) {
    return compact([
      udf.isRequired && FormConstraints.required,
      udf.requiredTrue && FormConstraints.requiredTrue,
      udf.fieldLength && FormConstraints.maxLength(udf.fieldLength),
    ])
  },
  fromFormToValues(form) {
    return Object.entries(form.values)
      .filter(([key]) => Boolean(form.fields[key]))
      .reduce(
        (acc, [key, value]) =>
          form.fields[key].type === 'dropdown'
            ? { ...acc, [key]: value.value }
            : { ...acc, [key]: value },
        {}
      )
  },

  toFieldDefinition(udfs) {
    return udfs
      ? udfs.reduce(
          (acc, udf) => ({
            ...acc,
            [udf.fieldName]: {
              label: udf.label,
              constraints: UserDefinedFields.toConstraints(udf),
              type: udf.inputType,
              options:
                udf.options && udf.options.map((o) => ({ value: o, label: o })),
            },
          }),
          {}
        )
      : {}
  },

  toValues(udfs, udfValues) {
    return udfs
      ? udfs.reduce((acc, udf) => {
          const { value = '' } =
            udfValues && udfValues.length > 0
              ? udfValues.find((v) => v.fieldName === udf.fieldName) || {}
              : {}
          return {
            ...acc,
            [udf.fieldName]:
              udf.inputType === 'dropdown' ? { value, label: value } : value,
          }
        }, {})
      : {}
  },

  fromValues(udfs, values) {
    return udfs
      ? udfs.map(({ fieldName }) => ({
          fieldName,
          value: values[fieldName],
        }))
      : undefined
  },
}

const TextInput = ({
  value = '',
  onChange,
  onBlur,
  errors,
  placeholder,
  name,
  type,
}) => {
  return (
    <input
      type={type === 'password' || type == 'email' ? type : 'text'}
      name={name}
      value={value}
      className={c('border-2 rounded outline-none focus:border-primary-400', {
        'border-danger': errors && errors.length > 0,
      })}
      onChange={(event) => onChange(event.target.value)}
      onBlur={onBlur}
      placeholder={placeholder}
    />
  )
}

const NumberInput = ({
  value,
  onChange,
  onBlur,
  errors,
  placeholder,
  name,
  min,
  max,
}) => {
  return (
    <input
      name={name}
      type="number"
      value={value}
      className={c('border-2 rounded outline-none focus:border-primary-400', {
        'border-danger': errors && errors.length > 0,
      })}
      onChange={(event) => onChange(event.target.value)}
      onBlur={onBlur}
      placeholder={placeholder}
      min={min}
      max={max}
    />
  )
}

const DateInput = ({
  value,
  onChange,
  placeholder,
  name,
  dateFormat,
  includeDates,
  highlightDates,
}) => {
  return (
    <DatePicker
      name={name}
      wrapperClassName="w-full"
      selected={value ? parse(value) : undefined}
      onChange={(v) => onChange(format(v, 'YYYY-MM-DD'))}
      placeholderText={placeholder}
      includeDates={includeDates && includeDates.map(parse)}
      dateFormat={dateFormat}
      highlightDates={
        highlightDates &&
        highlightDates.map((e) =>
          Object.entries(e).reduce(
            (acc, [className, dates]) => ({
              ...acc,
              [className]: dates.map(parse),
            }),
            {}
          )
        )
      }
    />
  )
}

const CreatableSelect = ({
  value,
  onBlur,
  onChange,
  options,
  other = { fields: {} },
  placeholder,
  name,
}) => {
  const form = useForm(other.fields, other.values, {
    onChange(value) {
      onChange({ label: 'Other', value })
    },
    onBlur,
  })

  function handleChange(value) {
    if (value && value.label !== 'Other') {
      form.reset()
    }

    if (onChange) {
      onChange(value)
    }
  }

  return (
    <div className="space-y-2">
      <Select
        name={name}
        value={value}
        onChange={handleChange}
        options={[...options, { label: 'Other', value: 'other' }]}
        placeholder={placeholder}
      />
      {value && value.label === 'Other' && (
        <div className="px-5 border-l-2 border-solid border-secondary">
          <Form {...form} subform />
        </div>
      )}
    </div>
  )
}

const DropdownInput = ({
  value,
  onChange,
  options,
  other,
  placeholder,
  name,
  onBlur,
}) => {
  return !other ? (
    <Select
      name={name}
      value={value}
      onChange={onChange}
      options={options}
      placeholder={placeholder}
    />
  ) : (
    <CreatableSelect
      name={name}
      value={value}
      onChange={onChange}
      options={options}
      other={other}
      placeholder={placeholder}
      onBlur={onBlur}
    />
  )
}
const CheckboxInput = ({ value, onChange, name }) => {
  return (
    <CheckBox
      name={name}
      checked={parseInt(value)}
      onChange={(v) => onChange(`${v ? 1 : 0}`)}
    />
  )
}

const RadioInput = ({ label, value, onChange, options, name }) => {
  return (
    <RadioSelect
      name={name}
      value={value}
      label={label}
      labelSrOnly
      onChange={onChange}
      options={options}
    />
  )
}

const ArrayInput = ({
  label,
  minLength,
  maxLength,
  itemLabel,
  value,
  onChange,
  length,
  ...props
}) => {
  const isVariableLength = length === 'variable'

  const [itemCount, setItemCount] = useState(
    isVariableLength ? value.length || minLength : maxLength
  )

  const itemCountOptions = range(minLength, maxLength).map((i) => ({
    label: i ? `${i} ${label}` : 'None',
    value: i,
  }))

  return (
    <div className="space-y-10">
      {isVariableLength && (
        <label className="w-full space-y-2">
          <span>Number of {label}</span>
          <DropdownInput
            value={itemCountOptions.find((o) => o.value === itemCount)}
            placeholder={`Number of ${label}`}
            onChange={(option) => setItemCount(option.value)}
            options={itemCountOptions}
          />
        </label>
      )}
      {itemCount && (
        <ul className="space-y-7">
          {times(itemCount).map((index) => {
            const optional = index + 1 > minLength
            const label = optional
              ? `${itemLabel} ${index + 1} (optional)`
              : `${itemLabel} ${index + 1}`

            return (
              <li key={index} className="mb-0">
                <label className="text-lg">{label}</label>
                <ArrayItemInput
                  {...props}
                  value={value ? value[index] : undefined}
                  onChange={(v) => onChange({ ...value, [index]: v })}
                />
              </li>
            )
          })}
        </ul>
      )}
    </div>
  )
}

const ArrayItemInput = ({ fields, value, layout, onChange }) => {
  const form = useForm(fields, value, { onChange })

  return <Form {...form} layout={layout} subform />
}

const FORM_INPUTS = {
  string: TextInput,
  date: DateInput,
  dropdown: DropdownInput,
  checkbox: CheckboxInput,
  radio: RadioInput,
  numeric: NumberInput,
  array: ArrayInput,
}

const FormInput = (props) => {
  const { errors, type, constraints = [], labelSrOnly } = props

  const InputComponent = FORM_INPUTS[type] || TextInput

  const isDatePicker = type === 'date'
  const isCreatableDropdown = type === 'dropdown' && props.other
  const isArrayInput = type === 'array'

  const isRequired = constraints.includes(FormConstraints.required)
  const label = isRequired ? props.label : `${props.label} (optional)`

  const shouldShowErrors =
    !isCreatableDropdown || (props.value && props.value.label !== 'Other')

  const labelClassName = c({
    'sr-only fixed': labelSrOnly,
    'text-xl': isArrayInput,
    'text-base': !isArrayInput,
  })

  return isDatePicker || isArrayInput ? (
    <div className="w-full">
      <label className={c('m-0 text-base', labelClassName)}>{label}</label>
      <InputComponent {...props} />
    </div>
  ) : (
    <label className="w-full m-0">
      <span className={labelClassName}>{label}</span>
      <InputComponent {...props} />
      {shouldShowErrors && (
        <div className="mt-2 text-xs text-danger">{first(errors)}</div>
      )}
    </label>
  )
}

function useUserDefinedForm(fields, values, udfs, udfValues) {
  return useForm(
    { ...fields, ...UserDefinedFields.toFieldDefinition(udfs) },
    { ...values, ...UserDefinedFields.toValues(udfs, udfValues) }
  )
}

function useForm(fields, externalValues, { onChange, onBlur } = {}) {
  const [overrides, setOverrides] = useState({})
  const [errors, setErrors] = useState({})

  const values = useMemo(
    () => ({ ...externalValues, ...overrides }),
    [externalValues, overrides]
  )

  const reset = () => {
    setOverrides({})
    setErrors({})
  }

  function onInputChange(key) {
    return function handleChange(value) {
      setOverrides((current) => ({
        ...current,
        [key]: value,
      }))

      validate(key, value)

      if (onChange) {
        onChange({ ...values, [key]: value })
      }
    }
  }

  function onInputBlur(key) {
    return function handleBlur() {
      validate(key)

      if (onBlur) {
        onBlur()
      }
    }
  }

  function validate(key, value) {
    const vs = { ...values, [key]: value || values[key] }

    const errors = key
      ? FormConstraints.validate(key, { fields, values: vs })
      : FormConstraints.validateAll(fields, vs)

    setErrors((current) => (key ? { ...current, ...errors } : errors))

    return Object.values(errors).every((e) => e.length === 0)
  }

  const isValid = FormConstraints.isValid(fields, values)

  return {
    values,
    fields,
    errors,
    isValid,
    reset,
    validate,
    onInputBlur,
    onInputChange,
  }
}

const Form = ({
  values,
  errors,
  fields,
  onInputChange,
  onInputBlur,
  onSubmit,
  subform,
  layout = Object.keys(fields),
}) => {
  const Tag = subform ? 'div' : 'form'

  function handleSubmit(event) {
    event.preventDefault()

    if (onSubmit) {
      onSubmit()
    }
  }

  function renderFields(layout) {
    let key
    return layout.type === 'inline' ? (
      <div className="flex gap-5 justify-items-stretch">
        {layout.children.map(renderFields)}
      </div>
    ) : layout.type === 'section' ? (
      <section className="p-5 space-y-10 bg-white">
        <h1 className="m-0 text-left">{layout.title}</h1>
        <div className="space-y-3">{layout.children.map(renderFields)}</div>
      </section>
    ) : !isUndefined((key = layout.key)) ? (
      <FormInput
        {...fields[key]}
        key={key}
        value={values[key]}
        onChange={onInputChange(key)}
        onBlur={onInputBlur(key)}
        errors={errors[key]}
        name={fields[key].name || key}
        {...layout}
      />
    ) : isString((key = layout)) ? (
      renderFields({ key })
    ) : (
      <>Unknown form layout option</>
    )
  }

  return (
    <Tag
      className={c('flex flex-col', { 'gap-5': !subform, 'gap-2': subform })}
      onSubmit={Tag === 'form' ? handleSubmit : undefined}
    >
      {layout.map(renderFields)}
    </Tag>
  )
}

export default Form
export {
  useForm,
  useUserDefinedForm,
  FormConstraints,
  UserDefinedFields,
  DateInput,
}
