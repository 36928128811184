import z from 'zod'
import * as Sentry from '@sentry/react'
import { api } from 'services/api'

const MemberAddress = z.object({
  addressId: z.number(),
  addressTypeId: z.number(),
  addressee: z.nullable(z.string()),
  city: z.nullable(z.string()),
  country: z.nullable(z.string()),
  email: z.nullable(z.string()),
  isPrimaryAddress: z.boolean(),
  mobilePhone: z.nullable(z.string()),
  optInEmail: z.boolean(),
  phone: z.nullable(z.string()),
  state: z.nullable(z.string()),
  streetAddress: z.nullable(z.string()),
  streetAddress2: z.nullable(z.string()),
  streetAddress3: z.nullable(z.string()),
  streetAddress4: z.nullable(z.string()),
  suburb: z.nullable(z.string()),
  workPhone: z.nullable(z.string()),
  zip: z.nullable(z.string()),
})

const Member = z.object({
  address: MemberAddress.partial(),
  allowEditAddress: z.boolean(), //api unifinished, always returns true
  allowEditEmail: z.boolean(), // is true if email is blank
  allowEditName: z.boolean(), //api unifinished, always returns false
  allowEditPhone: z.boolean(), //api unifinished, always returns true
  currentMembership: z.nullable(z.any()),
  currentMembershipId: z.nullable(z.number()),
  currentMembershipType: z.nullable(z.string()),
  customerId: z.number(),
  dateOfBirth: z.nullable(z.string()),
  email: z.nullable(z.string()),
  externalId: z.nullable(z.string()),
  firstName: z.nullable(z.string()),
  fullName: z.nullable(z.string()),
  hasActiveMembership: z.boolean(),
  hasName: z.boolean(),
  id: z.number(),
  isChild: z.boolean(),
  isNanny: z.nullable(z.boolean()),
  isPrimary: z.boolean(),
  isSecondary: z.boolean(),
  lastName: z.nullable(z.string()),
  memberCode: z.number(),
  memberNumber: z.number(),
  memberType: z.nullable(z.string()),
  phone: z.nullable(z.string()),
  primaryMemberId: z.nullable(z.number()),
  title: z.nullable(z.string()),
})

const MemberAccount = z.object({
  costRateId: z.number(),
  primaryMember: Member,
  secondaryMembers: z.array(Member),
  token: z.nullable(z.string()),
})

const MemberAccountResponse = z.object({
  data: z.object({
    type: z.literal('member_account'),
    attributes: MemberAccount,
    additionalData: z.object({
      cartToken: z.nullable(z.string()),
    }),
  }),
})

const Users = {
  async createUserAndCustomer({
    primary,
    password,
    passwordConfirmation,
    membershipId,
    redirectOrigin,
    redirectUrl,
  }) {
    const payload = {
      customers: {
        primary: { ...primary, isPrimary: true },
        password,
        passwordConfirmation,
        membershipId,
      },
      redirectUrl: `${redirectOrigin}${redirectUrl}`,
    }

    const Response = z.object({
      data: z.object({
        type: z.literal('centaman_customer'),
        attributes: z.object({
          id: z.number(),
          firstName: z.string(),
          customerName: z.string(),
          lastName: z.string(),
          address: MemberAddress,
        }),
        additionalData: z.object({
          cartToken: z.nullable(z.string()),
        }),
      }),
    })

    const res = await api.post('/users/register_customer', payload)

    try {
      return Response.parse(res).data.attributes
    } catch (error) {
      Sentry.captureException(error)
      throw error
    }
  },

  async createUser({
    email,
    password,
    passwordConfirmation,
    redirectOrigin,
    redirectUrl,
    membershipId,
    membershipLastName,
  }) {
    const Response = z.object({
      data: z.object({
        type: z.literal('user'),
        attributes: z.object({
          id: z.number(),
        }),
        additionalData: z.object({
          cartToken: z.nullable(z.string()),
        }),
      }),
    })
    const res = await api.post('/sign-up', {
      user: {
        primaryEmail: email,
        password,
        passwordConfirmation,
        redirectUrl: `${redirectOrigin}${redirectUrl}`,
        membershipId,
        membershipLastName,
      },
    })

    try {
      return Response.parse(res)
    } catch (error) {
      Sentry.captureException(error)
      throw error
    }
  },

  async verify(email) {
    const Response = z.object({
      user: z.boolean(),
      emailVerified: z.boolean(),
      newUserNotMember: z.nullable(z.boolean()),
      centamanCustomerId: z.nullable(z.number()),
    })

    const res = await api.get('/user/find_by_email', {
      query: { email },
    })

    return Response.parse(res)
  },

  async authenticate({ email, password }) {
    const user = { primaryEmail: email, password }
    const res = await api.post('login_with_user', { user })

    return MemberAccountResponse.parse(res).data.attributes
  },

  async verifyCentamanMemberRecord({ memberNumber, lastName }) {
    const res = await api.post('/users/verify_centaman_member_record', {
      member: {
        memberNumber,
        lastName,
      },
    })

    return MemberAccountResponse.parse(res).data.attributes
  },

  sendVerificationEmail(email, redirectOrigin, redirectPath) {
    api.post('/user/send_email_verification', {
      email: email,
      redirectUrl: `${redirectOrigin}${redirectPath}`,
    })
  },

  async sendForgotPasswordEmail(email, redirectOrigin, redirectPath) {
    const res = await api.post('/users/passwords', {
      user: {
        primaryEmail: email,
        redirectUrl: `${redirectOrigin}${redirectPath}`,
      },
    })
    return res
  },

  async resetPassword({ password, passwordConfirmation, token }) {
    const Response = z.object({
      data: z.object({
        type: z.literal('user'),
        attributes: z.object({
          primaryEmail: z.nullable(z.string()),
        }),
        additionalData: z.object({
          cartToken: z.nullable(z.string()),
        }),
      }),
    })

    const res = await api.patch('/users/passwords', {
      user: {
        password,
        passwordConfirmation,
        resetPasswordToken: token,
      },
    })

    try {
      return Response.parse(res)
    } catch (error) {
      Sentry.captureException(error)
      throw error
    }
  },
}

export default Users
