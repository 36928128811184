import { api } from 'services/api'
import * as LS from 'services/sessionStorage'
import {
  mapOrderData,
  mapDonationOrderData,
  mapGiftCardCartData,
  mapStandardOrderData,
  mapFoundationLineItemData,
} from 'utils'

// USER

export function signOut() {
  return api.destroy('/logout')
}

export function accountCreateAccount(params) {
  return api.post('/foundation/sign-up', { user: params })
}

export function accountSignIn(params) {
  return api.post('/foundation/login', { user: params })
}

export function accountForgotPassword(params) {
  return api.post('/foundation/passwords', { user: params })
}

export function accountResetPassword(params) {
  return api.patch('/foundation/passwords', { user: params })
}

// OAUTH

export function verifyIdMe(code) {
  return api.post(
    '/oauth/id_me/verify',
    {
      code,
    },
    {
      token: LS.getAuthToken(),
    }
  )
}

// MEMBERSHIP

export function createMembers(params) {
  return api.post(`/members`, mapOrderData(params))
}

export function updateMembers(params) {
  return api.patch(
    '/members',
    { members: params },
    { token: LS.getAuthToken() }
  )
}

export function validateSellerCode(params) {
  return api.post(`/coupons/validate_seller`, mapOrderData(params))
}

export function validateCouponCode(params) {
  return api.post(`/coupons/validate_coupon`, mapOrderData(params))
}

export function updateCheckout(params) {
  return api.post(`/checkouts`, mapOrderData(params))
}

export function createOrder(params) {
  return api.post(`/orders`, mapOrderData(params), { token: LS.getAuthToken() })
}

// TICKETING

export function createOrUpdateTicketLineItem({
  ticketCartToken,
  productId,
  productType,
  eventId,
  eventName,
  startDatetime,
  endDatetime,
  parentLineItemId,
  baseFeeId,
  baseFeePrice,
  baseFeeCostRateId,
  bookingUdfs,
}) {
  return api.post(
    `/ticketing/line_items`,
    {
      productId,
      productType,
      cartToken: ticketCartToken,
      eventId,
      eventName,
      startDatetime,
      endDatetime,
      parentLineItemId,
      baseFeeId,
      baseFeePrice,
      baseFeeCostRateId,
      bookingUdfs,
    },
    { token: LS.getAuthToken() }
  )
}

export function holdTickets(ticketCartToken, lineItemId) {
  return api.post(
    `/ticketing/orders/hold`,
    { cartToken: ticketCartToken, lineItemId },
    { token: LS.getAuthToken() }
  )
}

export function destroyTicketLineItem(ticketCartToken, ticketLineItemId) {
  return api.destroy(
    `/ticketing/line_items/${ticketLineItemId}`,
    {
      cart_token: ticketCartToken,
    },
    { token: LS.getAuthToken() }
  )
}

export function createTicketLineItemPrice({
  ticketCartToken,
  ticketLineItemId,
  ticketPriceId,
  costRateId,
  price,
  alternatePriceId,
  alternatePrice,
  alternateCostRateId,
  attendee,
  attendeeUdfs,
}) {
  return api.post(
    `ticketing/line_items/${ticketLineItemId}/line_item_prices`,
    {
      priceId: ticketPriceId,
      price,
      costRateId,
      alternatePriceId,
      alternatePrice,
      alternateCostRateId,
      cartToken: ticketCartToken,
      attendee,
      attendeeUdfs,
    },
    { token: LS.getAuthToken() }
  )
}

export function updateTicketLineItemPrice({
  ticketCartToken,
  ticketLineItemId,
  ticketLineItemPriceId,
  quantity,
  attendee,
  attendeeUdfs,
}) {
  return api.patch(
    `ticketing/line_items/${ticketLineItemId}/line_item_prices/${ticketLineItemPriceId}`,
    {
      quantity,
      cartToken: ticketCartToken,
      attendee,
      attendeeUdfs,
    },
    { token: LS.getAuthToken() }
  )
}

export function destroyTicketLineItemPrice({
  ticketCartToken,
  ticketLineItemId,
  ticketLineItemPriceId,
}) {
  return api.destroy(
    `/ticketing/line_items/${ticketLineItemId}/line_item_prices/${ticketLineItemPriceId}`,
    {
      cart_token: ticketCartToken,
    },
    { token: LS.getAuthToken() }
  )
}

export function massUpdateTicketLineItemPrices({
  ticketCartToken,
  ticketLineItemId,
  ticketPriceId,
  costRateId,
  price,
  quantity,
}) {
  return api.post(
    `ticketing/line_items/${ticketLineItemId}/mass/line_item_prices`,
    {
      priceId: ticketPriceId,
      quantity,
      price,
      cartToken: ticketCartToken,
      costRateId,
    },
    { token: LS.getAuthToken() }
  )
}

export function updateTicketCart(ticketCartToken, cartAttrs) {
  return api.patch(
    `/ticketing/cart`,
    {
      cart: cartAttrs,
      cart_token: ticketCartToken,
    },
    { token: LS.getAuthToken() }
  )
}

export function createTicketOrder(orderAttrs) {
  return api.post(`/ticketing/orders`, mapStandardOrderData(orderAttrs), {
    token: LS.getAuthToken(),
  })
}

export function validateTicketingCouponCode(params) {
  return api.post(`/ticketing/coupons/validate/${params.couponCode}`, {
    cart_token: params.cartToken,
  })
}

// ensure that tickets added to cart are still available for purchase at time of cart submission
export function validateTicketAvailability(cartToken) {
  return api.get(
    `/ticketing/cart/validate_availability?cart_token=${cartToken}`
  )
}

// send waiver emails to the provided emails, given context of an activity ID
export function sendWaiverEmails({ activityId, emails }) {
  return api.post('/ticketing/waivers/emails', {
    activity_id: activityId,
    emails: emails,
  })
}

export function getTicketMailDeliveryFees(cartToken) {
  return api.get(`/ticketing/ticket_delivery_fees?cart_token=${cartToken}`)
}

// DONATION

export function createDonationOrder(params) {
  return api.post(`/donations/orders`, mapDonationOrderData(params))
}

export function updateDonationCheckout(params) {
  return api.post(`/donations/checkouts`, mapDonationOrderData(params))
}

// GIFTCARDS

export function createGiftCardOrder(recaptcha) {
  const token = LS.getCartToken()
  return api.post('/gift_cards/orders', { recaptcha }, { token })
}

export function updateGiftCardCartWithPayment(params) {
  const token = LS.getCartToken()
  return api.patch('/gift_cards/cart', mapGiftCardCartData(params), { token })
}

// Foundation

export function createFoundationLineItem(params) {
  return api.post(`/foundation/line_items`, mapFoundationLineItemData(params))
}

export function destroyFoundationLineItem(
  foundationLineItemId,
  foundationCartToken
) {
  return api.destroy(`/foundation/line_items/${foundationLineItemId}`, {
    cart_token: foundationCartToken,
  })
}

export function updateFoundationLineItem(lineItem, foundationCartToken) {
  return api.patch(`/foundation/line_items/${lineItem.foundationLineItemId}`, {
    foundationLineItem: lineItem,
    cartToken: foundationCartToken,
  })
}

export async function getFoundationOrders() {
  const response = await api.get('/foundation/user/orders')
  return response.data.attributes
}

export function getFoundationRecurringSchedules() {
  return api.get('/foundation/user/foundation_recurring_schedules')
}

export function cancelFoundationRecurringSchedule(id) {
  return api.post(
    `/foundation/user/foundation_recurring_schedules/cancel/${id}`
  )
}

export function getFoundationDonationYears() {
  return api.get('/foundation/user/donation_years')
}

// Google reCaptcha

export function verifyReCaptcha(captchaResponse) {
  return api.post('/verify', { captchaResponse })
}

// foundation

export function setFoundationCartPersonalDetails({
  foundationCartToken,
  cartAttrs,
}) {
  return api.post(
    `/foundation/personal_details`,
    {
      ...cartAttrs,
      cart_token: foundationCartToken,
    },
    { token: LS.getAuthToken() }
  )
}

export function setFoundationCartPaymentDetails({
  foundationCartToken,
  cartAttrs,
}) {
  return api.post(
    `/foundation/payment_details`,
    {
      ...cartAttrs,
      cart_token: foundationCartToken,
    },
    { token: LS.getAuthToken() }
  )
}

export function createFoundationOrder({ foundationCartToken }) {
  return api.post(`/foundation/orders`, { cart_token: foundationCartToken })
}

export function updateFoundationUserPassword(params) {
  return api.patch(`/foundation/user/account_password`, { user: params })
}
