import React from 'react'
import ReactDOM from 'react-dom'
import LinkButton from './LinkButton'
import Button from './Button'
import { isString } from 'lodash'
import c from 'classnames'

const CloseIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" {...props}>
    <path
      fill="currentColor"
      d="m249-207-42-42 231-231-231-231 42-42 231 231 231-231 42 42-231 231 231 231-42 42-231-231-231 231Z"
    />
  </svg>
)

const ModalDialog = ({
  title,
  content,
  isOpen,
  onClose,
  onConfirm,
  confirmLabel = 'Confirm',
  confirmDisabled,
  confirmLoading,
  closeLabel = 'Close',
  compact,
  type = 'primary',
  onSecondaryConfirm,
  secondaryConfirmLabel,
  secondaryType = 'primary-outline',
  cover,
}) => {
  const handleOverlayClick = (event) => {
    if (
      !event.target.matches('[role="dialog"], [role="dialog"] *') &&
      !confirmLoading
    ) {
      onClose()
    }
  }

  return isOpen
    ? ReactDOM.createPortal(
        <div
          aria-modal="true"
          className="absolute inset-0 w-screen h-full overflow-hidden overflow-y-auto bg-gray-900 bg-opacity-50 theme-society sm:p-10"
          onClick={handleOverlayClick}
        >
          <div
            role="dialog"
            className={c(
              'relative flex h-full flex-col gap-5 mx-auto overflow-y-auto overflow-hidden text-xl bg-white md:w-1/2 sm:rounded-lg font-frutiger',
              { 'sm:h-auto': compact }
            )}
          >
            {cover && <img className="object-cover h-64" src={cover} />}

            {onClose && (
              <button
                className={c(
                  'absolute top-9 right-8 p-0 h-8 w-8',
                  'bg-white border-none rounded-full',
                  'flex items-center justify-center',
                  'cursor-pointer transition-colors hover:bg-primary hover:text-white'
                )}
              >
                <CloseIcon onClick={onClose} className="w-7 h-7" />
              </button>
            )}
            <section className="flex flex-col flex-1 gap-8 p-10">
              <h1 className="mx-5 my-0 text-4xl text-center text-gray-900">
                {title}
              </h1>
              <div className="flex-1">
                {isString(content) ? (
                  <p className="text-md">{content}</p>
                ) : (
                  content
                )}
              </div>
              <section className="flex flex-col items-center gap-5 no-print">
              {onSecondaryConfirm && (
                  <Button
                    label={secondaryConfirmLabel}
                    onClick={onSecondaryConfirm}
                    type={secondaryType}
                    xl
                  />
                )}
                {onConfirm && (
                  <Button
                    label={confirmLabel}
                    onClick={onConfirm}
                    type={type}
                    xl
                    disabled={confirmDisabled}
                    loading={confirmLoading}
                  />
                )}
                {onClose && (
                  <LinkButton
                    label={closeLabel}
                    onClick={onClose}
                    neutral
                    lg
                    disabled={confirmLoading}
                  />
                )}
              </section>
            </section>
          </div>
        </div>,
        document.body
      )
    : null
}

export default ModalDialog
