import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'main/types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import * as reduxForm from 'redux-form'
import { selectors } from '../reducer'
import { onMount, onUpdate, waitFor, modifyProps } from '@launchpadlab/lp-hoc'
import * as routerActions from 'react-router-redux'
import * as apiActions from 'main/apiActions'
import { selectors as apiSelectors } from '@launchpadlab/lp-redux-api'
import { Spinner } from '@launchpadlab/lp-components'
import { get } from 'lodash/fp'
import { OrderReceipt } from '../components'
import { DonationPaymentForm } from '../forms'
import * as actions from '../actions'
import * as effects from 'main/effects'
import {
  displaySubmitFailure,
  isEmpty,
  findFirstErrorField,
  splitFullName,
  flashErrorMessage,
} from 'utils'
import { scroller } from 'react-scroll'
import ReactGA from 'react-ga4'
import { GpiWrapper, GpiSubmit } from 'components/Gpi'
import SocietyCheckoutLayout from '../../../layout/SocietyCheckoutLayout'
import { DONATION_CHECKOUT_STEPS } from '../../types'
import SocietyHeading from '../../../layout/SocietyHeading'
import DonationInput from '../../../components/DonationInput'
import RenderedHtml from '../../../components/RenderedHtml'

const propTypes = {
  customerCountry: PropTypes.string,
  donationForm: PropTypes.object.isRequired,
  isFormSubmitting: PropTypes.bool.isRequired,
  selectedFund: Types.donationFund.isRequired,
  setFundId: PropTypes.func.isRequired,
  setLevel: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  changeForm: PropTypes.func.isRequired,
  submitOrderFailed: PropTypes.func.isRequired,
  submitOrderSucceeded: PropTypes.func.isRequired,
  submitOrder: PropTypes.func.isRequired,
  submitPaymentAndOrder: PropTypes.func.isRequired,
  orderTotals: PropTypes.object.isRequired,
  appliedGiftCardAmount: PropTypes.number,
  remainingGiftCardBalance: PropTypes.number,
  setGiftCard: PropTypes.func.isRequired,
  clearGiftCard: PropTypes.func.isRequired,
  flashErrorMessage: PropTypes.func.isRequired,
  skipBillingInfo: PropTypes.bool.isRequired,
}

const defaultProps = {
  appliedGiftCardAmount: 0,
  remainingGiftCardBalance: null,
}

function DonationDetails({
  customerCountry,
  donationForm,
  isFormSubmitting,
  selectedFund,
  setFundId,
  setLevel,
  submitForm,
  changeForm,
  submitOrderFailed,
  submitOrderSucceeded,
  submitOrder,
  submitPaymentAndOrder,
  orderTotals: { donationAmount, total },
  appliedGiftCardAmount,
  remainingGiftCardBalance,
  setGiftCard,
  clearGiftCard,
  flashErrorMessage,
  skipBillingInfo,
}) {
  useEffect(() => {
    if (selectedFund.id !== donationForm.fundId) {
      setLevel(null)
      setFundId(selectedFund.id)
      clearGiftCard()
    }
  }, [selectedFund])

  useEffect(() => {
    ReactGA.event('view_item', {
      currency: 'USD',
      value: donationAmount,
      item_name: selectedFund?.displayName,
    })
  }, [selectedFund, donationAmount])

  const handleSubmit = ({
    cardToken,
    expirationDate,
    lastFourDigits,
    cardType,
    // maskedCardNumber, // not currently used but could be sent to server
  }) => {
    // https://redux-form.com/8.3.0/docs/api/props.md/#-code-change-field-string-value-any-function-code-

    // update the form's card token with the temporary payment token from GPI
    // and add any other payment-related fields that our server needs to know about
    changeForm('donation-payment', 'cardToken', cardToken)
    changeForm('donation-payment', 'expirationDate', expirationDate)
    changeForm('donation-payment', 'lastFourDigits', lastFourDigits)
    changeForm('donation-payment', 'cardType', cardType)
    // submit the form, which triggers other form validations and then ultimately submits to our server
    submitForm('donation-payment')
  }

  const levels = selectedFund.donationLevels
    .filter(({ customAmount }) => !customAmount)
    .map(({ displayName, amount }) => ({
      label: displayName.replace(',', ''),
      value: amount,
    }))
    .sort((l1, l2) => l1.value - l2.value)

  const { minAmount, maxAmount } = selectedFund.donationLevels.find(
    ({ customAmount }) => customAmount
  )

  return (
    <SocietyCheckoutLayout
      title="Donation Details"
      progress={{ steps: DONATION_CHECKOUT_STEPS, currentStep: 1 }}
      backUrl="/donation"
      summary={
        <OrderReceipt
          amount={donationAmount}
          selectedFund={selectedFund}
          subtotal={donationAmount || 0}
          setGiftCard={setGiftCard}
          donationForm={donationForm}
          total={total}
          appliedGiftCardAmount={appliedGiftCardAmount}
        />
      }
      ContinueButton={skipBillingInfo ? null : <GpiSubmit />}
      onContinue={() => submitForm('donation-payment')}
      continueDisabled={isFormSubmitting || !donationAmount}
    >
      <p>
        You have chosen the <strong>{selectedFund.displayName}</strong> fund.
        Please select your donation amount below.
      </p>
      {selectedFund.id !== donationForm.fundId ? (
        <Spinner />
      ) : (
        <GpiWrapper
          onTokenSuccess={handleSubmit}
          gpiFormHasBeenRendered={!skipBillingInfo}
          isDisabled={isFormSubmitting}
        >
          <div className="p-5 bg-white shadow">
            <SocietyHeading>
              <RenderedHtml>{selectedFund.donationHeader}</RenderedHtml>
            </SocietyHeading>
            <RenderedHtml>{selectedFund.donationText}</RenderedHtml>
            <DonationInput
              levels={levels}
              value={donationForm.level}
              onChange={setLevel}
              min={minAmount}
              max={maxAmount}
            />
          </div>
          <div className="p-5 bg-white shadow">
            <DonationPaymentForm
              customerCountry={customerCountry}
              onSubmit={(params) =>
                skipBillingInfo
                  ? submitOrder(params)
                  : submitPaymentAndOrder(params)
              }
              onSubmitSuccess={submitOrderSucceeded}
              onSubmitFail={submitOrderFailed}
              initialValues={{ address: { country: 'US' } }}
              skipBillingInfo={skipBillingInfo}
              appliedGiftCardAmount={appliedGiftCardAmount}
              remainingGiftCardBalance={remainingGiftCardBalance}
              onGiftCardApply={(giftCardNumber) => {
                return effects.updateDonationCheckout({
                  ...donationForm,
                  giftCard: { giftCardNumber },
                })
              }}
              onGiftCardApplySuccess={setGiftCard}
              onGiftCardApplyFail={(err) =>
                flashErrorMessage(err.errors.message)
              }
              onGiftCardRemove={() => {
                if (!donationAmount) return Promise.resolve()
                return effects.updateDonationCheckout({
                  ...donationForm,
                  giftCard: { giftCardNumber: null },
                })
              }}
              onGiftCardRemoveSuccess={setGiftCard}
              onGiftCardRemoveFail={(err) =>
                flashErrorMessage(err.errors.message)
              }
            />
          </div>
        </GpiWrapper>
      )}
    </SocietyCheckoutLayout>
  )
}

DonationDetails.propTypes = propTypes
DonationDetails.defaultProps = defaultProps

function mapStateToProps(state) {
  const orderTotals = selectors.orderTotals(state)
  const skipBillingInfo =
    orderTotals.total === 0 && orderTotals.donationAmount > 0

  return {
    customerCountry: reduxForm.formValueSelector('donation-payment')(
      state,
      'address.country'
    ),
    donationForm: selectors.donationForm(state),
    isFormSubmitting: reduxForm.isSubmitting('donation-payment')(state),
    selectedFund: selectors.selectedFund(state),
    donationFundFetchFailed: apiSelectors.isFailure(
      state,
      apiActions.REQ_DONATION_FUND
    ),
    orderTotals,
    appliedGiftCardAmount: selectors.appliedGiftCardAmount(state),
    remainingGiftCardBalance: selectors.remainingGiftCardBalance(state),
    skipBillingInfo,
  }
}

const mapDispatchToProps = {
  fetchDonationFund: apiActions.fetchDonationFund,
  push: routerActions.push,
  replace: routerActions.replace,
  setFundId: actions.setFundId,
  setLevel: actions.setLevel,
  setOrder: actions.setOrder,
  submitForm: reduxForm.submit,
  changeForm: reduxForm.change,
  setGiftCard: actions.setGiftCard,
  clearGiftCard: actions.clearGiftCard,
  flashErrorMessage,
}

function modify({ push, setOrder, donationForm, clearGiftCard }) {
  return {
    submitOrder: (params) => {
      return effects.createDonationOrder({
        ...donationForm,
        ...params,
        ...splitFullName(params.nameOnCard),
      })
    },
    submitPaymentAndOrder: ({
      address: { streetAddress, streetAddress2, ...addressParams },
      ...rest
    }) => {
      const paymentResponse = {
        streetAddress,
        streetAddress2,
        ...rest,
      }

      return effects.createDonationOrder({
        paymentResponse,
        ...addressParams,
        ...donationForm,
        ...splitFullName(rest.nameOnCard),
      })
    },
    submitOrderSucceeded: (response) => {
      setOrder(response)
      clearGiftCard()
      effects
        .updateDonationCheckout({ order: response.order, ...donationForm })
        .then((res) => {
          const gaObj = {
            currency: 'USD',
            transaction_id: response.transactionId,
            value: response.order.totalPaid,
            items: res.purchaseDetails.map((item) => ({
              item_name: item.name,
              item_id: item.sku,
              price: Number(item.price),
              item_category: item.category,
              quantity: item.quantity,
            })),
          }

          ReactGA.event('purchase', gaObj)
        })
      push('/donation/confirmation')
    },
    submitOrderFailed: (errors, dispatch, submitError, { syncErrors }) => {
      displaySubmitFailure(errors, dispatch, submitError)
      // Check for sync errors here as onSubmitFail is overridden in props
      if (!isEmpty(syncErrors)) {
        const firstErrorField = findFirstErrorField(syncErrors)
        if (!firstErrorField) return

        return scroller.scrollTo(firstErrorField, { smooth: true })
      }
    },
  }
}

function fetchDonationProduct({
  location: { query },
  fetchDonationFund,
  selectedFund,
}) {
  const donationId = query.id || get('id', selectedFund)

  // Do not call API if the correct donation fund already exists
  if (selectedFund && selectedFund.id === donationId) return

  return fetchDonationFund(donationId || 0)
}

function redirectOnFailure({ replace, donationFundFetchFailed }) {
  if (donationFundFetchFailed) replace('/no-donation')
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  modifyProps(modify),
  onMount(fetchDonationProduct),
  onUpdate(redirectOnFailure),
  waitFor('selectedFund', Spinner)
)(DonationDetails)
