import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'main/types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { selectors } from '../../reducer'
import * as apiActions from 'main/apiActions'
import { Button, Spinner } from '@launchpadlab/lp-components'
import { Link } from 'react-router'
import { orderBy } from 'lodash'
import { routerActions } from 'react-router-redux'
import bannerImage from 'images/zoo-parent.png'

const propTypes = {
  fetchFoundationProductCategories: PropTypes.func.isRequired,
  fetchOrCreateFoundationCart: PropTypes.func.isRequired,
  foundationCart: Types.foundationCart,
  foundationProductCategories: PropTypes.arrayOf(
    Types.foundationProductCategory
  ),
  push: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
}

const defaultProps = {
  foundationProductCategories: [],
  foundationCart: null,
}

function SelectAnimal({
  fetchFoundationProductCategories,
  fetchOrCreateFoundationCart,
  foundationCart,
  foundationProductCategories,
  location: {
    query: { cartToken },
  },
  push,
}) {
  useEffect(() => {
    if (foundationProductCategories.length == 0)
      fetchFoundationProductCategories()
  }, [foundationProductCategories])
  useEffect(() => {
    fetchOrCreateFoundationCart(cartToken)
  }, [cartToken])
  if (foundationProductCategories.length == 0 || !foundationCart)
    return <Spinner />

  const adoptAnAnimalFeaturedCategories = orderBy(
    foundationProductCategories.filter(
      (category) =>
        category.donationType == 'adopt_an_animal' && category.featured
    ),
    ['featured', 'position'],
    ['desc', 'asc']
  )
  const adoptAnAnimalNotFeaturedCategories = orderBy(
    foundationProductCategories.filter(
      (category) =>
        category.donationType == 'adopt_an_animal' && !category.featured
    ),
    ['featured', 'position'],
    ['desc', 'asc']
  )
  return (
    <div>
      <div className="title">
        <div className="title__banner-group">
          <h2 className="title__banner-group__zoo-parent">
            Be a Zoo Mama, Zoo Daddy or Zoo Parent
          </h2>
          <div className="title__banner-group__banner-image">
            <img src={bannerImage} alt="" />
          </div>
        </div>
        <div className="title__description-container">
          <p className="title__description-container__description">
            We think “adopting an animal” just doesn't do justice the impact
            your support actually provides. That's why we're giving you a better
            title — a Zoo Mama, Zoo Daddy or Zoo Parent. After all, your support
            is helping us provide the best care possible for our animals and to
            help fund wildlife conservation efforts here and all over the world.
            That's big — and that's important
          </p>
        </div>
      </div>
      <div className="all-animals">
        <div>
          {adoptAnAnimalFeaturedCategories.map((category) => (
            <div className="featured" key={category.id}>
              <img alt="" src={category.featuredImage.url} />
              <div className="featured__group">
                <div className="featured__group__banner">FEATURED ANIMAL</div>
                <div className="featured__group__product-name">
                  {category.name}
                </div>
                {!!category.featuredText && (
                  <div className="featured__group__description">
                    <p className="featured__group__description--text-block">
                      {category.featuredText}
                    </p>
                  </div>
                )}

                <Button
                  className="featured__button foundation-button-primary"
                  onClick={() =>
                    push(
                      `/give/packages?categoryId=${category.id}&cartToken=${foundationCart.token}`
                    )
                  }
                >
                  Adopt Me
                </Button>
              </div>
            </div>
          ))}
        </div>
        <div className="animal-container grid gap-10 auto-cols-fr auto-rows-fr grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
          {adoptAnAnimalNotFeaturedCategories.map((category) => (
            <Link
              to={`/give/packages?categoryId=${category.id}&cartToken=${foundationCart.token}`}
              key={category.id}
            >
              <div className="animal-container__tile">
                <img alt="" src={category.largeImage.url} />
              </div>
              <div className="animal-container__product-name">
                {category.name}
              </div>
              <div className="animal-container__product-description">
                <p className="animal-container__product-description--status">
                  <span>Status:</span> {category.animalStatus}
                </p>
                <p className="animal-container__product-description--location">
                  <span>Zoo Location:</span> {category.animalZooLocation}
                </p>
              </div>
              <Button
                className="animal-container__button foundation-button-primary"
                onClick={() =>
                  push(
                    `/give/packages?categoryId=${category.id}&cartToken=${foundationCart.token}`
                  )
                }
              >
                Adopt Me
              </Button>
            </Link>
          ))}
        </div>
      </div>
    </div>
  )
}

SelectAnimal.propTypes = propTypes
SelectAnimal.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    foundationProductCategories: selectors.foundationProductCategories(state),
    foundationCart: selectors.foundationCart(state),
  }
}

const mapDispatchToProps = {
  fetchFoundationProductCategories: apiActions.fetchFoundationProductCategories,
  push: routerActions.push,
  fetchOrCreateFoundationCart: apiActions.fetchOrCreateFoundationCart,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  SelectAnimal
)
