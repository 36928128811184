import React, { useState } from 'react'
import { RadioSelect } from './ui'
import displayCurrency from '../utils/displayCurrency'

const DEFAULT_LEVELS = [
  { label: '$5', value: 5 },
  { label: '$10', value: 10 },
  { label: '$25', value: 25 },
  { label: '$50', value: 50 },
  { label: '$100', value: 100 },
]

const DonationInput = ({ value, onChange, min, max, levels }) => {
  const [error, setError] = useState()

  function validate(value) {
    if (value > max)
      return `Donation amount cannot exceed ${displayCurrency(max)}`
    if (value < min)
      return `Donation amount must be at least ${displayCurrency(min)}`
  }

  return (
    <div>
      <RadioSelect
        label="Donation amount"
        name="level"
        value={value}
        options={levels && levels.length ? levels : DEFAULT_LEVELS}
        onChange={({ target: { value } }) => {
          setError(validate(value))
          onChange(value ? parseInt(value) : value)
        }}
        other={{ decorator: '$' }}
        inline
      />
      <div className="h-4 -mt-4 text-sm text-danger">{error}</div>
    </div>
  )
}

export default DonationInput
