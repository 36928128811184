import { requestWithKey, createRequest } from '@launchpadlab/lp-redux-api'
import * as LS from 'services/sessionStorage'
import { format } from 'date-fns'

export const REQ_MEMBERSHIP_TYPES = 'REQ_MEMBERSHIP_TYPES'
export const REQ_MEMBERSHIP_TYPE = 'REQ_MEMBERSHIP_TYPE'
export const REQ_ADD_ONS = 'REQ_ADD_ONS'
export const REQ_MEMBER_DETAILS = 'REQ_MEMBER_DETAILS'
export const REQ_CUSTOMER_DETAILS = 'REQ_CUSTOMER_DETAILS'
export const REQ_FNBO_DISCOUNTS = 'REQ_FNBO_DISCOUNTS'
export const REQ_TICKET_CART = 'REQ_TICKET_CART'
export const REQ_TICKET_ORDER = 'REQ_TICKET_ORDER'
export const REQ_TICKET_ACTIVITIES = 'REQ_TICKET_ACTIVITIES'
export const REQ_TICKET_TIMES = 'REQ_TICKET_TIMES'
export const REQ_TICKET_TIME = 'REQ_TICKET_TIME'
export const REQ_UNTIMED_PRICES = 'REQ_UNTIMED_PRICES'
export const REQ_TICKET_ADD_ON_CATEGORIES = 'REQ_TICKET_ADD_ON_CATEGORIES'
export const REQ_ADD_ON_PRICE = 'REQ_ADD_ON_PRICE'
export const REQ_SCHOOLS = 'REQ_SCHOOLS'
export const REQ_DONATION_FUNDS = 'REQ_DONATION_FUNDS'
export const REQ_DONATION_FUND = 'REQ_DONATION_FUND'
export const REQ_GIFTCARDS = 'REQ_GIFTCARDS'
export const REQ_GIFTCARD = 'REQ_GIFTCARD'
export const REQ_GIFT_CARD_CART = 'REQ_GIFT_CARD_CART'
export const REQ_QUESTIONNAIRE_ACTIVITIES = 'REQ_QUESTIONNAIRE_ACTIVITIES'
export const REQ_FOUNDATION_CART = 'REQ_FOUNDATION_CART'
export const REQ_FOUNDATION_PRODUCT_CATEGORIES =
  'REQ_FOUNDATION_PRODUCT_CATEGORIES'
export const REQ_FOUNDATION_ORDER = 'REQ_FOUNDATION_ORDER'
export const REQ_FOUNDATION_ACCOUNT_GIVING_ORDERS =
  'REQ_FOUNDATION_ACCOUNT_GIVING_ORDERS'
export const REQ_FOUNDATION_ACCOUNT_INFO = 'REQ_FOUNDATION_ACCOUNT_INFO'
export const REQ_FOUNDATION_UPDATE_ACOUNT_INFO =
  'REQ_FOUNDATION_UPDATE_ACOUNT_INFO'
export const REQ_MEMBER_MOBILE_WALLET = 'REQ_MEMBER_MOBILE_WALLET'
export const REQ_ADS = 'REQ_ADS'

// USER

export const fetchUserOrderHistory = createRequest(
  'REQ_USER_ORDER_HISTORY',
  () => ({
    url: '/transactions',
    token: LS.getAuthToken(),
    successDataPath: 'data.attributes',
  })
)

// MEMBERSHIP

export function fetchMembershipTypes() {
  return requestWithKey(REQ_MEMBERSHIP_TYPES, {
    url: '/membership_types',
    token: LS.getAuthToken(),
  })
}

export function fetchMembershipType(membershipTypeId) {
  return requestWithKey(REQ_MEMBERSHIP_TYPE, {
    url: `/membership_types/${membershipTypeId}`,
    token: LS.getAuthToken(),
  })
}

export function fetchAddOns(membershipTypeId) {
  return requestWithKey(REQ_ADD_ONS, {
    url: `/membership_types/${membershipTypeId}/add_ons`,
    token: LS.getAuthToken(),
  })
}

export function fetchMemberDetails(memberId) {
  return requestWithKey(REQ_MEMBER_DETAILS, {
    url: `/members/${memberId}`,
    token: LS.getAuthToken(),
  })
}

export function fetchMobileWallet() {
  return requestWithKey(REQ_MEMBER_MOBILE_WALLET, {
    url: `/members/mobile_wallet/`,
    token: LS.getAuthToken(),
  })
}

export function fetchCustomerDetails(customerID) {
  return requestWithKey(REQ_CUSTOMER_DETAILS, {
    url: `/user/customer_udfs?customer_id=${customerID}`,
    token: LS.getAuthToken(),
  })
}
export function fetchFnboDiscounts() {
  return requestWithKey(REQ_FNBO_DISCOUNTS, {
    url: `/fnbo_discounts`,
    token: LS.getAuthToken(),
  })
}

// TICKETING

// this function attempts to fetch existing cart
// based on a variable passed into func or locally stored cart token
// if no token or cart exists, server will create new cart and return it
export function fetchOrCreateTicketCart(cartToken = null) {
  const tokenForCart = cartToken || LS.getTicketCartToken()
  return requestWithKey(REQ_TICKET_CART, {
    url: `/ticketing/cart`,
    query: { cartToken: tokenForCart },
    token: LS.getAuthToken(),
  })
}

export function fetchTicketOrder(orderToken) {
  return requestWithKey(REQ_TICKET_ORDER, {
    url: `/ticketing/orders/${orderToken}`,
    token: LS.getAuthToken(),
  })
}

export function fetchTicketActivities() {
  return requestWithKey(REQ_TICKET_ACTIVITIES, {
    url: `/ticketing/activities`,
    token: LS.getAuthToken(),
  })
}

// Note: When endDate is not provided, the API will default to the time range
// defined in Centaman.
export function fetchTicketActivityTimes(
  id,
  { type = 'activity', startDate = Date(), endDate } = {}
) {
  return requestWithKey(REQ_TICKET_TIMES, {
    url: `/ticketing/activity_times`,
    query: {
      [`${type}_id`]: id,
      start_date: format(startDate, 'YYYY-MM-DD'),
      end_date: endDate ? format(endDate, 'YYYY-MM-DD') : undefined,
    },
    token: LS.getAuthToken(),
  })
}

export function fetchTicketActivityTime(
  ticketId,
  timedTicketCentamanId,
  startDate,
  cartToken = null
) {
  const tokenForCart = cartToken || LS.getTicketCartToken()
  return requestWithKey(REQ_TICKET_TIME, {
    url: `/ticketing/activities/${ticketId}/activity_times/${timedTicketCentamanId}`,
    query: {
      startDate: format(startDate, 'YYYY-MM-DD'),
      cartToken: tokenForCart,
    },
    token: LS.getAuthToken(),
  })
}

export function fetchUntimedPrices(addOnId, addOnType) {
  return requestWithKey(REQ_UNTIMED_PRICES, {
    url: `/ticketing/prices`,
    query: {
      productType: addOnType,
      productId: addOnId,
    },
    token: LS.getAuthToken(),
  })
}

export function fetchTicketAddOnCategories(ticketActivityId) {
  return requestWithKey(REQ_TICKET_ADD_ON_CATEGORIES, {
    url: `ticketing/activities/${ticketActivityId}/add_on_categories`,
    token: LS.getAuthToken(),
  })
}

export function fetchSchools() {
  return requestWithKey(REQ_SCHOOLS, {
    url: '/ticketing/schools',
    token: LS.getAuthToken(),
  })
}

// DONATION

export function fetchDonationFunds() {
  return requestWithKey(REQ_DONATION_FUNDS, {
    url: `/donations/donation_funds`,
    token: LS.getAuthToken(),
  })
}

export function fetchDonationFund(donationFundId) {
  return requestWithKey(REQ_DONATION_FUND, {
    url: `/donations/donation_funds/${donationFundId}`,
    token: LS.getAuthToken(),
  })
}

// GIFTCARD

export function fetchGiftCards() {
  return requestWithKey(REQ_GIFTCARDS, {
    url: `/gift_cards/gift_cards`,
    token: LS.getAuthToken(),
  })
}

export function fetchGiftCard(giftCardId) {
  return requestWithKey(REQ_GIFTCARD, {
    url: `/gift_cards/gift_cards/${giftCardId}`,
    token: LS.getAuthToken(),
  })
}

export function fetchGiftCardCart() {
  const tokenForCart = LS.getAuthToken() || LS.getCartToken()
  return requestWithKey(REQ_GIFT_CARD_CART, {
    url: `/gift_cards/cart`,
    token: tokenForCart,
  })
}

export const validateGiftCard = createRequest(
  'VALIDATE_GIFT_CARD',
  (giftCardNumber) => ({
    url: `/gift_cards/validate/${giftCardNumber}`,
    token: LS.getAuthToken(),
    successDataPath: 'data.attributes',
  })
)

// ADS

export function fetchAds() {
  return requestWithKey(REQ_ADS, {
    url: `/product_ads`,
    token: LS.getAuthToken(),
  })
}

// USER

export const validateIdMeSession = createRequest(
  'VALIDATE_ID_ME_SESSION',
  () => ({
    url: '/oauth/id_me/validate_session',
    token: LS.getAuthToken(),
  })
)

// QUESTIONNAIRE

export function fetchQuestionnaireActivities() {
  return requestWithKey(REQ_QUESTIONNAIRE_ACTIVITIES, {
    url: '/questionnaire/activities',
  })
}

// FOUNDATION

// this function attempts to fetch existing foundation cart
// based on a variable passed into func or locally stored cart token
// if no token or cart exists, server will create new cart and return it
export function fetchOrCreateFoundationCart(cartToken = null) {
  const tokenForCart = cartToken || LS.getFoundationCartToken()
  return requestWithKey(REQ_FOUNDATION_CART, {
    url: `/foundation/cart`,
    query: { cartToken: tokenForCart },
    token: LS.getAuthToken(),
  })
}

export function fetchFoundationProductCategories() {
  return requestWithKey(REQ_FOUNDATION_PRODUCT_CATEGORIES, {
    url: '/foundation/product_categories',
  })
}

export function fetchFoundationOrder(orderToken) {
  return requestWithKey(REQ_FOUNDATION_ORDER, {
    url: `/foundation/orders/${orderToken}`,
    token: LS.getAuthToken(),
  })
}

export function getFoundationAccountInfo() {
  return requestWithKey(REQ_FOUNDATION_ACCOUNT_INFO, {
    url: `/foundation/user/account_info`,
    token: LS.getFoundationAuthToken(),
  })
}

export const updateFoundationAccountInfo = (params) => {
  const mappedParams = { ...params, streetAddress1: params.address_1 }

  return requestWithKey(REQ_FOUNDATION_UPDATE_ACOUNT_INFO, {
    url: `/foundation/user/account_info`,
    method: 'PATCH',
    body: { user: mappedParams },
  })
}
