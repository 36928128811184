import React, { Fragment } from 'react'
import {
  Field,
  propTypes as formPropTypes,
  formValueSelector,
} from 'redux-form'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { lpForm } from '@launchpadlab/lp-form'
import PropTypes from 'prop-types'
import { scroller } from 'react-scroll'
import { Checkbox } from '@launchpadlab/lp-components'
import { FoundationInput } from 'components'
import { first } from 'lodash'
import { modifyProps } from '@launchpadlab/lp-hoc'
import { keys, getCountryOptions, setZipConstraints } from 'utils'
import * as options from 'main/options'
import { RadioSelect } from 'components/ui'
import { TRadioSelectOption } from '../../../../components/ui/RadioSelect'
import c from 'classnames'

const propTypes = {
  ...formPropTypes,
  designationOptions: PropTypes.arrayOf(TRadioSelectOption),
  memorial: PropTypes.bool,
  countryValue: PropTypes.string,
  letterShouldBeMailedValue: PropTypes.bool,
  recurringScheduleType: PropTypes.string,
}

const defaultProps = {
  deliveryMethodValue: null,
  foundationProductCategories: [],
  memorial: false,
  countryValue: 'US',
  letterShouldBeMailedValue: null,
}

const AMOUNT_OPTIONS = [
  { label: '$35', value: '35' },
  { label: '$50', value: '50' },
  { label: '$250', value: '250' },
  { label: '$500', value: '500' },
]

const RECURRING_SCHEDULE_OPTIONS = [
  { label: 'One time', value: 'one_time' },
  { label: 'Monthly', value: 'monthly' },
]

const TRIBUTE_TYPE_OPTIONS = [
  { label: 'In Memory of', value: 'in_memory_of' },
  { label: 'In Honor of', value: 'in_honor_of' },
]

function GeneralDonationForm({
  handleSubmit,
  memorial,
  countryValue,
  letterShouldBeMailedValue,
  designationOptions,
  recurringScheduleType,
}) {
  const isMonthlyDonation = recurringScheduleType === 'monthly'

  let today = new Date().toLocaleString('en-US', {
    timeZone: 'America/Chicago',
    day: 'numeric',
  })

  let suffix = { one: 'st', two: 'nd', few: 'rd', other: 'th' }[
    new Intl.PluralRules('en-US', { type: 'ordinal' }).select(today)
  ]

  return (
    <form className="space-y-5" onSubmit={handleSubmit}>
      <h1 className="mx-5 text-center md:text-left">Make a Donation</h1>
      <Field
        name="recurringScheduleType"
        component={({ input: { value, onChange } }) => (
          <>
            <RadioSelect
              label="Schedule type"
              name="recurring-schedule-type"
              value={value}
              onChange={(event) => onChange(event.target.value)}
              options={RECURRING_SCHEDULE_OPTIONS}
              labelSrOnly
              inline
              shrink
            />
            <div
              className={c('info-message', { invisible: !isMonthlyDonation })}
            >
              Thank you! You will be billed on the {today}
              {suffix} of each month
              {Number(today) >= 28 && (
                <>
                  <br />
                  If the month doesn't have {Number(today)} days, you will be
                  billed on the last day of the month.
                </>
              )}
            </div>
          </>
        )}
      />
      <Field
        name="donationAmount"
        normalize={false}
        component={({ input: { value, onChange }, meta }) => {
          const error =
            meta.submitFailed && meta.invalid ? meta.error[0] : undefined

          return (
            <RadioSelect
              label="Donation Amount"
              name="donation-amount"
              value={value.toString()}
              options={AMOUNT_OPTIONS}
              onChange={(event) => onChange(event.target.value)}
              other={{
                decorator: '$',
                error,
              }}
              inline
            />
          )
        }}
      />
      <hr></hr>
      {memorial ? (
        <>
          <Field
            name="tributeType"
            component={({ input: { value, onChange } }) => (
              <RadioSelect
                label="This Donation is:"
                name="recurring-schedule-type"
                value={value}
                onChange={(event) => onChange(event.target.value)}
                options={TRIBUTE_TYPE_OPTIONS}
                inline
              />
            )}
          />
          <Field
            name="inMemoryOfName"
            component={FoundationInput}
            label="Enter Name"
            placeholder="Enter Name"
          />
          <Field
            name="letterShouldBeMailed"
            component={Checkbox}
            label="Send notification of this gift to:"
          />
          {letterShouldBeMailedValue && (
            <Fragment>
              <div className="flex gap-5">
                <Field
                  name="recipientFirstName"
                  component={FoundationInput}
                  label="Recipient's First Name"
                  placeholder="Recipient's First Name"
                />
                <Field
                  name="recipientLastName"
                  component={FoundationInput}
                  label="Recipient's Last Name"
                  placeholder="Recipient's Last Name"
                />
              </div>

              <Field
                name="recipientCountry"
                component={FoundationInput}
                options={getCountryOptions()}
                label="Country"
                inputType="select"
                placeholder="Select"
              />
              <Field
                name="recipientAddress1"
                component={FoundationInput}
                label="Recipient's Address"
                placeholder="Recipient's Address"
              />
              <Field
                name="recipientAddress2"
                component={FoundationInput}
                label="Recipient's Address Line 2"
                placeholder="Recipient's Address Line 2"
              />
              <Field
                name="recipientCity"
                component={FoundationInput}
                label="City"
                placeholder="City"
              />
              <div className="flex gap-5">
                {countryValue == 'US' && (
                  <Field
                    name="recipientState"
                    component={FoundationInput}
                    options={options.states}
                    label="State"
                    inputType="select"
                    placeholder="Select State"
                  />
                )}
                <Field
                  name="recipientZip"
                  component={FoundationInput}
                  label="ZIP"
                  placeholder="ZIP"
                />
              </div>
            </Fragment>
          )}
        </>
      ) : (
        <Field
          name="foundationProductId"
          component={({ input: { value, onChange } }) => (
            <RadioSelect
              label="Designation"
              name="designation"
              value={value}
              onChange={(event) => onChange(event.target.value)}
              options={designationOptions}
            />
          )}
        />
      )}
    </form>
  )
}

const modify = ({ countryValue }) => {
  return {
    // reduxForm will only check for constraints on displayed fiels
    // so we can just have all of these constrains as "presence: true"
    // and they will only get picked up when the field is displayed
    constraints: {
      recurringScheduleType: { presence: true },
      donationAmount: {
        presence: true,
        numericality: { greaterThanOrEqualTo: 1 },
      },
      foundationProductId: { presence: true },
      tributeType: { presence: true },
      inMemoryOfName: { presence: true },
      recipientFirstName: {
        presence: true,
      },
      recipientLastName: {
        presence: true,
      },
      recipientAddress1: {
        presence: true,
      },
      recipientCity: {
        presence: true,
      },
      recipientState: {
        presence: true,
      },
      recipientZip: setZipConstraints(countryValue),
    },
  }
}

GeneralDonationForm.propTypes = propTypes
GeneralDonationForm.defaultProps = defaultProps

const generalDonationFormSelector = formValueSelector(
  'foundation-general-donation-form'
)

function mapStateToProps(state) {
  return {
    letterShouldBeMailedValue: generalDonationFormSelector(
      state,
      'letterShouldBeMailed'
    ),
    countryValue: generalDonationFormSelector(state, 'recipientCountry'),
    recurringScheduleType: generalDonationFormSelector(
      state,
      'recurringScheduleType'
    ),
  }
}

export default compose(
  connect(mapStateToProps, null),
  modifyProps(modify),
  lpForm({
    name: 'foundation-general-donation-form',
    onSubmitFail: (params, dispatch, _, { syncErrors }) => {
      const firstErrorField = first(keys(syncErrors))
      return scroller.scrollTo(firstErrorField, {
        smooth: true,
      })
    },
    enableReinitialize: true,
  })
)(GeneralDonationForm)
