import React from 'react'
import TermsCheckbox from './TermsCheckbox'

function TicketingTermsCheckbox({ ...props }) {
  return (
    <TermsCheckbox
      label="Terms and Conditions"
      id="termsAndConditions"
      {...props}
    >
      <h3>Website Terms &amp; Conditions</h3>
      <p>
        Your user name and password are for your personal use only. If you use
        the Site, you are responsible for maintaining the confidentiality of
        your account and password and for restricting access to your computer,
        and you agree to accept responsibility for all activities that occur
        under your account or password.
      </p>
      <p>
        We may, at any time: modify or discontinue all or any part of the Site;
        charge, modify or waive fees required to use the Site; or offer
        opportunities to some or all Site users.
      </p>

      <h3>Tickets</h3>

      <p>
        This ticket is a revocable license that may be cancelled at any time.
        This ticket has no cash value and will not be replaced or refunded if
        lost or stolen. Resale of this ticket is strictly prohibited and will
        result in cancellation of ticket. In the case of inclement weather, no
        refunds will be given. A distinct admission ticket is required for
        admission for anyone 3 years of age or older.
      </p>

      <h3>Consent to Photograph: Photo/Video Release Authorization</h3>

      <p>
        I give permission to Omaha’s Henry Doorly Zoo and Aquarium of 3701 S.
        10th St., Omaha, NE 68107 and/or parties designated by Omaha’s Henry
        Doorly Zoo and Aquarium to photograph me or my children and use such
        photographs in all forms of media, for any and all promotional purposes
        including advertising, publicity, display, audiovisual, exhibition,
        commercial or editorial use.
      </p>
      <p>
        I understand that the term “photograph” as used herein encompasses still
        photographs, audio recordings and motion picture footage.
      </p>
      <p>
        I further consent to the reproduction and/or authorization by Omaha’s
        Henry Doorly Zoo and Aquarium to reproduce and use such photographs for
        use in all domestic and foreign markets.
      </p>
      <p>
        I hereby release Omaha’s Henry Doorly Zoo and Aquarium and any of its
        associates, affiliates, appointed advertising agencies and designated
        directors, officers, agents, employees and customers from any claims.
      </p>
    </TermsCheckbox>
  )
}

export default TicketingTermsCheckbox
