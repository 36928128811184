import { configureApi, composeMiddleware } from '@launchpadlab/lp-requests'
import { middleware as configureMiddleware } from '@launchpadlab/lp-redux-api'
import * as LS from 'services/sessionStorage'

const API_HOST = process.env.REACT_APP_API_HOST
const API_PROTOCOL = process.env.REACT_APP_API_PROTOCOL
export const API_URL = `${API_PROTOCOL}://${API_HOST}/api/v1`

// always include member auth token (if it exists) for internal requests
function getMemberAuthToken(root) {
  if (root !== API_URL) return
  return LS.getAuthToken()
}

function addBearerToken({ root, token }) {
  const bearerToken = token || getMemberAuthToken(root)
  if (!bearerToken) return
  return {
    bearerToken,
  }
}

function addIdentityProfileToken() {
  const token = LS.getOAuthIdentityProfileToken()

  if (!token) return
  return {
    headers: {
      'X-Identity-Profile': token,
    },
  }
}

export const config = {
  root: API_URL,
  mode: 'cors',
  before: composeMiddleware(addBearerToken, addIdentityProfileToken),
}

export const middleware = configureMiddleware(config)
export const api = configureApi(config)
