import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import * as userActions from '../user/actions'
import { selectors as userSelectors } from '../user/reducer'
import { selectors as questionnaireSelectors } from './reducer'
import * as apiActions from 'main/apiActions'
import { findLargeGroupAndFieldTrip } from '../../utils'

const propTypes = {
  children: PropTypes.node.isRequired,
  memberId: PropTypes.number,
  fetchMemberDetails: PropTypes.func.isRequired,
  fetchQuestionnaireActivities: PropTypes.func.isRequired,
  activities: PropTypes.object,
}

function Layout({
  children,
  memberId = null,
  fetchMemberDetails,
  fetchQuestionnaireActivities,
  activities,
}) {
  const [pageReady, setPageReady] = useState(false)
  useEffect(() => {
    if (memberId) fetchMemberDetails(memberId)
    // these activities will be used to redirect user to the right activity
    fetchQuestionnaireActivities()
  }, [])

  useEffect(() => {
    const activitiesPresent = Object.values(activities).every(
      (element) => element
    )

    if (activitiesPresent) setPageReady(true)
  }, [activities])

  return pageReady && React.cloneElement(children, { activities })
}

Layout.propTypes = propTypes

function mapStateToProps(state) {
  const rawActivities = questionnaireSelectors.activities(state)

  return {
    memberId: userSelectors.memberId(state),
    isLoggedIn: userSelectors.isAuthenticated(state),
    activities: findLargeGroupAndFieldTrip(rawActivities),
  }
}

const mapDispatchToProps = {
  clearInfo: userActions.clearInfo,
  fetchMemberDetails: apiActions.fetchMemberDetails,
  fetchQuestionnaireActivities: apiActions.fetchQuestionnaireActivities,
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout)
