import React from 'react'
// import PropTypes from 'prop-types'
import { propTypes as formPropTypes } from 'redux-form'
import { compose } from 'redux'
import { lpForm } from '@launchpadlab/lp-form'
import { Input } from '@launchpadlab/lp-components'
import { NormalizedField as Field } from 'components'
import SocietyHeading from '../../../layout/SocietyHeading'

const propTypes = {
  ...formPropTypes,
}

const defaultProps = {}

function EditMembersForm({ handleSubmit }) {
  return (
    <form onSubmit={handleSubmit}>
      <SocietyHeading>Listed Members</SocietyHeading>
      <Field
        name="primaryMember"
        label="Primary Member - First and Last Name"
        component={Input}
      />
      <Field
        name="secondMember"
        label="Second Adult Member (at same household) - First and Last Name"
        component={Input}
        autoComplete="secondaryMember"
      />
    </form>
  )
}

EditMembersForm.propTypes = propTypes

EditMembersForm.defaultProps = defaultProps

export default compose(
  lpForm({
    name: 'membership-edit-members',
    constraints: {
      primaryMember: { presence: true },
    },
  })
)(EditMembersForm)
