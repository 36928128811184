import React from 'react'
import TermsCheckbox from './TermsCheckbox'

const DonationTermsCheckbox = (props) => {
  return (
    <TermsCheckbox
      label="Terms and Conditions"
      id="termsAndConditions"
      {...props}
    >
      <p>
        Your user name and password are for your personal use only. If you use
        the Site, you are responsible for maintaining the confidentiality of
        your account and password and for restricting access to your computer,
        and you agree to accept responsibility for all activities that occur
        under your account or password.
      </p>
      <p>
        We may, at any time: modify or discontinue all or any part of the Site;
        charge, modify or waive fees required to use the Site; or offer
        opportunities to some or all Site users.
      </p>
    </TermsCheckbox>
  )
}

export default DonationTermsCheckbox
