import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import * as routerActions from 'react-router-redux'
import { Spinner } from '@launchpadlab/lp-components'
import { displayCurrency, formatDate } from 'utils'
import SocietyCheckoutLayout from 'layout/SocietyCheckoutLayout'
import ButtonLink from 'components/ui/ButtonLink'
import MobileWalletButtons from 'components/MobileWalletButtons'
import * as apiActions from 'main/apiActions'

import { OrderReceipt } from '../components'

const propTypes = {
  fetchOrder: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  push: PropTypes.func.isRequired,
}

const defaultProps = {}

function DataList({ data }) {
  return (
    <dl className="space-y-9">
      {data.map(({ label, details }, i) => {
        return (
          details.length && (
            <div key={i}>
              <dt className="font-medium">{label}</dt>
              {details.map(
                (v, i) =>
                  v && (
                    <dd key={i} className="font-light mt-2">
                      {v}
                    </dd>
                  )
              )}
            </div>
          )
        )
      })}
    </dl>
  )
}

function Confirmation({ fetchOrder, location, push }) {
  const [order, setOrder] = useState(null)
  const redirectLink = '/ticketing'
  useEffect(() => {
    const orderToken = location.query.orderToken

    fetchOrder(orderToken)
      .then((resp) => setOrder(resp.data.attributes))
      .catch(() => push(redirectLink))
  }, [location, fetchOrder])

  const isFieldTripBooking =
    Boolean(order) && Boolean(order.cart) && Boolean(order.cart.schoolName)

  if (!order) return <Spinner />

  const introduction = (
    <div className="m-0 text-2xl md:mx-10 md:text-3xl">
      <p>
        Your order confirmation number is <strong>{order.receipt}</strong>
      </p>
      {order.hasCamps && (
        <p>
          A copy of your purchase along with our{' '}
          <a
            href={process.env.REACT_APP_DAY_CAMP_INFORMATIONAL_PACKET_URL}
            target="_blank"
            rel="noreferrer"
          >
            day camp information packet
          </a>{' '}
          has been sent to <strong>{order.email}</strong>.
        </p>
      )}
      {isFieldTripBooking && (
        <>
          Please download this{' '}
          <a
            href={process.env.REACT_APP_FIELD_TRIPS_INFORMATIONAL_PACKET_URL}
            target="_blank"
            rel="noreferrer"
          >
            informational packet
          </a>{' '}
          to answer important questions about school field trips.
        </>
      )}
    </div>
  )

  return (
    <SocietyCheckoutLayout
      title="Thank You!"
      introduction={introduction}
      className="md:max-w-4xl mx-auto"
    >
      <div className="flex flex-col space-y-28">
        <div className="flex flex-col align-center md:flex-row gap-28">
          <section className="md:w-7/12">
            <header className="text-2xl font-medium mb-10">
              Order Summary
            </header>
            <OrderReceipt order={order} ticketCart={order.cart} readOnly />
          </section>
          <section>
            <header className="text-2xl font-medium mb-10">
              Order Details
            </header>
            <DataList
              data={[
                {
                  label: 'Contact',
                  details: [order.customerName, order.email],
                },
                {
                  label: 'Payment Method',
                  details: [order.paymentMethod],
                },
                {
                  label: 'Order Date',
                  details: [formatDate(order.purchaseDate)],
                },
                {
                  label: 'Gift Card Redeemed',
                  details: [displayCurrency(order.totalGiftCard)],
                },
              ]}
            />
          </section>
        </div>

        <div className="flex flex-col justify-center text-center gap-5 print-buttons">
          <MobileWalletButtons iosLink={order.iosLink} androidLink={order.androidLink} className="justify-center"/>
          <div className="flex flex-col md:flex-row gap-5">
            {order.attachmentLink && (
              <ButtonLink
                label="Download Tickets"
                href={order.attachmentLink}
                primary
                lg
                target="_blank"
                rel="noopener noreferrer"
                className="flex-1 no-print"
              />
            )}
            <ButtonLink
              label="Print This Page"
              primary
              lg
              onClick={window.print}
              className="flex-1 no-print"
            />
          </div>
          <ButtonLink
            label="View Account"
            primaryOutline
            lg
            href="/my-account"
          />
        </div>
      </div>
    </SocietyCheckoutLayout>
  )
}

Confirmation.propTypes = propTypes
Confirmation.defaultProps = defaultProps

function mapStateToProps() {
  return {}
}

const mapDispatchToProps = {
  fetchOrder: apiActions.fetchTicketOrder,
  push: routerActions.push,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  Confirmation
)
