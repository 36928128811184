import React from 'react'
import { connect } from 'react-redux'
import { selectors } from '../reducer'
import PropTypes from 'prop-types'

TicketsRemaining.propTypes = {
  showTicketsRemaining: PropTypes.bool.isRequired,
  showTicketsLeftThresholdYellow: PropTypes.number.isRequired,
  showTicketsLeftThresholdGreen: PropTypes.number.isRequired,
  showTicketsLeftThresholdRed: PropTypes.number.isRequired,
  numTicketsLeftToSelect: PropTypes.number.isRequired,
  activityId: PropTypes.number.isRequired,
}

TicketsRemaining.defaultProps = {
  showTicketsRemaining: false,
  showTicketsLeftThresholdYellow: null,
  showTicketsLeftThresholdGreen: null,
  showTicketsLeftThresholdRed: null,
  numTicketsLeftToSelect: null,
  activityId: null,
}

function TicketsRemaining({
  showTicketsRemaining,
  showTicketsLeftThresholdYellow,
  showTicketsLeftThresholdGreen,
  showTicketsLeftThresholdRed,
  numTicketsLeftToSelect,
}) {
  if (!showTicketsRemaining) return null
  if (numTicketsLeftToSelect <= showTicketsLeftThresholdRed)
    return (
      <p className={`rounded px-2 py-1 font-bold bg-danger-50 text-danger-400`}>
        Only {numTicketsLeftToSelect} Tickets Left- Act Now!
      </p>
    )

  if (numTicketsLeftToSelect <= showTicketsLeftThresholdYellow)
    return (
      <p
        className={`rounded px-2 py-1 font-bold bg-tertiary-shade-50 text-tertiary-700`}
      >
        Only {numTicketsLeftToSelect} Tickets Left
      </p>
    )

  if (numTicketsLeftToSelect <= showTicketsLeftThresholdGreen)
    return (
      <p
        className={`rounded px-2 py-1 font-bold bg-primary-50 text-primary-700`}
      >
        {numTicketsLeftToSelect} Tickets Left!
      </p>
    )

  return null
}

function mapStateToProps(state, props) {
  const activityId = props?.activityId
  const activities = selectors.ticketActivities(state)
  if (!props || !activityId) {
    return {
      showTicketsRemaining: false,
    }
  }

  const activity = activities.find((ta) => ta.id === parseInt(activityId))
  return {
    showTicketsRemaining: activity.showTicketsRemaining,
    showTicketsLeftThresholdYellow: activity.showTicketsLeftThresholdYellow,
    showTicketsLeftThresholdGreen: activity.showTicketsLeftThresholdGreen,
    showTicketsLeftThresholdRed: activity.showTicketsLeftThresholdRed,
  }
}

export default connect(mapStateToProps)(TicketsRemaining)
