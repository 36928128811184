export const CHAPERONE_RATIO = 1 / 5
export const CHAPERONE_FLAG = 'chaperone'
export const FREE_CHAPERONE_FLAG = 'free_chaperone'

export default function calculateFreeChaperoneAmount(
  ticketCart,
  ticketTimes,
  currentTicketActivityTime
) {
  const initialCount = 0
  const sumItems = (prev, current) => prev + current
  const totalTicketsQuantity = getLineItemQuantities(
    ticketCart,
    ticketTimes,
    currentTicketActivityTime
  ).reduce(sumItems, initialCount)

  const freeChaperoneTicketAmount = Math.ceil(
    totalTicketsQuantity * CHAPERONE_RATIO
  )

  return freeChaperoneTicketAmount
}

function getLineItemQuantities(
  ticketCart,
  ticketTimes,
  { id: ticketActivityTimeId }
) {
  const currentLineItem = ticketCart.lineItems.find(
    ({ eventId }) => eventId === ticketActivityTimeId
  )

  if (!currentLineItem) return [0]

  const { prices } = ticketTimes.find(
    ({ id }) => id === currentLineItem.eventId
  )

  if (!prices.length) return [0]

  return currentLineItem.lineItemPrices.map(({ priceId, quantity }) => {
    const price = prices.find(({ id }) => id === priceId)

    // paid or free chaperones don't count for free chaperone calculation
    const shouldNotBeCounted = [CHAPERONE_FLAG, FREE_CHAPERONE_FLAG].includes(
      price.flag
    )
    return shouldNotBeCounted ? 0 : quantity
  })
}
