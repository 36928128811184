import React from 'react'
import ModalDialog from './ui/ModalDialog'
import { submit } from 'redux-form'
import { connect } from 'react-redux'
import DonationForm from './DonationForm'
import RenderedHtml from './RenderedHtml'

function DonationModal({
  onConfirm,
  header = 'Donate Today',
  levels,
  text = 'Would you like to further support conservation initiatives?',
  isOpen,
  onClose,
  dispatch,
  amount,
  min,
  max,
  cover,
}) {
  const formName = 'donation-modal-form'

  return (
    <ModalDialog
      isOpen={isOpen}
      onClose={onClose}
      title={header}
      content={
        <div className="md:overflow-y-scroll">
          <RenderedHtml>{text}</RenderedHtml>
          <DonationForm
            form={formName}
            levels={levels}
            onSubmit={onConfirm}
            onSubmitSuccess={onClose}
            hideSubmit
            initialValues={{ level: amount || 5 }}
            min={min}
            max={max}
          />
        </div>
      }
      confirmLabel="Donate"
      onConfirm={() => dispatch(submit('donation-modal-form'))}
      compact
      cover={cover}
    />
  )
}

export default connect()(DonationModal)
