import React from 'react'
import TicketAttendee from './TicketAttendee'
import { orderBy } from 'lodash'

const TicketAttendees = ({ ticketActivity, lineItem, lineItemPrices }) => {
  return (
    <ol className="space-y-3">
      {orderBy(lineItemPrices, 'createdAt', 'asc').map((lineItemPrice) => (
        <li key={lineItemPrice.id} className="mb-0">
          <TicketAttendee
            ticketActivity={ticketActivity}
            lineItemPrice={lineItemPrice}
            lineItem={lineItem}
          />
        </li>
      ))}
    </ol>
  )
}

export default TicketAttendees
