import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'main/types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { formValueSelector, initialize, Fields } from 'redux-form'
import * as actions from '../actions'
import { uniqBy } from 'lodash'
import { selectors } from '../reducer'
import { joinNames, setIf } from 'utils'
import { EditableMemberFieldsInput } from '../components'

const propTypes = {
  nonPrimaryMembers: PropTypes.arrayOf(Types.member),
  secondaryMember: PropTypes.object,
  secondaryMemberOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  setMember: PropTypes.func.isRequired,
}

const defaultProps = {
  nonPrimaryMembers: [],
  secondaryMember: {},
  selectedSecondaryMember: null,
}

function getSecondaryMemberOptions({
  secondaryMemberOptions,
  secondaryMember,
}) {
  const selectedOption = secondaryMemberOptions.find(
    (member) => member.value === secondaryMember.id
  )
  if (!selectedOption) return secondaryMemberOptions

  // If full name is available for the member based on initial values, prioritize that value
  const updatedSelectedOption = setIf(
    secondaryMember.firstName || secondaryMember.lastName,
    'key',
    joinNames(secondaryMember.firstName, secondaryMember.lastName)
  )(selectedOption)

  return uniqBy([updatedSelectedOption, ...secondaryMemberOptions], 'value')
}

function SecondaryMemberFormSection({
  nonPrimaryMembers,
  secondaryMember,
  secondaryMemberOptions,
  setMember,
}) {
  return (
    <Fields
      prefix="secondaryMember"
      legend="Second Adult Member (at same household)"
      names={[
        'secondaryMember.id',
        'secondaryMember.firstName',
        'secondaryMember.lastName',
        'secondaryMember.address.email'
      ]}
      component={EditableMemberFieldsInput}
      options={getSecondaryMemberOptions({
        secondaryMemberOptions,
        secondaryMember,
      })}
      members={nonPrimaryMembers}
      replaceMember={({ member }) => setMember(member)}
      saveMember={setMember}
      parse={(value, name) => {
        // Parse the id to a number to facilitate strict comparisons (i.e., ===)
        if (name === 'secondaryMember.id' && value) return Number(value)
        return value
      }}
    />
  )
}

SecondaryMemberFormSection.propTypes = propTypes
SecondaryMemberFormSection.defaultProps = defaultProps

function mapStateToProps(state) {
  const formName = selectors.formName(state)
  return {
    secondaryMember: formValueSelector(formName)(state, 'secondaryMember'),
  }
}

const mapDispatchToProps = {
  setMember: (secondaryMember) =>
    actions.setSelectedMembershipDetails({ secondaryMember }),
  resetInput: initialize,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  SecondaryMemberFormSection
)
