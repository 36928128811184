import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'main/types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { replace } from 'react-router-redux'
import { ButtonArea } from '@launchpadlab/lp-components'
import { capitalize, displayCurrency } from 'utils'
import { selectors } from '../reducer'
import { OrderReceipt } from '../components'
import * as actions from '../actions'
import SocietyCheckoutLayout from '../../../layout/SocietyCheckoutLayout'
import { compact } from 'lodash'

const propTypes = {
  donationForm: PropTypes.object.isRequired,
  order: Types.donationOrder.isRequired,
  selectedFund: Types.donationFund.isRequired,
  setGiftCard: PropTypes.func.isRequired,
  replace: PropTypes.func.isRequired,
}

const defaultProps = {}

function DonationConfirmation({
  donationForm,
  setGiftCard,
  order: {
    paymentMethod,
    email,
    orderNumber,
    totalPaid,
    donationTotal,
    totalGiftCard,
    purchaseDate,
  },
  selectedFund,
  replace,
}) {
  if (!selectedFund) {
    replace('/donation')
    return null
  }

  const details = compact([
    ['Receipt Number', orderNumber],
    ['Purchase Date', purchaseDate],
    ['Donation Amount', displayCurrency(donationTotal)],
    totalGiftCard && ['Gift Card Redeemed', displayCurrency(totalGiftCard)],
    ['Purchase Total', displayCurrency(totalPaid)],
    paymentMethod && ['Payment Method', capitalize(paymentMethod)],
    ['Email', email],
  ])

  return (
    <SocietyCheckoutLayout
      title="Thank You!"
      summary={
        <OrderReceipt
          amount={donationTotal}
          subtotal={donationTotal}
          selectedFund={selectedFund}
          donationForm={donationForm}
          setGiftCard={setGiftCard}
        />
      }
      reversed
    >
      <p>Your donation has been submitted successfully.</p>

      <div className="p-5 bg-white shadow no-print">
        <p>A copy of your purchase has been sent to the email provided.</p>
        <ul className="space-y-4">
          {details.map(([label, value], index) => (
            <li key={index} className="mb-0">
              <div>{label}</div>
              <strong>{value}</strong>
            </li>
          ))}
        </ul>
      </div>

      <ButtonArea className="print-buttons">
        <button onClick={window.print} className="button-primary no-print">
          Print This Page
        </button>
      </ButtonArea>
    </SocietyCheckoutLayout>
  )
}

DonationConfirmation.propTypes = propTypes
DonationConfirmation.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    order: selectors.order(state),
    selectedFund: selectors.selectedFund(state),
    donationForm: selectors.donationForm(state),
  }
}

const mapDispatchToProps = {
  replace,
  setGiftCard: actions.setGiftCard,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  DonationConfirmation
)
