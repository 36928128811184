import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import parrotImg from 'images/parrot.svg'
import frogImg from 'images/frog.svg'
import SocietyPageLayout from '../../../layout/SocietyPageLayout'
import Button from '../../../components/ui/Button'

const propTypes = {
  push: PropTypes.func.isRequired,
  activities: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
}

const QuestionnaireResult = ({ push, activities, route: { path } }) => {
  const largeGroupActivityId = activities.largeGroup.id

  const pageConfig = {
    qualified: {
      imgSrc: parrotImg,
      header: 'You qualify for a large group discount!',
      subHeader:
        'Click the button below to get started with your large group ordering',
      buttonText: 'Continue with large group tickets',
      pushUrl: `/ticketing/details?activityId=${largeGroupActivityId}`,
    },
    'not-qualified': {
      imgSrc: frogImg,
      header: "Sorry, you don't qualify for a large group discount!",
      subHeader:
        "While you don't qualify for a large group discount, you can still continue with general admission",
      buttonText: 'Continue to general admission',
      pushUrl: '/ticketing',
    },
  }
  const [imageLoaded, setImageLoaded] = useState(false)
  const { imgSrc, header, subHeader, buttonText, pushUrl } = pageConfig[path]

  return (
    <SocietyPageLayout>
      <div className="flex flex-col items-center justify-center h-full gap-5 text-center">
        <img
          src={imgSrc}
          onLoad={() => setImageLoaded(true)}
          className="w-24 h-24"
        />
        {/* Prevents text flickering */}
        {imageLoaded && (
          <>
            <div className="mb-10 space-y-3">
              <h1 className="text-5xl">{header}</h1>
              <p>{subHeader}</p>
            </div>
            <Button
              label={buttonText}
              primary
              lg
              onClick={() => push(pushUrl)}
            />
          </>
        )}
      </div>
    </SocietyPageLayout>
  )
}

QuestionnaireResult.propTypes = propTypes

const mapDispatchToProps = { push }

export default compose(connect(null, mapDispatchToProps))(QuestionnaireResult)
